import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workspaceName: null,
    workspaceId: null,
    ownerId: null,
    ownerUid: null,
    rootRoleId: null,
    employeeFields: [],
    customFields: [],
    roleFields: [],
    enabledModules: {},
    workspaceHasLoaded: false,
    configured: false,
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        SET_ACTIVE_WORKSPACE(state, action) {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value;
            });
            state.workspaceHasLoaded = true;
        },
        RESET_WORKSPACE: () => initialState,
    },
});

const { actions, reducer } = workspaceSlice;

export const { SET_ACTIVE_WORKSPACE, RESET_WORKSPACE } = actions;

export default reducer;
