import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { LoadingIndicator, RichTextEditor, TraitPicker } from "../../../components";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { setAction } from "../../../firebase/actions/action";
import { addActionToTraits, removeActionFromTraits } from "../../../firebase/actions/trait";
import { fetchAction } from "../../../firebase/fetch";
import ActionModel from "../../../models/ActionModel";
import { SET_SIDE_AREA } from "../../../redux/appSlice";

const EditActionArea = (props) => {
    // Fetch the action details
    const dispatch = useDispatch();
    const [prevAction, setPrevAction] = useState();
    const [editedAction, setEditedAction] = useState();
    const [disableSave, setDisableSave] = useState(true);
    const [prevLinkedTraitIds, setPrevLinkedTraitIds] = useState([]);
    const [linkedTraitIds, setLinkedTraitIds] = useState([]);
    const selectedActionId = useSelector((state) => state.app.selectedActionId);

    useEffect(() => {
        const noLinkChanges = isEqual(linkedTraitIds, prevLinkedTraitIds);
        const noActionChanges = isEqual(editedAction, prevAction);
        setDisableSave(noLinkChanges && noActionChanges);
    }, [editedAction, prevAction, linkedTraitIds, prevLinkedTraitIds]);

    useEffect(() => {
        async function fetchActionDoc() {
            const actionDoc = await fetchAction(selectedActionId);
            const roleModel = new ActionModel(actionDoc);
            const linkedTraitIds = roleModel.getLinkedTraitIds();
            setPrevLinkedTraitIds(linkedTraitIds);
            setLinkedTraitIds(linkedTraitIds);
            setPrevAction(actionDoc);
            setEditedAction(actionDoc);
        }
        fetchActionDoc();
    }, [selectedActionId]);

    if (!editedAction) return <LoadingIndicator />;

    const handlePrepChange = (field, change) => {
        const update = { ...editedAction, [field]: change };
        setEditedAction(update);
    };

    const handleStageLinkChanges = (changes) => {
        // Update the state (uncontrolled)
        setLinkedTraitIds(changes);
    };

    const handleSubmit = () => {
        setAction(editedAction).then(() => {
            // Remove actionId from removed traits
            const removed = prevLinkedTraitIds.filter((id) => !linkedTraitIds.includes(id));
            // Add actionId to added traits
            const added = linkedTraitIds.filter((id) => !prevLinkedTraitIds.includes(id));
            removeActionFromTraits(editedAction.id, removed);
            addActionToTraits(editedAction.id, added);
            toast.success("Edited Action");
        });
        dispatch(SET_SIDE_AREA());
    };

    return (
        <div className="side-area no-scroll h-100 d-flex flex-column align-items-stretch bg-transparent">
            <div className="f-1 pt-3 scrollable">
                <FormGroup>
                    <Label for="actionName">Action Name</Label>
                    <Input
                        type="text"
                        name="actionName"
                        maxLength={FIELD_SIZE_LIMITS.ACTION_NAME}
                        placeholder="Action Name"
                        onChange={(e) => handlePrepChange("actionName", e.target.value)}
                        value={editedAction.actionName || ""}
                        autoComplete="off"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="details">Details</Label>
                    <RichTextEditor
                        onChange={(rawContent) => handlePrepChange("details", rawContent)}
                        minHeight={240}
                        maxLength={800}
                        content={editedAction.details}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="actionName">Linked Traits</Label>
                    <TraitPicker
                        selectedIds={linkedTraitIds}
                        isMulti
                        onChange={(changes) => {
                            handleStageLinkChanges(changes);
                        }}
                    />
                </FormGroup>
            </div>
            <div className="pt-3 border-top">
                <Button className="float-end d-flex align-items-center" disabled={disableSave} onClick={handleSubmit}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default EditActionArea;
