import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { TalentAreaRadialChart } from "../../../components";
import { RichTextDisplay } from "../../../components/RichTextEditor/RichTextEditor";
import TraitWordCloud from "../../../components/WordClouds/TraitWordCloud";
import { fetchPaginatedAssessments } from "../../../firebase/fetch";
import { makeRatingsArray, prepEmployeeSnapshot } from "../../../utils/snapshotUtils";
import "../people.scss";
import { lookEmployee, lookThresholdDate } from "../../../redux/utils/looks";

const DATE_OPTIONS = { day: "numeric", month: "long", year: "numeric" };

const Footer = ({ onOlderClick, onNewerClick, disableNewer, disabledOlder }) => {
    return (
        <div className="pt-3 border-top">
            <Button className="float-start d-flex align-items-center" onClick={onOlderClick} disabled={disabledOlder}>
                <FaChevronLeft />
            </Button>
            <Button className="float-end d-flex align-items-center" disabled={disableNewer} onClick={onNewerClick}>
                <FaChevronRight />
            </Button>
        </div>
    );
};

const prepAssessment = (assDoc, selectedEmployeeId) => {
    if (assDoc) {
        const employee = lookEmployee(selectedEmployeeId);
        const thresholdDate = lookThresholdDate();
        let preppedSnapshot = prepEmployeeSnapshot(employee, thresholdDate, assDoc);
        preppedSnapshot.assessmentDate = assDoc.assessmentDate;
        preppedSnapshot.notes = assDoc.notes;
        preppedSnapshot.snapshotId = assDoc.snapshotId;
        return preppedSnapshot;
    }
};

const AssessmentHistoryArea = () => {
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const [allLoaded, setAllLoaded] = useState(false);
    const [lastVisible, setLastVisible] = useState();
    const [assessments, setAssessments] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Fetch the first document
    useEffect(() => {
        const fetchFirstDocument = async () => {
            const querySnapshot = await fetchPaginatedAssessments(selectedEmployeeId, 1);
            if (!querySnapshot.empty) {
                const thisDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                setLastVisible(thisDoc);
                if (thisDoc) {
                    const preppedAssessment = prepAssessment(thisDoc.data(), selectedEmployeeId);
                    setAssessments((prevAssessments) => [...prevAssessments, preppedAssessment]);
                    return preppedAssessment;
                }
            }
            setCurrentIndex(0);
            return null;
        };

        // Clear the data when the employee changes
        setAssessments([]);
        setLastVisible(null);
        setCurrentIndex(0);
        setAllLoaded(false);
        if (selectedEmployeeId) {
            fetchFirstDocument();
        }
    }, [selectedEmployeeId, setAssessments, setLastVisible, setCurrentIndex, setAllLoaded]);

    const fetchDocument = async (index) => {
        // Return the document if it's already in the array
        if (assessments[index]) {
            return assessments[index];
        }
        // Get the document from Firestore if it's not in the array
        const querySnapshot = await fetchPaginatedAssessments(selectedEmployeeId, 1, lastVisible);
        if (!querySnapshot.empty) {
            const thisDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastVisible(thisDoc);
            if (thisDoc) {
                const preppedAssessment = prepAssessment(thisDoc.data(), selectedEmployeeId);
                setAssessments((prevAssessments) => [...prevAssessments, preppedAssessment]);
                return preppedAssessment;
            } else {
                setAllLoaded(true);
            }
        } else {
            setAllLoaded(true);
        }
        return null;
    };

    const fetchNewerDocument = async () => {
        const newerIndex = currentIndex - 1;
        const documentData = await fetchDocument(newerIndex);
        if (documentData) {
            setCurrentIndex(newerIndex);
        }
    };

    const fetchOlderDocument = async () => {
        const olderIndex = currentIndex + 1;
        const documentData = await fetchDocument(olderIndex);
        if (documentData) {
            setCurrentIndex(olderIndex);
        }
    };

    const activeAssessment = assessments[currentIndex] || {};
    const talentAreasRatings = makeRatingsArray(activeAssessment.talentAreas);
    const ratings = makeRatingsArray(activeAssessment.ratings);
    const { overall } = activeAssessment;
    const assDate = activeAssessment?.assessmentDate?.toDate()?.toLocaleDateString(undefined, DATE_OPTIONS);

    return (
        <div className="side-area h-100 d-flex flex-column align-items-stretch bg-transparent">
            <div className="scrollable f-1 pt-3">
                <div className="d-flex flex-column h-100">
                    <h4 className="mb-1 text-center fs-small">{selectedEmployee.displayName}</h4>
                    <h4 className="mb-2 text-center fs-tiny">{assDate}</h4>
                    <div className="f-1">
                        <TalentAreaRadialChart
                            width={220}
                            pointer
                            ratings={talentAreasRatings}
                            overallRating={overall}
                        />
                    </div>
                    <div className="f-5">
                        {ratings && <TraitWordCloud ratings={ratings} maxSize={16} />}
                        <hr />
                        {activeAssessment.notes ? (
                            <RichTextDisplay content={activeAssessment.notes}></RichTextDisplay>
                        ) : (
                            <p className="ps-4">No notes were provided with this assessment.</p>
                        )}
                    </div>
                </div>
            </div>
            <Footer
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                onNewerClick={fetchNewerDocument}
                onOlderClick={fetchOlderDocument}
                disabledOlder={allLoaded && currentIndex === assessments.length - 1}
                disableNewer={currentIndex === 0}
            />
        </div>
    );
};

AssessmentHistoryArea.propTypes = {};

AssessmentHistoryArea.defaultProps = {};

export default AssessmentHistoryArea;
