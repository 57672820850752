import { lookEmployee, lookTraits } from "../redux/utils/looks";
import { cLog } from "../utils/basicUtils";

class ActionModel {
    constructor(action) {
        if (!action || typeof action === "string") {
            cLog("Action received invalid value:", typeof action);
        } else {
            this.id = action.id;
            this._action = action;
        }
    }

    getLinkedTraitIds(fullTrait) {
        const traits = lookTraits();
        const linkedTraits = Object.values(traits).filter((trait) => {
            return trait.actionIds && trait.actionIds.includes(this.id);
        });
        if (fullTrait) return linkedTraits;
        return linkedTraits.map((trait) => trait.id);
    }

    getScheduledEmployees() {
        const scheduledToComplete = this._action.scheduledEmployeeIds || [];
        return scheduledToComplete.map((employeeId) => {
            return lookEmployee(employeeId);
        });
    }

    getCompletedEmployees() {
        const completed = this._action.completedEmployeeIds || [];
        return completed.map((employeeId) => {
            return lookEmployee(employeeId);
        });
    }

    status() {
        const allTraits = lookTraits();
        const linkedTraits = Object.values(allTraits).filter((trait) => {
            return trait.actionIds && trait.actionIds.includes(this.id);
        });
        return linkedTraits.length > 0;
    }
}

export default ActionModel;
