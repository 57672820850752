import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { EMPLOYEE_SEARCH_SETTINGS } from "../../constants";
import { setToDefaultOrgTable } from "../../firebase/actions/user";
import { EmployeeModel } from "../../models";
import { selectEmployee } from "../../redux/utils/dispatches";
import { getChildEmployeeIds } from "../../utils/roleUtils";

const GlobalSearchField = ({ placeholder }) => {
    const navigate = useNavigate();
    const [employeeList, setEmployeeList] = useState([]);
    const allEmployees = useSelector((state) => state.org.employees);
    const allRoles = useSelector((state) => state.org.roles);
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const topLevelAccess = useSelector((state) => state.user.claims.topLevelAccess);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);

    useEffect(() => {
        let employeeIds;
        if (topLevelRoleId && !topLevelAccess) {
            employeeIds = getChildEmployeeIds(topLevelRoleId, true, allRoles);
        } else {
            employeeIds = Object.keys(allEmployees);
        }

        const newEmployeeList = employeeIds
            .map((empId) => {
                const employee = allEmployees[empId];
                const employeeModel = new EmployeeModel(employee);
                return employeeModel.getSearchObject();
            })
            .filter((item) => {
                const hasName = Object.keys(item).includes("name");
                const hasValue = Object.keys(item).includes("value");
                return hasName && hasValue;
            });
        setEmployeeList(newEmployeeList);
    }, [allRoles, allEmployees, topLevelRoleId, topLevelAccess]);

    function fuzzySearch(options) {
        const fuse = new Fuse(options, EMPLOYEE_SEARCH_SETTINGS);
        return (value) => {
            if (!value.length) {
                return [];
            }
            const filtered = fuse.search(value);
            const returnList = filtered.map((item) => {
                return { ...item.item };
            });
            return returnList;
        };
    }

    const handleSelectEmployee = (empId) => {
        const employee = allEmployees[empId];
        const roleId = employee && employee.roleId;
        if (!roleId) {
            // Ensure the all employees table is selected so the employee is visible
            setToDefaultOrgTable();
        }
        // Deselecting the employee first ensures the employee node gets expanded if the employee was already selected
        if (selectedEmployeeId === empId) {
            selectEmployee();
        }
        selectEmployee(empId);
        navigate(`/people`);
    };

    if (employeeList.length === 0) return null;
    return (
        <SelectSearch
            options={employeeList}
            filterOptions={fuzzySearch}
            onChange={handleSelectEmployee}
            search
            placeholder={placeholder}
        />
    );
};

export default GlobalSearchField;
