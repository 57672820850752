import React from "react";
import { useLanguage } from "../../translations/LanguageContext";
import { checkAccess } from "../../utils/userUtils";
import AppTour from "./AppTour";
import TourWelcome from "./TourWelcome";

const OrgChartTour = () => {
    const adminAccess = checkAccess("manageAssessmentRequests");
    const { t } = useLanguage();
    const language = t("org_chart_tour", "tours");
    let tourSteps = [
        {
            target: "body",
            placement: "center",
            content: <TourWelcome language={language} />,
        },
        {
            target: ".tour-rating-filter",
            placement: "auto",
            content: (
                <div className="ts-small">
                    <p>{language.step1}</p>
                    <ul className="text-start bullet-points">
                        <li>
                            <span className="fw-bold text-capitalize">{t("employee")}</span>
                            {` :${language.step1}`}
                        </li>
                        <li>
                            <span className="fw-bold text-capitalize">{t("branch")}</span>
                            {` :${language.step1b}`}
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            target: ".tour-rating-view",
            placement: "bottom",
            content: language.step2,
        },
        {
            target: ".tour-toggle-assessment",
            placement: "left",
            adminOnly: true,
            content: (
                <div className="ts-small">
                    <p>{language.step3a}</p>
                    <p>{language.step3b}</p>
                </div>
            ),
        },
        {
            target: ".tour-orgchart",
            placement: "auto",
            content: language.step4,
        },
        {
            target: ".tour-orgchart",
            placement: "auto",
            adminOnly: true,
            content: (
                <div className="ts-small">
                    <p>{language.step5a}</p>
                    <p>{language.step5b}</p>
                </div>
            ),
        },
        {
            target: ".tour-action-bar",
            placement: "auto",
            content: language.step6,
        },
        {
            target: ".tour-org-chart-admin-buttons",
            placement: "auto",
            adminOnly: true,
            content: language.step7,
        },
    ];

    let filteredSteps = tourSteps.filter((step) => {
        return !step.adminOnly || adminAccess;
    });
    return <AppTour tourId="orgchart" steps={filteredSteps} />;
};

export default OrgChartTour;
