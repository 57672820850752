import classNames from "classnames";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Button, Input, Label } from "reactstrap";
import { TalentAreaPicker, TalentBoardPicker, TraitPicker } from "../../../../../components";
import UncontrolledCollapseBlock from "../../../../../components/CollapseBlock/CollapseBlock";
import { confirmModal } from "../../../../../components/Dialogs/ConfirmAlert";
import { Help } from "../../../../../components/Help/Help";
import { DeleteIcon, SaveIcon, UndoIcon } from "../../../../../components/Icons/MyIcons";
import CustomFieldPicker from "../../../../../components/Pickers/IdPickers/CustomFieldPicker";
import IdPicker from "../../../../../components/Pickers/IdPickers/IdPicker";
import { DEFAULT_TABLE_ID, FIELD_SIZE_LIMITS, ORG_TABLE_COLUMNS } from "../../../../../constants";
import { saveLocalPreferences } from "../../../../../firebase/actions/user";
import { SET_SIDE_AREA, SET_UNSAVED_SIDE_AREA } from "../../../../../redux/appSlice";
import { setLocalPreferences } from "../../../../../redux/utils/dispatches";
import { getUniqueId } from "../../../../../utils/basicUtils";
import { getUpdatedWorkspaceTables } from "../../../../../utils/workspaceUtils";
import TableFilters from "./TableFilters";

const TABLE_ID = "org-table-settings";

const HelpSection = () => (
    <Help helpId={TABLE_ID}>
        <p className="mb-3">This area allows you to edit the selected Table.</p>
        <h6 className="mb-2">What you can do here:</h6>
        <ul className="bullet-points">
            <li>Select which columns will be included in the table</li>
            <li>Add filters to only included employees that match specific criteria</li>
        </ul>
    </Help>
);

const OrgTableSettings = ({ tableId, initialTable, showDelete }) => {
    const dispatch = useDispatch();
    const [nameInUse, setNameInUse] = useState(false);
    const [updatedTable, setUpdatedTable] = useState({});
    const [enableSave, setEnableSave] = useState(false);
    const [enableUndo, setEnableUndo] = useState(false);
    const orgTableConfig = useSelector((state) => state.org.orgTableConfig);
    const { tableName, standardColumns, assessmentFieldIds, traitIds, talentAreaIds, talentBoardIds, filters } =
        updatedTable;

    // Populate the current saved settings
    useEffect(() => {
        setUpdatedTable(initialTable);
    }, [initialTable]);

    // Disable close button if there are changes to be submitted
    useEffect(() => {
        dispatch(SET_UNSAVED_SIDE_AREA(!isEqual(initialTable, updatedTable)));
    }, [dispatch, initialTable, updatedTable]);

    // Enable action buttons only when the table has changed
    useEffect(() => {
        const {
            standardColumns = [],
            assessmentFieldIds = [],
            traitIds = [],
            talentAreaIds = [],
            talentBoardIds = [],
        } = updatedTable;
        const allColumns = [
            ...standardColumns,
            ...assessmentFieldIds,
            ...traitIds,
            ...talentAreaIds,
            ...talentBoardIds,
        ];
        const hasChanged = !isEqual(initialTable, updatedTable);
        const atLeastOneColumn = allColumns.length > 0;
        setEnableSave(hasChanged && !nameInUse && atLeastOneColumn);
        setEnableUndo(hasChanged);
    }, [initialTable, updatedTable, nameInUse]);

    const handleAddFilter = (filter) => {
        let newFilters = [...filters];
        newFilters.push(filter);
        setUpdatedTable({ ...updatedTable, filters: newFilters });
    };

    const handleDeleteFilter = (id) => {
        let newFilters = filters.filter((f) => f.filterId !== id);
        setUpdatedTable({ ...updatedTable, filters: newFilters });
    };

    const handleDiscard = () => {
        const onConfirm = () => setUpdatedTable(initialTable);

        // Confirm before discarding
        confirmModal("discard", onConfirm);
    };

    const handleDeleteTable = () => {
        const onConfirm = () => {
            const newTables = getUpdatedWorkspaceTables(tableId);
            const newConfig = {
                ...orgTableConfig,
                tables: newTables,
                activeOrgTableId: DEFAULT_TABLE_ID,
            };
            setLocalPreferences({ orgTableConfig: newConfig }, true);
            setLocalPreferences({ activeOrgTableId: DEFAULT_TABLE_ID });
            saveLocalPreferences();
            dispatch(SET_UNSAVED_SIDE_AREA(false));
            dispatch(SET_SIDE_AREA(false));
        };

        // Confirm before deleting
        confirmModal("delete", onConfirm, "table");
    };

    const handleUpdateSettings = (fieldId, value) => {
        setUpdatedTable({ ...updatedTable, [fieldId]: value });
    };

    const handleSave = () => {
        const newTables = getUpdatedWorkspaceTables(tableId, updatedTable);
        const newConfig = { ...orgTableConfig, tables: newTables };
        setLocalPreferences({ orgTableConfig: newConfig }, true);
        saveLocalPreferences();
        dispatch(SET_UNSAVED_SIDE_AREA(false));
        dispatch(SET_SIDE_AREA(false));
    };

    const renderColumnPicker = () => (
        <UncontrolledCollapseBlock title="Included Columns">
            <div className="children-mb-3">
                <IdPicker
                    options={ORG_TABLE_COLUMNS}
                    selectedIds={standardColumns}
                    label="Standard Fields"
                    onChange={(ids) => handleUpdateSettings("standardColumns", ids)}
                    isMulti
                />
                <CustomFieldPicker
                    selectedIds={assessmentFieldIds}
                    fieldCategory="assessment"
                    label="Assessment Fields"
                    onChange={(ids) => handleUpdateSettings("assessmentFieldIds", ids)}
                    isMulti
                    isClearable
                />
                <TraitPicker
                    selectedIds={traitIds}
                    label="Trait Ratings"
                    onChange={(ids) => handleUpdateSettings("traitIds", ids)}
                    isMulti
                    isClearable
                />
                <TalentAreaPicker
                    selectedIds={talentAreaIds}
                    label="Talent Area Ratings"
                    onChange={(ids) => handleUpdateSettings("talentAreaIds", ids)}
                    isMulti
                    isClearable
                />
                <TalentBoardPicker
                    selectedIds={talentBoardIds}
                    label="Talent Board Ratings"
                    onChange={(ids) => handleUpdateSettings("talentBoardIds", ids)}
                    isMulti
                    isClearable
                />
            </div>
        </UncontrolledCollapseBlock>
    );

    return (
        <div className="h-100 px-3 d-flex inner-container flex-column align-items-stretch justify-content-between bg-transparent">
            <div className="scrollable pb-8">
                <HelpSection />
                <Label>Table Name</Label>
                <Input
                    className="mb-3"
                    type="text"
                    name="tableName"
                    maxLength={FIELD_SIZE_LIMITS.TABLE_NAME}
                    placeholder="(required)"
                    onChange={(e) => {
                        handleUpdateSettings("tableName", e.target.value);
                    }}
                    onBlur={() => {
                        const currentTables = orgTableConfig.tables;
                        const nameInUse = Object.values(currentTables).find((table) => table.tableName === tableName);
                        setNameInUse(nameInUse);
                    }}
                    value={tableName || ""}
                    autoComplete="off"
                />
                <hr />
                {renderColumnPicker()}
                <hr />
                <TableFilters filters={filters} onDeleteFilter={handleDeleteFilter} onAddFilter={handleAddFilter} />
                <hr />
            </div>
            <div className={classNames("pt-3 border-top d-flex justify-content-end")}>
                <Button
                    data-for={TABLE_ID}
                    data-tip="Delete Table"
                    className={classNames("me-3", { hidden: !showDelete })}
                    onClick={handleDeleteTable}
                >
                    <DeleteIcon />
                </Button>
                <Button
                    data-for={TABLE_ID}
                    data-tip="Discard Changes"
                    disabled={!enableUndo}
                    className="me-3"
                    onClick={handleDiscard}
                >
                    <UndoIcon />
                </Button>
                <Button
                    data-for={TABLE_ID}
                    data-tip="Update Table"
                    disabled={!enableSave}
                    onClick={() => handleSave(false)}
                >
                    <SaveIcon />
                </Button>
            </div>
            <ReactTooltip id={TABLE_ID} delayShow={250} />
        </div>
    );
};

export const EditOrgTable = () => {
    const [initialTable, setInitialTable] = useState({});
    const orgTableConfig = useSelector((state) => state.org.orgTableConfig);
    const { activeOrgTableId, tables } = orgTableConfig;
    useEffect(() => {
        const activeOrgTable = tables[activeOrgTableId];
        setInitialTable(activeOrgTable);
    }, [activeOrgTableId, tables]);

    return <OrgTableSettings tableId={activeOrgTableId} initialTable={initialTable} showDelete />;
};

export const CreateOrgTable = () => {
    const [tableId, setTableId] = useState("");
    const [initialTable, setInitialTable] = useState({});

    useEffect(() => {
        setInitialTable({ standardColumns: [], filters: [], tableName: "" });
        setTableId(getUniqueId(5));
    }, []);

    return <OrgTableSettings tableId={tableId} initialTable={initialTable} />;
};
