import React from "react";
import IdPicker from "../IdPickers/IdPicker";
import "../picker.scss";

const TEMP_OPTIONS = [
    { label: "Plan Strength", id: "strength" },
    { label: "Percentage Female", id: "femaleRatio" },
    { label: "Percentage External", id: "externalRation" },
    { label: "Under 1 Year", id: "oneYear" },
    { label: "1-3 Years", id: "oneToThree" },
    { label: "Over 3 Years", id: "overThree" },
];

const SuccessionFieldPicker = ({ onChange, selected }) => {
    return (
        <div className="picker" style={{ minWidth: 260 }}>
            <IdPicker options={TEMP_OPTIONS} onChange={onChange} selectedIds={selected || "strength"} />
        </div>
    );
};

SuccessionFieldPicker.propTypes = {};

SuccessionFieldPicker.defaultProps = {};

export default SuccessionFieldPicker;
