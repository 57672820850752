import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ExpandButton } from "../../../../../components/Buttons/MyButtons";
import AssessmentIndicator from "../Components/AssessmentIndicator";
import "../org.scss";
import SuccessionIndicator from "../Components/SuccessionIndicator";

const NodeIndicator = ({ isSelected, activeRoleId, size, tooltipId, roleSnapshot, nodeCategory }) => {
    if (nodeCategory === "succession") {
        return <SuccessionIndicator roleId={activeRoleId} roleSnapshot={roleSnapshot} size={size} />;
    }
    return (
        <AssessmentIndicator isSelected={isSelected} activeRoleId={activeRoleId} size={size} tooltipId={tooltipId} />
    );
};

const OrgChartItem = (props) => {
    const { node, expanded, tooltipId } = props;
    const ref = useRef(null);
    const workspaceName = useSelector((state) => state.workspace.workspaceName);
    const thisRole = useSelector((state) => state.org.roles[node.roleId]);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const preferences = useSelector((state) => state.user.localPreferences);
    const incumbentId = thisRole && thisRole.incumbentId;
    const thisEmployee = useSelector((state) => state.org.employees[incumbentId]);
    const isSelected = node.roleId === selectedRoleId;
    const hasChildren = node.nodes && node.nodes.length > 0;
    const displayName = thisEmployee?.displayName ?? `${thisRole?.jobTitle} (Vacant)`;
    const { showJobTitle, nodeCategory } = preferences;

    // Scroll into view when selected
    useEffect(() => {
        if (isSelected && ref.current) {
            ref.current.focus();
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [isSelected]);

    const handleToggleExpand = (e) => {
        e.stopPropagation();
        props.toggleNodeExpanded(node.roleId);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        props.onSelectNode(node, incumbentId);
    };

    const handleDoubleClick = (e) => {
        e.stopPropagation();
        props.onViewRole(node, incumbentId);
    };

    const renderFooterButtons = () => {
        let buttons = [];
        if (hasChildren) {
            buttons.push(<ExpandButton key="expand-button" onClick={handleToggleExpand} checked={expanded} />);
        }
        return buttons;
    };

    if (!thisRole) return null;
    return (
        <div
            ref={ref}
            className={classNames(
                thisRole.rootRole && "root-item",
                `org-chart-item-expanded-${props.expanded}`,
                "org-chart-item clickable"
            )}
        >
            {!thisRole.rootRole ? (
                <div
                    className={classNames({ selected: isSelected }, "clickable org-chart-item-body")}
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                >
                    <div>
                        <NodeIndicator
                            nodeCategory={nodeCategory}
                            isSelected={isSelected}
                            activeRoleId={node.roleId}
                            size={40}
                            tooltipId={tooltipId}
                            showName={preferences.showName}
                        />
                        <div className={classNames({ "c-primary": isSelected })}>
                            {preferences.showName && <p className="fs-small mt-2 mb-1">{displayName || "Vacant"}</p>}
                            {showJobTitle && (
                                <p className="fs-small mt-2 mb-1">{thisRole.jobTitle || "No Job Title"}</p>
                            )}
                        </div>
                    </div>
                    <div className="org-chart-item-footer pt-0">{renderFooterButtons()}</div>
                </div>
            ) : (
                <div className="root-role">
                    <h6 className="mt-2">{workspaceName}</h6>
                </div>
            )}
        </div>
    );
};

OrgChartItem.propTypes = {};

OrgChartItem.defaultProps = {};

export default OrgChartItem;
