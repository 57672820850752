import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useDrag } from "react-dnd";
import TableSearch from "../Search/TableSearch";

/// TODO (maybe) Ensure any key present on the employees or roles is included in the searchKeys
/*
    const roleKeys = Object.keys(role);
    const employeeKeys = Object.keys(employee);
    const combined = roleKeys.concat(employeeKeys);
    setSearchKeys((prev) => {
        const newKeys = [...prev];
        combined.forEach((key) => {
            if (!newKeys.includes(key)) {
                newKeys.push(key);
            }
        });
        return newKeys;
    });
    */
/////////

const OptionCard = ({ item }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "CARD",
        item: { ...item, isNew: true },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div className={classNames("clickable p-3 border-bottom")} ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div className="card-content">
                <div className="card-title">{item?.title}</div>
            </div>
        </div>
    );
};

const SuccessionOptions = ({ options, excludeOptionIds }) => {
    const [filteredOptionIds, setFilteredOptionIds] = useState();

    const handleChange = (options) => {
        setFilteredOptionIds(options);
    };

    const visibleOptions = useMemo(() => {
        let newOptions = [...options];
        if (excludeOptionIds.length) {
            newOptions = newOptions.filter((option) => !excludeOptionIds.includes(option.key));
        }
        if (filteredOptionIds) {
            newOptions = newOptions.filter((option) => filteredOptionIds.includes(option.key));
        }
        return newOptions;
    }, [filteredOptionIds, excludeOptionIds, options]);

    return (
        <div className="board-options-container border-right">
            <div className="toolbar">
                <TableSearch
                    items={options}
                    displayKey="title"
                    searchKeys={["title", "jobTitle"]}
                    idKey="key"
                    placeholder="Search"
                    onChange={handleChange}
                />
            </div>
            <div className="board-options scrollable">
                {visibleOptions.map((option) => (
                    <OptionCard key={option.key} item={option} />
                ))}
            </div>
        </div>
    );
};

export default SuccessionOptions;
