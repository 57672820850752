import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingIndicator } from "../../../components";
import { confirmModal } from "../../../components/Dialogs/ConfirmAlert";
import { scheduleActions } from "../../../firebase/actions/action";
import { setSelfAssessment } from "../../../firebase/actions/assessments";
import { fetchLatestSelfAssessment } from "../../../firebase/fetch";
import { EmployeeModel } from "../../../models";
import { CLEAR_ASSESSMENT_PROGRESS, SAVE_ASSESSMENT_PROGRESS } from "../../../redux/assessmentsSlice";
import { selectEmployee } from "../../../redux/utils/dispatches";
import { lookEmployee, lookSavedAssessment, lookSnapshotId } from "../../../redux/utils/looks";
import AssessmentStepper from "../AssessmentStepper";
import { RatingStep } from "../RatingStep";
import SelfAssessmentSummary from "./SelfAssessmentSummary";

const SelfAssessment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [actionsToSchedule, setActionsToSchedule] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allTraitsToAssess, setAllTraitsToAssess] = useState([]);
    const [stepIndex, setStepIndex] = useState(0);
    const [unratedIds, setUnratedIds] = useState([]);
    const [idToOverwrite, setIdToOverwrite] = useState();
    const [hasChanged, setHasChanged] = useState(false);
    const [assessment, setAssessment] = useState({ ratings: {} });
    const [prevAssessment, setPrevAssessment] = useState({ ratings: {} });
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const userEmployee = useSelector((state) => state.org.self);
    const userEmployeeId = userEmployee?.id;
    const thisStep = allTraitsToAssess && allTraitsToAssess[stepIndex];
    const empModel = new EmployeeModel(userEmployee);
    const traitsToAssess = empModel.getTraitsToAssess();
    const { ratings, notes } = assessment;
    const currentSnapshotId = lookSnapshotId();

    // Select the employee
    useEffect(() => {
        selectEmployee(userEmployeeId);
    }, [userEmployeeId]);

    useEffect(() => {
        async function fetchData(traitsToAssess) {
            let assessmentValues = { ratings: {}, actionIds: [] };
            const doc = await fetchLatestSelfAssessment(userEmployeeId);
            if (doc) {
                const data = doc.data();
                const { ratings, notes } = data;

                // If this isn't a a new assessment period, include the notes and set to overwrite the old assessment doc
                if (data.snapshotId && data.snapshotId === currentSnapshotId) {
                    setIdToOverwrite(doc.id);
                    assessmentValues.notes = notes;
                }

                let traitRatings = {};
                if (ratings) {
                    traitsToAssess.forEach((trait) => {
                        if (ratings[trait.id]) {
                            traitRatings[trait.id] = ratings[trait.id];
                        }
                    });
                }
                assessmentValues.ratings = traitRatings;
            }
            setAssessment(assessmentValues);
            setPrevAssessment(assessmentValues);
        }

        async function prepareAndFetchData(traitsToAssess) {
            setIsLoading(true);
            await fetchData(traitsToAssess);
            setIsLoading(false);
        }

        const emp = lookEmployee(userEmployeeId);
        if (emp) {
            const empModel = new EmployeeModel(emp);
            const traitsToAssess = empModel.getTraitsToAssess();
            setAllTraitsToAssess(traitsToAssess);

            const savedAssessment = lookSavedAssessment(userEmployeeId);
            if (!savedAssessment) {
                prepareAndFetchData(traitsToAssess);
            } else {
                const { actionIds, ...assessment } = savedAssessment;
                setAssessment(assessment);
                setIsLoading(false);
            }
        }
    }, [dispatch, userEmployeeId, currentSnapshotId]);

    // Set the previous actions from the employee doc
    const prevActionIds = useMemo(() => {
        const { scheduledActionIds = [], completedActionIds = [] } = userEmployee;
        return [...scheduledActionIds, ...completedActionIds];
    }, [userEmployee]);

    // clean the assessment so it only has currently relevant traits
    useEffect(() => {
        // Include only applicable previous assessment data
        let traitRatings = {};
        if (ratings) {
            traitsToAssess.forEach((trait) => {
                if (ratings[trait.id]) {
                    traitRatings[trait.id] = ratings[trait.id];
                }
            });
        }
        setAssessment((prev) => {
            return { ...prev, ratings: ratings };
        });
    }, [traitsToAssess, ratings]);

    // Determine if any changes have been made
    useEffect(() => {
        const { ratings } = assessment;
        const ratingsHaveChanged = !isEqual(prevAssessment.ratings, ratings);
        const actionsHaveChanged = actionsToSchedule.length > 0;
        const changed = ratingsHaveChanged || actionsHaveChanged;
        if (changed) {
            dispatch(SAVE_ASSESSMENT_PROGRESS({ [userEmployeeId]: assessment }));
        } else {
            dispatch(CLEAR_ASSESSMENT_PROGRESS(userEmployeeId));
        }
        setHasChanged(changed);
    }, [dispatch, userEmployeeId, actionsToSchedule, assessment, prevAssessment]);

    // Determine if the assessment is complete and can be submitted
    useEffect(() => {
        const unratedIds = ratings && allTraitsToAssess.filter((trait) => !ratings[trait.id]).map((trait) => trait.id);
        setUnratedIds(unratedIds);
    }, [allTraitsToAssess, ratings]);

    if (!userEmployee) return <LoadingIndicator />;

    // Checks if there's a rating for every trait that needs rating
    const checkUnfininished = () => {
        const undefinedRatings = Object.values(ratings).filter((rating) => rating === undefined);
        const noRatings = allTraitsToAssess.filter((trait) => !Object.keys(ratings).includes(trait.id));
        return undefinedRatings.length + noRatings.length > 0;
    };

    const handleSetRating = (traitId, rating) => {
        const newRatings = { ...ratings, [traitId]: rating };
        const editedAssessment = { ...assessment, ratings: newRatings };
        setAssessment(editedAssessment);
    };

    const handleSetNotes = (newNotes) => {
        const editedAssessment = { ...assessment, notes: newNotes };
        setAssessment(editedAssessment);
    };

    const handleCheckAction = (actionId) => {
        const indexOf = actionsToSchedule.indexOf(actionId);
        let newActionIds = [...actionsToSchedule];
        if (indexOf >= 0) {
            newActionIds.splice(indexOf, 1);
        } else {
            newActionIds.push(actionId);
        }
        setActionsToSchedule(newActionIds);
    };

    const handleSubmit = async () => {
        const actionsHaveChanged = actionsToSchedule.length > 0;
        const ratingsHaveChanged = !isEqual(prevAssessment.ratings, ratings);
        const notesHaveChanged = prevAssessment.notes !== notes;
        const newSnapshot = prevAssessment?.snapshotId !== currentSnapshotId;
        if (ratingsHaveChanged || notesHaveChanged || newSnapshot) {
            let preppedAssessment = { ratings: ratings };
            if (notes) preppedAssessment.notes = notes;
            await setSelfAssessment(userEmployeeId, preppedAssessment, idToOverwrite)
                .commit()
                .catch((error) => {
                    toast.error(error.message);
                });
        }

        // Schedule any new actions
        if (actionsHaveChanged) {
            await scheduleActions(userEmployeeId, actionsToSchedule)
                .commit()
                .catch((error) => {
                    toast.error(error.message);
                });
        }

        // Clear Leave the page
        dispatch(CLEAR_ASSESSMENT_PROGRESS(userEmployeeId));
        navigate(`/people/${selectedRoleId}/employee`);
    };

    const handleChangeIndex = (idx) => {
        setStepIndex(idx);
    };

    const handleDiscardChanges = () => {
        const onConfirm = () => {
            dispatch(CLEAR_ASSESSMENT_PROGRESS(userEmployeeId));
            navigate(-1);
        };

        // Confirm before discarding
        confirmModal("discard", onConfirm, "assessment");
    };

    if (isLoading) return <LoadingIndicator />;

    return (
        <div key={userEmployeeId} className="d-flex inner-container flex-column f-1">
            <AssessmentStepper
                headerText="Self Assessment"
                onDiscardChanges={handleDiscardChanges}
                onSubmit={handleSubmit}
                onStepChange={handleChangeIndex}
                allowSkipToEnd={checkUnfininished()}
                employeeId={userEmployeeId}
                unratedIds={unratedIds}
                hasChanged={hasChanged}
            >
                {allTraitsToAssess.map((trait) => {
                    const isActive = thisStep && thisStep.id === trait.id;
                    const traitRating = trait && ratings[trait.id];
                    return (
                        <RatingStep
                            title={trait.traitName}
                            trait={trait}
                            id={trait.id}
                            active={isActive}
                            key={`rating-step-${trait.id}`}
                            traitRating={traitRating}
                            actionIds={actionsToSchedule}
                            prevActionIds={prevActionIds}
                            onCheckAction={handleCheckAction}
                            onSetRating={handleSetRating}
                        />
                    );
                })}
                <SelfAssessmentSummary
                    hideSidePanel
                    id="final"
                    title="Finish"
                    onCheckAction={handleCheckAction}
                    actionIds={actionsToSchedule}
                    prevActionIds={prevActionIds}
                    onSetNotes={handleSetNotes}
                    preppedAssessment={assessment}
                />
            </AssessmentStepper>
        </div>
    );
};

export default SelfAssessment;
