import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "react-vertical-timeline-component/style.min.css";
import { listenEmployee, listenEmployeeTimeline } from "../../../firebase/listeners";
import { sortArrayOfObjects } from "../../../utils/basicUtils";
import { checkAccess } from "../../../utils/userUtils";
import TimeLine from "../../../components/Timeline/Timeline";

const EmployeeTimelineArea = () => {
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [privateTimeline, setPrivateTimeline] = useState([]);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const privateAccess = checkAccess("privateEmployeeTimeline", selectedRoleId);

    useEffect(() => {
        const unsubscribeTimeline = privateAccess
            ? listenEmployeeTimeline(selectedEmployeeId, (events) => {
                  setPrivateTimeline(events || []);
              })
            : undefined;

        const unsubscribeEmployee = listenEmployee(selectedEmployeeId, (employee) => {
            setSelectedEmployee(employee);
        });

        return () => {
            unsubscribeEmployee();
            if (unsubscribeTimeline) {
                unsubscribeTimeline();
            }
        };
    }, [selectedEmployeeId, privateAccess]);

    const timeline = useMemo(() => {
        let { timeline = [] } = selectedEmployee;
        let mergedTimeline = [...privateTimeline, ...timeline];
        const sorted = sortArrayOfObjects(mergedTimeline, "eventDate", "desc");
        return sorted;
    }, [selectedEmployee, privateTimeline]);

    return (
        <div className="side-area no-scroll h-100 d-flex flex-column justify-content-between bg-transparent dashboard-headers">
            <div className="scrollable f-1">
                <TimeLine timeline={timeline} timelineId={selectedEmployeeId} />
            </div>
        </div>
    );
};

export default EmployeeTimelineArea;
