import classNames from "classnames";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { sortArrayOfObjects } from "../../../utils/basicUtils";
import "../picker.scss";

const IdPicker = (props) => {
    const { idField, isMulti, excludeIds, options, getLabel, unsorted } = props;
    const [preppedOptions, setPreppedOptions] = useState([]);
    const selectedIds = Array.isArray(props.selectedIds) ? props.selectedIds : [props.selectedIds];

    useEffect(() => {
        const optionsAreInArray = Array.isArray(options);
        let optionsArray = optionsAreInArray ? options : Object.values(options);
        let newOptions = optionsArray
            .map((option) => ({
                value: option[idField],
                label: getLabel(option),
            }))
            .filter((option) => !excludeIds.includes(option.value));
        if (!unsorted) {
            newOptions = sortArrayOfObjects(newOptions, "label");
        }
        setPreppedOptions(newOptions);
    }, [options, excludeIds, getLabel, idField, unsorted]);

    const deriveValueFromProps = () => {
        let selectTheseIds = selectedIds || props.defaultValue;
        return preppedOptions.filter((option) => {
            return selectTheseIds.includes(option.value);
        });
    };

    const handleChange = (selectedOptions) => {
        let returnValue;
        if (isMulti) {
            returnValue = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        } else {
            returnValue = selectedOptions && selectedOptions.value;
        }
        props.onChange(returnValue);
    };

    return (
        <div className="picker" style={{ minWidth: props.minWidth }}>
            {props.label && <h4 className="fs-small c-light mb-1">{props.label}</h4>}
            <Select
                name={props.name}
                isDisabled={props.disabled}
                className={classNames("picker", props.className)}
                classNamePrefix={classNames("picker", props.classNamePrefix)}
                maxMenuHeight={props.maxMenuHeight}
                menuIsOpen={props.menuIsOpen}
                type="select"
                isClearable={props.isClearable}
                isMulti={props.isMulti}
                placeholder={props.placeholder}
                onChange={handleChange}
                onBlur={props.onBlur}
                options={preppedOptions}
                value={deriveValueFromProps()}
            />
        </div>
    );
};

IdPicker.propTypes = {
    name: PropTypes.string,
    idField: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    getLabel: PropTypes.func,
    maxMenuHeight: PropTypes.number,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    unsorted: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    excludeIds: PropTypes.array,
    selectedIds: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

IdPicker.defaultProps = {
    options: {},
    idField: "id",
    selectedIds: [],
    defaultValue: [],
    maxMenuHeight: 180,
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
    getLabel: (option) => {
        return option.label;
    },
};

export default IdPicker;
