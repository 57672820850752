import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { ExpandButton, RemoveButton } from "../Buttons/MyButtons";
import { CheckBox } from "../Inputs/Inputs";

export const CollapseBlock = ({
    children,
    title,
    checked,
    type,
    onAction,
    className,
    onExpand = () => {},
    onCollapse = () => {},
    onToggle = () => {},
    shadedExpandArea,
    disabled,
    isOpen,
    smallHeader,
    usePlusIcon,
}) => {
    const handleToggleExpand = () => {
        if (isOpen) {
            onCollapse();
        } else {
            onExpand();
        }
        onToggle();
    };

    const renderHeader = () => {
        switch (type) {
            case "checkbox":
                return (
                    <div className="f-1 d-flex align-items-center">
                        <CheckBox disabled={disabled} type="checkbox" checked={checked} onClick={onAction} />
                        <span className={classNames("mb-0", { "fw-bold fs-large": !smallHeader })}>{title}</span>
                    </div>
                );
            case "remove":
                return (
                    <div className="f-1 d-flex align-items-center">
                        <RemoveButton disabled={disabled} className="me-2 outline" onClick={onAction} />
                        <span className={classNames("mb-0", { "fw-bold fs-large": !smallHeader })}>{title}</span>
                    </div>
                );
            case "header":
                return (
                    <div className="f-1">
                        <span className={classNames("mb-0", { "fw-bold fs-large": !smallHeader })}>{title}</span>
                    </div>
                );
            case "list":
                return (
                    <div className="f-1 d-flex align-items-center">
                        <span className={classNames("mb-0", { "fw-bold fs-large": !smallHeader })}>{title}</span>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div className={classNames(className)}>
            <div className="d-flex justify-content-between">
                {renderHeader()}
                <ExpandButton usePlusIcon={usePlusIcon} checked={isOpen} onClick={handleToggleExpand} />
            </div>
            <Collapse isOpen={isOpen}>
                <div
                    className={classNames("rounded py-2 mt-2", {
                        "bg-dark": shadedExpandArea,
                    })}
                >
                    {children}
                </div>
            </Collapse>
        </div>
    );
};

const UncontrolledCollapseBlock = (props) => {
    const { defaultOpen, onCollapse, onExpand } = props;
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CollapseBlock
            {...props}
            isOpen={isOpen}
            className={classNames({ [props.collapsedClassName]: !isOpen })}
            setIsOpen={setIsOpen}
            onCollapse={onCollapse}
            onExpand={onExpand}
            onToggle={handleToggle}
        />
    );
};

const propTypes = {
    defaultOpen: PropTypes.bool,
    usePlusIcon: PropTypes.bool,
    className: PropTypes.string,
    smallHeader: PropTypes.bool,
    collapsedClassName: PropTypes.string,
    type: PropTypes.oneOf(["checkbox", "remove", "header", "list"]),
    onAction: PropTypes.func,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
};

const defaultProps = {
    type: "header",
    collapsedClassName: "collapsed",
    onAction: () => {},
    onExpand: () => {},
    onCollapse: () => {},
};

UncontrolledCollapseBlock.propTypes = propTypes;
UncontrolledCollapseBlock.defaultProps = defaultProps;
CollapseBlock.propTypes = propTypes;
CollapseBlock.defaultProps = defaultProps;

export default UncontrolledCollapseBlock;
