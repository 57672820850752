import { deleteField, setDoc, writeBatch } from "firebase/firestore";
import { chunk } from "lodash";
import { MANAGEMENT_BOARD_ID } from "../../constants";
import { removeRole, setPendingOrgChanges } from "../../redux/utils/dispatches";
import { lookRole } from "../../redux/utils/looks";
import { removeUndefined } from "../../utils/basicUtils";
import { db } from "../firebase";
import { getEmployeeDocRef, getNewRoleOrgInfoDocId, getRoleDocRef } from "../firebaseUtils";

export function setRole(roleId, receivedData, batch = writeBatch(db)) {
    let { id, childRoles, importCount, ...preppedData } = receivedData;
    preppedData = removeUndefined(preppedData);

    // don't do anything if there are no values to update
    if (Object.keys(preppedData).length === 0) return batch;

    // Set to pending if any global fields are changing
    const currentData = lookRole(roleId);
    const dataAfterUpdate = { ...currentData, ...receivedData };
    if (currentData) setPendingOrgChanges(roleId, currentData, dataAfterUpdate);

    // Set the public infoDocId
    if (!preppedData.publicInfoDocId) {
        const currentRole = lookRole(roleId);
        const existingPublicInfoDocId = currentRole && currentRole.publicInfoDocId;
        preppedData.publicInfoDocId = existingPublicInfoDocId || getNewRoleOrgInfoDocId();
    }

    const prevBoards = currentData?.talentBoardIds ?? [];
    const newBoards = preppedData.talentBoardIds || [];

    // Prevent the management board being auto-assigned to this role again if it's removed
    if (prevBoards.includes(MANAGEMENT_BOARD_ID) && !newBoards.includes(MANAGEMENT_BOARD_ID)) {
        preppedData.noAutoAssignManagement = true;
    }

    // Update the employee doc doc
    const roleDocRef = getRoleDocRef(roleId);

    batch.set(roleDocRef, preppedData, { merge: true });
    return batch;
}

export function saveSuccessors(roleId, successors, batch = writeBatch(db)) {
    return setRole(roleId, { successors: successors }, batch);
}

export function addTalentBoardToRoles(roleIds, boardId, batch = writeBatch(db)) {
    roleIds.forEach((roleId) => {
        const role = lookRole(roleId);
        let prevBoards = role && role.talentBoardIds;
        let newBoards = prevBoards ? [...prevBoards] : [];
        newBoards.push(boardId);
        batch = setRole(roleId, { talentBoardIds: newBoards }, batch);
    });
    return batch;
}

export function removeTalentBoardFromRole(roleId, boardId, batch = writeBatch(db)) {
    const role = lookRole(roleId);
    const prevBoards = role && role.talentBoardIds;
    if (prevBoards) {
        const newBoards = prevBoards.filter((id) => id !== boardId);
        batch = setRole(roleId, { talentBoardIds: newBoards }, batch);
    }
    return batch;
}

export function removeTalentBoardFromRoles(roleIds, boardId) {
    // Break into batches of 400
    const batchArray = chunk(roleIds, 400).map((idBatch) => {
        let batch = writeBatch(db);
        idBatch.forEach((roleId) => {
            batch = removeTalentBoardFromRole(roleId, boardId, batch);
        });
        return batch;
    });
    return batchArray;
}

export function moveRole(roleId, newParentId, batch = writeBatch(db)) {
    batch = setRole(roleId, { parentRoleId: newParentId }, batch);
    return batch;
}

export async function deleteRole(roleId, batch = writeBatch(db)) {
    const thisRole = lookRole(roleId);
    if (thisRole) {
        const incumbentId = thisRole.incumbentId;
        const roleRef = getRoleDocRef(roleId);
        if (incumbentId) {
            const employeeRef = getEmployeeDocRef(incumbentId);
            batch = setDoc(employeeRef, { roleId: deleteField() }, { merge: true });
        }
        batch = setDoc(roleRef, { disabled: true }, { merge: true });
    }
    // Remove the role from the state immediately
    removeRole(roleId, thisRole.parentRoleId);
    return batch;
}
