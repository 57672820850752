import classNames from "classnames";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import PropTypes from "prop-types";

const ResponsiveContainer = ({ children, className, style, pX = 0, pY = 0}) => {
    const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    return (
        <div ref={ref} className={classNames(className, "responsive-container")} style={style}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, { style: { width: width - pX, height: height - pY } });
            })}
        </div>
    );
};

ResponsiveContainer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

ResponsiveContainer.defaultProps = {
    style: {},
    className: "flex-grow-1 overflow-auto",
};

export default ResponsiveContainer;
