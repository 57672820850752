import PropTypes from "prop-types";
import React from "react";
import { RadialBar, RadialBarChart, Tooltip } from "recharts";

const RadialChart = (props) => {
    const { data, size, disableTooltip } = props;
    return (
        <RadialBarChart
            cursor={props.cursor}
            innerRadius="10%"
            outerRadius="100%"
            barSize={15}
            data={data}
            height={size}
            width={size}
            className="m-auto"
        >
            <RadialBar minAngle={15} background clockWise dataKey="value" />
            {!disableTooltip && (
                <Tooltip wrapperStyle={{ border: "none", outline: "none" }} content={props.customTooltip} />
            )}
        </RadialBarChart>
    );
};

RadialChart.propTypes = {
    data: PropTypes.array,
    size: PropTypes.number,
    disableTooltip: PropTypes.bool,
    customTooltip: PropTypes.element,
};

RadialChart.defaultProps = {
    talentAreaRatings: [],
    size: 100,
};

export default RadialChart;
