import React from "react";
import { Card, Col } from "reactstrap";
import ChartContainer from "../../Charts/BaseCharts/ChartContainer";
import PercentageGaugeChart from "../../Charts/BaseCharts/PercentageGaugeChart";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";

const GaugeCard = ({ boardId, isPending, value, strokeColor, colors, label, onClick }) => {
    return (
        <Col md={3} sm={4}>
            <Card
                className="border-none clickable"
                onClick={() => {
                    if (value) {
                        onClick(boardId);
                    }
                }}
            >
                <div style={{ minHeight: 92 }}>
                    {isPending && <LoadingIndicator />}
                    {!isPending && (
                        <ChartContainer>
                            <PercentageGaugeChart value={value} strokeColor={strokeColor} colors={colors} />
                        </ChartContainer>
                    )}
                </div>
                <h6 className="mt-3 mb-2 fw-bold text-center fs-small">{label}</h6>
            </Card>
        </Col>
    );
};

export default GaugeCard;
