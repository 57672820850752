import { collection, deleteField, doc, writeBatch } from "firebase/firestore";
import { groupBy } from "lodash";
import store from "../redux/store";
import { getUniqueId, removeUndefined } from "../utils/basicUtils";
import { auth, db } from "./firebase";

export function convertTimeStamp(value) {
    // if value is a date convert it to a ISOstring
    if (value instanceof Date) {
        return value.toISOString();
    }
    // if value is a timestamp convert it to a ISOstring
    if (value && value.toDate) {
        return value.toDate().toISOString();
    }
    return value;
}

export function getAdminRequestRef() {
    return doc(collection(db, "adminRequests"));
}

export function getWorkspacesInfoDoc() {
    return doc(db, "metaData", "workspaceInfo");
}

/// Actions
export function getActionsCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "actions");
}

export function getActionDocRef(docId) {
    const actionsCollectionRef = getActionsCollectionRef();
    if (docId) {
        return doc(actionsCollectionRef, docId);
    } else {
        return doc(actionsCollectionRef);
    }
}

export function getBatch() {
    return writeBatch(db);
}

export function getLogsCollectionRef() {
    return collection(db, "logs");
}

export function getLogDocRef(docId) {
    const colRef = getLogsCollectionRef();
    if (docId) {
        return doc(colRef, docId);
    } else {
        return doc(colRef);
    }
}

////////////////////////////////////////////////////////

/// Scheduled actions
export function getScheduledActionsCollectionRef(actionId) {
    const actionDocRef = getActionDocRef(actionId);
    return collection(actionDocRef, "scheduledActions");
}

export function getScheduledActionDocRef(actionId, employeeId) {
    const scheduledActionsCollectionRef = getScheduledActionsCollectionRef(actionId);
    return doc(scheduledActionsCollectionRef, employeeId);
}

////////////////////////////////////////////////////////

export function getWorkspaceDocRef(workspaceId) {
    if (workspaceId) {
        return doc(db, "workspaces", workspaceId);
    } else {
        // Gets the active workspace if a specific one isn't specified
        let id = store.getState().user.activeWorkspaceId;
        return doc(db, "workspaces", id);
    }
}

export function getTalentAreasCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "talentAreas");
}

export function getTalentAreaDocRef(docId) {
    const talentAreasCollectionRef = getTalentAreasCollectionRef();
    if (docId) {
        return doc(talentAreasCollectionRef, docId);
    } else {
        return doc(talentAreasCollectionRef);
    }
}

export function getTalentBoardsCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "talentBoards");
}

export function getTalentBoardDocRef(docId) {
    const talentBoardsCollectionRef = getTalentBoardsCollectionRef();
    if (docId) {
        return doc(talentBoardsCollectionRef, docId);
    } else {
        const newId = getUniqueId();
        return doc(talentBoardsCollectionRef, newId);
    }
}

export function getTraitsCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "traits");
}

export function getTraitDocRef(docId) {
    const traitsCollectionRef = getTraitsCollectionRef();
    if (docId) {
        return doc(traitsCollectionRef, docId);
    } else {
        const newId = getUniqueId();
        return doc(traitsCollectionRef, newId);
    }
}

export function getUserDocRef(userId) {
    return doc(db, "users", userId);
}

export function getOrgInfoCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "orgInfo");
}

export function getTalentInfoCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "talentInfo");
}

export function getRolesCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "roles");
}

export function getRoleDocRef(roleId) {
    const rolesCollectionRef = getRolesCollectionRef();
    if (roleId) {
        return doc(rolesCollectionRef, roleId);
    } else {
        const newId = getUniqueId();
        return doc(rolesCollectionRef, newId);
    }
}

export function getEmployeesCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "employees");
}

export function getEmployeeDocRef(empId) {
    const employeesCollectionRef = getEmployeesCollectionRef();
    if (empId) {
        return doc(employeesCollectionRef, empId);
    } else {
        const newId = getUniqueId();
        return doc(employeesCollectionRef, newId);
    }
}

export function getEmployeeTimelineRef(empId) {
    const employeeDocRef = getEmployeeDocRef(empId);
    return doc(collection(employeeDocRef, "privateTimeline"), "timeline");
}

export function getEmployeeSnapshotsCollectionRef(roleId) {
    const roleDoc = getRoleDocRef(roleId);
    return collection(roleDoc, "employeeSnapshots");
}

export function getEmployeeSnapshotDocRef(roleId, docId) {
    const colRef = getEmployeeSnapshotsCollectionRef(roleId);
    return doc(colRef, docId);
}

export function getRoleSnapshotsCollectionRef(roleId) {
    const roleDoc = getRoleDocRef(roleId);
    return collection(roleDoc, "roleSnapshots");
}

export function getRoleSnapshotDocRef(roleId, docId) {
    const colRef = getRoleSnapshotsCollectionRef(roleId);
    return doc(colRef, docId);
}

export function getAssessmentDatesCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "assessmentDates");
}

export function getAssessmentDatesDocRef() {
    const assessmentDatesCollectionRef = getAssessmentDatesCollectionRef();
    return doc(assessmentDatesCollectionRef, "employeeAssessments");
}

export function getNotificationsCollectionRef(roleId) {
    const roleDocRef = getRoleDocRef(roleId);
    return collection(roleDocRef, "notifications");
}

export function getNotificationDocRef(roleId, docId) {
    const notificationCollectionRef = getNotificationsCollectionRef(roleId);
    if (docId) {
        return doc(notificationCollectionRef, docId);
    } else {
        return doc(notificationCollectionRef);
    }
}

export function getAssessmentsCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "employeeAssessments");
}

export function getAssessmentDocRef(docId) {
    const assessmentsCollectionRef = getAssessmentsCollectionRef();
    if (docId) {
        return doc(assessmentsCollectionRef, docId);
    } else {
        return doc(assessmentsCollectionRef);
    }
}

export function getSelfAssessmentCollectionRef() {
    const workspaceDocRef = getWorkspaceDocRef();
    return collection(workspaceDocRef, "selfAssessments");
}

export function getSelfAssessmentDocRef(docId) {
    const collectionRef = getSelfAssessmentCollectionRef();
    if (docId) {
        return doc(collectionRef, docId);
    } else {
        return doc(collectionRef);
    }
}

export function getSuccessionPlansCollectionRef(roleId) {
    const collectionRef = getRoleDocRef(roleId);
    return collection(collectionRef, "successionPlans");
}

export function getSuccessionPlanDocRef(roleId, employeeId) {
    const collectionRef = getSuccessionPlansCollectionRef(roleId);
    return doc(collectionRef, employeeId);
}

export function getInvitesCollectionRef() {
    return collection(db, "invites");
}

export function getInviteDocRef(email) {
    const invitesCollectionRef = getInvitesCollectionRef();
    return doc(invitesCollectionRef, email);
}

export function getLoggedInUserDocRef() {
    const currentUser = auth.currentUser;
    if (!currentUser) return null;
    return doc(db, "users", currentUser.uid);
}

export function getNewEmployeeOrgInfoId() {
    const employees = store.getState().org.employees;
    return getNewPublicInfoDocId(employees);
}

export function getNewRoleOrgInfoDocId() {
    const roles = store.getState().org.roles;
    return getNewPublicInfoDocId(roles);
}

function getNewPublicInfoDocId(collection) {
    // Defines the maximum number of entries there can be in each doc
    const docSizeLimit = 200;

    // Find a viable doc that has fewer entries than the size limit, if there is one
    const entriesToGroup = Object.values(collection);
    const docGroups = groupBy(entriesToGroup, "publicInfoDocId");
    const viableIds = Object.keys(docGroups).filter((docId) => {
        return docGroups[docId].length <= docSizeLimit;
    });

    // Return the first docId with space, or a new id if they're all full
    const id = viableIds[0] || getUniqueId();
    return id;
}

export function getNewTalentInfoDocId() {
    // Defines the maximum number of entries there can be in each doc
    const docSizeLimit = 200;

    // Find a viable doc that has fewer entries than the size limit, if there is one
    const { talentBoards, talentAreas, traits, actions } = store.getState().talent;
    const entriesToGroup = [
        ...Object.values(talentBoards),
        ...Object.values(talentAreas),
        ...Object.values(traits),
        ...Object.values(actions),
    ];

    // Return the first docId with space, or a new id if they're all full
    const docGroups = groupBy(entriesToGroup, "publicInfoDocId");
    let viableIds = Object.keys(docGroups).filter((docId) => {
        return docGroups[docId].length <= docSizeLimit;
    });

    viableIds = removeUndefined(viableIds);

    // Return the id of the first doc with space, or a new doc id if all current ones are full
    return viableIds[0] || getUniqueId();
}

// Do a deep iterate of an object and replace all null values or removed keys with deleteField()
export function deleteNullOrRemoved(dirty, prevVals = {}) {
    let clean = { ...dirty };

    // Set null values to deleteField()
    Object.keys(clean).forEach((key) => {
        if (clean[key] === null) {
            clean[key] = deleteField();
        } else if (typeof clean[key] === "object") {
            deleteNullOrRemoved(clean[key], prevVals[key]);
        }
    });

    // Remove deleted keys
    if (prevVals) {
        Object.keys(prevVals).forEach((key) => {
            if (prevVals[key] && !Object.keys(clean).includes(key)) {
                clean[key] = deleteField();
            }
        });
    }

    return clean;
}
