import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import ResponsiveContainer from "../../../../../components/ResponsiveContainer/ResponsiveContainer";
import EmployeesTable from "../../../../../components/Tables/EmployeesTable/EmployeesTable";
import { EMPLOYEE_SEARCH_SETTINGS } from "../../../../../constants";
import { EmployeeModel } from "../../../../../models";
import { selectRole } from "../../../../../redux/utils/dispatches";
import { lookEmployee } from "../../../../../redux/utils/looks";
import { getChildEmployeeIds } from "../../../../../utils/roleUtils";
import OrgToolbar from "../Components/OrgToolbar";

const TOOLTIP_ID = "orgTable";
const TABLE_ID = "orgTable";

const OrgTable = (props) => {
    const { tableView, setTableView, orgActions, filteredRoleIds } = props;
    const [filteredData, setFilteredData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [filteredEmployeeIds, setFilteredEmployeeIds] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const employees = useSelector((state) => state.org.employees);
    const orgTableConfig = useSelector((state) => state.org.orgTableConfig);
    const activeOrgTableId = orgTableConfig && orgTableConfig.activeOrgTableId;
    const activeOrgTable = orgTableConfig.tables[activeOrgTableId] || {};

    const snapshots = useSelector((state) => state.talent.snapshots);

    const { filters, standardColumns, traitIds, talentAreaIds, talentBoardIds, assessmentFieldIds } = activeOrgTable;

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    // Filter based on the on the orgTableConfig
    useEffect(() => {
        let newTableData = Object.values(employees);
        if (filters) {
            filters.forEach((filter) => {
                const { fieldName, condition, fieldId, category, filterValue } = filter;
                switch (fieldName) {
                    case "employeeStatus":
                        newTableData = newTableData.filter((employee) => {
                            const isTrue = !!employee[condition];
                            return isTrue === filterValue;
                        });
                        break;
                    case "ratings":
                    case "talentAreas":
                    case "talentBoards":
                        newTableData = newTableData.filter((employee) => {
                            const returnGreaterThan = condition === "isAbove";
                            const snapshot = snapshots[employee.id];
                            const collection = snapshot && snapshot[fieldName];
                            let meetsAllConditons = [];
                            if (collection && fieldId) {
                                meetsAllConditons = fieldId.filter((traitId) => {
                                    const rating = collection[traitId];
                                    const greaterThan = rating >= filterValue;
                                    return rating && greaterThan === returnGreaterThan;
                                });
                            }
                            return meetsAllConditons.length === fieldId.length;
                        });
                        break;
                    case "reportingLine":
                        const fullBranch = condition === "subordinateOf";
                        const manager = lookEmployee(filterValue);
                        const roleId = manager && manager.roleId;
                        const includeIds = getChildEmployeeIds(roleId, fullBranch);
                        newTableData = newTableData.filter((employee) => {
                            return includeIds.includes(employee.id);
                        });
                        break;
                    case "f3":
                    case "hasSuccessors":
                        newTableData = newTableData.filter((employee) => {
                            const snapshot = snapshots[employee.id];
                            const successors = snapshot && snapshot.f3;
                            if (fieldName === "f3") {
                                return successors && successors.includes(filterValue);
                            } else {
                                let hasSuccessors = successors && successors.length > 0;
                                hasSuccessors = hasSuccessors || false;
                                return hasSuccessors === filterValue;
                            }
                        });
                        break;
                    case "missingField":
                        newTableData = newTableData.filter((employee) => {
                            return !employee[filterValue];
                        });
                        break;
                    default:
                        newTableData = newTableData.filter((employee) => {
                            let lookIn = { ...employee };
                            if (category === "assessment") {
                                lookIn = snapshots[employee.id] || {};
                            }
                            return lookIn[fieldName] === filterValue;
                        });
                }
            });
        }
        setTableData(newTableData);
    }, [employees, filters, snapshots]);

    // Filter based on the filteredRoleIds provided in props (based on toplevelroleid)
    useEffect(() => {
        let filtered = Object.values(tableData)
            .filter((employee) => !filteredRoleIds || filteredRoleIds.includes(employee.roleId))
            .map((employee) => {
                const employeeModel = new EmployeeModel(employee);
                return employeeModel.getSearchObject();
            });
        setFilteredData(filtered);
    }, [tableData, filteredRoleIds]);

    const handleSetPageIndex = (newIndex, keepSelection) => {
        setPageIndex(newIndex);
        if (!keepSelection) {
            selectRole();
        }
    };

    // Filter based on table filter
    const handleSetFilteredEmployees = (e) => {
        handleSetPageIndex(0);
        const value = e.target.value;
        const fuse = new Fuse(tableData, EMPLOYEE_SEARCH_SETTINGS);
        if (!value.length) {
            setFilteredEmployeeIds(false);
        }
        const filtered = fuse.search(value);
        const filteredItems = filtered.map((item) => {
            return item.item;
        });
        const filteredIds = filteredItems.map((item) => item.id);
        setFilteredEmployeeIds(value.length > 0 ? filteredIds : false);
    };

    const handleIsDisabled = (employee) => {
        return !employee || !Object.keys(employee).includes("roleId");
    };

    return (
        <div className="inner-container">
            <OrgToolbar
                onSetFiltered={handleSetFilteredEmployees}
                tableId={TABLE_ID}
                orgActions={orgActions}
                tableView={tableView}
                onSetTableView={setTableView}
                tooltipId={TOOLTIP_ID}
            />
            <ResponsiveContainer>
                <EmployeesTable
                    className="org-table"
                    tableId={TABLE_ID}
                    data={filteredData}
                    traitIds={traitIds}
                    talentAreaIds={talentAreaIds}
                    talentBoardIds={talentBoardIds}
                    assessmentFieldIds={assessmentFieldIds}
                    filteredIds={filteredEmployeeIds}
                    columnKeys={standardColumns}
                    tooltipId={TOOLTIP_ID}
                    pageIndex={pageIndex}
                    orgActions={orgActions}
                    isDisabled={handleIsDisabled}
                    onSetPageIndex={handleSetPageIndex}
                    exportable
                    includeEdit
                    selectableRows
                    pagination
                    checkbox
                    removeBorder
                />
            </ResponsiveContainer>
            <ReactTooltip id={TOOLTIP_ID} place="top" delayShow={250} />
        </div>
    );
};

export default OrgTable;
