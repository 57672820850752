import React, { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import { checkAccess } from "../../utils/userUtils";
import { updateCustomGrid } from "./DeveloperActions/devUpdateCustomGrid";

// Delete the employeeAssessment collection first
const DeveloperSettings = () => {
    const navigate = useNavigate();
    const customFields = useSelector((state) => state.workspace.customFields);
    const topTalent = customFields.find((field) => field.id === "f4");
    const isDeveloper = checkAccess("developerSettings");

    useEffect(() => {
        if (!isDeveloper) {
            navigate("/settings/profile");
        }
    }, [navigate, isDeveloper]);

    const handleUpdateCustomGrid = () => {
        function onConfirm() {
            updateCustomGrid(customFields, topTalent);
        }
        const options = {
            title: "Set Custom Updates",
            message: "Are you sure to do this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: onConfirm,
                },
                {
                    label: "No",
                },
            ],
        };
        confirmAlert(options);
    };

    return (
        <div className="f-1">
            <h4>Developer Settings</h4>
            <hr />
            <p>Developer Tasks that require a full workspace load to complete</p>
            <hr />
            <div className="d-flex">
                <div className="f-3">
                    <p>Add new custom fields and update grid threshold</p>
                </div>
                <div className="f-1">
                    <Button onClick={handleUpdateCustomGrid}>Set Custom Updates</Button>
                </div>
            </div>
        </div>
    );
};

export default DeveloperSettings;
