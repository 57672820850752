import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_TABLE_ID } from "../constants";
import { cLog } from "../utils/basicUtils";
import { prepOrg, setRoleSnapshots } from "./utils/thunks";

const allEmployeesTable = {
    tableName: "All Employees (Read-only)",
    standardColumns: ["userBadge", "employeeNumber", "displayName", "email", "jobTitle", "managerName", "edit"],
    filters: [],
};
const allUsersTable = {
    tableName: "All Users (Read-only)",
    standardColumns: ["userBadge", "employeeNumber", "displayName", "email", "jobTitle", "managerName", "edit"],
    filters: [{ fieldName: "employeeStatus", condition: "isUser", filterId: "d-fi1", filterValue: true }],
};
const activeEmployeesTable = {
    tableName: "Active Employees (Read-only)",
    standardColumns: ["userBadge", "employeeNumber", "displayName", "email", "jobTitle", "managerName", "edit"],
    filters: [{ fieldName: "employeeStatus", condition: "roleId", filterId: "d-fi2", filterValue: true }],
};

const DEFAULT_TABLE_CONFIG = {
    activeOrgTableId: DEFAULT_TABLE_ID,
    tables: {
        [DEFAULT_TABLE_ID]: allEmployeesTable,
        allUsr: allUsersTable,
        actEmp: activeEmployeesTable,
    },
};

const initialState = {
    self: {},
    rawEmployees: {},
    rawRoles: {},
    employees: {},
    roles: {},
    roleSnapshots: {},
    roleSnapshotDataChanged: null,
    successionPlans: {},
    orgDataChanged: null,
    orgDataUpdating: false,
    roleSnapshotsUpdating: false,
    orgTableConfig: DEFAULT_TABLE_CONFIG,
    loaded: false,
    expandedNodes: {},
    pendingChanges: [],
};

const orgSlice = createSlice({
    name: "org",
    initialState,
    reducers: {
        SET_EMPLOYEES(state, action) {
            state.rawEmployees = action.payload;
            state.orgDataChanged = Date.now();
        },
        SET_EMPLOYEE(state, action) {
            const { empId, update } = action.payload;
            state.employees[empId] = { ...state.employees[empId], ...update };
        },
        SET_ROLES(state, action) {
            state.rawRoles = action.payload;
            state.orgDataChanged = Date.now();
        },
        REMOVE_ROLE(state, action) {
            const roleId = action.payload;
            const thisRole = state.roles[roleId];
            const parentRoleId = thisRole && thisRole.parentRoleId;
            const parentRole = state.roles[parentRoleId];
            const parentChildren = parentRole.childRoles || [];
            const newChildren = parentChildren.filter((id) => id !== roleId);
            if (parentRoleId) {
                state.roles[parentRoleId].childRoles = newChildren;
            }
            delete state.roles[roleId];
        },
        SET_PENDING_CHANGES(state, action) {
            state.pendingChanges.push(action.payload);
        },
        SET_COMPLETED_CHANGE(state, action) {
            state.pendingChanges = state.pendingChanges.filter((id) => id !== action.payload);
        },
        SET_ORG_TABLE_CONFIG(state, action) {
            const newConfig = action.payload;
            if (!newConfig || !newConfig.tables) {
                state.orgTableConfig = DEFAULT_TABLE_CONFIG;
            } else {
                state.orgTableConfig = newConfig;
            }
        },
        SET_ROLE_SNAPSHOTS(state, action) {
            state.roleSnapshots = action.payload || {};
            state.roleSnapshotDataChanged = Date.now();
        },
        SET_EXPANDED_NODES(state, action) {
            state.expandedNodes = action.payload;
        },
        EXPAND_NODE(state, action) {
            state.expandedNodes = { ...state.expandedNodes, [action.payload]: true };
        },
        SET_SELF(state, action) {
            state.self = action.payload;
        },
        RESET_ORG: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(prepOrg.pending, (state, action) => {
                state.orgDataUpdating = true;
            })
            .addCase(prepOrg.fulfilled, (state, action) => {
                const { newEmployees, newRoles } = action.payload;
                const roleIds = Object.keys(state.rawRoles);
                const employeeIds = Object.keys(state.rawEmployees);
                state.employees = newEmployees;
                state.roles = newRoles;
                state.pendingChanges = state.pendingChanges.filter(
                    (id) => !roleIds.includes(id) && !employeeIds.includes(id)
                );
                state.rawEmployees = {};
                state.rawRoles = {};
                state.orgDataUpdating = false;
                state.loaded = true;
            })
            .addCase(prepOrg.rejected, (state, action) => {
                state.error = action.error.message;
                state.orgDataUpdating = false;
                cLog(action.error.message, "ERROR MESSAGE");
            })
            .addCase(setRoleSnapshots.pending, (state, action) => {
                state.roleSnapshotsUpdating = true;
            })
            .addCase(setRoleSnapshots.fulfilled, (state, action) => {
                const { successionPlans } = action.payload;
                state.successionPlans = successionPlans || {};
                state.roleSnapshotsUpdating = false;
            })
            .addCase(setRoleSnapshots.rejected, (state, action) => {
                state.error = action.error.message;
                state.roleSnapshotsUpdating = false;
                cLog(action.error.message, "ERROR MESSAGE");
            });
    },
});

const { actions, reducer } = orgSlice;

export const {
    SET_EMPLOYEES,
    SET_EMPLOYEE,
    SET_ROLES,
    REMOVE_ROLE,
    SET_PENDING_CHANGES,
    SET_COMPLETED_CHANGE,
    RESET_ORG,
    SET_EXPANDED_NODES,
    EXPAND_NODE,
    SET_ROLE_SNAPSHOTS,
    SET_ORG_TABLE_CONFIG,
    SET_SELF,
} = actions;

export default reducer;

/*
        SET_EMPLOYEE_SNAPSHOTS: {
            // Test this - it should be a way to reformat dates before they go into the state
            reducer: (state, action) => {
                state.rawSnapshots = action.payload;
            },
            prepare: (firebaseSnapshots) => {
                return { payload: firebaseSnapshots };
            },
        },
        */
