import React from "react";
import { withFormik } from "formik";
import { Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";

const SubscriptionInnerForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    dirty,
}) => {
    return (
        <Form onSubmit={handleSubmit}>
            <div className="form-container">
                <FormGroup>
                    <Label for="firstname">Firstname</Label>
                    <Input
                        type="text"
                        name="firstname"
                        maxLength="80"
                        invalid={errors.firstname && touched.firstname}
                        placeholder="firstname.."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname || ""}
                        autoComplete="off"
                    />
                    <FormFeedback>{errors.firstname}</FormFeedback>
                </FormGroup>
            </div>
            <div className="form-footer">
                <Button className="float-right" type="submit" color="primary" disabled={!isValid || !dirty}>
                    Save
                </Button>
            </div>
        </Form>
    );
};

const SubscriptionSettings = withFormik({
    mapPropsToValues: (props) => {
        return {
            firstname: "Matt",
        };
    },
    enableReinitialize: true,
    validationSchema: (props) => {
        // lets you validate what's been added to the form
        const validationSchema = Yup.object().shape({
            firstname: Yup.string()
                .required("firstname name is required.")
                .max(80, "firstname can be a maximum of 80 characters."),
        });
        //props.onValidate(validationSchema.isValid())
        return validationSchema;
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(SubscriptionInnerForm);

export default SubscriptionSettings;
