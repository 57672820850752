import classNames from "classnames";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Nav, NavItem, NavLink } from "reactstrap";
import AppButton, {
    FilterButton,
    OrgWaitingButton,
    SettingsButton,
    TalentWaitingButton,
} from "../../../../../components/Buttons/MyButtons";
import { CompareIcon, CompleteAssessmentIcon, DownloadIcon, EditIcon } from "../../../../../components/Icons/MyIcons";
import { SET_SIDE_AREA } from "../../../../../redux/appSlice";
import { checkIfSelf } from "../../../../../utils/employeeUtils";
import { getFieldLabel } from "../../../../../utils/snapshotUtils";

const TOOLTIP_ID = "role-view";
const TOOLBAR_BUTTON_SIZE = 22;

const RoleViewToolbar = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hasChildren, activeTab, changeTab, tabs } = props;
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const talentMapConfig = useSelector((state) => state.talent.talentMapConfig);
    const enableSelfAssessment = useSelector((state) => state.assessments.settings.enableSelfAssessment);
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const filters = talentMapConfig.filters || [];
    const noRole = !selectedRoleId;
    const isSelf = checkIfSelf(selectedEmployeeId);
    const enabledModules = useSelector((state) => state.workspace.enabledModules);
    const { successionPlanning } = enabledModules;

    const activeTabs = useMemo(() => {
        let newTabs = [...tabs];
        if (!successionPlanning) {
            const successionIndex = newTabs.findIndex((tab) => tab.key === "succession-planning");
            newTabs.splice(successionIndex, 1);
        }
        return newTabs;
    }, [tabs, successionPlanning]);

    const handleEditAssessment = () => {
        if (selectedEmployeeId) navigate(`/people/assessments/${selectedEmployeeId}`);
    };

    const handleOpenTalentMapConfig = () => {
        dispatch(SET_SIDE_AREA("talentMapConfig"));
    };

    const handleEditRole = () => {
        dispatch(SET_SIDE_AREA("roleAdminArea"));
    };

    const handleCompare = () => {
        dispatch(SET_SIDE_AREA("compareRatings"));
    };

    const yAxisLabel = getFieldLabel(talentMapConfig.z) || "Empty";

    const renderTalentMapToolbar = () => (
        <div className="f-2 d-flex justify-content-end align-items-center px-3">
            <div className="me-3 fs-small fw-bold">{`Colour: ${yAxisLabel}`}</div>
            <AppButton className="me-3 outline" onClick={props.exportTalentMap}>
                <DownloadIcon size={TOOLBAR_BUTTON_SIZE} />
            </AppButton>
            <FilterButton
                className="me-3"
                onClick={handleOpenTalentMapConfig}
                size={TOOLBAR_BUTTON_SIZE}
                count={filters.length}
            />
            <SettingsButton
                className="me-3"
                size={TOOLBAR_BUTTON_SIZE}
                checked={false}
                onClick={handleOpenTalentMapConfig}
            />
        </div>
    );

    const renderStandardToolbar = () => (
        <div className="f-2 d-flex justify-content-end px-3">
            {enableSelfAssessment && (
                <AppButton
                    className="me-3 outline"
                    tooltipId={TOOLTIP_ID}
                    tooltip="Compare Ratings"
                    size={TOOLBAR_BUTTON_SIZE}
                    onClick={handleCompare}
                >
                    <CompareIcon size={TOOLBAR_BUTTON_SIZE} />
                </AppButton>
            )}

            <OrgWaitingButton
                className="me-3"
                tooltipId={TOOLTIP_ID}
                tooltip="Edit Employee"
                outline
                disabled={noRole}
                orgIds={[selectedEmployeeId, selectedRoleId]}
                onClick={handleEditRole}
                size={TOOLBAR_BUTTON_SIZE}
            >
                <EditIcon size={TOOLBAR_BUTTON_SIZE} />
            </OrgWaitingButton>
            {assessmentsOpen && (
                <TalentWaitingButton
                    outline
                    tooltipId={TOOLTIP_ID}
                    tooltip="Edit Assessment"
                    disabled={noRole || isSelf}
                    orgIds={[selectedEmployeeId]}
                    onClick={handleEditAssessment}
                    size={TOOLBAR_BUTTON_SIZE}
                >
                    <CompleteAssessmentIcon size={TOOLBAR_BUTTON_SIZE} />
                </TalentWaitingButton>
            )}
        </div>
    );

    return (
        <div className="d-flex justify-content-between control-section px-2">
            <Nav tabs className="f-3 d-flex fullpage-tabs px-3">
                {activeTabs.map((tab) => {
                    const { key, label } = tab;
                    let disabled = !hasChildren && key !== "employee";
                    disabled = disabled || (!selectedEmployee && key === "employee");
                    return (
                        <NavItem key={`tab-${key}`}>
                            <NavLink
                                className={classNames({
                                    active: (!activeTab && key === "employee") || activeTab === key,
                                    disabled: disabled,
                                })}
                                onClick={() => {
                                    changeTab(key);
                                }}
                                disabled={disabled}
                            >
                                {label}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            {props.activeTab === "talent-map" && renderTalentMapToolbar()}
            {props.activeTab !== "talent-map" && renderStandardToolbar()}
            <ReactTooltip id={TOOLTIP_ID} place="left" delayShow={500} />
        </div>
    );
};

export default RoleViewToolbar;