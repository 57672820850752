import classNames from "classnames";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Fade, NavItem, NavLink } from "reactstrap";
import { checkAccess } from "../../utils/userUtils";
import "./settings.scss";

const Settings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const activeTab = location.pathname.replace("/settings/", "");
    const isDeveloper = checkAccess("developerSettings");

    let tabs = [
        { key: "profile", label: "Profile" },
        { key: "workspace", label: "Workspace" },
    ];
    if (isDeveloper) {
        tabs.push({ key: "developer", label: "Developer" });
    } 

    return (
        <Fade id="settings-container" className="inner-container d-flex f-1 p-5">
            <div className="f-1 inner-container d-flex p-3 flex-row border-all rounded box-shadow bg-white">
                <div className="f-1 border-right">
                    {tabs.map((tab) => {
                        const { key, label} = tab
                        const enabled = checkAccess(`${key}Settings`);
                        return (
                            <NavItem key={`tab-${key}`}>
                                <NavLink
                                    className={classNames(
                                        {
                                            active: activeTab === key,
                                            disabled: !enabled,
                                            clickable: enabled,
                                        },
                                        "py-3 border-bottom"
                                    )}
                                    onClick={() => {
                                        navigate(key);
                                    }}
                                    disabled={!enabled}
                                >
                                    {label}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </div>
                <div className="d-flex ps-4 f-3 scrollable">
                    <Outlet />
                </div>
            </div>
        </Fade>
    );
};

export default Settings;
