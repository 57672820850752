import React from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./switch.scss";

const MySwitch = (props) => {
    const { onColor, offColor, vertical, className } = props;
    return (
        <div
            className={classNames(className, {
                "flex-column": vertical,
            })}
        >
            {props.label && (
                <div className="switch-label d-flex">
                    <h6 className="mb-0 fs-tiny fw-lighter align-items-center">{props.label}</h6>
                </div>
            )}
            <Switch
                offColor={offColor}
                onColor={onColor}
                onChange={props.onChange}
                checked={props.checked}
                disabled={props.disabled}
            />
        </div>
    );
};

MySwitch.propTypes = {
    invertColors: PropTypes.bool,
    label: PropTypes.string,
    shape: PropTypes.string,
    size: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
};

MySwitch.defaultProps = {
    onColor: "#3182CE",
    offColor: "#E5E5E5",
    className: "d-flex align-items-center justify-content-between",
    shape: "pill",
    size: "sm",
    neutral: false,
    checked: false,
    onChange: () => {},
};

export default MySwitch;
