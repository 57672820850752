import PropTypes from "prop-types";
import React from "react";
import { getInitials } from "../../utils/basicUtils";

const UserAvatar = (props) => {
    const { displayName, size } = props;
    const initials = getInitials(displayName);
    return (
        <div style={{ height: size, width: size }} className="avatar clickable">
            <span className="m-0">{initials}</span>
        </div>
    );
};

UserAvatar.propTypes = {
    displayName: PropTypes.string,
    disableTooltip: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
};

UserAvatar.defaultProps = {
    disableTooltip: false,
    size: 38,
    onClick: () => {},
};

export default UserAvatar;
