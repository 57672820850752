import React from "react";
import { MdPrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { Button } from "reactstrap";
import AppButton from "../Buttons/MyButtons";
import RadioButtons from "../Buttons/RadioButtons";
import TableSearch from "../Search/TableSearch";

const SuccessionToolbar = ({
    setFilteredRowIds,
    searchKeys,
    view,
    onChangeView,
    departmentView,
    disabled,
    unsavedChanges,
    onSave,
    onDiscard,
    rows,
    boardRef,
}) => {
    const handlePrint = useReactToPrint({
        content: () => boardRef.current,
    });

    return (
        <div className="d-flex f-1 justify-content-between">
            <div className="justify-end">
                <TableSearch
                    items={rows}
                    displayKey="title"
                    searchKeys={searchKeys}
                    idKey="key"
                    placeholder="Search any employee or role property"
                    onChange={setFilteredRowIds}
                    minWidth={320}
                />
            </div>
            <div className="d-flex justify-content-end">
                {unsavedChanges && (
                    <div className="d-flex">
                        <Button className="me-3 outline fs-tiny pt-1" disabled={disabled} onClick={onDiscard}>
                            Discard Changes
                        </Button>
                        <Button className="bg-danger c-white outline fs-tiny pt-1" disabled={disabled} onClick={onSave}>
                            Save Changes
                        </Button>
                    </div>
                )}
                {departmentView && (
                    <RadioButtons
                        disabled={unsavedChanges}
                        selected={view}
                        options={["team", "branch"]}
                        onChange={onChangeView}
                        outline
                    />
                )}
                <AppButton className="ms-3 outline" onClick={handlePrint}>
                    <MdPrint size={28} />
                </AppButton>
            </div>
        </div>
    );
};

export default SuccessionToolbar;
