import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

const EmptyState = (props) => {
    return (
        <div
            className={classNames(
                { "py-3": props.smallPadding, "py-5": !props.smallPadding && !props.noPadding },
                props.className,
                "d-flex flex-column align-items-center h-100 f-1 text-center"
            )}
        >
            {props.header && <span className="mb-2 fw-lighter">{props.header}</span>}
            {props.subHeader && <span className="fs-small text-faded px-5">{props.subHeader}</span>}
            <div className="d-flex">
                <div className="f-3"></div>
                <div className="f-1 p-3 text-faded">{props.children}</div>
                <div className="f-3"></div>
            </div>
        </div>
    );
};

EmptyState.propTypes = {
    header: PropTypes.string,
    subHeader: PropTypes.string,
    smallPadding: PropTypes.bool,
    noPadding: PropTypes.bool,
    className: PropTypes.string,
};

export default EmptyState;
