import { createContext, useContext, useState, useMemo } from 'react';
import enTranslations from './en.json';
import esTranslations from './es.json';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('en');

  const translations = useMemo(() => {
    switch (language) {
      case 'es':
        return esTranslations;
      default:
        return enTranslations;
    }
  }, [language]);

  function changeLanguage(newLanguage) {
    setLanguage(newLanguage);
  }

  function t(key, subKey = "basic") {
    return translations[subKey][key] || key;
  }

  const value = {
    language,
    changeLanguage,
    t,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}
