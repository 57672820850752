import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BaseTable, TalentAreaRadialChart } from "../..";
import { SELECT_TALENT_BOARD } from "../../../redux/appSlice";
import {
    filterSnapshotsByTalentBoard,
    getAverageTalentAreaRatings,
    getDivisionSnapshot,
    makeRatingsArray,
} from "../../../utils/snapshotUtils";
import { AssignedToTalentBoard, LinkedToTalentBoard } from "../../TalentBoards/TalentBoardSnippets";

const TalentBoardsTable = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { columnKeys, tooltipId, snapshots, onEditTalentBoard, iconSize } = props;
    const [fullTreeSnapshot, setFullTreeSnapshot] = useState({});
    const [includedColumns, setIncludedColumns] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const selectedTalentBoardId = useSelector((state) => state.app.selectedTalentBoardId);
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const roles = useSelector((state) => state.org.roles);
    const traits = useSelector((state) => state.talent.traits);

    useEffect(() => {
        const fullTreeSnapshot = getDivisionSnapshot(topLevelRoleId, snapshots, roles, traits);
        setFullTreeSnapshot(fullTreeSnapshot);
    }, [snapshots, topLevelRoleId, roles, traits]);

    useEffect(() => {
        const columns = [
            {
                key: "boardRatings",
                title: "",
                noSort: true,
                render: (label, talentBoard, index) => {
                    const overallRating = fullTreeSnapshot?.talentBoards?.[talentBoard.id]?.overallRating ?? 0;
                    const filteredSnapshots = filterSnapshotsByTalentBoard(snapshots, talentBoard.id);
                    const boardRatings = makeRatingsArray(getAverageTalentAreaRatings(filteredSnapshots));
                    return (
                        <TalentAreaRadialChart
                            width={36}
                            ratings={boardRatings}
                            overallRating={overallRating}
                            disableTooltip
                            height={iconSize}
                        />
                    );
                },
            },
            {
                key: "talentBoardName",
                title: "Board Name",
                dataIndex: "talentBoardName",
                width: "50%",
            },
            {
                key: "members",
                title: "Members",
                noSort: true,
                render: (label, talentBoard, index) => <AssignedToTalentBoard talentBoard={talentBoard} />,
            },
            {
                key: "traits",
                title: "Linked Traits",
                noSort: true,
                render: (label, talentBoard, index) => <LinkedToTalentBoard talentBoard={talentBoard} />,
            },
        ];
        const filteredColumns = columns.filter((column) => columnKeys.includes(column.key));
        setIncludedColumns(filteredColumns);
    }, [columnKeys, tooltipId, fullTreeSnapshot, snapshots, iconSize, onEditTalentBoard]);

    const handleRowClick = (talentBoardId) => {
        dispatch(SELECT_TALENT_BOARD(talentBoardId));
    };

    const handleRowDoubleClick = (talentBoardId) => {
        if (talentBoardId) {
            navigate(`/talentboards/${talentBoardId}`);
        }
    };

    return (
        <BaseTable
            {...props}
            className="talent-boards-table"
            columns={includedColumns}
            selectedId={selectedTalentBoardId}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            selectableRows
            sortKey="talentBoardName"
            checkbox={props.checkbox}
            pageIndex={pageIndex}
            onSetPageIndex={setPageIndex}
        />
    );
};

TalentBoardsTable.propTypes = {
    columnKeys: PropTypes.array,
    snapshots: PropTypes.object,
    disableRowClick: PropTypes.bool,
    disableRowDoubleClick: PropTypes.bool,
    checkbox: PropTypes.bool,
    iconSize: PropTypes.number,
    onEditTalentBoard: PropTypes.func,
};

TalentBoardsTable.defaultProps = {
    columnKeys: [],
    snapshots: {},
    iconSize: 42,
    onEditTalentBoard: () => {},
};

export default TalentBoardsTable;
