import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { SettingsButton } from "../components/Buttons/MyButtons";

const AppDropDown = ({
    className,
    title,
    caret,
    settingsToggle,
    children,
    disabled,
    minWidth = 240,
    disableCloseOnClick,
}) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    const renderToggle = () => {
        if (settingsToggle) {
            return (
                <DropdownToggle nav className="p-0">
                    <SettingsButton checked={isOpen} onClick={() => setIsOpen(!isOpen)} />
                </DropdownToggle>
            );
        } else {
            return (
                <DropdownToggle caret={caret} className="basic" onClick={() => setIsOpen(!isOpen)}>
                    <span className="px-2">{title}</span>
                </DropdownToggle>
            );
        }
    };

    return (
        <div ref={ref} className={classNames(className)}>
            <UncontrolledDropdown disabled={disabled} isOpen={isOpen} group>
                <UncontrolledDropdown disabled={disabled} isOpen={isOpen} group>
                    {renderToggle()}
                    <DropdownMenu end className="dropdown-menu" style={{ margin: 0, minWidth: minWidth }}>
                        {React.Children.map(children, (child) => {
                            const onClick = () => {
                                child.props.onClick();
                                !disableCloseOnClick && setIsOpen(false);
                            };
                            return React.cloneElement(child, {
                                onClick: onClick,
                            });
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </UncontrolledDropdown>
        </div>
    );
};

export default AppDropDown;
