import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { Fade } from "reactstrap";
import { LoadingIndicator } from "../../components";
import { SELECT_EMPLOYEE } from "../../redux/appSlice";
import { selectRole } from "../../redux/utils/dispatches";
import "./people.scss";

const People = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const activeRoleId = params.roleId;
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const incumbentId = selectedRole && selectedRole.incumbentId;

    useEffect(() => {
        setLoading(false);
    }, [topLevelRoleId]);

    // If the role incumbent changes, reselect the employee
    useEffect(() => {
        selectRole(selectedRoleId);
    }, [selectedRoleId]);

    // If the role incumbent changes, reselect the employee
    useEffect(() => {
        dispatch(SELECT_EMPLOYEE(incumbentId));
    }, [dispatch, incumbentId]);

    if (loading) return <LoadingIndicator message="Building your Org Chart" />;

    return (
        <Fade className="p-0 inner-container">
            <Outlet context={activeRoleId} />
        </Fade>
    );
};

People.propTypes = {
    selectedRole: PropTypes.object,
};

export default People;
