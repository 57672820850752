import { PropTypes } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import IdPicker from "./IdPicker";

const TalentBoardPicker = (props) => {
    const { selectedIds, excludeIds, isMulti, isClearable } = props;
    const talentBoards = useSelector((state) => state.talent.talentBoards);

    const getLabel = (talentBoard) => {
        return talentBoard.talentBoardName;
    };

    return (
        <IdPicker
            {...props}
            minWidth={260}
            name="talentBoards"
            getLabel={getLabel}
            options={talentBoards}
            selectedIds={selectedIds}
            excludeIds={excludeIds}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

TalentBoardPicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

TalentBoardPicker.defaultProps = {
    selectedTalentBoardIds: [],
    excludeIds: [],
    isMulti: true,
    isClearable: true,
    onBlur: () => {},
    onChange: () => {},
};

export default TalentBoardPicker;
