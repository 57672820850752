import { lookRole, lookTrait } from "../redux/utils/looks";
import { cLog, getUniqueId } from "../utils/basicUtils";
import { getChildRoleIds, getRoleIncumbent, getRoleParentRole } from "../utils/roleUtils";
import { getTraitIdsForAssessment, prepAssessmentSteps } from "../utils/talentUtils";
import { checkAccess } from "../utils/userUtils";

class EmployeeModel {
    constructor(employee, calledBy) {
        if (!employee || typeof employee === "string") {
            cLog(calledBy, typeof employee);
        } else {
            this.id = employee.id;
            this._employee = employee;
        }
    }

    getDisplayName() {
        return this._employee.displayName;
    }

    getJobTitle() {
        const role = lookRole(this._employee.roleId);
        return role && role.jobTitle;
    }

    getDepartment() {
        const role = lookRole(this._employee.roleId);
        return role && role.departmentName;
    }

    getSearchObject() {
        if (!this._employee) {
            // WORKAROUND
            // When a new child role is created, the parent will briefly reference an employeeId that doesn't exist
            // This is ugly, but prevents that error
            const tempId = getUniqueId();
            return { name: "", value: tempId };
        }
        const nameLabel = this._employee.displayName;
        const jobTitle = this.getJobTitle();
        const department = this.getDepartment();
        const manager = this.getManager();
        const managerName = manager && manager.displayName;
        let searchObject = {
            name: nameLabel,
            displayName: nameLabel,
            value: this.id,
            jobTitle: jobTitle,
            department: department,
            managerName: managerName,
            ...this._employee,
        };

        // Remove any undefined values from searchObject
        Object.keys(searchObject).forEach((key) => searchObject[key] === undefined && delete searchObject[key]);
        return searchObject;
    }

    getParentRole() {
        return getRoleParentRole(this._employee.roleId);
    }

    getRole() {
        if (this._employee.roleId) {
            return lookRole(this._employee.roleId);
        }
    }

    getTalentBoardIds() {
        return this._employee.talentBoardIds || [];
    }

    isUser() {
        return this._employee.isUser;
    }

    isManager() {
        return this._employee && this._employee.roleId && getChildRoleIds(this._employee.roleId).length > 0;
    }

    getManager() {
        const parentRole = this.getParentRole();
        return parentRole && getRoleIncumbent(parentRole.id);
    }

    isThisMyManager(empId) {
        const myManager = this.getManager();
        return myManager && myManager.id === empId;
    }

    hasActiveManager() {
        const parentRole = this.getParentRole();
        const manager = parentRole && getRoleIncumbent(parentRole.id);
        return manager && manager.isUser;
    }

    canYouAssessMe(empId) {
        const assessAnyone = checkAccess("assessAnyone");
        if (this.id === empId) return false;
        if (!this.hasTraitsToAssess()) return false;
        if (!assessAnyone && !this.isThisMyManager(empId)) return false;
        return true;
    }

    isOrphaned() {
        // Has no role
        // Has a role with a missing ancestor so isn't in a branch
    }

    getTraitIdsToAssess() {
        const talentBoardIds = this._employee.talentBoardIds || [];
        return getTraitIdsForAssessment(talentBoardIds);
    }

    getTraitsToAssess() {
        const traitIdsToAssess = this.getTraitIdsToAssess();
        return traitIdsToAssess.map((traitId) => {
            return lookTrait(traitId);
        });
    }

    hasTraitsToAssess() {
        const traitsToAssess = this.getTraitsToAssess();
        return traitsToAssess.length > 0;
    }

    getAssessmentSteps() {
        const traitsToAssess = this.getTraitsToAssess();
        return prepAssessmentSteps(traitsToAssess);
    }
}

export default EmployeeModel;
