import { lookEmployee, lookRole, lookRoles, lookTopLevelRoleId } from "../redux/utils/looks";
import { removeUndefined } from "./basicUtils";

export function getRoleOrgLevel(roleId) {
    const allParents = getRoleParentRoles(roleId);
    return allParents.length;
}

export function getRoleIncumbent(roleId) {
    const role = lookRole(roleId);
    const incumbentId = role && role.incumbentId;
    return lookEmployee(incumbentId);
}

export function getRoleParentRole(roleId) {
    const role = lookRole(roleId);
    return role && lookRole(role.parentRoleId);
}

export function getRoleParentRoles(roleId, parentRoles = []) {
    const parentRole = getRoleParentRole(roleId);
    if (parentRole) {
        parentRoles.push(parentRole.id);
        if (parentRole.parentRoleId) {
            parentRoles = getRoleParentRoles(parentRole.id, parentRoles);
        }
    }
    parentRoles = removeUndefined(parentRoles);
    return parentRoles || [];
}

export function canRoleAccessRole(roleIdAttempting, roleIdToAccess) {
    const roleAttempting = lookRole(roleIdAttempting);
    if (roleAttempting.rootRole) return true;
    const parentRoles = getRoleParentRoles(roleIdToAccess);
    return parentRoles.includes(roleIdAttempting);
}

export function getChildRoleIds(roleId, wholeBranch, allRoles) {
    const roles = allRoles || lookRoles();
    const role = roles[roleId];
    let juniorRoles = [];
    if (role && role.childRoles) {
        const childRoles = role.childRoles;
        juniorRoles = [...juniorRoles, ...childRoles];
        if (wholeBranch) {
            childRoles.forEach((childId) => {
                juniorRoles = [...juniorRoles, ...getChildRoleIds(childId, wholeBranch, roles)];
            });
        }
    }
    return juniorRoles;
}

export function getChildEmployeeIds(roleId, wholeBranch, allRoles) {
    const roles = allRoles || lookRoles();
    const subordinateRoles = getChildRoleIds(roleId, wholeBranch, roles);
    const subordinateEmployees = subordinateRoles.map((subRoleId) => {
        const childRole = roles[subRoleId];
        return childRole && childRole.incumbentId;
    });
    return removeUndefined(subordinateEmployees);
}

export function getAccessibleParentRoles(roleId) {
    const topLevelRoleId = lookTopLevelRoleId();
    const allParents = getRoleParentRoles(roleId);
    const topRoleIndex = allParents.indexOf(topLevelRoleId) + 1;
    return allParents.splice(0, topRoleIndex).reverse();
}

export function getSuccessionStrength(successors = []) {
    const ready = successors.filter((successor) => successor.colId === 1).length;
    const preparing = successors.filter((successor) => successor.colId === 2).length;
    if (ready >= 1 && ready + preparing >= 2) {
        return 100;
    }
    if (ready === 1 && preparing === 0) {
        return 60;
    }
    if (ready === 0 && preparing >= 1) {
        return 40;
    }
    return 0;
}
