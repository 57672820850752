import { arrayRemove, arrayUnion, deleteField, setDoc, writeBatch } from "firebase/firestore";
import { DEFAULT_TABLE_ID } from "../../constants";
import { resetAllState, setLocalPreferences } from "../../redux/utils/dispatches";
import {
    lookLocalPreferences,
    lookOrgTableConfig,
    lookSavedPreferences,
    lookWorkspace,
} from "../../redux/utils/looks";
import { removeNulls } from "../../utils/basicUtils";
import { updateAuthProfile } from "../auth";
import { db } from "../firebase";
import { deleteNullOrRemoved, getLoggedInUserDocRef } from "../firebaseUtils";
import { setEmployee } from "./employee";

export function setTourViewed(tourId) {
    const currentUserRef = getLoggedInUserDocRef();
    setDoc(currentUserRef, { viewedTours: arrayUnion(tourId) }, { merge: true });
}

export function setHelpViewed(helpId) {
    const currentUserRef = getLoggedInUserDocRef();
    setDoc(currentUserRef, { viewedHelp: arrayUnion(helpId) }, { merge: true });
}

export function unsetHelpViewed(helpId) {
    const currentUserRef = getLoggedInUserDocRef();
    setDoc(currentUserRef, { viewedHelp: arrayRemove(helpId) }, { merge: true });
}

export function revokeAccess(employeeId) {
    let batch = writeBatch(db);
    batch = setEmployee(
        employeeId,
        {
            uid: deleteField(),
            isUser: deleteField(),
            pending: deleteField(),
            userRole: deleteField(),
            canCreateAdmins: deleteField(),
        },
        batch
    );
    return batch;
}

export function setToDefaultOrgTable() {
    const workspaceId = lookWorkspace().id;
    const currentUserRef = getLoggedInUserDocRef();
    const orgTableConfig = lookOrgTableConfig();
    const activeOrgTableId = orgTableConfig && orgTableConfig.activeOrgTableId;
    if (activeOrgTableId || DEFAULT_TABLE_ID) {
        const newPreferences = {
            tableView: true,
            [workspaceId]: {
                orgTableConfig: {
                    activeOrgTableId: DEFAULT_TABLE_ID,
                },
            },
        };
        setDoc(currentUserRef, { preferences: { ...newPreferences } }, { merge: true });
    }
}

export function saveLocalPreferences() {
    const preferences = lookLocalPreferences();
    const savedPreferences = lookSavedPreferences() || {};
    let newSaved = deleteNullOrRemoved(preferences, savedPreferences);
    let newLocal = {...preferences}
    removeNulls(newLocal);
    setLocalPreferences(newLocal);
    // Clean up the nodeView object
    const newNodeView = preferences && preferences.nodeView;
    if (newNodeView) {
        if (!Object.keys(newNodeView).includes("fieldValue")) {
            let newNodeView = { ...newSaved.nodeView, fieldValue: deleteField() };
            newSaved.nodeView = newNodeView;
        }
    } else {
        newSaved.nodeView = deleteField();
    }
    const currentUserRef = getLoggedInUserDocRef()
    setDoc(currentUserRef, { preferences: newSaved }, { mergeFields: [`preferences`] });
}

export function updateUserDoc(values, uid) {
    // Update the firebase auth user
    const userDocRef = getLoggedInUserDocRef();
    uid = uid || userDocRef.id;
    const updates = {
        firstname: values.firstname,
        surname: values.surname,
        displayName: `${values.firstname} ${values.surname}`,
    };
    updateAuthProfile(updates);

    // Update the firestore user document
    setDoc(userDocRef, updates, { merge: true });
}

export async function setActiveWorkspace(workspaceId) {
    const userDocRef = getLoggedInUserDocRef();
    let update = { activeWorkspaceId: workspaceId || deleteField() };
    resetAllState();
    return setDoc(userDocRef, update, { merge: true });
}
