import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { SET_AUTH_HAS_LOADED, UPDATE_NOTIFICATIONS } from "../redux/appSlice";
import { RESET_ASSESSMENTS, SET_ASSESSMENT_REQUEST_DATES, SET_ASSESSMENT_SETTINGS } from "../redux/assessmentsSlice";
import { RESET_ORG, SET_EMPLOYEES, SET_ROLES, SET_ROLE_SNAPSHOTS } from "../redux/orgSlice";
import store from "../redux/store";
import {
    RESET_TALENT,
    SET_ACTIONS,
    SET_DEFAULT_MAP_CONFIG,
    SET_EMPLOYEE_SNAPSHOTS,
    SET_TALENT_AREAS,
    SET_TALENT_BOARDS,
    SET_TRAITS,
} from "../redux/talentSlice";
import {
    RESET_USER,
    SET_ROOT_ROLE_ID,
    SET_SIGNIN_USER,
    SET_USER,
    SET_VIEWED_HELP,
    SET_VIEWED_TOURS,
    SET_WORKSPACE_INVITES,
} from "../redux/userSlice";
import { resetAllState } from "../redux/utils/dispatches";
import { lookActiveWorkspaceId } from "../redux/utils/looks";
import { setSavedPreferences } from "../redux/utils/thunks";
import { RESET_WORKSPACE, SET_ACTIVE_WORKSPACE } from "../redux/workspaceSlice";
import { cLog } from "../utils/basicUtils";
import { haveClaimsChanged } from "../utils/userUtils";
import { setActiveWorkspace } from "./actions/user";
import { ejectFromActiveWorkspace, userSignOut } from "./auth";
import { auth } from "./firebase";
import {
    convertTimeStamp,
    getActionDocRef,
    getAssessmentDatesDocRef,
    getAssessmentsCollectionRef,
    getEmployeeDocRef,
    getEmployeeSnapshotsCollectionRef,
    getEmployeeTimelineRef,
    getInviteDocRef,
    getNotificationsCollectionRef,
    getOrgInfoCollectionRef,
    getRoleSnapshotsCollectionRef,
    getSelfAssessmentCollectionRef,
    getTalentInfoCollectionRef,
    getUserDocRef,
    getWorkspaceDocRef,
} from "./firebaseUtils";

let documentReads = 0;
function documentRead(readBy) {
    documentReads++;
    cLog(readBy, `Total Reads: ${documentReads}`);
}

export function listenAuth() {
    cLog("Setting auth listener");
    return auth.onAuthStateChanged((user) => {
        cLog("auth state changed");
        store.dispatch(SET_AUTH_HAS_LOADED(true));
        if (user) {
            const { email, uid } = user;
            store.dispatch(SET_SIGNIN_USER({ email: email, authId: uid }));
        } else {
            store.dispatch(RESET_USER());
            store.dispatch(RESET_WORKSPACE());
            store.dispatch(RESET_TALENT());
            store.dispatch(RESET_ASSESSMENTS());
            store.dispatch(RESET_ORG());
        }
    });
}

export function listenActiveUser(userId) {
    cLog("listenActiveUser", "!! Setting");
    if (!userId) return;
    const userDocRef = getUserDocRef(userId);
    return onSnapshot(
        userDocRef,
        async (doc) => {
            if (doc.exists()) {
                let data = doc.data();
                if (data) {
                    data.createdDate = convertTimeStamp(data && data?.createdDate);
                    const viewedTours = data && data.viewedTours;
                    const viewedHelp = data && data.viewedHelp;
                    // Refresh users token if their claims have changed
                    if (haveClaimsChanged(data && data.claims)) {
                        const workspaceId = lookActiveWorkspaceId();
                        const currentUser = auth.currentUser;
                        const claims = data.claims;
                        await currentUser.getIdToken(true);
                        if (workspaceId && !claims.developer) {
                            const workspaceClaims = data.claims.workspaceClaims || {};
                            const allowedWorkspaces = Object.keys(workspaceClaims);
                            if (!allowedWorkspaces.includes(workspaceId)) {
                                ejectFromActiveWorkspace();
                            }
                        }
                    }
                    store.dispatch(SET_USER({ id: userId, ...data }));
                    store.dispatch(setSavedPreferences());
                    store.dispatch(SET_VIEWED_TOURS(viewedTours || []));
                    store.dispatch(SET_VIEWED_HELP(viewedHelp || []));
                }
            } else {
                // If the user was previously logged into a workspace and has been deleted, reset the app
                const workspaceId = lookActiveWorkspaceId();
                if (workspaceId) {
                    resetAllState();
                    userSignOut();
                }
                cLog("No user document!", "listenActiveUser");
            }
        },
        (error) => {
            cLog("listenActiveUser", error);
        }
    );
}

export function listenWorkspaceInvites(email) {
    cLog("Checking for invites", "listenWorkspaceInvites");
    const docRef = getInviteDocRef(email);
    return onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
            cLog("Invite Found", "listenWorkspaceInvites");
            store.dispatch(SET_WORKSPACE_INVITES([{ id: doc.id, ...doc.data() }]));
        } else {
            cLog("No Invite Found", "listenWorkspaceInvites");
            store.dispatch(SET_WORKSPACE_INVITES([]));
        }
    });
}

export function listenActiveWorkspace() {
    cLog("listenActiveWorkspace");
    const workspaceDocRef = getWorkspaceDocRef();
    return onSnapshot(
        workspaceDocRef,
        (doc) => {
            documentRead("workspace");
            if (doc.exists()) {
                const docData = doc && doc.data();
                let { assessmentSettings, ...data } = docData;
                let { lastRequestDate, lastSnapshotDate, ...restOfSettings } = assessmentSettings;
                restOfSettings.lastSnapshotDate = convertTimeStamp(lastSnapshotDate);
                // Submit the result to redux
                store.dispatch(SET_ROOT_ROLE_ID(data.rootRoleId));
                store.dispatch(
                    SET_ACTIVE_WORKSPACE({ ...{ id: doc.id, assessmentSettings: { ...restOfSettings } }, ...data })
                );
                store.dispatch(setSavedPreferences());
                store.dispatch(SET_ASSESSMENT_SETTINGS({ ...restOfSettings, workspaceId: doc.id }));
                store.dispatch(SET_DEFAULT_MAP_CONFIG(restOfSettings.talentMapConfig || {}));
            } else {
                setActiveWorkspace(false);
                cLog("Failed to load workspace. No document exists");
            }
        },
        (error) => {
            cLog("ERROR: listenActiveWorkspace", error);
        }
    );
}

export function listenOrgInfo() {
    cLog("listenOrgInfo", "!! Setting");
    const orgInfoCollection = getOrgInfoCollectionRef();
    return onSnapshot(
        orgInfoCollection,
        (querySnapshot) => {
            let allEmployees = {};
            let allRoles = {};
            querySnapshot.docChanges().forEach((change) => {
                documentRead("orgInfo");
            });
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const employees = data.employees;
                const roles = data.roles;
                if (employees) {
                    Object.entries(employees).forEach(([empId, emp]) => {
                        allEmployees[empId] = {
                            id: empId,
                            publicInfoDocId: doc.id,
                            displayName: `${emp.firstname} ${emp.surname}`,
                            ...emp,
                        };
                    });
                }
                if (roles) {
                    Object.entries(roles).forEach(([roleId, role]) => {
                        allRoles[roleId] = { id: roleId, publicInfoDocId: doc.id, ...role };
                    });
                }
            });

            store.dispatch(SET_EMPLOYEES(allEmployees));
            store.dispatch(SET_ROLES(allRoles));
        },
        (error) => {
            cLog("listenOrgInfo", error);
        }
    );
}

export function listenTalentInfo() {
    cLog("listenTalentInfo", "!! Setting");
    const talentInfoCollection = getTalentInfoCollectionRef();
    return onSnapshot(
        talentInfoCollection,
        (querySnapshot) => {
            // Log doc reads to make sure nothing weird is going on
            querySnapshot.docChanges().forEach((change) => {
                documentRead("listenTalentInfo");
            });
            let talentAreas = {};
            let traits = {};
            let talentBoards = {};
            let actions = {};
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.talentAreas) {
                    Object.entries(data.talentAreas).forEach(([key, value]) => {
                        talentAreas[key] = { id: key, publicInfoDocId: doc.id, ...value };
                    });
                }
                if (data.traits) {
                    Object.entries(data.traits).forEach(([key, value]) => {
                        traits[key] = { id: key, publicInfoDocId: doc.id, ...value };
                    });
                }
                if (data.talentBoards) {
                    Object.entries(data.talentBoards).forEach(([key, value]) => {
                        talentBoards[key] = { id: key, publicInfoDocId: doc.id, ...value };
                    });
                }
                if (data.actions) {
                    Object.entries(data.actions).forEach(([key, value]) => {
                        actions[key] = { id: key, publicInfoDocId: doc.id, ...value };
                    });
                }
            });

            // Submit the result to redux
            store.dispatch(SET_ACTIONS(actions));
            store.dispatch(SET_TALENT_AREAS(talentAreas));
            store.dispatch(SET_TRAITS(traits));
            store.dispatch(SET_TALENT_BOARDS(talentBoards));
        },
        (error) => {
            cLog("listenTalentInfo", error);
        }
    );
}

export function listenEmployee(employeeId, callback) {
    cLog("listenEmployee", "!! Setting");
    const employeeRef = getEmployeeDocRef(employeeId);
    return onSnapshot(employeeRef, (doc) => {
        documentRead("listenEmployee");
        if (doc.exists()) {
            let { timeline = [], dateLastAssessed, dateLastSelfAssessed, ...data } = doc.data();
            let newTimeline = timeline.map((item) => {
                return { ...item, eventDate: convertTimeStamp(item.eventDate) };
            });
            newTimeline = newTimeline.reverse();
            let update = { id: doc.id, timeline: newTimeline, ...data };
            update.dateLastSelfAssessed = convertTimeStamp(dateLastSelfAssessed);
            callback(update);
        }
    });
}

export function listenEmployeeTimeline(employeeId, callback) {
    cLog("listenEmployeeTimeline", "!! Setting");
    const employeeRef = getEmployeeTimelineRef(employeeId);
    return onSnapshot(employeeRef, (doc) => {
        documentRead("listenEmployeeTimeline");
        if (doc.exists()) {
            let { events = [] } = doc.data();
            const newEvents = events.map((item) => {
                return { ...item, eventDate: convertTimeStamp(item.eventDate) };
            });
            callback(newEvents.reverse());
        }
    });
}

export function listenAction(actionId, callback) {
    cLog("listenAction", "!! Setting");
    const actionDocRef = getActionDocRef(actionId);
    return onSnapshot(actionDocRef, (doc) => {
        documentRead("listenAction");
        if (doc.exists()) {
            const data = doc.data();
            callback({ id: doc.id, ...data });
        }
    });
}

export function listenEmployeeSnapshots(roleId) {
    cLog("listenEmployeeSnapshots", "!! Setting");
    const employeeSnapshotsCollectionRef = getEmployeeSnapshotsCollectionRef(roleId);
    return onSnapshot(
        employeeSnapshotsCollectionRef,
        (querySnapshot) => {
            // Log doc reads to make sure nothing weird is going on
            querySnapshot.docChanges().forEach((change) => {
                documentRead("listenEmployeeSnapshots");
            });

            let allSnapshots = {};
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                let employeeSnapshots = docData && docData.employees;
                if (employeeSnapshots) {
                    Object.entries(employeeSnapshots).forEach(([empId, snapshotWithDate]) => {
                        let { ad, ...snapshot } = snapshotWithDate;
                        snapshot.date = convertTimeStamp(ad);
                        employeeSnapshots[empId] = snapshot;
                    });
                    allSnapshots = { ...allSnapshots, ...employeeSnapshots };
                }
            });
            store.dispatch(SET_EMPLOYEE_SNAPSHOTS(allSnapshots));
        },
        (error) => {
            cLog("listenEmployeeSnapshots", error);
        }
    );
}

export function listenRoleSnapshots(roleId) {
    cLog("listenRoleSnapshots", "!! Setting");
    const employeeSnapshotsCollectionRef = getRoleSnapshotsCollectionRef(roleId);
    return onSnapshot(
        employeeSnapshotsCollectionRef,
        (querySnapshot) => {
            // Log doc reads to make sure nothing weird is going on
            querySnapshot.docChanges().forEach((change) => {
                documentRead("listenRoleSnapshots");
            });

            let allSnapshots = {};
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                let snapshots = docData && docData.roles;
                if (snapshots) {
                    Object.entries(snapshots).forEach(([roleId, snapshot]) => {
                        const successors = snapshot.s?.map((successor) => {
                            const convertedDate = convertTimeStamp(successor.d);
                            return {
                                date: convertedDate,
                                key: successor.e,
                                colId: successor.p,
                            };
                        });
                        snapshots[roleId] = { successors: successors };
                    });
                    allSnapshots = { ...allSnapshots, ...snapshots };
                }
            });
            store.dispatch(SET_ROLE_SNAPSHOTS(allSnapshots));
        },
        (error) => {
            cLog("listenEmployeeSnapshots", error);
        }
    );
}

export function listenAssessmentRequestDates() {
    cLog("listenAssessmentRequestDates", "!! Setting");
    const assessmentDatesDocRef = getAssessmentDatesDocRef();
    return onSnapshot(assessmentDatesDocRef, (doc) => {
        documentRead("listenAssessmentRequestDates");
        if (doc.exists()) {
            let data = doc.data();
            const roles = data && data.roles;
            if (roles) {
                Object.entries(roles).forEach(([roleId, lastRequestDate]) => {
                    if (lastRequestDate) roles[roleId] = convertTimeStamp(lastRequestDate);
                });
                store.dispatch(SET_ASSESSMENT_REQUEST_DATES(roles));
            }
        }
    });
}

export function listenNotifications(roleId) {
    cLog("listenNotifications", "!! Setting");
    const notificationsCollection = getNotificationsCollectionRef(roleId);
    const unseenNotifications = query(notificationsCollection, where("seen", "==", false));
    return onSnapshot(
        unseenNotifications,
        (querySnapshot) => {
            // Log doc reads to make sure nothing weird is going on
            querySnapshot.docChanges().forEach((change) => {
                documentRead("listenNotifications");
            });

            let notifications = [];
            querySnapshot.forEach((doc) => {
                const { received, ...data } = doc.data();
                let update = { id: doc.id, ...data };
                if (received) update.received = convertTimeStamp(received);
                notifications.push(update);
            });
            store.dispatch(UPDATE_NOTIFICATIONS(notifications));
        },
        (error) => {
            cLog("listenNotificatons", error);
        }
    );
}

export function listenLatestAssessment(empId, callback) {
    cLog("listenLatestAssessment", "!! Setting");
    const assessmentsCollectionRef = getAssessmentsCollectionRef();
    const latestAssessment = query(
        assessmentsCollectionRef,
        where("employeeId", "==", empId),
        orderBy("assessmentDate", "desc"),
        limit(1)
    );
    return onSnapshot(
        latestAssessment,
        (querySnapshot) => {
            // Log doc reads to make sure nothing weird is going on
            querySnapshot.docChanges().forEach((change) => {
                documentRead("listenLatestAssessment");
            });
            const doc = querySnapshot.docs[querySnapshot.docs.length - 1];
            callback(doc);
        },
        (error) => {
            cLog("listenLatestAssessment", error);
        }
    );
}

export function listenSelfAssessment(empId, callback) {
    cLog("listenSelfAssessment", "!! Setting");
    const selfAssessmentColRef = getSelfAssessmentCollectionRef();
    const latestAssessment = query(
        selfAssessmentColRef,
        where("employeeId", "==", empId),
        orderBy("assessmentDate", "desc"),
        limit(1)
    );
    return onSnapshot(
        latestAssessment,
        (querySnapshot) => {
            // Log doc reads to make sure nothing weird is going on
            querySnapshot.docChanges().forEach((change) => {
                documentRead("listenSelfAssessment");
            });
            const doc = querySnapshot.docs[querySnapshot.docs.length - 1];
            callback(doc);
        },
        (error) => {
            cLog("listenSelfAssessment", error);
        }
    );
}
