import { getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { cLog } from "../utils/basicUtils";
import {
    getActionDocRef,
    getAssessmentDocRef,
    getAssessmentsCollectionRef,
    getEmployeeDocRef,
    getSelfAssessmentCollectionRef,
    getTalentBoardDocRef,
    getTraitDocRef,
    getWorkspaceDocRef,
    getWorkspacesInfoDoc,
} from "./firebaseUtils";

export async function fetchWorkspaceList() {
    cLog("fetching workspace list");
    const docRef = getWorkspacesInfoDoc();
    return getDoc(docRef).then((doc) => {
        if (doc.exists()) {
            const { workspaces } = doc.data();
            return workspaces;
        } else {
            return false;
        }
    });
}

export async function fetchWorkspace(workspaceId) {
    cLog("fetching workspace");
    const docRef = getWorkspaceDocRef(workspaceId);
    return getDoc(docRef).then((doc) => {
        if (doc.exists()) {
            return { id: doc.id, ...doc.data() };
        } else {
            return false;
        }
    });
}

export async function fetchTrait(traitId) {
    cLog("fetching trait");
    const traitDetailsDocRef = getTraitDocRef(traitId);
    return getDoc(traitDetailsDocRef).then((doc) => {
        if (doc.exists()) {
            return { id: doc.id, ...doc.data() };
        } else {
            return false;
        }
    });
}

export async function fetchEmployee(employeeId) {
    cLog("fetching employee");
    const employeeRef = getEmployeeDocRef(employeeId);
    const doc = await getDoc(employeeRef);
    if (doc.exists()) {
        const data = doc.data();
        return { id: doc.id, ...data };
    } else {
        return false;
    }
}

export async function fetchAction(actionId) {
    cLog("fetching action");
    const actionDocRef = getActionDocRef(actionId);
    const doc = await getDoc(actionDocRef);
    if (doc.exists()) {
        return { id: doc.id, ...doc.data() };
    } else {
        return false;
    }
}

export async function fetchTalentBoard(actionId) {
    cLog("fetching talent board");
    const boardDetailsRef = getTalentBoardDocRef(actionId);
    return getDoc(boardDetailsRef).then((doc) => {
        if (doc.exists()) {
            return { id: doc.id, ...doc.data() };
        } else {
            return false;
        }
    });
}

export async function fetchAssessmentById(assessmentId) {
    cLog("fetching assessment");
    const assessmentDocRef = getAssessmentDocRef(assessmentId);
    const doc = await getDoc(assessmentDocRef);
    if (doc.exists()) {
        return { id: doc.id, ...doc.data() };
    } else {
        return false;
    }
}

export async function fetchLatestAssessment(empId) {
    cLog("fetching latest assessment");
    const assessmentsCollectionRef = getAssessmentsCollectionRef();
    const docSnapshots = await getDocs(
        query(assessmentsCollectionRef, where("employeeId", "==", empId), orderBy("assessmentDate", "desc"), limit(1))
    );
    const doc = docSnapshots.docs[docSnapshots.docs.length - 1];
    return doc;
}

export async function fetchLatestSelfAssessment(empId) {
    cLog("fetching latest assessment");
    const assessmentsCollectionRef = getSelfAssessmentCollectionRef();
    const docSnapshots = await getDocs(
        query(assessmentsCollectionRef, where("employeeId", "==", empId), orderBy("assessmentDate", "desc"), limit(1))
    );
    const doc = docSnapshots.docs[docSnapshots.docs.length - 1];
    return doc;
}

export async function fetchCurrentSelfAssessment(empId, snapshotId) {
    cLog("fetching current assessment");
    const assessmentsCollectionRef = getSelfAssessmentCollectionRef();
    const docSnapshots = await getDocs(
        query(
            assessmentsCollectionRef,
            where("employeeId", "==", empId),
            where("snapshotId", "==", snapshotId),
            limit(1)
        )
    );
    const doc = docSnapshots.docs[docSnapshots.docs.length - 1];
    return doc;
}

export async function fetchPaginatedAssessments(empId, docsPerPage, lastVisible) {
    const assessmentsCollectionRef = getAssessmentsCollectionRef();
    if (lastVisible) {
        return await getDocs(
            query(
                assessmentsCollectionRef,
                where("employeeId", "==", empId),
                orderBy("assessmentDate", "desc"),
                startAfter(lastVisible),
                limit(docsPerPage)
            )
        );
    } else {
        return await getDocs(
            query(
                assessmentsCollectionRef,
                where("employeeId", "==", empId),
                orderBy("assessmentDate", "desc"),
                limit(docsPerPage)
            )
        );
    }
}

/*
export async function fetchPaginatedAssessments(callback, empId, docsPerPage, nextRef) {
    const assessmentsCollectionRef = getAssessmentsCollectionRef();
    let documentSnapshots;
    if (!nextRef) {
        documentSnapshots = await getDocs(
            query(
                assessmentsCollectionRef,
                where("employeeId", "==", empId),
                orderBy("assessmentDate", "desc"),
                limit(docsPerPage)
            )
        );
    } else {
        documentSnapshots = await getDocs(nextRef);
    }
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

    if (!lastVisible) {
        return false;
    } else {
        const data = documentSnapshots.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
        });
        callback(data);
        const next = query(
            assessmentsCollectionRef,
            where("employeeId", "==", empId),
            orderBy("assessmentDate", "desc"),
            startAfter(lastVisible),
            limit(docsPerPage)
        );
        return next;
    }
}
*/
