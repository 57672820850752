import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { useSelector } from "react-redux";
import AppButton, {
    AddRoleButton,
    ChangeUserRoleButton,
    DeleteRoleButton,
    MoveRoleButton,
    RequestAssessmentButton,
    SkipAssessmentButton,
    VacateOrFillRoleButton,
} from "../../../../../components/Buttons/MyButtons";
import FloatingActionButtons from "../../../../../components/FloatingActionButtons/FloatingActionButtons";
import { RoleModel } from "../../../../../models";
import { checkIfAdmin, checkIfOwner } from "../../../../../utils/employeeUtils";
import { checkAccess } from "../../../../../utils/userUtils";

const OrgChartActionButtons = (props) => {
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const employeeSnapshot = useSelector((state) => state.talent.snapshots[selectedEmployeeId]);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const roleModel = selectedRole && new RoleModel(selectedRole);
    const canCreateAdmins = checkAccess("canCreateAdmins");
    const isSelf = selectedEmployeeId === userEmployeeId;
    const noEmployee = !selectedEmployeeId;
    const noRole = !selectedRole || selectedRole.rootRole;
    const hasChildren = roleModel && roleModel.hasChildren();
    const isOwner = checkIfOwner(selectedEmployeeId);
    const isAdmin = checkIfAdmin(selectedEmployee);
    const isUser = selectedEmployee && selectedEmployee.isUser;
    const hideVacateOrFill = isSelf && !isOwner && !noEmployee;
    const hideChangeRole = noEmployee || isSelf || isOwner || (!canCreateAdmins && isAdmin);
    const hideDeleteRole = hasChildren;
    const hideMoveRole = noEmployee && !hasChildren;
    const hideAddRole = noRole;
    const hideRequestAssessments = !assessmentsOpen || !hasChildren;
    const { skipped, expired } = employeeSnapshot || {};
    const hideSkipAssessment = noEmployee || isSelf || !assessmentsOpen || skipped || !expired;

    return (
        <FloatingActionButtons tooltipId={props.tooltipId} disabled={noRole} className="tour-org-chart-admin-buttons">
            <VacateOrFillRoleButton
                hide={hideVacateOrFill}
                tooltipId={props.tooltipId}
                toggle={noEmployee}
                onClick={props.onRemoveOrFillRole}
            />
            <ChangeUserRoleButton hide={hideChangeRole} tooltipId={props.tooltipId} onClick={props.changeUserRole} />
            <AppButton
                disabled={!isUser}
                tooltip="Resend Invite"
                tooltipId={props.tooltipId}
                onClick={props.resendInvite}
            >
                <FaRegEnvelope />
            </AppButton>
            <DeleteRoleButton hide={hideDeleteRole} onClick={props.onDeleteRole} />
            <MoveRoleButton hide={hideMoveRole} onClick={props.onMoveRole} />
            <AddRoleButton hide={hideAddRole} onClick={props.onAddChildRole} />
            <RequestAssessmentButton
                hide={hideRequestAssessments}
                tooltipId={props.tooltipId}
                onClick={props.onRequestAssessments}
            />
            <SkipAssessmentButton
                hide={hideSkipAssessment}
                tooltipId={props.tooltipId}
                onClick={props.onSkipAssessment}
            />
        </FloatingActionButtons>
    );
};

export default OrgChartActionButtons;
