import PropTypes from "prop-types";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";

const RatingSlider = ({ value, onChange, handleHeight, handleWidth, color }) => {
    return (
        <Slider
            min={1}
            max={100}
            step={1}
            dots
            value={value}
            onChange={(value) => onChange(value)}
            trackStyle={{
                backgroundColor: color,
            }}
            railStyle={{
                backgroundColor: color,
            }}
            handleStyle={{
                border: "unset",
                borderColor: "white",
                height: handleHeight,
                width: handleWidth,
                backgroundColor: color,
            }}
        />
    );
};

RatingSlider.propTypes = {
    color: PropTypes.string,
    railHeight: PropTypes.number,
    trackHeight: PropTypes.number,
    handleHeight: PropTypes.number,
    handleWidth: PropTypes.number,
};

RatingSlider.defaultProps = {
    color: "#666666",
    railHeight: 10,
    trackHeight: 10,
    handleHeight: 15,
    handleWidth: 15,
};

export default RatingSlider;
