import { getDoc, writeBatch } from "firebase/firestore";
import { auth, db } from "../firebase";
import { getInviteDocRef } from "../firebaseUtils";

export async function checkPendingInvites(email) {
    const inviteRef = getInviteDocRef(email);
    return await getDoc(inviteRef);
}

export function acceptInvite(invite, formValues) {
    let batch = writeBatch(db);
    const currentUser = auth.currentUser;

    const updatedValues = {
        ...formValues,
        accepted: true,
        uid: currentUser.uid,
    };

    // Accept the Invite. This kicks off a cloud function which applies all the access changes securely
    const inviteRef = getInviteDocRef(invite.email);
    batch.set(inviteRef, updatedValues, { merge: true });

    return batch;
}
