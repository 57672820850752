import { Button } from "reactstrap";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import { MdClose } from "react-icons/md";
import Dock from "./Dock";
import "./SplitPane.scss";
import { useSelector } from "react-redux";

const SplitPane = (props) => {
    const { className, leftClassName, rightClassName } = props;
    const disableClose = useSelector((state) => state.app.unsavedSideArea);
    //const colSpan = 12; // props.isOpen ? 6 : 12;
    const closeButton = props.showCloseButton ? (
        <div
            className="side-area-header d-flex px-3 justify-content-start align-items-center border-bottom position-fixed w-100"
            style={{ backgroundColor: "white", zIndex: 1 }}
        >
            <Button className="text-only" disabled={disableClose}>
                <MdClose size="22px" onClick={props.onClose} />
            </Button>
            <h6 className="mb-0 ms-3">{props.headerText}</h6>
        </div>
    ) : null;
    return (
        <div className={classNames("outer-container", className && className)}>
            <div className={classNames("split-pane-left scrollable-container", leftClassName && leftClassName)}>
                {props.left}
            </div>
            <Dock
                position="right"
                isVisible={props.isOpen}
                dimMode={props.dimMode}
                defaultSize={0.35}
                onBlur={props.onBlur}
            >
                {closeButton}
                <div className={classNames("split-pane-right-inner", rightClassName && rightClassName)}>
                    {props.right}
                </div>
            </Dock>
        </div>
    );
};

SplitPane.propTypes = {
    left: PropTypes.node.isRequired,
    right: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    showCloseButton: PropTypes.bool,
    className: PropTypes.string,
    rightClassName: PropTypes.string,
    leftClassName: PropTypes.string,
    dimMode: PropTypes.oneOf(["none", "transparent", "opaque"]),
};

SplitPane.defaultProps = {
    isOpen: false,
    showCloseButton: true,
    dimMode: "opaque",
    onClose: () => {},
};

export default SplitPane;
