import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { SET_SIDE_AREA } from "../../../../../redux/appSlice";
import { lookCustomField, lookEmployee, lookRole } from "../../../../../redux/utils/looks";
import { getContrastColor } from "../../../../../utils/basicUtils";
import { getFieldLabel, getExactColor } from "../../../../../utils/snapshotUtils";

const EmployeeEntry = ({ entry, onClick, simpleList }) => {
    const employee = lookEmployee(entry.employeeId);
    const roleId = employee && employee.roleId;
    const role = lookRole(roleId);
    const backgroundColor = getExactColor(entry.z);
    const color = getContrastColor(backgroundColor);
    const xl = 3;
    const lg = 4;
    const md = 6;
    const sm = 12;
    const xs = 12;

    if (simpleList) {
        const style = { backgroundColor: backgroundColor, color: color, border: "solid .5px white", padding: "3px 0" };
        return (
            <Col md={4} className="text-center clickable" onClick={() => onClick(entry.employeeId)} style={style}>
                <p className="fw-bold fs-tiny mb-0 text-break overflow-auto text-nowrap hide-scroll-bars">
                    {employee.displayName}
                </p>
            </Col>
        );
    }

    return (
        <Col
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            className="px-3 py-2 text-center rounded-3 clickable"
            onClick={() => onClick(entry.employeeId)}
            style={{ backgroundColor: backgroundColor, color: color, border: "solid 1px white" }}
        >
            <p className="fw-bold mb-0 text-break">{employee.displayName}</p>
            <p className="mb-0 text-break">{role.jobTitle}</p>
        </Col>
    );
};

const DetailTile = ({ onClick, label, value = "" }) => {
    const handleClick = () => {
        if (onClick) onClick();
    };
    return (
        <div
            onClick={handleClick}
            className={classNames("bg-white rounded-4 p-3 m-1 border-all", { clickable: onClick })}
        >
            <h6 className={classNames("mb-2 text-capitalize")}>{value.toString()}</h6>
            <h6 className={classNames("mb-2 fw-light fs-tiny")}>{label}</h6>
        </div>
    );
};

const SegmentDetails = ({ cohort, segment }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tile = segment.tile;
    const title = tile && tile.title;
    const percent = segment.percent;
    const talentMapConfig = useSelector((state) => state.talent.talentMapConfig);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const filters = talentMapConfig.filters || [];
    const zAxisLabel = getFieldLabel(talentMapConfig.z);

    const handleConfig = () => {
        dispatch(SET_SIDE_AREA("talentMapConfig"));
    };

    const handleTalentMap = () => {
        navigate(`/people/${selectedRoleId}/talent-map`);
    };

    return (
        <div className="d-flex dashboard-headers text-center justify-content-between">
            <div className="f-1 d-flex">
                <DetailTile onClick={handleTalentMap} label="Grid Cohort" value={title} />
                <DetailTile onClick={handleConfig} label="Tile View" value={zAxisLabel} />
                {filters.map((filter) => {
                    const label = getFieldLabel(filter);
                    let value = filter.fieldValue;
                    if (filter.type === "picker") {
                        const field = lookCustomField(filter.fieldId);
                        const options = field && field.options;
                        const option = options && options.find((option) => option.value === filter.fieldValue);
                        value = option && option.label;
                    }
                    return (
                        <DetailTile
                            onClick={handleConfig}
                            key={`${filter.fieldId}-${filter.fieldValue}`}
                            label={label}
                            value={value}
                        />
                    );
                })}
            </div>
            <div className="d-flex">
                <DetailTile label="Total" value={cohort.length} />
                <DetailTile label="Proportion" value={`${percent}%`} />
            </div>
        </div>
    );
};

const SegmentView = ({ segment, simpleList, onItemClick }) => {
    let cohort = segment.cohort || [];
    cohort.sort((a, b) => parseFloat(b.z) - parseFloat(a.z));

    return (
        <div className={classNames("segment-view f-1 d-flex flex-column", { "pb-5 px-4": !simpleList })}>
            {!simpleList && <SegmentDetails cohort={cohort} segment={segment} />}
            <Container className="f-1 rounded-4 bg-white" fluid>
                <Row className="p-1">
                    {cohort.map((entry) => (
                        <EmployeeEntry
                            simpleList={simpleList}
                            key={entry.employeeId}
                            entry={entry}
                            onClick={onItemClick}
                        />
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default SegmentView;
