import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import Select from "react-select";
import "../picker.scss";

const FieldPicker = (props) => {
    const { isClearable, disabled, optionGroups, selected, placeholder } = props;

    function deriveValueFromProps() {
        if (!selected) return [];
        const optionGroup = optionGroups.find((option) => option.fieldId === selected.fieldId);
        const options = optionGroup && optionGroup.options;
        if (options) {
            return options.find((option) => option.fieldValue === selected.fieldValue);
        } else {
            return optionGroup;
        }
    }

    if (optionGroups.length === 0) return null;

    return (
        <div className="picker">
            <Select
                isDisabled={disabled}
                isClearable={isClearable}
                className={classNames("field-picker", props.className)}
                classNamePrefix={classNames("field-picker", props.classNamePrefix)}
                maxMenuHeight={320}
                type="select"
                onChange={props.onChange}
                options={optionGroups}
                value={deriveValueFromProps()}
                placeholder={placeholder}
            />
        </div>
    );
};

FieldPicker.propTypes = {
    placeholder: PropTypes.string,
    excludeUnrated: PropTypes.bool,
    optionGroups: PropTypes.array,
    selected: PropTypes.object,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

FieldPicker.defaultProps = {
    placeholder: "Overall Rating",
    selected: {},
    optionGroups: [],
    onBlur: () => {},
    onChange: () => {},
};

export default FieldPicker;
