import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Fade } from "reactstrap";
import { LoadingIndicator } from "../../components";
import UncontrolledCollapseBlock from "../../components/CollapseBlock/CollapseBlock";
import { RichTextDisplay } from "../../components/RichTextEditor/RichTextEditor";
import RatingSlider from "../../components/Sliders/RatingSlider";
import { fetchTrait } from "../../firebase/fetch";
import { lookTalentArea } from "../../redux/utils/looks";
import ActionItem from "./ActionItem";
import { AssessmentStep } from "./AssessmentStepper";

const ActionsForStep = ({
    currentRating,
    checkedActionIds,
    potentialActionIds,
    onCheckAction,
    prevActionIds,
    loading,
}) => {
    const isLowRated = currentRating && currentRating < 100;
    const [filteredPlannedActions, setFilteredPlannedActions] = useState([]);
    const [recommendedActions, setRecommendedActions] = useState([]);

    // Group actions by status
    useEffect(() => {
        setFilteredPlannedActions(prevActionIds.filter((id) => potentialActionIds.includes(id)));
        setRecommendedActions(potentialActionIds.filter((id) => !prevActionIds.includes(id)));
    }, [potentialActionIds, prevActionIds]);

    if (loading) {
        return (
            <div className="f-1 h-100 action-list">
                <h6 className="fw-lighter">Recommended Actions</h6>
                <LoadingIndicator />
            </div>
        );
    }

    if (!isLowRated) {
        return (
            <div className="f-1 action-list">
                <h6>Recommended Actions</h6>
                <span>No actions required.</span>
            </div>
        );
    }

    return (
        <div className="f-1 h-100 action-list">
            <UncontrolledCollapseBlock title="Planned/Completed Actions" className="mb-3" defaultOpen>
                {filteredPlannedActions.map((actionId, idx) => (
                    <div className="mb-3" key={`${actionId}-${idx}`}>
                        <ActionItem actionId={actionId} type="list" />
                    </div>
                ))}
                {filteredPlannedActions.length === 0 && <p className="pt-3">No Scheduled Actions</p>}
            </UncontrolledCollapseBlock>
            <hr />
            <UncontrolledCollapseBlock title="Recommended Actions" defaultOpen>
                {recommendedActions.map((actionId) => (
                    <div className="mb-3" key={actionId}>
                        <ActionItem
                            actionId={actionId}
                            onCheckAction={onCheckAction}
                            checked={checkedActionIds.includes(actionId)}
                            type="checkbox"
                        />
                    </div>
                ))}
                {recommendedActions.length === 0 && <p className="pt-3">No Recommended Actions</p>}
            </UncontrolledCollapseBlock>
        </div>
    );
};

const TraitRatingCard = ({ traitId, traitRating, shadowRating, onSetRating, className, disabled }) => {
    const allColors = useSelector((state) => state.assessments.settings.allColors);
    const [ratingColor, setRatingColor] = useState("lightgrey");

    useEffect(() => {
        if (traitRating) {
            setRatingColor(allColors[traitRating - 1]);
        }
    }, [traitRating, allColors]);

    const handleSetTraitRating = (rating) => {
        onSetRating(traitId, rating);
    };

    return (
        <div
            className={classNames(className, "trait-rating-card", {
                disabled: disabled,
            })}
            style={{ borderColor: ratingColor }}
        >
            <Fade className={classNames("trait-card py-2 px-4")}>
                {traitRating && (
                    <h2 style={{ borderColor: ratingColor }} className="slider-label">
                        {traitRating}
                    </h2>
                )}
                <RatingSlider
                    value={traitRating}
                    shadowValue={shadowRating}
                    onChange={handleSetTraitRating}
                    color={ratingColor}
                />
            </Fade>
        </div>
    );
};

export const RatingStep = (props) => {
    const [fullTrait, setFullTrait] = useState({});
    const { actionIds, traitRating, shadowRating, trait } = props;
    const traitId = trait && trait.id;

    useEffect(() => {
        async function fetchData() {
            if (traitId) {
                const traitDoc = await fetchTrait(traitId);
                setFullTrait(traitDoc);
            }
        }
        fetchData();
    }, [traitId]);

    const renderTalentAreaLabels = () => {
        let talentAreaIds = fullTrait && fullTrait.talentAreaIds;
        talentAreaIds = talentAreaIds || [];
        return (
            <div className="d-flex justify-content-center mb-2">
                {talentAreaIds.map((talentAreaId) => {
                    const talentArea = lookTalentArea(talentAreaId);
                    const label = talentArea ? talentArea.talentAreaName : "Missing talent area";
                    return (
                        <span className="px-2 fs-tiny text-center fst-italic" key={talentAreaId}>
                            {label}
                        </span>
                    );
                })}
            </div>
        );
    };

    return (
        <AssessmentStep key={`trait-step-${traitId}`} className="inner-container rating-section d-flex border-left">
            <div className="f-1 d-flex rating-step inner-container flex-row">
                <div className="f-3 rating-body scrollable">
                    <div className="trait-details">
                        <h2 className="mb-1 text-center">{fullTrait.traitName}</h2>
                        {renderTalentAreaLabels()}
                        <p className="fs-small text-center mb-4">{fullTrait.description}</p>
                    </div>
                    <hr />
                    <div className="tour-trait-ratings f-2">
                        <div className="d-flex flex-nowrap align-items-center justify-content-center">
                            <div className="fs-tiny text-center fw-lighter fst-italic">Not seen</div>
                            <TraitRatingCard
                                key={`ratin-card-${traitId}`}
                                className="f-1 m-2"
                                traitId={traitId}
                                traitRating={traitRating}
                                shadowRating={shadowRating}
                                onSetRating={props.onSetRating}
                            />
                            <div className="fs-tiny text-center fw-lighter fst-italic">
                                Seen <br /> consistently
                            </div>
                        </div>
                    </div>
                    {shadowRating && (
                        <div className="fs-tiny text-center">{`Employee Self-Rating: ${shadowRating}`}</div>
                    )}
                    <hr />
                    <div className="mb-3 pb-3 rating-guidance">
                        <h6 className="text-center">Rating Guidance</h6>
                        <div>
                            <RichTextDisplay
                                content={fullTrait.guidance || "No guidance for this Trait."}
                                removePadding
                            />
                        </div>
                    </div>
                </div>
                <div className="f-2 d-flex flex-column scrollable">
                    <ActionsForStep
                        prevActionIds={props.prevActionIds}
                        currentRating={traitRating}
                        loading={!fullTrait.actionIds}
                        potentialActionIds={fullTrait.actionIds || []}
                        checkedActionIds={actionIds}
                        onCheckAction={props.onCheckAction}
                    />
                </div>
            </div>
        </AssessmentStep>
    );
};

RatingStep.propTypes = {
    prevActionIds: PropTypes.array,
    actionIds: PropTypes.array,
};

RatingStep.defaultProps = {
    prevActionIds: [],
    actionIds: [],
};
