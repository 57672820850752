import { groupBy } from "lodash";
import { lookCustomFields, lookOrgTableConfig, lookWorkspace } from "../redux/utils/looks";

export function getRootRoleId() {
    const workspace = lookWorkspace();
    return workspace && workspace.rootRoleId;
}

export function groupByCommonInfoDocId(list) {
    if (!list || list.length === 0) return [];
    return groupBy(list, function (entry) {
        return entry.publicInfoDocId;
    });
}

export function isOwner(employeeId) {
    const activeWorkspace = lookWorkspace();
    return activeWorkspace && activeWorkspace.ownerId === employeeId;
}

export function getFieldValue(fieldId, value) {
    const customFields = lookCustomFields();
    const customField = customFields.find((field) => field.id === fieldId);
    if (customField) {
        switch (customField.type) {
            case "picker":
                const option = customField.options.find((option) => option.value === value);
                return option ? option.label : value;
            default:
                return value;
        }
    } else {
        return value;
    }
}

export function getUpdatedWorkspaceTables(tableId, update) {
    const orgTableConfig = lookOrgTableConfig();
    const { tables = {} } = orgTableConfig;
    let newTables = { ...tables };
    if (!update) {
        delete newTables[tableId];
    } else {
        newTables[tableId] = update;
    }
    return newTables;
}
