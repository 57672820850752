import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { fetchTrait } from "../../firebase/fetch";
import { lookTrait } from "../../redux/utils/looks";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import WordCloud from "./WordCloud";

const TraitPopover = (props) => {
    if (!props.selectedTrait) return null;
    return (
        <Modal isOpen={true} centered backdrop toggle={props.deselect}>
            <ModalBody className="p-3 text-center">
                <h4 style={{ color: props.selectedTrait.color }}>{props.selectedTrait.traitName}</h4>
                <p>{props.selectedTrait.description}</p>
            </ModalBody>
        </Modal>
    );
};

const TraitWordCloud = (props) => {
    const [data, setData] = useState([]);
    const ratings = props.ratings;
    const [selectedTrait, setSelectedTrait] = useState();
    const colors = useSelector((state) => state.assessments.settings.allColors);

    useEffect(() => {
        let newData = [];
        let newHighestRating = 0;
        ratings.forEach((rating) => {
            const trait = lookTrait(rating.id);
            newData.push({
                word: trait && trait.traitName,
                traitId: rating.id,
                rating: rating.rating,
            });
            newHighestRating = Math.max(newHighestRating, rating.rating);
        });
        newData = orderBy(newData, ["value", "desc"]);
        setData(newData);
    }, [ratings]);

    const handleClickTag = async (word) => {
        const fullTrait = await fetchTrait(word.traitId);
        setSelectedTrait({ ...fullTrait, color: word.color });
    };

    if (props.isPending) {
        return <div className="f-1 d-flex justify-content-center"><LoadingIndicator /></div>;
    }

    return (
        <>
            <TraitPopover selectedTrait={selectedTrait} deselect={() => setSelectedTrait()} />
            <WordCloud wordEntries={data} colors={colors} onWordClick={(word) => handleClickTag(word)} />
        </>
    );
};

TraitWordCloud.propTypes = {
    ratings: PropTypes.array,
    maxSize: PropTypes.number,
};

TraitWordCloud.defaultProps = {
    ratings: [],
    maxSize: 32,
};

export default memo(TraitWordCloud);
