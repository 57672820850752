import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { listenSelfAssessment } from "../../../firebase/listeners";
import RatingComparisonTable from "../../../components/Tables/RatingComparisonTable/RatingComparisonTable";

const ComparisonArea = () => {
    const [selfRatings, setSelfRatings] = React.useState({});
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const snapshot = useSelector((state) => state.talent.snapshots?.[selectedEmployeeId]);
    const ratings = snapshot?.ratings;

    // Listen to the self assessment doc
    useEffect(() => {
        // Listen for the employee self-assessment, if self-assessment is enabled
        const unsubscribeSelfAssessment = listenSelfAssessment(selectedEmployeeId, (doc) => {
            if (doc) {
                const ratings = doc.data().ratings || {};
                setSelfRatings(ratings);
            } else {
                setSelfRatings({});
            }
        });

        return () => {
            unsubscribeSelfAssessment();
        };
    }, [selectedEmployeeId]);

    // TODO change randomRatings to selfRatings when finished testing
    const ratingGroup = useMemo(() => {
        return {
            employee: selfRatings,
            manager: ratings,
        };
    }, [selfRatings, ratings]);

    return (
        <div className="side-area no-scroll h-100 d-flex flex-column justify-content-between bg-transparent dashboard-headers">
            <div className="pt-3 px-3 border-bottom"></div>
            <div className="scrollable f-1">
                <RatingComparisonTable ratings={ratingGroup} />
            </div>
        </div>
    );
};

export default ComparisonArea;
