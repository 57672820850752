import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import SuccessionBoard from "../../components/SuccessionBoard/SuccessionBoard";
import { getChildRoleIds } from "../../utils/roleUtils";

const SuccessionPlanning = () => {
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const successionPlans = useSelector((state) => state.org.successionPlans);

    const roleIds = useMemo(() => getChildRoleIds(topLevelRoleId, true), [topLevelRoleId]);

    return (
        <div className="inner-container">
            <SuccessionBoard boardTopRoleId={topLevelRoleId} roleIds={roleIds} successionPlans={successionPlans} />
        </div>
    );
};

export default SuccessionPlanning;
