import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { lookClaims } from "../../../redux/utils/looks";
import { getChildEmployeeIds } from "../../../utils/roleUtils";
import IdPicker from "./IdPicker";

const EmployeePicker = (props) => {
    const {
        selectedIds,
        directReportsOnly,
        isClearable,
        isMulti,
        includeAll,
        excludeSelf,
        filter,
        onlyOrphans,
        includeOrphans,
    } = props;
    const [excludeIds, setExcludeIds] = useState([]);
    const employees = useSelector((state) => state.org.employees);
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);

    // If Include all, ignore the users topLevelRoleId and include all roles
    const topLevelFilterId = includeAll ? rootRoleId : topLevelRoleId;

    // Determine the additional ids to exclude based on the props provided
    useEffect(() => {
        let extraExcluded = [];
        const employeeList = Object.values(employees);
        if (onlyOrphans) {
            extraExcluded = employeeList.filter((employee) => !!employee.roleId).map((employee) => employee.id);
        } else if (!includeOrphans) {
            let includeIds = getChildEmployeeIds(topLevelFilterId, !directReportsOnly);
            if (!excludeSelf) {
                const claims = lookClaims();
                const selfEmployeeId = claims && claims.employeeId;
                includeIds.push(selfEmployeeId);
            }
            extraExcluded = employeeList
                .filter((employee) => !includeIds.includes(employee.id))
                .map((employee) => employee.id);
        }

        setExcludeIds(extraExcluded);
    }, [topLevelFilterId, employees, filter, onlyOrphans, directReportsOnly, includeOrphans, excludeSelf]);

    const getLabel = (employee) => {
        return employee && employee.displayName;
    };

    return (
        <IdPicker
            {...props}
            name="employees"
            getLabel={getLabel}
            options={employees}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds.concat(props.excludeIds)}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

EmployeePicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    includeAll: PropTypes.bool,
    excludeSelf: PropTypes.bool,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    directReportsOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

EmployeePicker.defaultProps = {
    selectedEmployeeIds: [],
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
};

export default EmployeePicker;
