import React, { useEffect, useRef, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { EllipsisEditButton } from "../Buttons/MyButtons";

const TableRowMenu = ({ children, disableCloseOnClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    return (
        <div ref={ref}>
            <UncontrolledDropdown isOpen={isOpen} className="me-3" direction="down">
                <DropdownToggle nav className="p-0">
                    <EllipsisEditButton onClick={() => setIsOpen(!isOpen)} />
                </DropdownToggle>
                <DropdownMenu end className="dropdown-menu" style={{ margin: 0 }}>
                    {React.Children.map(children, (child) => {
                        const onClick = () => {
                            child.props.onClick();
                            !disableCloseOnClick && setIsOpen(false);
                        };
                        if (child) {
                            return React.cloneElement(child, {
                                onClick: onClick,
                            });
                        }
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export default TableRowMenu;
