import { setDoc } from "firebase/firestore";
import { getWorkspaceDocRef } from "../firebaseUtils";

export function configureWorkspace(workspaceName) {
    const workspaceRef = getWorkspaceDocRef();
    const workspace = {
        workspaceName: workspaceName,
        configured: true,
    };
    return setDoc(workspaceRef, workspace, { merge: true });
}

export function updateWorkspace(values) {
    const workspaceDocRef = getWorkspaceDocRef();
    return setDoc(workspaceDocRef, values, { merge: true });
}

export function setDefaultTalentMapConfig(newConfig) {
    updateWorkspace({
        assessmentSettings: { talentMapConfig: newConfig },
    });
}
