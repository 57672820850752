import classNames from "classnames";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { setLocalPreferences } from "../../../redux/utils/dispatches";
import { lookCustomField } from "../../../redux/utils/looks";

function getOptionValue(fieldId, fieldValue) {
    return `${fieldId}-${fieldValue}`;
}

function createCustomFieldOptions(customFields, prevOptions) {
    let newOptions = [...prevOptions];
    const multipleOptionFields = customFields.filter((field) => field.type === "picker");
    const switchFields = customFields.filter((field) => field.type === "switch");

    switchFields.forEach((field) => {
        const switchOptions = [
            {
                label: "True",
                fieldId: field.id,
                type: "switch",
                fieldValue: true,
                category: field.category,
                value: getOptionValue(field.id, "true"),
            },
            {
                label: "False",
                fieldId: field.id,
                type: "switch",
                fieldValue: false,
                category: field.category,
                value: getOptionValue(field.id, "false"),
            },
        ];
        newOptions.push({
            label: field.label,
            fieldId: field.id,
            type: "switch",
            category: field.category,
            options: switchOptions,
            value: field.id,
        });
        return;
    });

    multipleOptionFields.forEach((field) => {
        const fieldOptions = field.options;
        const optionGroup = fieldOptions.map((fieldOption) => {
            return {
                label: fieldOption.label,
                fieldId: field.id,
                fieldValue: fieldOption.value,
                category: field.category,
                type: "picker",
                value: getOptionValue(field.id, fieldOption.value),
            };
        });
        newOptions.push({
            label: field.label,
            fieldId: field.id,
            type: "picker",
            category: field.category,
            options: optionGroup,
            value: field.id,
        });
    });
    return newOptions;
}

const TalentMapFilterPicker = (props) => {
    const talentBoards = useSelector((state) => state.talent.talentBoards);
    const customFields = useSelector((state) => state.workspace.customFields);
    const talentMapConfig = useSelector((state) => state.talent.talentMapConfig);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const [groupedOptions, setGroupedOptions] = useState([]);

    useEffect(() => {
        function boardInUse() {
            return true;
        }
        let newGroupedOptions = [];
        const assessmentFields = customFields.filter((field) => field.category === "assessment");
        const employeeFields = customFields.filter((field) => field.category === "employee");
        newGroupedOptions = createCustomFieldOptions(assessmentFields, []);
        newGroupedOptions = createCustomFieldOptions(employeeFields, newGroupedOptions);

        const talentBoardOptions = Object.values(talentBoards)
            .filter((board) => boardInUse(board.id))
            .map((board) => {
                return {
                    label: board.talentBoardName,
                    fieldId: "talentBoardIds",
                    fieldValue: board.id,
                    type: "talentBoard",
                    category: "employee",
                    value: getOptionValue("talentBoards", board.id),
                };
            });
        newGroupedOptions.push({
            label: "Talent Boards",
            fieldId: "talentBoardIds",
            type: "talentBoard",
            options: talentBoardOptions,
            category: "employee",
            value: "talentBoards",
        });

        setGroupedOptions(newGroupedOptions);
    }, [talentBoards, snapshots, customFields]);

    function deriveValueFromProps() {
        const filters = talentMapConfig.filters || [];
        return filters.map((filter) => {
            const primaryField = groupedOptions.find((group) => group.fieldId === filter.fieldId);
            const options = primaryField && primaryField.options;
            if (options) {
                const option = options.find((option) => option.fieldValue === filter.fieldValue);
                return {
                    ...option,
                    label: `${primaryField.label}: ${option.label}`,
                };
            } else {
                return primaryField;
            }
        });
    }

    const handleChange = (filters) => {
        let newConfig = { ...talentMapConfig };
        let newFilters = filters.map((filter) => {
            const field = lookCustomField(filter.fieldId);
            const axis = field && field.axis;
            let newFilter = {
                fieldId: filter.fieldId,
                fieldValue: filter.fieldValue,
                type: filter.type || false,
                category: filter.category || false,
                axis: axis || false,
            };
            return newFilter;
        });
        newConfig.filters = newFilters;
        setLocalPreferences({ talentMapConfig: newConfig }, true);
    };

    if (groupedOptions.length === 0) return null;

    return (
        <div className="picker">
            <Select
                name="field-picker"
                isDisabled={props.disabled}
                className={classNames("field-picker", props.className)}
                classNamePrefix="field-picker"
                maxMenuHeight={320}
                isMulti
                type="select"
                onChange={handleChange}
                options={groupedOptions}
                value={deriveValueFromProps()}
                placeholder={props.placeholder}
            />
        </div>
    );
};

TalentMapFilterPicker.propTypes = {
    axis: PropTypes.string,
    config: PropTypes.object,
    placeholder: PropTypes.string,
    excludeUnrated: PropTypes.bool,
};

TalentMapFilterPicker.defaultProps = {
    axis: "z",
    placeholder: "Choose filter",
    config: {},
    onBlur: () => {},
    onChange: () => {},
};

export default TalentMapFilterPicker;
