import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import { lookOverallRatingLabel, lookTalentBoard } from "../../../redux/utils/looks";
import { useLanguage } from "../../../translations/LanguageContext";
import { getExactColor } from "../../../utils/snapshotUtils";
import BreakdownModal from "./BreakdownModal";
import GaugeCard from "./GaugeCard";

const Gauges = ({ isPending, snap, employeeTalentBoardIds }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [boardId, setBoardId] = useState(null);
    const overallLabel = lookOverallRatingLabel();
    const scaleColors = useSelector((state) => state.assessments.settings.scaleColors);
    const overallRatingColor = getExactColor(snap.overall);
    const { t } = useLanguage();
    const breakdownHeader = t("rating_breakdown");

    const gauges = useMemo(() => {
        const boardIds = employeeTalentBoardIds || [];
        return boardIds.map((boardId) => {
            const board = lookTalentBoard(boardId);
            const boardRating = snap && snap.talentBoards && snap.talentBoards[boardId];
            return {
                boardRating: boardRating,
                ratingColor: getExactColor(boardRating),
                boardId: board.id,
                label: board && board.talentBoardName,
            };
        });
    }, [employeeTalentBoardIds, snap]);

    const handleCardClick = (boardId) => {
        setBoardId(boardId);
        setModalIsOpen(true);
    };

    return (
        <Container className="gauges" fluid>
            <Row>
                <GaugeCard
                    isPending={isPending}
                    value={snap.overall}
                    strokeColor={overallRatingColor}
                    colors={scaleColors}
                    label={overallLabel}
                    onClick={handleCardClick}
                />
                {gauges.map((gauge) => {
                    const { boardId, boardRating, ratingColor, label } = gauge;
                    return (
                        <GaugeCard
                            isPending={isPending}
                            boardId={boardId}
                            key={`board-${boardId}`}
                            value={boardRating}
                            strokeColor={ratingColor}
                            colors={scaleColors}
                            label={label}
                            onClick={handleCardClick}
                        />
                    );
                })}
            </Row>
            <BreakdownModal
                snapshot={snap}
                boardId={boardId}
                isOpen={modalIsOpen}
                toggle={() => setModalIsOpen(!modalIsOpen)}
                header={breakdownHeader}
            />
        </Container>
    );
};

export default Gauges;
