import classNames from "classnames";
import { useEffect, useState } from "react";
import {
    FaArrowLeft,
    FaArrowRight,
    FaBorderAll,
    FaCog,
    FaDna,
    FaSitemap,
    FaTachometerAlt,
    FaTasks,
} from "react-icons/fa";
import { BsLadder } from "react-icons/bs";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo/logo-50x50.png";
import { SaveIcon, TalentAreaIcon } from "../components/Icons/MyIcons";
import { ALT_TEXT } from "../constants";
import { saveLocalPreferences } from "../firebase/actions/user";
import { checkAccess } from "../utils/userUtils";
import { isEqual } from "lodash";

const AppSideBar = () => {
    const navigate = useNavigate();
    const [settingsSaving, setSettingsSaving] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [unsavedPreferences, setUnsavedPreferences] = useState(false);
    const [keepExpanded, setKeepExpanded] = useState(false);
    const [delayHandler, setDelayHandler] = useState(null);
    const localPreferences = useSelector((state) => state.user.localPreferences);
    const savedPreferences = useSelector((state) => state.user.savedPreferences);
    const enabledModules = useSelector((state) => state.workspace.enabledModules);
    const { successionPlanning } = enabledModules;

    useEffect(() => {
        const timeout = setTimeout(() => {
            setUnsavedPreferences(!isEqual(localPreferences, savedPreferences));
            setSettingsSaving(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [savedPreferences, localPreferences]);

    const disableAutoOpen = true;

    const handleMouseEnter = () => {
        if (!keepExpanded && !disableAutoOpen) {
            setDelayHandler(
                setTimeout(() => {
                    setExpanded(true);
                }, 1400)
            );
        }
    };

    const handleGoHome = () => [navigate("/people")];

    const handleMouseLeave = () => {
        if (!keepExpanded && !disableAutoOpen) {
            clearTimeout(delayHandler);
            setExpanded(false);
        }
    };

    const handleSetKeepExpanded = () => {
        setKeepExpanded(!expanded);
        setExpanded(!expanded);
    };

    const handleSavePreferences = () => {
        if (!settingsSaving) {
            setSettingsSaving(true);
            saveLocalPreferences();
        }
    };

    return (
        <ProSidebar collapsed={!expanded}>
            <SidebarHeader>
                <img className="clickable logo" src={logo} alt={ALT_TEXT.LOGO} onClick={handleGoHome} />
            </SidebarHeader>
            <SidebarContent onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Menu iconShape="circle">
                    <MenuItem title="Dashboard" icon={<FaTachometerAlt />}>
                        Dashboard
                        <Link to="dashboard" />
                    </MenuItem>
                    <MenuItem title="People" icon={<FaSitemap />}>
                        People
                        <Link to="people" />
                    </MenuItem>
                    {checkAccess("talent") && (
                        <>
                            <MenuItem title="Traits" icon={<FaDna />}>
                                Traits
                                <Link to="traits" />
                            </MenuItem>
                            <MenuItem title="Talent Areas" icon={<TalentAreaIcon />}>
                                Talent Areas
                                <Link to="talentareas" />
                            </MenuItem>
                            <MenuItem title="Talent Boards" icon={<FaBorderAll />}>
                                Talent Boards
                                <Link to="talentboards" />
                            </MenuItem>
                            <MenuItem title="Actions" icon={<FaTasks />}>
                                Actions
                                <Link to="actions" />
                            </MenuItem>
                            {successionPlanning && (
                                <MenuItem title="Succession Planning" icon={<BsLadder />}>
                                    Succession Planning
                                    <Link to="succession-planning" />
                                </MenuItem>
                            )}
                        </>
                    )}
                    <MenuItem title="Settings" icon={<FaCog />}>
                        Settings
                        <Link to="settings/profile" />
                    </MenuItem>
                </Menu>
            </SidebarContent>
            {disableAutoOpen && (
                <SidebarFooter className={classNames(expanded && "expanded")}>
                    <Menu>
                        <MenuItem
                            title="Save Current Config"
                            icon={<SaveIcon />}
                            hidden={!unsavedPreferences}
                            onClick={handleSavePreferences}
                        >
                            Save Current Config
                        </MenuItem>
                        <MenuItem onClick={handleSetKeepExpanded}>
                            {expanded ? <FaArrowLeft /> : <FaArrowRight />}
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            )}
        </ProSidebar>
    );
};

export default AppSideBar;
