import React, { useState, useEffect } from "react";
import "./tabs.scss";
import classNames from "classnames";

const Tabs = ({ children, className }) => {
    const [activeTab, setActiveTab] = useState(null);
    const [tabData, setTabData] = useState([]);

    useEffect(() => {
        const tabs = React.Children.map(children, (child) => ({
            key: child.props.tabKey,
            label: child.props.label,
        }));

        setTabData(tabs);
        if (tabs.length > 0) {
            setActiveTab(tabs[0].key);
        }
    }, [children]);

    if (tabData.length === 1) return <>{children}</>;

    return (
        <div className={classNames("app-tabs d-flex flex-column", className)}>
            <ul className="nav nav-tabs justify-content-start">
                {tabData.map((tab) => (
                    <li key={tab.key} className="nav-item">
                        <button
                            className={`nav-link ${activeTab === tab.key ? "active" : ""}`}
                            onClick={() => setActiveTab(tab.key)}
                        >
                            {tab.label}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="tab-content f-1 d-flex flex-column">
                {React.Children.map(children, (child) => {
                    if (child.type.name === "Tab" && child.props.tabKey === activeTab) {
                        return (
                            <div
                                className={classNames("f-1 d-flex flex-column tab-pane fade", child.props.className, {
                                    "show active": activeTab === child.props.tabKey,
                                })}
                            >
                                {child.props.children}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    );
};

const Tab = ({ tabKey, label, children, className }) => {
    return <>{children}</>;
};

export default Tabs;
export { Tab };
