import React, { useMemo } from "react";
import { lookTrait } from "../../../redux/utils/looks";
import { getExactColor } from "../../../utils/snapshotUtils";
import ChartContainer from "../BaseCharts/ChartContainer";
import PolarAreaChart from "../BaseCharts/PolarAreaChart";

const TraitPolarAreaChart = ({ ratings, padding, selected, onSegmentHover }) => {
    const data = useMemo(() => {
        return Object.entries(ratings).map(([traitId, rating]) => {
            const trait = lookTrait(traitId);
            return {
                id: traitId,
                label: trait && trait.traitName,
                value: rating,
                color: getExactColor(rating),
            };
        });
    }, [ratings]);

    const handleItemHover = (item) => {
        const id = item?.data?.id;
        onSegmentHover(id);
    };

    return (
        <ChartContainer padding={padding}>
            <PolarAreaChart
                max={100}
                data={data}
                selected={selected}
                onItemEnter={handleItemHover}
                onItemLeave={handleItemHover}
            />
        </ChartContainer>
    );
};

export default TraitPolarAreaChart;
