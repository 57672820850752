import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { FormStep, FormStepper, RolePicker } from "../..";
import { moveEmployeeToNewRole } from "../../../firebase/actions/employee";
import { moveRole } from "../../../firebase/actions/role";
import { lookEmployee, lookRole } from "../../../redux/utils/looks";
import { useLanguage } from "../../../translations/LanguageContext";
import { getUniqueId } from "../../../utils/basicUtils";
import { getChildRoleIds } from "../../../utils/roleUtils";
import RadioButtons from "../../Buttons/RadioButtons";

const MoveRoleOrEmployeeInnerForm = ({
    toggle,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setStatus,
    language,
    validationSchemas,
    status,
}) => {
    const handleChangeOption = (option) => {
        setFieldTouched("moveType", true);
        setFieldValue("moveType", option);
    };

    const role = lookRole(status.selectedRoleId);
    const employee = lookEmployee(status.selectedEmployeeId);

    const renderHeader = () => {
        const title = employee ? employee.displayName : role.jobTitle;
        const subTitle = employee && role.jobTitle;
        return (
            <div>
                <h6 className="mb-2">{title}</h6>
                {subTitle && <p className="fs-small text-muted">{subTitle}</p>}
            </div>
        );
    };

    return (
        <FormStepper
            isOpen={status.isOpen}
            toggle={toggle}
            onSubmit={handleSubmit}
            validationSchemas={validationSchemas}
            values={values}
            size="md"
            headerText={language.form_header}
            isWaiting={status.isWaiting}
            minHeight={460}
        >
            <FormStep title="Move To">
                <FormGroup className="mb-3">
                    {renderHeader()}
                    <Label>{language.choose_parent}</Label>
                    <RolePicker
                        selectedIds={values.parentRoleId || ""}
                        filterBasisRoleId={status.selectedRoleId}
                        excludeJuniors
                        excludeTeam
                        excludeSelf
                        excludeParent
                        onBlur={() => {
                            setFieldTouched("parentRoleId", true);
                        }}
                        onChange={(parentRoleId) => {
                            setFieldValue("parentRoleId", parentRoleId);
                        }}
                    />
                </FormGroup>
                {status.hasChildren && (
                    <FormGroup>
                        <Label>{language.move_type}</Label>
                        <RadioButtons
                            options={language.move_options}
                            onChange={handleChangeOption}
                            selected={values.moveType}
                        />
                    </FormGroup>
                )}
                {values.moveType === "employee" && <p className="fs-normal">{language.employee_move}</p>}
                {values.moveType === "branch" && (
                    <div className="move-type-info">
                        <p>{language.role_move}</p>
                    </div>
                )}
            </FormStep>
        </FormStepper>
    );
};

const MoveRoleOrEmployeeForm = withFormik({
    mapPropsToStatus: (props) => {
        const { selectedEmployeeId, selectedRoleId } = props;
        return {
            isOpen: props.isOpen,
            childRoles: props.childRoles,
            selectedRoleId: selectedRoleId,
            selectedEmployeeId: selectedEmployeeId,
            hasChildren: props.hasChildren,
            isWaiting: props.isWaiting,
        };
    },
    mapPropsToValues: (props) => {
        if (!props.hasChildren) {
            return {
                moveType: "employee",
            };
        } else {
            return {};
        }
    },
    validationSchema: (props) => {
        let mergedSchema = {};
        props.validationSchemas.forEach((schema) => {
            mergedSchema = { ...mergedSchema, ...schema };
        });
        return Yup.object().shape(mergedSchema);
    },
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
})(MoveRoleOrEmployeeInnerForm);

const MoveRoleOrEmployee = (props) => {
    const [newParentId, setNewParentId] = useState();
    const [isWaiting, setIsWaiting] = useState(false);
    const [movingToNewRole, setMovingToNewRole] = useState(false);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const allRoles = useSelector((state) => state.org.roles);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const childRoles = getChildRoleIds(selectedRoleId);
    const hasChildren = childRoles.length > 0;
    const newParent = allRoles[newParentId];
    const parentChildren = newParent && newParent.childRoles;
    const toggle = props.toggle;
    const { t } = useLanguage();
    const language = t("move_role_or_employee", "forms");

    // Wait for the newParent to have a new roleId when a role is being created by the move
    useEffect(() => {
        const childRoles = parentChildren || [];
        if (childRoles.includes(movingToNewRole)) {
            toast.success(t("employee_moved", "toasts"));
            toggle();
        }
    }, [t, movingToNewRole, parentChildren, toggle]);

    // Wait for the newParent to have this role as a child when this role is moving
    useEffect(() => {
        const childRoles = parentChildren || [];
        if (childRoles.includes(selectedRoleId)) {
            toast.success(t("role_moved", "toasts"));
            toggle();
        }
    }, [t, parentChildren, toggle, selectedRoleId]);

    const validationSchemas = [
        {
            parentRoleId: Yup.string().required(),
            moveType: Yup.string().required(),
        },
    ];

    const handleSubmit = (values) => {
        if (values.parentRoleId) {
            setNewParentId(values.parentRoleId);
            setIsWaiting(true);
            const moveBranch = values.moveType === "branch";
            if (!hasChildren || moveBranch) {
                moveRole(selectedRoleId, values.parentRoleId)
                    .commit()
                    .then()
                    .catch((error) => {
                        toast.error(t("role_move_failed", "toasts"));
                    });
            } else {
                // Move the employee to a new role if the employee will vacate a role when the move
                const newRoleId = getUniqueId();
                setMovingToNewRole(newRoleId);
                moveEmployeeToNewRole(newRoleId, selectedEmployee.id, values.parentRoleId)
                    .commit()
                    .then()
                    .catch((error) => {
                        toast.error(t("employee_move_failed", "toasts"));
                    });
            }
        }
    };

    return (
        <MoveRoleOrEmployeeForm
            selectedRoleId={selectedRoleId}
            selectedEmployeeId={selectedEmployeeId}
            roleIsVacant={!selectedEmployeeId}
            childRoles={childRoles}
            hasChildren={hasChildren}
            isWaiting={isWaiting}
            language={language}
            validationSchemas={validationSchemas}
            onSubmit={handleSubmit}
            {...props}
        />
    );
};

export default MoveRoleOrEmployee;
