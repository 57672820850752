import React, { useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import { saveSuccessors } from "../../firebase/actions/role";
import { lookEmployee, lookRole } from "../../redux/utils/looks";
import { sortArrayOfObjects, yearsAgo } from "../../utils/basicUtils";
import { getChildRoleIds, getRoleIncumbent, getSuccessionStrength } from "../../utils/roleUtils";
import DraggableBoard from "../DraggableBoard/DraggableBoard";
import SuccessionOptions from "./SuccessionOptions";
import SuccessionSummary from "./SuccessionSummary";
import SuccessionToolbar from "./SuccessionToolbar";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

function areItemsEqual(arr1, arr2) {
    // Check if both arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Create two new arrays containing only colId and employeeId keys
    const reducedArr1 = arr1.map((obj) => ({ colId: obj.colId, employeeId: obj.employeeId }));
    const reducedArr2 = arr2.map((obj) => ({ colId: obj.colId, employeeId: obj.employeeId }));

    // Sort both arrays by colId and employeeId
    const sortedArr1 = reducedArr1.sort((a, b) =>
        a.colId === b.colId ? a.employeeId - b.employeeId : a.colId - b.colId
    );
    const sortedArr2 = reducedArr2.sort((a, b) =>
        a.colId === b.colId ? a.employeeId - b.employeeId : a.colId - b.colId
    );

    // Compare both sorted arrays
    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i].colId !== sortedArr2[i].colId || sortedArr1[i].employeeId !== sortedArr2[i].employeeId) {
            return false;
        }
    }

    // If the function didn't return false before this point, the arrays are equal
    return true;
}

function getKey(roleId, employeeId) {
    return `${roleId}-${employeeId}`;
}

function prepItem(employeeId, roleId, colId, businessUnitRoles, savedPlan) {
    const today = new Date();
    const ageDetails = {
        highlightLevel: savedPlan?.yearsAgo ?? 0,
        subtitle: getAgeString(savedPlan?.yearsAgo ?? 0),
        planDate: savedPlan?.date ?? today,
        yearsAgo: yearsAgo(savedPlan?.date) ?? 0,
    };

    const employee = lookEmployee(employeeId);
    if (employee) {
        const outsideBusinessUnit = !businessUnitRoles?.includes(employee.roleId);
        return {
            key: getKey(roleId, employeeId),
            ...ageDetails,
            roleId: roleId,
            employeeId: employeeId,
            colId: colId,
            rowId: roleId,
            title: employee?.displayName,
            external: outsideBusinessUnit,
            gender: employee.gender,
        };
    } else {
        return null;
    }
}

function getAgeString(yearsAgo) {
    if (yearsAgo < 1) {
        return "Added this year";
    } else {
        return `Added ${yearsAgo} years ago`;
    }
}

const boardColumns = [
    { key: 1, title: "Ready", color: "#AEE7C6" },
    { key: 2, title: "Preparing", color: "#F7EDC7" },
    { key: 3, title: "Potential", color: "#F5D9C4" },
];

const searchKeys = ["title", "subtitle", "jobTitle", "department", "email"];

const SuccessionBoard = (props) => {
    const { successionPlans, roleIds, boardTopRoleId, departmentView, excludeOptionIds } = props;
    const boardRef = useRef();
    const [savePending, setSavePending] = useState(false);
    const [savedRows, setSavedRows] = useState([]);
    const [unsavedRows, setUnsavedRows] = useState([]);
    const [view, setView] = useState(departmentView ? "team" : "branch");
    const [filteredRowIds, setFilteredRowIds] = useState();
    const employees = useSelector((state) => state.org.employees);
    const roleSnapshotsUpdating = useSelector((state) => state.org.roleSnapshotsUpdating);

    // Prep board structure
    useEffect(() => {
        if (!roleSnapshotsUpdating) {
            const newSavedRows = roleIds.map((roleId) => {
                const successionPlan = successionPlans[roleId] ?? {};
                const { successors = [], strength } = successionPlan;
                const preppedSuccessors = successors.map((successor) => {
                    const { key, colId } = successor;
                    return prepItem(key, roleId, colId, roleIds, successor);
                });
                const incumbent = getRoleIncumbent(roleId);
                const role = lookRole(roleId);
                return {
                    key: roleId,
                    title: role.jobTitle,
                    subtitle: incumbent?.displayName,
                    items: preppedSuccessors,
                    strength: strength || 0,
                };
            });
            setSavePending(false);
            setSavedRows(newSavedRows);
        }
    }, [roleIds, roleSnapshotsUpdating, successionPlans]);

    // Clear unsaved rows when a save completes
    useEffect(() => {
        if (!savePending) {
            setUnsavedRows([]);
        }
    }, [savePending]);

    const rows = useMemo(() => {
        return roleIds
            .map((roleId) => {
                const unsavedRow = unsavedRows.find((unsavedRow) => unsavedRow.key === roleId);
                if (unsavedRow) {
                    return unsavedRow;
                } else {
                    return savedRows.find((savedRow) => savedRow.key === roleId);
                }
            })
            .filter((row) => !!row);
    }, [roleIds, savedRows, unsavedRows]);

    const visibleRows = useMemo(() => {
        let newVisibleRows = rows ? [...rows] : [];

        const teamIds = getChildRoleIds(boardTopRoleId, false);

        // If the team filter is on, show only the rows that are direct children of the top role
        if (view === "team") {
            newVisibleRows = newVisibleRows.filter((row) => teamIds.includes(row.key));
        }

        // Show only the rows in the filteredRowIds array
        if (filteredRowIds) {
            newVisibleRows = newVisibleRows.filter((row) => filteredRowIds.includes(row.key));
        }

        // sort the rows so the ones with a roleId that is a direct child of the top role are first
        if (departmentView) {
            let teamRows = newVisibleRows.filter((row) => teamIds.includes(row.key));
            let otherRows = newVisibleRows.filter((row) => !teamIds.includes(row.key));
            teamRows = sortArrayOfObjects(teamRows, "title");
            otherRows = sortArrayOfObjects(otherRows, "title");
            newVisibleRows = teamRows.concat(otherRows);
        } else {
            newVisibleRows = sortArrayOfObjects(newVisibleRows, "title");
        }
        return newVisibleRows;
    }, [boardTopRoleId, departmentView, filteredRowIds, rows, view]);

    const options = useMemo(() => {
        const opts = Object.values(employees).map((employee) => {
            const role = lookRole(employee.roleId);
            const jobTitle = role ? role.jobTitle : "";
            return {
                key: employee.id,
                employeeId: employee.id,
                title: employee.displayName,
                subtitle: jobTitle,
            };
        });
        return sortArrayOfObjects(opts, "title");
    }, [employees]);

    const handleSaveChanges = () => {
        setSavePending(true);
        unsavedRows.forEach((row) => {
            const successors = row?.items ?? [];
            const cleanedSuccessors = successors.map((item) => {
                return {
                    e: item.employeeId,
                    d: item.planDate,
                    p: item.colId,
                };
            });
            saveSuccessors(row.key, cleanedSuccessors).commit();
        });
    };

    const handleDiscardChanges = () => {
        setUnsavedRows([]);
    };

    const handleDrop = (droppedItem, newRowId, newColId) => {
        // don't drop if the employee is the role incumbent
        if (droppedItem.employeeId === getRoleIncumbent(newRowId)?.id) {
            return;
        }

        // Don't drop if it's being dropped to the same place
        if (droppedItem.rowId === newRowId && droppedItem.colId === newColId) {
            return;
        }

        setUnsavedRows((prev) => {
            let newUnsavedRows = [...prev];
            let { rowId: prevRowId, isNew, ...updatedItem } = droppedItem;

            // Row being dropped on
            const unsavedNewRow = newUnsavedRows.find((row) => row.key === newRowId);
            const savedNewRow = savedRows.find((row) => row.key === newRowId);
            const newRow = unsavedNewRow || savedNewRow;
            let updatedNewRow = Object.assign({}, newRow);
            let rowItems = [...(updatedNewRow?.items ?? [])];

            // Remove the previous item;
            if (isNew || newRowId === prevRowId) {
                // Ensure no duplicate item on the row
                rowItems = rowItems.filter((item) => item.employeeId !== droppedItem.employeeId);
            } else {
                // Update the row the card was removed from
                const unsavedPrevRow = newUnsavedRows.find((row) => row.key === prevRowId);
                const savedPrevRow = savedRows.find((row) => row.key === prevRowId);
                const prevRow = unsavedPrevRow || savedPrevRow;
                let updatedPrevRow = Object.assign({}, prevRow);
                let prevRowItems = [...(updatedPrevRow?.items ?? [])];
                prevRowItems = prevRowItems.filter((item) => item.employeeId !== droppedItem.employeeId);
                updatedPrevRow.items = prevRowItems;
                updatedPrevRow.strength = getSuccessionStrength(prevRowItems);

                // Remove the previous row from the unsaved rows, queue it for saving if it doesn't match the saved row
                newUnsavedRows = newUnsavedRows.filter((row) => row.key !== prevRowId);
                if (!areItemsEqual(updatedPrevRow.items, savedPrevRow.items)) {
                    newUnsavedRows.push(updatedPrevRow);
                }
            }

            // Prep & add the new item, rescore the row the card was added to
            const successionPlan = successionPlans[newRowId] ?? {};
            const { successors = [] } = successionPlan;
            const savedPlan = successors.find((plan) => plan.key === updatedItem.employeeId && plan.colId === newColId);
            updatedItem = prepItem(updatedItem.employeeId, newRowId, newColId, roleIds, savedPlan);
            rowItems.push(updatedItem);
            updatedNewRow.strength = getSuccessionStrength(rowItems);
            updatedNewRow.items = rowItems;

            // Add the row to the unsaved rows
            newUnsavedRows = newUnsavedRows.filter((row) => row.key !== newRowId);
            // If the updatedRow doesn't match the save row, queue it up for saving
            if (!areItemsEqual(rowItems, savedNewRow?.items)) {
                newUnsavedRows.push(updatedNewRow);
            }
            return newUnsavedRows;
        });
    };

    const handleRemove = (item) => {
        const { rowId, employeeId } = item;
        const row = rows.find((row) => row.key === rowId);
        let updatedRow = Object.assign({}, row);
        let newItems = [...(updatedRow.items || [])];
        newItems = newItems.filter((item) => item.employeeId !== employeeId);
        updatedRow.items = newItems;
        updatedRow.strength = getSuccessionStrength(newItems);

        let newUnsavedRows = [...unsavedRows];
        newUnsavedRows = newUnsavedRows.filter((row) => row.key !== rowId);

        const savedRow = savedRows.find((row) => row.key === rowId);
        if (!areItemsEqual(newItems, savedRow.items)) {
            newUnsavedRows.push(updatedRow);
        }
        setUnsavedRows(newUnsavedRows);
    };

    const summaryProps = useMemo(() => {
        return {
            rows: visibleRows,
        };
    }, [visibleRows]);

    const toolbarProps = useMemo(() => {
        return {
            searchKeys: searchKeys,
            view: view,
            departmentView: departmentView,
            rows: rows,
            disabled: savePending,
            unsavedChanges: unsavedRows.length > 0,
            boardRef: boardRef,
        };
    }, [boardRef, departmentView, rows, savePending, unsavedRows, view]);

    const toolbarFunctions = {
        onChangeView: setView,
        setFilteredRowIds: setFilteredRowIds,
        onSave: handleSaveChanges,
        onDiscard: handleDiscardChanges,
    };

    const optionProps = useMemo(() => {
        return {
            excludeOptionIds: excludeOptionIds || [],
            options: options,
        };
    }, [excludeOptionIds, options]);

    if (!rows || rows.length === 0) return <LoadingIndicator />;

    return (
        <div className="inner-container succession-planning">
            <DndProvider backend={HTML5Backend}>
                <DraggableBoard
                    ref={boardRef}
                    rows={visibleRows}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                    filteredRowIds={filteredRowIds}
                    columns={boardColumns}
                    summaryComponent={SuccessionSummary}
                    toolbarComponent={SuccessionToolbar}
                    optionsComponent={SuccessionOptions}
                    summaryProps={summaryProps}
                    toolbarProps={{ ...toolbarProps, ...toolbarFunctions }}
                    optionProps={optionProps}
                />
            </DndProvider>
        </div>
    );
};

export default SuccessionBoard;
