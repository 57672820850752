import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTraitsToTalentBoard } from "../../../firebase/actions/talentBoard";
import { setTrait } from "../../../firebase/actions/trait";
import { lookTalentBoard } from "../../../redux/utils/looks";
import { useLanguage } from "../../../translations/LanguageContext";
import { getUniqueId } from "../../../utils/basicUtils";
import TraitForm from "./TraitForm";
import * as Yup from "yup";
import { FIELD_SIZE_LIMITS } from "../../../constants";

const CreateTrait = (props) => {
    const [createdId, setCreatedId] = useState(false);
    const selectedTalentAreaId = useSelector((state) => state.app.selectedTalentAreaId);
    const pendingChanges = useSelector((state) => state.talent.pendingChanges);
    const isWaiting = createdId && pendingChanges.includes(createdId);
    const toggle = props.toggle;
    const { t } = useLanguage();

    const initialValues = {
        talentAreaIds: selectedTalentAreaId ? [selectedTalentAreaId] : [],
        actionIds: [],
        traitAlreadyExists: false,
    };

    // Closes the modal once the trait update has fully completed
    useEffect(() => {
        if (createdId && !isWaiting) {
            toggle();
        }
    }, [createdId, isWaiting, toggle]);

    const handleSubmit = (values) => {
        const newId = getUniqueId();
        setCreatedId(newId);
        let { talentBoardIds, ...traitUpdate } = values;
        traitUpdate = { ...traitUpdate, id: newId };
        setTrait(traitUpdate)
            .then(() => {
                // Add traits to talentBoards if needed
                if (talentBoardIds && talentBoardIds.length > 0) {
                    talentBoardIds
                        .filter((boardId) => {
                            const talentBoard = lookTalentBoard(boardId);
                            const traitsAlreadyOnBoard = talentBoard.traitIds || [];
                            return !traitsAlreadyOnBoard.includes(newId);
                        })
                        .forEach((boardId) => {
                            addTraitsToTalentBoard(boardId, [newId]);
                        });
                }
            })
            .catch((error) => {
                toast.error(t("trait_create_failed", "toasts"));
                toggle();
            });
    };

    const validationSchemas = [
        {
            traitName: Yup.string()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.TRAIT_NAME, t("length_max", "warnings")),
            traitAlreadyExists: Yup.boolean().oneOf([false], t("name_in_use", "warnings")),
            talentAreaIds: Yup.array().min(1, t("talent_area_required", "warnings")),
        },
    ];

    return (
        <TraitForm
            {...props}
            isWaiting={isWaiting}
            onSubmit={handleSubmit}
            validationSchemas={validationSchemas}
            initialValues={initialValues}
            header="Create Trait"
        />
    );
};

export default CreateTrait;
