import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { getExactColor } from "../../../utils/snapshotUtils";

const TraitPieChart = ({ className, showGridPosition, ratingCounts = [] }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        let newData = [];
        if (showGridPosition) {
            newData = ratingCounts.map((entry) => {
                return { value: entry.count, color: entry.color };
            });
        } else {
            newData = Object.entries(ratingCounts).map(([rating, count]) => {
                return { value: count, color: getExactColor(rating) };
            });
        }
        if (newData.length === 0) {
            newData = [{ value: 1, color: "#e3e3e3" }];
        }
        setData(newData);
    }, [showGridPosition, ratingCounts]);

    return (
        <div className={className} style={{ height: "100%", margin: "auto" }}>
            <PieChart data={data} />
        </div>
    );
};

export default TraitPieChart;