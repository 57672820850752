import { updateWorkspace } from "../../../firebase/actions/workspace";

const newPointThree =
    "High level of drive, agility, and builds brilliant relationships and potential for a bigger or more complex role including line management.";

const pointFour = "Potential for a bigger or more complex role and future leader";

const fieldOne = {
    axis: false,
    category: "assessment",
    enabled: true,
    fieldInfo: ["Regularly goes above and beyond to support the division"],
    label: "Supports the Division",
    id: "f9",
    type: "switch",
};

const fieldTwo = {
    axis: false,
    category: "assessment",
    enabled: true,
    fieldInfo: ["Regularly goes above and beyond to support Hachette UK"],
    label: "Supports Hachette UK",
    id: "f10",
    type: "switch",
};

function updateTopTalent(current) {
    const fieldInfo = current.fieldInfo;
    // new array with only first 3 elements
    const newFieldInfo = fieldInfo.slice(0, 3);
    newFieldInfo.push(newPointThree);
    newFieldInfo.push(pointFour);
    const updated = { ...current, fieldInfo: newFieldInfo };
    return updated;
}

export const updateCustomGrid = (customFields, topTalent) => {
    let newCustomFields = [...customFields];
    const updatedAlready = newCustomFields.find((field) => field.id === "f9");
    if (!updatedAlready) {
        newCustomFields.push(fieldOne);
        newCustomFields.push(fieldTwo);
    }
    newCustomFields = newCustomFields.filter((field) => field.id !== "f4");
    const newTopTalent = updateTopTalent(topTalent);
    newCustomFields.push(newTopTalent);
    const update = {
        assessmentSettings: {
            gridThresholds: [
                { col: 1, row: 1, min: 1, max: 25, label: "Under performer" },
                { col: 1, row: 2, min: 26, max: 50, label: "Inconsistent" },
                { col: 2, row: 1, min: 51, max: 85, label: "Good fit" },
                { col: 3, row: 1, min: 86, max: 100, label: "Expert Guru" },
            ],
        },
        customFields: newCustomFields,
    };
    updateWorkspace(update);
};
