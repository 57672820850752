import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import ValueWidget from "../Widgets/ValueWidget";

const SuccessionSummary = ({ rows = [] }) => {
    const allItems = rows.flatMap((row) => row.items);
    const totalSuccessors = allItems.length || 0;
    const kpis = useMemo(() => {
        // Calculate the average strength of the visible rows and add it to the kpis
        const totalStrength = rows.reduce((acc, row) => acc + (row.strength || 0), 0);
        const strength = Math.round(totalStrength / rows.length);
        let newKpis = [
            {
                key: "strength",
                title: `Succession Strength`,
                subtitle: `${rows.length} roles`,
                value: strength,
                size: 58,
                append: "%",
                primary: true,
            },
        ];

        // Total number of successors on the board
        newKpis.push({
            key: "totalSuccessors",
            title: "Successors on Board",
            value: totalSuccessors,
            size: 24,
        });

        // the number of items in allItems with an external value as true
        const externalSuccessors = allItems.filter((item) => item.external).length;
        let externalRatio = Math.round((externalSuccessors / totalSuccessors) * 100);
        if (isNaN(externalRatio)) {
            externalRatio = 0;
        }
        newKpis.push({
            key: "externalSuccessors",
            title: "Outside Business Unit",
            subtitle: `(${externalRatio}%)`,
            value: externalSuccessors,
            size: 24,
        });

        // number of female items in allItems
        const femaleSuccessors = allItems.filter((item) => item.gender === "f").length;
        let femaleRatio = Math.round((femaleSuccessors / totalSuccessors) * 100);
        if (isNaN(femaleRatio)) {
            femaleRatio = 0;
        }
        newKpis.push({
            key: "femaleSuccessors",
            title: "Female Successors",
            subtitle: `(${femaleRatio}%)`,
            value: femaleSuccessors,
            size: 24,
        });

        // Number of yearsAgo items 1 or under in allItems
        const underOneYear = allItems.filter((item) => item.yearsAgo < 1).length;
        let oneYearRatio = Math.round((underOneYear / totalSuccessors) * 100);
        newKpis.push({
            key: "underOneYear",
            title: "In Position for < 1 Year",
            value: underOneYear,
            subtitle: `(${oneYearRatio}%)`,
            size: 24,
        });

        // Number of successors on the board that are 1-3 years old
        const oneToThree = allItems.filter((item) => item.yearsAgo > 1 && item.yearsAgo < 3).length;
        let oneToThreeRatio = Math.round((oneToThree / totalSuccessors) * 100);
        newKpis.push({
            key: "oneToThreeYears",
            title: "In Position for 1-3 Years",
            value: oneToThree,
            subtitle: `(${oneToThreeRatio}%)`,
            size: 24,
        });

        // Number of successors on the board that are 3+ years old
        const overThree = allItems.filter((item) => item.yearsAgo >= 3).length;
        let overThreeRatio = Math.round((overThree / totalSuccessors) * 100);
        newKpis.push({
            key: "overThreeYears",
            title: "In Position for 3+ Years",
            value: overThree,
            subtitle: `(${overThreeRatio}%)`,
            size: 24,
        });

        return newKpis;
    }, [rows, allItems, totalSuccessors]);

    const renderWidget = (kpi) => {
        const bgColor = kpi.primary && "#4878ff";
        const color = kpi.primary && "#fff";
        return (
            <ValueWidget
                primary={kpi.primary}
                key={kpi.key}
                value={`${kpi.value}${kpi.append || ""}`}
                info={kpi.title}
                subInfo={kpi.subtitle || " "}
                size={kpi.size}
                bgColor={bgColor}
                color={color}
            />
        );
    };

    const primaryWidget = kpis[0];
    const secondaryWidgets = kpis.slice(1);
    return (
        <div>
            <Row className="g-0">
                <Col md={3}>{renderWidget(primaryWidget)}</Col>
                <Col md={9}>
                    <Row className="g-0">
                        {secondaryWidgets.map((kpi, i) => {
                            return (
                                <Col md={4} key={kpi.key}>
                                    {renderWidget(kpi)}
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default SuccessionSummary;

// For when you enable the custom widgets:
/*
const placeholderWidget = {
    label: "Add Widget",
};
const allItems = rows.flatMap((row) => row.items);
const uniqueKeys = [
    ...new Map(
        allItems.flatMap((item) => Object.entries(item)).map(([key, value]) => [key, typeof value])
    ).entries(),
];

// Add placeholders to ensure there are always 6 widgets
const showWidgets = useMemo(() => {
    let toShow = [];
    for (let i = 0; i < 6; i++) {
        const widget = widgets[i];
        toShow.push(widget || placeholderWidget);
    }
    return toShow;
}, [widgets]);

*/
