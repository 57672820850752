import { increment, serverTimestamp, setDoc, writeBatch } from "firebase/firestore";
import { chunk } from "lodash";
import { setPendingSnapshot } from "../../redux/utils/dispatches";
import { lookClaims, lookEmployee, lookSnapshotId, lookUser } from "../../redux/utils/looks";
import { getRoleIncumbent } from "../../utils/roleUtils";
import { fetchLatestAssessment } from "../fetch";
import { db } from "../firebase";
import {
    getAssessmentDatesDocRef,
    getAssessmentDocRef,
    getEmployeeDocRef,
    getNotificationDocRef,
    getSelfAssessmentDocRef,
    getWorkspaceDocRef,
} from "../firebaseUtils";
import { cLog } from "../../utils/basicUtils";

export async function closeAssessmentPeriod() {
    const workspaceDocRef = getWorkspaceDocRef();
    let workspaceUpdate = {
        assessmentSettings: {
            assessmentsOpen: false,
        },
    };
    setDoc(workspaceDocRef, workspaceUpdate, { merge: true });
}

export function openAssessmentPeriod() {
    const workspaceRef = getWorkspaceDocRef();
    return setDoc(
        workspaceRef,
        {
            assessmentSettings: {
                assessmentsOpen: true,
            },
        },
        { merge: true }
    );
}

export function updateAssessmentRequestDates(updates, batch = writeBatch(db)) {
    const assessmentDatesRef = getAssessmentDatesDocRef();
    batch.set(
        assessmentDatesRef,
        {
            roles: updates,
        },
        { merge: true }
    );
    return batch;
}

// Create's a notification for each manager to complete their assessments
export async function requestAssessments(roles, isReminder) {
    let transactions = [];
    let roleUpdates = {};
    const createdBy = lookUser();
    const messageType = isReminder ? "assessmentReminder" : "assessmentRequest";
    roles.forEach((role) => {
        roleUpdates[role.id] = serverTimestamp();
        // Create the notitification transactions
        const incumbent = lookEmployee(role.incumbentId);
        const email = incumbent && incumbent.email;
        const isUser = incumbent && incumbent.isUser;

        if (isUser && email && email !== createdBy.email) {
            let newNotificationRef = getNotificationDocRef(role.id);
            let update = {
                type: messageType,
                completed: false,
                seen: false,
                createdByName: createdBy.displayName,
                received: serverTimestamp(),
            };
            const roleIncumbent = getRoleIncumbent(role.id);
            if (roleIncumbent) {
                update.firstname = roleIncumbent.firstname;
                update.email = roleIncumbent.email;
            }

            if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
                cLog("NOTIFICATION WOULD HAVE BEEN CREATED", update);
            } else {
                transactions.push({
                    ref: newNotificationRef,
                    update,
                });
            }
        }
    });

    const batchArray = chunk(transactions, 300).map((transactionBatch) => {
        let batch = writeBatch(db);
        transactionBatch.forEach((transaction) => {
            batch.set(transaction.ref, transaction.update);
        });
        return batch;
    });

    const promises = batchArray.map((batch) => batch.commit());

    await Promise.all(promises);

    if (Object.keys(roleUpdates).length > 0) {
        promises.push(updateAssessmentRequestDates(roleUpdates).commit());
    }

    return true;
}

export function setEmployeeAssessment(employeeId, rawAssessment, overwriteId, batch = writeBatch(db)) {
    const { date, skipped, importCount, gridPosition, talentAreas, talentBoards, management, overall, ...cleaned } =
        rawAssessment;
    const snapshotId = lookSnapshotId();
    let preppedAssessment = {
        ...cleaned,
        snapshotId: snapshotId,
        employeeId: employeeId,
        assessmentDate: serverTimestamp(),
    };

    // Include the assessor employeeId
    const claims = lookClaims();
    if (claims.employeeId) {
        preppedAssessment.assessedBy = claims.employeeId;
    }

    setPendingSnapshot(employeeId);

    // If the employee has been assessed in this period, overwrite the previous assessment. Otherwise, create a new one.
    const assessmentDocRef = getAssessmentDocRef(overwriteId);
    batch.set(assessmentDocRef, preppedAssessment);
    return batch;
}

export function setSelfAssessment(employeeId, rawAssessment, overwriteId, batch = writeBatch(db)) {
    const { date, skipped, importCount, gridPosition, talentAreas, talentBoards, management, overall, ...cleaned } =
        rawAssessment;
    const snapshotId = lookSnapshotId();

    const preppedAssessment = {
        ...cleaned,
        snapshotId: snapshotId,
        employeeId: employeeId,
        assessmentDate: serverTimestamp(),
    };

    // Update the date the employee last self-assessed
    const employeeRef = getEmployeeDocRef(employeeId);
    batch.set(
        employeeRef,
        {
            dateLastSelfAssessed: serverTimestamp(),
        },
        { merge: true }
    );

    // If the employee has been assessed in this period, overwrite the previous assessment. Otherwise, create a new one.
    const assessmentDocRef = getSelfAssessmentDocRef(overwriteId);
    batch.set(assessmentDocRef, preppedAssessment);
    return batch;
}

export async function skipAssessment(employeeId, batch = writeBatch(db)) {
    const lastAssessmentDoc = await fetchLatestAssessment(employeeId);
    const lastAssessmentData = lastAssessmentDoc ? lastAssessmentDoc.data() : {};
    let {
        date,
        skipped,
        importCount,
        gridPosition,
        talentAreas,
        talentBoards,
        management,
        overall,
        ...skippedAssessment
    } = lastAssessmentData;

    // Include the assessor employeeId
    const claims = lookClaims();
    if (claims?.employeeId) {
        skippedAssessment.assessedBy = claims.employeeId;
    }
    skippedAssessment.ratings = skippedAssessment.ratings || {};
    skippedAssessment.employeeId = employeeId;
    skippedAssessment.skipped = true;
    skippedAssessment.assessmentDate = serverTimestamp();

    // If the employee has been assessed in this period, overwrite the previous assessment. Otherwise, create a new one.
    setPendingSnapshot(employeeId);
    const assessmentDocRef = getAssessmentDocRef();
    batch.set(assessmentDocRef, skippedAssessment);
    batch.commit();
}

// Temporary function to allow for the assessment to be set without triggering the snapshot builds
// Only triggered in Developer settings and used when filling a demo space with dummy data
export function setEmployeeAssessmentNoTrigger(employeeId, rawAssessment, batch = writeBatch(db)) {
    const { date, importCount, gridPosition, talentAreas, talentBoards, management, overall, ...cleaned } =
        rawAssessment;
    const snapshotId = lookSnapshotId();

    const preppedAssessment = {
        ...cleaned,
        snapshotId: snapshotId,
        employeeId: employeeId,
        importCount: increment(1),
        assessmentDate: serverTimestamp(),
    };

    // If the employee has been assessed in this period, overwrite the previous assessment. Otherwise, create a new one.
    const assessmentDocRef = getAssessmentDocRef();
    batch.set(assessmentDocRef, preppedAssessment);
    return batch;
}
