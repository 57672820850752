import classNames from "classnames";
import Fuse from "fuse.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import "./search.scss";

const TableSearch = (props) => {
    const [textEntered, setTextEntered] = useState();
    const [itemList, setItemList] = useState([]);
    const { items, displayKey, searchKeys, idKey, placeholder, onChange } = props;

    useEffect(() => {
        const list = Object.values(items).map((item) => {
            return {
                name: item[displayKey],
                value: item[idKey],
                ...item,
            };
        });
        setItemList(list);
    }, [items, displayKey, idKey]);

    function fuzzySearch(value) {
        const fuse = new Fuse(itemList, {
            keys: searchKeys,
            threshold: 0.2,
            ignoreLocation: true,
        });
        if (!value.length) {
            return [];
        }
        const filtered = fuse.search(value);
        const returnList = filtered.map((item) => {
            return { ...item.item };
        });
        return returnList;
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setTextEntered(value);
        const filteredItems = fuzzySearch(value);
        const filteredIds = filteredItems.map((item) => item.value);
        onChange(value.length > 0 ? filteredIds : false);
    };

    const style = { minWidth: props.minWidth };

    return (
        <Input
            style={style}
            className={classNames({ empty: !textEntered }, "table-search-field")}
            type="text"
            bsSize="sm"
            autoComplete="off"
            name="filter"
            placeholder={placeholder}
            onChange={handleChange}
        />
    );
};

TableSearch.propTypes = {
    searchKeys: PropTypes.array,
    displayKey: PropTypes.string,
    idKey: PropTypes.string,
    placeholder: PropTypes.string,
    minWidth: PropTypes.number,
    onItemClick: PropTypes.func,
};

TableSearch.defaultProps = {
    searchKeys: ["name"],
    displayKey: "name",
    idKey: "id",
    placeholder: "Search",
    minWidth: 200,
    onItemClick: () => {},
};

export default TableSearch;
