import * as d3 from "d3";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef } from "react";

const PolarAreaChart = ({ data, max = 100, onItemClick, height, width, onItemEnter, onItemLeave, selected }) => {
    const svgRef = useRef();

    useEffect(() => {

        const getFillColor = (data, i) => {
            if (selected === data[i].id) {
                return "#4878ff";
            }
            if (data[i].color) {
                return data[i].color;
            } else {
                return i % 2 === 0 ? "grey" : "darygrey";
            }
        };

        if (!isNaN(width) && !isNaN(height)) {
            const svg = d3.select(svgRef.current);
            const chartWidth = width;
            const chartHeight = height;
            const radius = Math.min(chartWidth, chartHeight) / 2;
            const centerX = chartWidth / 2;
            const centerY = chartHeight / 2;

            const angleScale = d3
                .scaleLinear()
                .range([0, 2 * Math.PI])
                .domain([0, data.length]);

            const radiusScale = d3.scaleLinear().range([0, radius]).domain([0, max]);

            const pie = d3
                .pie()
                .value((d) => d.valueOf)
                .sort(null);

            const arc = d3
                .arc()
                .innerRadius(0)
                .outerRadius((d) => radiusScale(d.data.value))
                .startAngle((d) => angleScale(d.index))
                .endAngle((d) => angleScale(d.index + 1));

            const gridLines = d3.range(0, radius, radius / 10);

            svg.selectAll("*").remove();

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("path")
                .data(pie(data))
                .join("path")
                .attr("d", arc)
                //.attr("fill", (d, i) => d3.schemeCategory10[i])
                //.attr("fill", (d, i) => data[i].color)
                .attr("fill", (d, i) => getFillColor(data, i))
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", 0.7)
                .attr("data-tip", (d) => d.data.label)
                .on("click", function (d, i) {
                    onItemClick(i);
                })
                .on("mouseenter", function (d, i) {
                    onItemEnter(i);
                })
                .on("mouseleave", function (d, i) {
                    onItemLeave();
                });

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("circle")
                .data(gridLines)
                .join("circle")
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", (d) => d)
                .attr("fill", "none")
                .attr("stroke", "#a3abb3")
                .style("stroke-dasharray", "1,1");

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("line")
                .data(data)
                .join("line")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", (d, i) => radius * Math.sin(angleScale(i)))
                .attr("y2", (d, i) => -radius * Math.cos(angleScale(i)))
                .attr("stroke", "#a3abb3")
                .style("stroke-dasharray", "2,2");

            // Add a final ring around the outside of the chart
            svg.append("circle")
                .attr("cx", centerX)
                .attr("cy", centerY)
                .attr("r", radius)
                .attr("fill", "none")
                .attr("stroke", "#a3abb3");
        }
    }, [data, selected, max, width, height, onItemClick, onItemEnter, onItemLeave]);

    if (isNaN(width) || isNaN(height)) return null;

    return (
        <div className="d-flex flex-column f-1 justify-content-center align-items-center">
            <svg ref={svgRef} width={width} height={height}></svg>
        </div>
    );
};

PolarAreaChart.propTypes = {
    data: PropTypes.array,
    onItemClick: PropTypes.func,
    onItemEnter: PropTypes.func,
    onItemLeave: PropTypes.func,
};

PolarAreaChart.defaultProps = {
    data: [],
    onItemClick: () => {},
    onItemEnter: () => {},
    onItemLeave: () => {},
};

export default memo(PolarAreaChart);
