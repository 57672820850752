import { arrayUnion, deleteField, serverTimestamp, setDoc, writeBatch } from "firebase/firestore";
import { lookAction, lookEmployee, lookRole } from "../../redux/utils/looks";
import { getUniqueId } from "../../utils/basicUtils";
import { db } from "../firebase";
import { getActionDocRef, getEmployeeDocRef, getNewTalentInfoDocId, getScheduledActionDocRef } from "../firebaseUtils";

export function setAction(receivedData) {
    let { id, importCount, ...preppedData } = receivedData;
    id = id || getUniqueId();

    // Set the public infoDocId
    if (!preppedData.publicInfoDocId) {
        const currentAction = lookAction(id);
        const existingPublicInfoDocId = currentAction && currentAction.publicInfoDocId;
        preppedData.publicInfoDocId = existingPublicInfoDocId || getNewTalentInfoDocId();
    }

    const actionDocRef = getActionDocRef(id);
    return setDoc(actionDocRef, preppedData, { merge: true });
}

export async function deleteAction(actionId) {
    // Cloud function removes this action from any traits it's linked to,
    // and deletes all subordinate scheduled action docs that are incomplete
    const actionDocRef = getActionDocRef(actionId);
    return setDoc(actionDocRef, { disabled: true }, { merge: true });
}

export function scheduleActions(employeeId, plannedActionIds, batch = writeBatch(db)) {
    const employee = lookEmployee(employeeId);
    const role = lookRole(employee.roleId);
    plannedActionIds.forEach((actionId) => {
        const action = lookAction(actionId);
        const actionValues = {
            actionId: actionId,
            actionName: action.actionName || actionId,
            employeeId: employeeId,
            parentRoleId: role.parentRoleId,
            roleId: role.id,
            complete: false,
        };
        const scheduledActionDocRef = getScheduledActionDocRef(actionId, employeeId);
        batch.set(scheduledActionDocRef, actionValues, { merge: true });
    });
    const employeeRef = getEmployeeDocRef(employeeId);
    batch.set(employeeRef, { scheduledActionIds: arrayUnion(...plannedActionIds) }, { merge: true });
    return batch;
}

export function deleteScheduledAction(actionId, employeeId) {
    const scheduledActionDocRef = getScheduledActionDocRef(actionId, employeeId);
    return setDoc(scheduledActionDocRef, { disabled: true }, { merge: true });
}

export function completeScheduledAction(actionId, employeeId) {
    const scheduledActionDocRef = getScheduledActionDocRef(actionId, employeeId);
    const update = {
        complete: true,
        completedDate: serverTimestamp(),
    };
    return setDoc(scheduledActionDocRef, update, { merge: true });
}

export function reopenScheduledAction(actionId, employeeId) {
    const scheduledActionDocRef = getScheduledActionDocRef(actionId, employeeId);
    const update = {
        complete: false,
        completedDate: deleteField(),
    };
    return setDoc(scheduledActionDocRef, update, { merge: true });
}
