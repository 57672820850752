import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ChangeUserRole, CreateAction,
    CreateOrFillRole,
    CreateTalentArea,
    CreateTrait,
    EditTrait, MoveRoleOrEmployee
} from "../components";
import { SET_MODAL } from "../redux/appSlice";

const AppModals = (props) => {
    const dispatch = useDispatch();
    const activeModal = useSelector((state) => state.app.activeModal);

    const closeModal = () => {
        dispatch(SET_MODAL(false));
    };

    switch (activeModal) {
        case "createTrait":
            return <CreateTrait isOpen={true} toggle={closeModal} />;
        case "editTrait":
            return <EditTrait isOpen={true} toggle={closeModal} />;
        case "createRole":
        case "fillVacantRole":
            return <CreateOrFillRole isOpen={true} toggle={closeModal} fillVacantRole={activeModal === "fillVacantRole"} />;
        case "createTalentArea":
            return <CreateTalentArea isOpen={true} toggle={closeModal} />;
        case "changeUserRole":
            return <ChangeUserRole isOpen={true} toggle={closeModal} />;
        case "moveRole":
            return <MoveRoleOrEmployee isOpen={true} toggle={closeModal} />;
        case "createAction":
            return <CreateAction isOpen={true} toggle={closeModal} />;
        default:
            return <div></div>;
    }
};

export default AppModals;
