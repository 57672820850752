import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { CountBadge } from "../BadgesAndPills/Badges";
import {
    AssessmentLateIcon,
    BackIcon,
    BackToStartIcon,
    CalendarIcon,
    CheckIcon,
    ClosedAssessmentIcon,
    CompleteAssessmentIcon,
    CreateRoleIcon,
    CrossIcon,
    DeleteIcon,
    DownChevronIcon,
    DownloadIcon,
    DuplicateIcon,
    EditIcon,
    EllipsisIcon,
    FillRoleIcon,
    FilterIcon,
    InfoIcon,
    InviteIcon,
    LoadingIconOval,
    MoveRoleIcon,
    OpenAssessmentIcon,
    OrgIcon,
    PlusIcon,
    ReopenIcon,
    RequestAssessmentIcon,
    SaveIcon,
    SettingsIcon,
    SkipAssessmentIcon,
    SkipToEndIcon,
    SnapshotDisabledIcon,
    SnapshotEnabledIcon,
    SortIcon,
    TableIcon,
    TalentBoardIcon,
    TourIcon,
    VacateRoleIcon,
    ViewActionsIcon,
    ViewAssessmentIcon,
    ViewRoleIcon,
    ViewStrengthsIcon,
    ViewTalentMapIcon
} from "../Icons/MyIcons";

export const EditTableButton = (props) => {
    return (
        <AppButton {...props}>
            <EditIcon />
        </AppButton>
    );
};

export const AddTableButton = (props) => {
    return (
        <AppButton {...props}>
            <PlusIcon />
        </AppButton>
    );
};

export const CopyTableButton = (props) => {
    return (
        <AppButton {...props}>
            <DuplicateIcon />
        </AppButton>
    );
};

export const VacateOrFillRoleButton = (props) => {
    const tooltip = props.toggle ? "Fill Role" : "Vacate Role";
    return (
        <AppButton tooltip={tooltip} {...props}>
            {props.toggle ? <FillRoleIcon size={props.size} /> : <VacateRoleIcon size={props.size} />}
        </AppButton>
    );
};

export const ChangeUserRoleButton = (props) => {
    const tooltip = "Change User Role";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <InviteIcon size={props.size} />
        </AppButton>
    );
};

export const MoveRoleButton = (props) => {
    const tooltip = "Move";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <MoveRoleIcon size={props.size} />
        </AppButton>
    );
};

export const OrgButton = (props) => {
    return (
        <AppButton {...props}>
            <OrgIcon size={props.size} />
        </AppButton>
    );
};

export const TableButton = (props) => {
    return (
        <AppButton {...props}>
            <TableIcon size={props.size} />
        </AppButton>
    );
};

export const EditButton = (props) => {
    const tooltip = "Edit";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <EditIcon size={props.size} />
        </AppButton>
    );
};

export const ViewRoleButton = (props) => {
    const tooltip = "View";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <ViewRoleIcon size={props.size} />
        </AppButton>
    );
};

export const DeleteRoleButton = (props) => {
    const tooltip = "Delete Role";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <DeleteIcon size={props.size} />
        </AppButton>
    );
};

export const DeleteButton = (props) => {
    return (
        <AppButton {...props}>
            <DeleteIcon size={props.size} />
        </AppButton>
    );
};

export const ReopenActionButton = (props) => {
    const tooltip = "Reopen Action";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <ReopenIcon size={props.size} />
        </AppButton>
    );
};

export const CompleteTaskButton = (props) => {
    const tooltip = "Complete Action";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <CheckIcon size={props.size} />
        </AppButton>
    );
};

export const RescheduleTaskButton = (props) => {
    const tooltip = "Reschedule Task";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <CalendarIcon size={props.size} />
        </AppButton>
    );
};

export const AddRoleButton = (props) => {
    const tooltip = "Add Role";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <CreateRoleIcon size={props.size} />
        </AppButton>
    );
};

export const ViewTalentMapButton = (props) => {
    const tooltip = "View Talent Map";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <ViewTalentMapIcon size={props.size} />
        </AppButton>
    );
};

export const ViewStrengthsButton = (props) => {
    const tooltip = "View Strengths";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <ViewStrengthsIcon size={props.size} />
        </AppButton>
    );
};

export const CompleteAssessmentButton = (props) => {
    const tooltip = "Edit Assessment";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <CompleteAssessmentIcon size={props.size} />
        </AppButton>
    );
};

export const SkipAssessmentButton = (props) => {
    const tooltip = "Skip Assessment";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <SkipAssessmentIcon size={props.size} />
        </AppButton>
    );
};

export const RequestAssessmentButton = (props) => {
    const tooltip = "Request Assessments";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <RequestAssessmentIcon size={props.size} />
        </AppButton>
    );
};

export const ToolbarPlusButton = (props) => {
    return (
        <AppButton {...props}>
            <PlusIcon size={props.size || 16} />
        </AppButton>
    );
};

export const RemoveButton = (props) => {
    return (
        <AppButton {...props}>
            <CrossIcon size={props.size || 16} />
        </AppButton>
    );
};

export const SaveButton = (props) => {
    return (
        <AppButton {...props}>
            <SaveIcon size={props.size || 16} />
        </AppButton>
    );
};

export const ViewAssessmentButton = (props) => {
    const tooltip = "View Assessments";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <ViewAssessmentIcon size={props.size} />
        </AppButton>
    );
};

export const ViewActionsButton = (props) => {
    const tooltip = "View Planned Actions";
    return (
        <AppButton tooltip={tooltip} {...props}>
            <ViewActionsIcon size={props.size} />
        </AppButton>
    );
};

export const ExpandButton = (props) => {
    if (props.usePlusIcon) {
        return (
            <AppButton icon {...props} className={classNames(props.className, "transition-checked-rotate-145")}>
                <PlusIcon size={props.size || 18} />
            </AppButton>
        );
    } else {
        return (
            <AppButton icon {...props} className={classNames(props.className, "transition-checked-rotate-180")}>
                <DownChevronIcon size={props.size || 18} />
            </AppButton>
        );
    }
};

export const AssessmentOpenToggle = (props) => {
    const tooltip = props.checked ? "Close assessment period" : "Open New Assessment Period";
    return (
        <AppButton tooltip={tooltip} rounded outline {...props}>
            {props.checked ? <OpenAssessmentIcon size={props.size} /> : <ClosedAssessmentIcon size={props.size} />}
        </AppButton>
    );
};

export const SnapshotButton = (props) => {
    const tooltip = props.checked ? "Take assessment snapshot" : "Snapshots disabled";
    return (
        <AppButton disabled={!props.checked} tooltip={tooltip} rounded outline {...props}>
            {props.checked ? <SnapshotEnabledIcon size={props.size} /> : <SnapshotDisabledIcon size={props.size} />}
        </AppButton>
    );
};

export const CreateTalentBoardButton = (props) => {
    const tooltip = "Create Talent Board";
    return (
        <AppButton outline tooltip={tooltip} {...props}>
            <TalentBoardIcon size={props.size} />
        </AppButton>
    );
};

export const EllipsisEditButton = (props) => {
    return (
        <AppButton round={false} outline {...props}>
            <EllipsisIcon size={props.size} />
        </AppButton>
    );
};

export const BackButton = (props) => {
    return (
        <AppButton outline {...props}>
            <BackIcon size={props.size} />
        </AppButton>
    );
};

export const TourButton = (props) => {
    const tooltip = "Run Tour";
    return (
        <AppButton tooltip={tooltip} outline {...props}>
            <TourIcon size={props.size} />
        </AppButton>
    );
};

export const HelpButton = (props) => {
    return (
        <AppButton outline {...props}>
            <InfoIcon size={props.size} />
        </AppButton>
    );
};

export const BackToStartButton = (props) => {
    return (
        <AppButton outline {...props}>
            <BackToStartIcon size={props.size} />
        </AppButton>
    );
};

export const SkipToEndButton = (props) => {
    return (
        <AppButton outline {...props}>
            <SkipToEndIcon size={props.size} />
        </AppButton>
    );
};

export const SettingsButton = (props) => {
    return (
        <AppButton className="transition-checked-rotate-180" outline {...props}>
            <SettingsIcon />
        </AppButton>
    );
};

export const AssessmentLateButton = (props) => {
    return (
        <AppButton className="transition-checked-rotate-180" outline {...props}>
            <AssessmentLateIcon />
        </AppButton>
    );
};

export const DownloadButton = (props) => {
    return (
        <AppButton outline {...props}>
            <DownloadIcon />
        </AppButton>
    );
};

export const FilterButton = (props) => {
    let tooltip = "";
    if (props.count > 0) {
        tooltip = `Applied Filters: ${props.count}`;
    } else {
        tooltip = "No Applied Filters";
    }
    return (
        <AppButton rounded outline flagButton tooltip={tooltip} {...props}>
            <FilterIcon size={props.size} />
        </AppButton>
    );
};

export const SortButton = (props) => {
    return (
        <AppButton icon {...props} className={classNames(props.className, "transition-checked-rotate-180")}>
            <SortIcon size={props.size || 12} />
        </AppButton>
    );
};

export const OrgWaitingButton = (props) => {
    const { orgIds = [], children, size } = props;
    const pendingChanges = useSelector((state) => state.org.pendingChanges);
    const hasPendingChanges = pendingChanges.filter((id) => orgIds.includes(id)).length > 0;
    return (
        <AppButton {...props} disabled={hasPendingChanges || props.disabled}>
            {hasPendingChanges ? <LoadingIconOval size={size} /> : <div>{children}</div>}
        </AppButton>
    );
};

export const TalentWaitingButton = (props) => {
    const { orgIds = [], children, size } = props;
    const pendingChanges = useSelector((state) => state.talent.pendingChanges);
    const hasPendingChanges = pendingChanges.filter((id) => orgIds.includes(id)).length > 0;
    return (
        <AppButton {...props} disabled={hasPendingChanges || props.disabled}>
            {hasPendingChanges ? <LoadingIconOval size={size} /> : <div>{children}</div>}
        </AppButton>
    );
};

const AppButton = (props) => {
    const handleClick = (e) => {
        if (!props.disabled) props.onClick(e);
    };
    return (
        <div
            className={classNames(
                {
                    round: props.round,
                    disabled: props.disabled,
                    hidden: props.hidden,
                    rounded: props.rounded,
                    fill: props.fill,
                    square: props.square,
                    flag: props.flagButton,
                    outline: props.outline,
                    checked: props.checked,
                    icon: props.icon,
                },
                props.className,
                "my-button"
            )}
            style={props.style}
            data-tip={props.tooltip}
            data-for={props.tooltipId}
            onClick={handleClick}
        >
            <CountBadge count={props.count} />
            {props.children}
        </div>
    );
};

AppButton.propTypes = {
    buttonId: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipId: PropTypes.string,
    disabled: PropTypes.bool,
    flagButton: PropTypes.bool,
    hidden: PropTypes.bool,
    round: PropTypes.bool,
    rounded: PropTypes.bool,
    outline: PropTypes.bool,
    icon: PropTypes.bool,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    color: PropTypes.bool,
    size: PropTypes.number,
    onClick: PropTypes.func,
};

AppButton.defaultProps = {
    size: 28,
    onClick: () => {},
};

export default AppButton;
