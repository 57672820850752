import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { DropdownItem } from "reactstrap";
import AppButton, {
    CompleteAssessmentButton,
    EditButton,
    ViewActionsButton,
    ViewAssessmentButton,
    ViewTalentMapButton,
} from "../../../../../components/Buttons/MyButtons";
import { HistoryIcon } from "../../../../../components/Icons/MyIcons";
import TableRowMenu from "../../../../../components/Tables/TableRowMenu";
import { EmployeeModel } from "../../../../../models";
import { checkAccess } from "../../../../../utils/userUtils";
import "../org.scss";

const TOOLTIP_ID = "org-chart-action-bar";

const OrgActions = (props) => {
    const { menuMode } = props;
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const isAdmin = checkAccess("roleAdmin");
    const noEmployee = !selectedEmployeeId;
    const noRole = !selectedRole || selectedRole.rootRole;
    const employeeModel = selectedEmployee && new EmployeeModel(selectedEmployee);
    const isSelf = selectedEmployeeId === userEmployeeId;
    const isManager = employeeModel && employeeModel.isManager();
    const isOwner = selectedEmployee && selectedEmployee.userRole === "owner";
    const isUser = selectedEmployee && selectedEmployee.isUser;
    const disableTalentMapView = noEmployee || !isManager;
    const disableAssessmentView = isSelf || noEmployee;
    const userCanAssessEmployee = employeeModel && employeeModel.canYouAssessMe(userEmployeeId);
    const assessmentsAvailable = userCanAssessEmployee && assessmentsOpen;
    const disableAssessmentEdit = noRole || !assessmentsAvailable || !userCanAssessEmployee;
    const disabledInvite = noRole || noEmployee || isSelf || isOwner;

    if (!menuMode) {
        return (
            <div className="org-chart-bar-container left">
                <div className="org-chart-bar tour-action-bar">
                    <ViewTalentMapButton
                        disabled={disableTalentMapView}
                        tooltipId={TOOLTIP_ID}
                        onClick={props.onViewTalentMap}
                    />
                    <EditButton disabled={noRole} tooltipId={TOOLTIP_ID} onClick={props.onEditRole} />
                    <ViewAssessmentButton
                        disabled={disableAssessmentView}
                        tooltipId={TOOLTIP_ID}
                        onClick={props.onViewHistory}
                    />
                    <AppButton
                        disabled={disableAssessmentView}
                        tooltip="Event Timeline"
                        tooltipId={TOOLTIP_ID}
                        onClick={props.onViewTimeline}
                    >
                        <HistoryIcon />
                    </AppButton>
                    <ViewActionsButton
                        disabled={disableAssessmentView}
                        tooltipId={TOOLTIP_ID}
                        onClick={props.onViewActions}
                    />
                    <CompleteAssessmentButton
                        disabled={disableAssessmentEdit}
                        tooltipId={TOOLTIP_ID}
                        onClick={props.onEditAssessment}
                    />
                </div>
                <ReactTooltip id={TOOLTIP_ID} place="right" delayShow={250} offset={{ right: 10, top: 1 }} />
            </div>
        );
    } else {
        // IMPORTANT - Admin menu hasn't been wrapped in <> because it messes with the inheritence
        return (
            <TableRowMenu>
                {isAdmin && <DropdownItem header>ADMIN ACTIONS</DropdownItem>}
                {isAdmin && isUser && <DropdownItem onClick={props.resendInvite}>Resend Invite</DropdownItem>}
                {isAdmin && (
                    <DropdownItem disabled={disabledInvite} onClick={props.changeUserRole}>
                        Change User Role
                    </DropdownItem>
                )}
                {isAdmin && !selectedRoleId && (
                    <DropdownItem onClick={props.onDeleteEmployee}>Remove Employee</DropdownItem>
                )}
                {isAdmin && isManager && (
                    <DropdownItem disabled={!assessmentsAvailable} onClick={props.onRequestAssessments}>
                        Request Assessments
                    </DropdownItem>
                )}
                {isAdmin && <hr />}
                <DropdownItem header>EMPLOYEE ACTIONS</DropdownItem>
                <DropdownItem disabled={noRole} onClick={props.onEditRole}>
                    Edit
                </DropdownItem>
                <DropdownItem disabled={disableAssessmentEdit} onClick={props.onEditAssessment}>
                    Edit Talent Assessment
                </DropdownItem>
                <DropdownItem disabled={disableTalentMapView} onClick={props.onViewTalentMap}>
                    View Talent Map
                </DropdownItem>
                <DropdownItem disabled={disableAssessmentView} onClick={props.onViewHistory}>
                    View History
                </DropdownItem>
                <DropdownItem disabled={disableAssessmentView} onClick={props.onViewActions}>
                    View Actions
                </DropdownItem>
            </TableRowMenu>
        );
    }
};

OrgActions.propTypes = {
    menuMode: PropTypes.bool,
};

export default OrgActions;
