import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Container, Collapse, Row } from "reactstrap";
import { COLORS } from "../../constants";
import { lookEmployee, lookRole } from "../../redux/utils/looks";
import { getColors } from "../../utils/assessmentUtils";
import { ExpandButton } from "../Buttons/MyButtons";
import { CheckIcon, CrossIcon, WarningIcon } from "../Icons/MyIcons";
import "./custom-field.scss";

const ICON_SIZE = 22;

const StandardTile = (props) => {
    const { field, value, isOpen, onExpand } = props;
    const options = field.options || [];
    const fieldType = field && field.type;
    const [rangeColors, setRangeColors] = useState([]);

    useEffect(() => {
        if (field.options && field.range) {
            const { options } = field;
            const optionCount = options && options.length;
            const colors = getColors(optionCount);
            if (field.highIsBad) {
                setRangeColors(colors.reverse());
            } else {
                setRangeColors(colors);
            }
        }
    }, [field]);

    const renderValue = () => {
        if (fieldType === "switch") {
            const onColor = field.onIsBad ? COLORS.BAD : COLORS.GOOD;
            let style = {};
            if (value) style = { color: onColor };

            const renderOnIcon = () => {
                if (field.onIsBad) return <WarningIcon size={ICON_SIZE} />;
                if (!field.onIsBad) return <CheckIcon size={ICON_SIZE} />;
            };

            return (
                <h6 style={style} className="value">
                    {value && renderOnIcon()}
                    {!value && <CrossIcon size={ICON_SIZE} />}
                </h6>
            );
        }
        if (fieldType === "picker") {
            const option = options.find((opt) => opt.value === value);
            const optionLabel = option && option.label;
            return <h6>{optionLabel || "No Info"}</h6>;
        }
        if (fieldType === "employeePicker") {
            const selectedCount = value && value.length;
            const label = selectedCount ? `${selectedCount} Selected` : "None Selected";
            return <h6>{label}</h6>;
        }
    };

    const renderRibbon = () => {
        const option = options.find((opt) => opt.value === value);
        const index = options.indexOf(option);
        const color = rangeColors[index];
        let style = { backgroundColor: color, color: color };
        if (!field.range || !color) return null;
        return (
            <div style={style} className="ribbon">
                res
            </div>
        );
    };

    return (
        <div className={classNames("custom-field-tile", { blank: !value })}>
            {renderRibbon()}
            <h5 className="fw-lighter fs-small">{field.label}</h5>
            <div className="d-flex align-items-center">
                <span className="mx-3">{renderValue()}</span>
                {value && field.expandable && (
                    <ExpandButton checked={isOpen} onClick={() => onExpand(isOpen ? false : field.id)} />
                )}
            </div>
        </div>
    );
};

const CustomFieldDisplay = (props) => {
    const { fields, assessment } = props;
    const [expandedId, setExpandedId] = useState();

    const renderExpanded = () => {
        const expandedField = fields.find((field) => field.id === expandedId);
        if (expandedField) {
            if (expandedField.type === "employeePicker") {
                const employeeIdList = assessment[expandedField.id] || [];
                return (
                    <div className="d-flex">
                        {employeeIdList.map((empId) => {
                            const employee = lookEmployee(empId);
                            const role = employee && lookRole(employee.roleId);
                            const displayName = employee && employee.displayName;
                            const jobTitle = role && role.jobTitle;
                            return (
                                <div key={empId} className="d-flex flex-column px-3">
                                    <span className="fs-small fw-bold">{displayName}</span>
                                    <span className="fs-tiny fw-lighter">{jobTitle}</span>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        }
    };

    return (
        <div className="d-flex flex-column custom-field-display-container">
            <Container className="custom-field-display" fluid>
                <Row className="no-">
                    {fields.map((field) => {
                        const isOpen = expandedId === field.id;
                        return (
                            <Col key={field.id} className="p-0 custom-field-col">
                                <StandardTile
                                    field={field}
                                    value={assessment[field.id]}
                                    isOpen={isOpen}
                                    onExpand={setExpandedId}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
            <Collapse isOpen={!!expandedId}>
                <div className="p-3 bg-white collapse-section">{renderExpanded()}</div>
            </Collapse>
        </div>
    );
};

CustomFieldDisplay.propTypes = {
    assessment: PropTypes.object,
    excludeFromOptions: PropTypes.array,
    fields: PropTypes.array,
    vertical: PropTypes.bool,
};

CustomFieldDisplay.defaultProps = {
    fields: [],
    excludeFromOptions: [],
};

export default CustomFieldDisplay;
