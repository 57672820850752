import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { TraitsTable } from "../../components";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import ResponsiveContainer from "../../components/ResponsiveContainer/ResponsiveContainer";
import TableSearch from "../../components/Search/TableSearch";
import { DEFAULT_ROUTE } from "../../constants";
import { setTalentBoard } from "../../firebase/actions/talentBoard";
import { disableTrait } from "../../firebase/actions/trait";
import { SELECT_TALENT_AREA, SET_CHECKED_IDS, SET_MODAL, SET_SIDE_AREA } from "../../redux/appSlice";
import { lookTalentArea, lookTalentBoard } from "../../redux/utils/looks";
import { checkAccess } from "../../utils/userUtils";
import "./traits.scss";

const includedColumns = ["traitName", "talentAreaIds", "linkedTalentBoardIds", "scope", "ratingCounts"];

const TABLE_ID = "traits";

const Traits = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filteredTraitIds, setFilteredTraitIds] = useState(false);
    const [unfilteredData, setUnfilteredData] = useState([]);
    const traits = useSelector((state) => state.talent.traits);
    const checkedTraitIds = useSelector((state) => state.app.checkedIds[TABLE_ID]);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const pendingChanges = useSelector((state) => state.talent.pendingChanges);
    const traitLinksUpdating = useSelector((state) => state.talent.traitLinksUpdating);

    // Leave page if unauthorized to view it
    useEffect(() => {
        if (!checkAccess("talent")) {
            navigate(DEFAULT_ROUTE);
        }
    });

    // Prep the table data, slight delay ensures it doesn't flash off/on when trait links are rebuilt
    useEffect(() => {
        if (!traitLinksUpdating) {
            let filtered = Object.values(traits).map((trait) => {
                // Ensure the table filter filters on talent board and talent area names
                const boardIds = trait.linkedTalentBoardIds || [];
                const talentBoardNames = JSON.stringify(
                    boardIds.map((id) => {
                        const talentBoard = lookTalentBoard(id);
                        return talentBoard && talentBoard.talentBoardName;
                    })
                );
                const talentAreaIds = trait.talentAreaIds || [];
                const talentAreaNames = JSON.stringify(
                    talentAreaIds.map((id) => {
                        const talentArea = lookTalentArea(id);
                        return talentArea && talentArea.talentAreaName;
                    })
                );

                // Get Scope Label
                const boardsOnly = trait && trait.boardsOnly;
                const scopeMessage = boardsOnly ? "Selected Employees" : "All Employees";

                return {
                    ...trait,
                    scope: scopeMessage,
                    talentAreaNames: talentAreaNames,
                    talentBoardNames: talentBoardNames,
                };
            });
            setUnfilteredData(filtered);
        }
    }, [traits, traitLinksUpdating]);

    const handleEditTalentArea = (talentAreaId) => {
        if (pendingChanges.length === 0) {
            dispatch(SELECT_TALENT_AREA(talentAreaId));
            dispatch(SET_SIDE_AREA("editTalentArea"));
        }
    };

    const handleAddTalentArea = () => {
        dispatch(SET_MODAL("createTalentArea"));
    };

    const handleDeleteCheckedTraits = () => {
        const onConfirm = () => {
            checkedTraitIds.forEach((traitId) => {
                disableTrait(traitId);
                dispatch(SET_CHECKED_IDS({ [TABLE_ID]: [] }));
            });
        };

        // Confirm before deleting
        if (checkedTraitIds && checkedTraitIds.length > 0) {
            confirmModal("delete", onConfirm, "traits");
        }
    };

    const handleCreateTrait = () => {
        dispatch(SET_MODAL("createTrait"));
    };

    const handleCreateTalentBoard = async () => {
        let values = {
            talentBoardName: "New Board",
            traitIds: checkedTraitIds,
        };
        const boardId = await setTalentBoard(values);
        if (boardId) {
            navigate(`/talentboards/${boardId}`);
        }
    };

    const traitsToolbar = () => {
        const checkedCount = checkedTraitIds ? checkedTraitIds.length : 0;
        return (
            <div className="toolbar">
                <TableSearch
                    items={unfilteredData}
                    searchKeys={["name", "talentAreaNames", "talentBoardNames", "scope"]}
                    displayKey="traitName"
                    placeholder="Filter all columns"
                    onChange={setFilteredTraitIds}
                    minWidth={300}
                    removeBorder
                />
                <div className="justify-end">
                    {checkedCount > 0 && (
                        <>
                            <Button className="basic me-3" onClick={handleDeleteCheckedTraits}>
                                {`DELETE ${checkedCount} TRAIT${checkedCount > 1 ? "S" : ""}`}
                            </Button>
                            <Button className="basic me-3" onClick={handleCreateTalentBoard}>
                                {`CREATE BOARD (${checkedCount} TRAIT${checkedCount > 1 ? "S" : ""})`}
                            </Button>
                        </>
                    )}
                    <Button className="basic me-3" onClick={handleAddTalentArea}>
                        CREATE TALENT AREA
                    </Button>
                    <Button className="basic" onClick={handleCreateTrait}>
                        CREATE TRAIT
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="inner-container">
            {traitsToolbar()}
            <ResponsiveContainer>
                <TraitsTable
                    tableId={TABLE_ID}
                    iconSize={32}
                    data={unfilteredData}
                    filteredIds={filteredTraitIds}
                    snapshots={snapshots}
                    columnKeys={includedColumns}
                    onEditTalentArea={handleEditTalentArea}
                    removeBorder
                    checkbox
                />
            </ResponsiveContainer>
        </div>
    );
};

export default Traits;
