import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { LoadingIndicator } from "../../components";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import ResponsiveContainer from "../../components/ResponsiveContainer/ResponsiveContainer";
import TableSearch from "../../components/Search/TableSearch";
import TalentBoardsTable from "../../components/Tables/TalentBoardTables/TalentBoardsTable";
import { DEFAULT_ROUTE } from "../../constants";
import { sortArrayOfObjects } from "../../utils/basicUtils";
import { checkAccess } from "../../utils/userUtils";

const includedColumns = ["boardRatings", "talentBoardName", "members", "traits"];

const TalentBoards = () => {
    const navigate = useNavigate();
    const [filteredBoardIds, setFilteredBoardIds] = useState(false);
    const [tableData, setTableData] = useState(false);
    const talentBoards = useSelector((state) => state.talent.talentBoards);
    const snapshots = useSelector((state) => state.talent.snapshots);

    useEffect(() => {
        if (!checkAccess("talent")) {
            navigate(DEFAULT_ROUTE);
        }
    });

    useEffect(() => {
        let preppedData = Object.values(talentBoards).filter(
            (board) => !filteredBoardIds || filteredBoardIds.includes(board.id)
        );
        preppedData = sortArrayOfObjects(preppedData, "boardName");
        setTableData(preppedData);
    }, [talentBoards, filteredBoardIds]);

    const handleCreateBoard = () => {
        const onConfirm = () => navigate("/traits");

        // Confirm before navigating
        confirmModal("create_talent_board", onConfirm, "talentBoard");
    };

    const toolbar = () => {
        return (
            <div className="toolbar">
                <TableSearch
                    items={tableData}
                    displayKey="talentBoardName"
                    searchKeys={["talentBoardName"]}
                    placeholder="Filter Talent Boards"
                    onChange={setFilteredBoardIds}
                    removeBorder
                />
                <Button className="basic" onClick={handleCreateBoard}>
                    Create Talent Board
                </Button>
            </div>
        );
    };

    if (!tableData) return <LoadingIndicator />;

    return (
        <div className="inner-container">
            {toolbar()}
            <ResponsiveContainer>
                <TalentBoardsTable
                    data={tableData}
                    snapshots={snapshots}
                    filteredIds={filteredBoardIds}
                    columnKeys={includedColumns}
                    removeBorder
                    selectableRows
                />
            </ResponsiveContainer>
        </div>
    );
};

export default TalentBoards;
