import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { LoadingIndicator } from "../../components";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import ResponsiveContainer from "../../components/ResponsiveContainer/ResponsiveContainer";
import TableSearch from "../../components/Search/TableSearch";
import { ActionsTable } from "../../components/Tables";
import { DEFAULT_ROUTE } from "../../constants";
import { deleteAction } from "../../firebase/actions/action";
import { SET_CHECKED_IDS, SET_MODAL } from "../../redux/appSlice";
import { REMOVE_FROM_STATE } from "../../redux/talentSlice";
import { checkAccess } from "../../utils/userUtils";

const includedColumns = ["checkbox", "actionName", "actionStatus"];
const TABLE_ID = "actions";

const Actions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actions = useSelector((state) => state.talent.actions);
    const traits = useSelector((state) => state.talent.traits);
    const checkedActionIds = useSelector((state) => state.app.checkedIds[TABLE_ID]);
    const [filteredActionIds, setFilteredActionIds] = useState(false);
    const [unfilteredData, setUnfilteredData] = useState(false);

    useEffect(() => {
        setUnfilteredData(Object.values(actions));
    }, [actions, traits]);

    useEffect(() => {
        if (!checkAccess("talent")) {
            navigate(DEFAULT_ROUTE);
        }
    });

    const handleDeleteCheckedActions = () => {
        const onConfirm = () => {
            checkedActionIds.forEach((id) => {
                deleteAction(id);
            });
            dispatch(REMOVE_FROM_STATE({ type: "actions", ids: checkedActionIds }));
            dispatch(SET_CHECKED_IDS({ [TABLE_ID]: [] }));
        };

        // Confirm before deleting
        if (checkedActionIds) {
            confirmModal("delete", onConfirm, "actions");
        }
    };

    const actionsToolbar = () => {
        const checkedCount = checkedActionIds ? checkedActionIds.length : 0;
        return (
            <div className="toolbar">
                <TableSearch
                    items={unfilteredData}
                    searchKeys={["name"]}
                    displayKey="actionName"
                    placeholder="Filter actions"
                    onChange={setFilteredActionIds}
                    removeBorder
                />
                <div className="justify-end">
                    {checkedCount > 0 && (
                        <Button className="basic me-3" onClick={handleDeleteCheckedActions}>
                            {`DELETE ${checkedCount} ACTION${checkedCount > 1 ? "S" : ""}`}
                        </Button>
                    )}
                    <Button className="basic" onClick={() => dispatch(SET_MODAL("createAction"))}>
                        CREATE ACTION
                    </Button>
                </div>
            </div>
        );
    };

    const canAccessControls = checkAccess("actionButtons");

    if (!unfilteredData) return <LoadingIndicator />;

    return (
        <div className="inner-container">
            {canAccessControls && actionsToolbar()}
            <ResponsiveContainer>
                <ActionsTable
                    tableId={TABLE_ID}
                    data={unfilteredData}
                    filteredIds={filteredActionIds}
                    columnKeys={includedColumns}
                    widePadding
                    selectableRows
                />
            </ResponsiveContainer>
        </div>
    );
};

export default Actions;
