import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import assessmentsReducer from "./assessmentsSlice";
import orgReducer from "./orgSlice";
import talentReducer from "./talentSlice";
import userReducer from "./userSlice";
import workspaceReducer from "./workspaceSlice";

const store = configureStore({
    reducer: {
        app: appReducer,
        assessments: assessmentsReducer,
        org: orgReducer,
        talent: talentReducer,
        user: userReducer,
        workspace: workspaceReducer,
    },
});

export default store;
