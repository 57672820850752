import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ButtonGroup } from "reactstrap";
import { AssessmentOpenToggle, OrgButton, TableButton } from "../../../../../components/Buttons/MyButtons";
import { confirmModal } from "../../../../../components/Dialogs/ConfirmAlert";
import { closeAssessmentPeriod, openAssessmentPeriod } from "../../../../../firebase/actions/assessments";
import { checkAccess } from "../../../../../utils/userUtils";
import OrgChartToolbar from "../OrgChart/OrgChartToolbar";
import OrgTableToolbar from "../OrgTable/OrgTableToolbar";

const TOOLTIP_ID = "org-toolbar";

const OrgToolbar = (props) => {
    const { tableView, onSetTableView } = props;
    const [viewLoading, setViewLoading] = useState(false);
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const canManageAssessments = checkAccess("manageAssessmentRequests");

    useEffect(() => {
        setViewLoading(false);
    }, [tableView]);

    const handleOpenAssessment = () => {
        const onConfirm = () => {
            openAssessmentPeriod().then(() => {
                toast.success("Assessments open");
            });
        };

        // Confirm before opening
        confirmModal("open_assessments", onConfirm);
    };

    const handleCloseAssessment = () => {
        const onConfirm = () => {
            closeAssessmentPeriod().then(() => {
                toast.success("Assessments closed");
            });
        };

        // Confirm before opening
        confirmModal("close_assessments", onConfirm);
    };

    return (
        <div className="toolbar">
            <div className="justify-start">
                {canManageAssessments && (
                    <AssessmentOpenToggle
                        className="tour-toggle-assessment ms-2"
                        size={18}
                        tooltipId={TOOLTIP_ID}
                        checked={assessmentsOpen}
                        onClick={!assessmentsOpen ? handleOpenAssessment : handleCloseAssessment}
                    />
                )}
                <ButtonGroup className={classNames("mx-3 border-all py-2 px-3")}>
                    <OrgButton
                        outline
                        checked={!tableView}
                        disabled={viewLoading}
                        onClick={() => {
                            if (tableView) {
                                setViewLoading(true);
                                onSetTableView(false);
                            }
                        }}
                        size={24}
                    />
                    <TableButton
                        outline
                        checked={tableView}
                        disabled={viewLoading}
                        onClick={() => {
                            if (!tableView) {
                                setViewLoading(true);
                                onSetTableView(true);
                            }
                        }}
                        size={24}
                    />
                </ButtonGroup>
            </div>
            <div className="f-1">
                {tableView && <OrgTableToolbar {...props} tooltipId={TOOLTIP_ID} />}
                {!tableView && <OrgChartToolbar {...props} tooltipId={TOOLTIP_ID} />}
            </div>
            <ReactTooltip id={TOOLTIP_ID} place="right" delayShow={250} />
        </div>
    );
};

OrgToolbar.propTypes = {
    tableView: PropTypes.bool,
    onSetTableView: PropTypes.func,
};

OrgToolbar.defaultProps = {
    onSetTableView: () => {},
};

export default OrgToolbar;
