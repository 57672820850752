import { useEffect, useState } from "react";
import {
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
    ZAxis,
} from "recharts";
import { lookEmployee } from "../../redux/utils/looks";
import { getFieldLabel, getAxisValue, getExactColor } from "../../utils/snapshotUtils";


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const info = payload[0];
        const employee = lookEmployee(info.payload.employeeId);
        return (
            <div className="scatter-tooltip border-all p-3">
                <h5 className="fs-small">{employee.displayName}</h5>
                {payload.map((entry) => {
                    let val = entry.payload[entry.dataKey];
                    if (entry.dataKey === "z" && entry.payload.noZ) {
                        val = "N/A";
                    }
                    return (
                        <div
                            key={`${info.payload.employeeId}-${entry.dataKey}`}
                            className="d-flex justify-content-between fs-tiny fw-lighter"
                        >
                            <span className="me-2">{entry.name}</span>
                            <span>{val}</span>
                        </div>
                    );
                })}
            </div>
        );
    }
    return null;
};


const TalentScatterChart = (props) => {
    const { snapshots, filter } = props;
    const { x, y, z } = props.config;
    const [data, setData] = useState([]);

    useEffect(() => {
        function createChartData(snapshots, x, y, z) {
            return Object.entries(snapshots).map(([empId, snap]) => {
                const xVal = getAxisValue(snap, x);
                const yVal = getAxisValue(snap, y);
                const zVal = getAxisValue(snap, z);
                return { employeeId: empId, x: xVal, y: yVal, z: isNaN(zVal) ? 50 : zVal, noZ: isNaN(zVal) };
            });
        }
        const data = createChartData(snapshots, x, y, z);
        setData(data);
    }, [snapshots, filter, x, y, z]);

    const getColor = (entry) => {
        const colorIndex = entry.noZ ? NaN : entry.z;
        return getExactColor(colorIndex);
    };

    return (
        <ResponsiveContainer>
            <ScatterChart margin={{ top: 20, right: 40, bottom: 10, left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" type="number" name={getFieldLabel(x)} />
                <YAxis dataKey="y" name={getFieldLabel(y)} />
                <ZAxis dataKey="z" range={[50, 250]} name={getFieldLabel(z)} />
                <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: "3 3" }} />
                <Scatter data={data}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={getColor(entry)} />
                    ))}
                </Scatter>
            </ScatterChart>
        </ResponsiveContainer>
    );
};

export default TalentScatterChart;
