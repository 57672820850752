import React, { useEffect, useState } from "react";
import { getContrastColor } from "../../utils/basicUtils";
import { getExactColor } from "../../utils/snapshotUtils";
import classNames from "classnames";
import { CrossIcon, PeopleIcon, TalentBoardIcon } from "../Icons/MyIcons";
import { Button } from "reactstrap";

export const TraitScopePill = ({ boardsOnly, managersOnly, label }) => {
    function renderIcon() {
        if (boardsOnly) {
            return <TalentBoardIcon />;
        } else {
            return <PeopleIcon />;
        }
    }
    return (
        <div className={classNames("pill trait-scope-pill", { boards: boardsOnly, managers: managersOnly })}>
            <span className="me-1">{renderIcon()}</span>
            <span>{label}</span>
        </div>
    );
};

export const RatingPill = ({ rating, label, onClick, className }) => {
    const [color, setColor] = useState("black");
    const [backgroundColor, setBackgroundColor] = useState("white");

    useEffect(() => {
        if (rating) {
            const backgroundColor = getExactColor(rating);
            const color = getContrastColor(backgroundColor);
            setBackgroundColor(backgroundColor);
            setColor(color);
        }
    }, [rating]);

    const style = {
        backgroundColor: backgroundColor,
        color: color,
    };

    return (
        <div onClick={onClick} className={classNames("py-1 d-inline-block", className)}>
            <Pill className={classNames({ unrated: !rating })} style={style}>
                {label}
            </Pill>
        </div>
    );
};

export const RemovePill = ({ style, children, className, onClose }) => {
    const removePillStyle = {
        ...style,
        whiteSpace: "nowrap"
    };
    return (
        <div style={removePillStyle} className={classNames("px-2 py-1", className)}>
            <div className="d-flex justify-content-between align-items-center">
                {children}
                <Button className="ms-4 text-only d-flex align-items-center" onClick={onClose}>
                    <CrossIcon size={16} />
                </Button>
            </div>
        </div>
    );
};

export const Pill = ({ style, children, className }) => {
    return (
        <div style={style} className={classNames("pill", className)}>
            {children}
        </div>
    );
};
