import React from "react";
import { withFormik } from "formik";
import { Button, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import * as Yup from "yup";
import { CheckBox } from "../../components/Inputs/Inputs";

function getFormContent(formType) {
    switch (formType) {
        case "requestLink":
            return {
                emailPlaceholder: "your email...",
                buttonLabel: "Request Sign-in Link",
            };
        case "confirmSignIn":
            return {
                emailPlaceholder: "confirm your email...",
                buttonLabel: "Sign-in",
            };
        default:
            return {
                emailPlaceholder: "your email...",
                buttonLabel: "Done",
            };
    }
}

const StaySignIn = (props) => {
    const handleChange = () => {
        props.onChange(!props.checked);
    };
    return (
        <div className="clickable mt-3 d-flex justify-content-center align-items-center" onClick={handleChange}>
            <CheckBox checked={props.checked} />
            <span>Stay Signed-in?</span>
        </div>
    );
};

const EmailInnerForm = ({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isValid }) => {
    const formContent = getFormContent(values.formType);
    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Input
                    type="text"
                    name="email"
                    maxLength="200"
                    invalid={errors.email && touched.email}
                    placeholder={formContent.emailPlaceholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email || ""}
                    autoComplete="on"
                />
                <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <Button color="primary" type="submit" disabled={!isValid}>
                {formContent.buttonLabel}
            </Button>
            {values.formType === "confirmSignIn" && (
                <StaySignIn checked={values.staySignIn} onChange={(newVal) => setFieldValue("staySignIn", newVal)} />
            )}
        </Form>
    );
};

const EmailForm = withFormik({
    mapPropsToValues: ({ ...props }) => {
        return {
            formType: props.formType,
            email: props.email,
            staySignIn: true,
        };
    },
    validationSchema: () => {
        // lets you validate what's been added to the form
        return Yup.object().shape({
            email: Yup.string().email().required("Email is required.").max(200, "Email is too long"),
        });
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues.email, formValues.staySignIn);
    },
})(EmailInnerForm);

export default EmailForm;