import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { CheckBoxCheckedIcon, CheckBoxUncheckedIcon, LoadingIconOval } from "../Icons/MyIcons";

export const CheckBox = (props) => {
    const size = props.size || 22;
    const handleClick = () => {
        if (!props.disabled) props.onClick(!props.checked);
    };

    if (props.pending) {
        return <LoadingIconOval className="me-2" size={size} />;
    } else {
        return (
            <div
                className={classNames(props.className, "me-2", { disabled: props.disabled, hidden: props.hide })}
                onClick={handleClick}
            >
                {props.checked ? <CheckBoxCheckedIcon size={size} /> : <CheckBoxUncheckedIcon size={size} />}
                {props.label && <span className="ms-2 text-capitalize">{props.label}</span>}
            </div>
        );
    }
};

CheckBox.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    checked: PropTypes.bool,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
};

CheckBox.defaultProps = {
    onClick: () => {},
};
