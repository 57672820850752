import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactLoading from "react-loading";
import "./loading-indicator.scss";

const LoadingIndicator = (props) => {
    return (
        <div
            className={classNames("loading-indicator", props.fullscreen && "fullscreen", props.flex && "flex")}
            data-testid="loading"
            role="progressbar"
        >
            {props.message && <span className="fw-lighter fs-4 text-center">{props.message}</span>}
            <ReactLoading className={classNames("pt-3", "text-center", "page-loading-indicator")} type={props.type} color="#DD4C4F" />
        </div>
    );
};

LoadingIndicator.propTypes = {
    fullscreen: PropTypes.bool,
    message: PropTypes.string,
    progress: PropTypes.number,
    flex: PropTypes.bool,
    type: PropTypes.oneOf(["blank", "balls", "bars", "bubbles", "cubes", "cylon", "spin", "spinningBubbles", "spokes"]),
};

LoadingIndicator.defaultProps = {
    type: "spinningBubbles",
    progress: 0,
};

export default LoadingIndicator;
