import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../translations/LanguageContext";
import { lookEmployee, lookRole } from "../../../redux/utils/looks";
import { fetchAction } from "../../../firebase/fetch";
import { RichTextDisplay } from "../../RichTextEditor/RichTextEditor";
import { Pill } from "../../BadgesAndPills/Pills";
import ActionModel from "../../../models/ActionModel";

const EmployeeActionReport = ({ plannedActions = [], employeeId }) => {
    const { t } = useLanguage();
    const [actions, setActions] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const employee = lookEmployee(employeeId);
    const role = lookRole(employee.roleId);
    const language = t("employeeActions", "reports");

    useEffect(() => {
        let newActions = [];
        async function getAction(actionId) {
            const action = await fetchAction(actionId);
            newActions.push(action);
            setIsReady(newActions.length === plannedActions.length);
        }
        if (!isReady) {
            plannedActions.forEach((planned) => {
                const actionId = planned.actionId;
                getAction(actionId);
            });
            setActions(newActions);
        }
    }, [plannedActions, isReady]);

    const Action = ({ action }) => {
        const actionModel = new ActionModel(action);
        const linkedTraits = actionModel.getLinkedTraitIds(true);
        return (
            <div className="mb-4 border-bottom">
                <h5 className="text-capitalize">{action.actionName}</h5>
                <div className="mb-2">
                    {linkedTraits.map((trait) => (
                        <Pill key={`${action.id}-${trait.id}`} className="border-all me-2">
                            {trait.traitName}
                        </Pill>
                    ))}
                </div>
                <RichTextDisplay content={action.details} />
            </div>
        );
    };

    return (
        <div className="p-5">
            <div>
                <h1>{language.title}</h1>
                <h3>{employee.displayName}</h3>
                <h5>{role?.jobTitle}</h5>
            </div>
            <hr />
            <div className="d-flex flex-column f-1">
                {actions.map((action, i) => (
                    <Action key={`${action.id}-${i}`} action={action} />
                ))}
            </div>
        </div>
    );
};

export default EmployeeActionReport;
