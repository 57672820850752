import classNames from "classnames";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, FormGroup, FormText, Input, Label } from "reactstrap";
import { TalentBoardPicker } from "../../../components";
import CustomField from "../../../components/CustomField/CustomField";
import { FIELD_SIZE_LIMITS, USER_ROLES } from "../../../constants";
import { setEmployee } from "../../../firebase/actions/employee";
import { setRole } from "../../../firebase/actions/role";
import { SET_SIDE_AREA } from "../../../redux/appSlice";
import { emailInUseAlready } from "../../../utils/employeeUtils";
import { checkAccess } from "../../../utils/userUtils";
import "../people.scss";

const EmployeeEdits = (props) => {
    const customFields = useSelector((state) => state.workspace.customFields);
    const employeeFields = customFields.filter((field) => field.category === "employee");
    employeeFields.sort((a, b) => a.type - b.type);
    const userIsAdmin = checkAccess("roleAdmin");
    const { editedEmployee, emailInUse } = props;
    if (Object.keys(editedEmployee).length === 0) return null;

    let emailMessage = "No User Role";
    if (emailInUse) {
        emailMessage = "Email in use";
    } else {
        if (editedEmployee.userRole === "owner") {
            emailMessage = "Owner";
        } else {
            let userRole = USER_ROLES[editedEmployee.userRole] || {};
            emailMessage = userRole.label || "No User Role";
        }
    }

    return (
        <div className="px-1 pt-2">
            <h6>Employee Fields</h6>
            <FormGroup>
                <Label for="firstname">Firstname</Label>
                <Input
                    type="text"
                    name="firstname"
                    maxLength={FIELD_SIZE_LIMITS.FIRSTNAME}
                    placeholder="Firstname"
                    onChange={(e) => props.onStageChange("firstname", e.target.value)}
                    value={editedEmployee.firstname || ""}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <Label for="surname">Surname</Label>
                <Input
                    type="text"
                    name="surname"
                    maxLength={FIELD_SIZE_LIMITS.SURNAME}
                    placeholder="Surname"
                    onChange={(e) => props.onStageChange("surname", e.target.value)}
                    value={editedEmployee.surname || ""}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <Label for="employeeNumber">Employee Number</Label>
                <Input
                    type="text"
                    name="employeeNumber"
                    maxLength={FIELD_SIZE_LIMITS.EMPLOYEE_NO}
                    readOnly={!userIsAdmin}
                    placeholder="Employee Number"
                    onChange={(e) => props.onStageChange("employeeNumber", e.target.value)}
                    value={editedEmployee.employeeNumber || ""}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup className="d-flex justify-content-between">
                <div className="f-3">
                    <Label for="email">Email</Label>
                    <Input
                        type="text"
                        name="email"
                        maxLength={FIELD_SIZE_LIMITS.EMAIL}
                        placeholder="Email"
                        readOnly={editedEmployee.isUser}
                        onBlur={(e) => {
                            const email = e.target.value;
                            if (email) {
                                props.setEmailInUse(emailInUseAlready(e.target.value, editedEmployee.id));
                            } else {
                                props.setEmailInUse(false);
                            }
                        }}
                        onChange={(e) => {
                            props.onStageChange("email", e.target.value);
                        }}
                        value={editedEmployee.email || ""}
                        autoComplete="off"
                    />
                    <FormText className={classNames({ "text-muted": !emailInUse, "c-danger": emailInUse })}>
                        {emailMessage}
                    </FormText>
                </div>
            </FormGroup>
            {employeeFields.map((field) => (
                <FormGroup key={field.id}>
                    <CustomField field={field} value={editedEmployee[field.id]} onChange={props.onStageChange} />
                </FormGroup>
            ))}
        </div>
    );
};

const RoleEdits = (props) => {
    const { editedRole } = props;
    const canEditRestricted = checkAccess("manageRoles");
    if (Object.keys(editedRole).length === 0) return null;
    return (
        <div className="px-1 pt-2">
            <h6>Role Fields</h6>
            <FormGroup>
                <Label for="talentBoardId">Talent Boards</Label>
                <TalentBoardPicker
                    isMulti
                    disabled={!canEditRestricted}
                    selectedIds={editedRole.talentBoardIds}
                    onChange={(boardIds) => {
                        props.onStageChange("talentBoardIds", boardIds);
                    }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="jobTitle">Job Title</Label>
                <Input
                    type="text"
                    name="jobTitle"
                    placeholder="Job Title"
                    maxLength={FIELD_SIZE_LIMITS.JOB_TITLE}
                    onChange={(e) => props.onStageChange("jobTitle", e.target.value)}
                    value={editedRole.jobTitle || ""}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <Label for="jobTitle">Department Name</Label>
                <Input
                    type="text"
                    name="departmentName"
                    placeholder="Department Name"
                    maxLength={FIELD_SIZE_LIMITS.DEPARTMENT_NAME}
                    onChange={(e) => props.onStageChange("departmentName", e.target.value)}
                    value={editedRole.departmentName || ""}
                    autoComplete="off"
                />
            </FormGroup>
        </div>
    );
};

const RoleEditArea = () => {
    const dispatch = useDispatch();
    const [employeeChanged, setEmployeeChanged] = useState(false);
    const [roleChanged, setRoleChanged] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [emailInUse, setEmailInUse] = useState(false);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const [editedRole, setEditedRole] = useState(selectedRole);
    const [editedEmployee, setEditedEmployee] = useState(selectedEmployee);

    useEffect(() => {
        setEditedRole(selectedRole);
        setEditedEmployee(selectedEmployee);
    }, [selectedEmployee, selectedRole]);

    useEffect(() => {
        if (emailInUse) {
            setDisableSave(true);
        } else {
            const roleChanged = !isEqual(editedRole, selectedRole);
            const employeeChanged = !isEqual(editedEmployee, selectedEmployee);
            setDisableSave(!roleChanged && !employeeChanged);
        }
    }, [selectedRole, editedRole, selectedEmployee, editedEmployee, emailInUse]);

    const handleStageEmployeeChange = (field, change) => {
        const update = { ...editedEmployee, [field]: change };
        setEditedEmployee(update);
        setEmployeeChanged(!isEqual(update, selectedEmployee));
    };

    const handleStageRoleChange = (field, change) => {
        const update = { ...editedRole, [field]: change };
        setEditedRole(update);
        setRoleChanged(!isEqual(update, selectedRole));
    };

    const handleSubmit = () => {
        setDisableSave(true);
        if (employeeChanged) {
            setEmployee(selectedEmployeeId, editedEmployee)
                .commit()
                .then(() => {
                    dispatch(SET_SIDE_AREA());
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
        if (roleChanged) {
            let update = {
                jobTitle: editedRole.jobTitle,
                departmentName: editedRole.departmentName || "",
                talentBoardIds: editedRole.talentBoardIds,
            };
            setRole(selectedRoleId, update)
                .commit()
                .then(() => {
                    dispatch(SET_SIDE_AREA());
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    };

    return (
        <div className="side-area no-scroll h-100 d-flex flex-column justify-content-between bg-transparent dashboard-headers">
            <div className="scrollable f-1">
                {selectedEmployeeId && (
                    <>
                        <EmployeeEdits
                            employeeId={selectedEmployeeId}
                            editedEmployee={editedEmployee}
                            onStageChange={handleStageEmployeeChange}
                            setEmailInUse={setEmailInUse}
                            emailInUse={emailInUse}
                        />
                        <hr />
                    </>
                )}
                <RoleEdits roleId={selectedRoleId} editedRole={editedRole} onStageChange={handleStageRoleChange} />
            </div>
            <div className="pt-3 px-3 border-top">
                <Button className="float-end d-flex align-items-center" disabled={disableSave} onClick={handleSubmit}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default RoleEditArea;
