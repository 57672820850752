import classNames from "classnames";
import React from "react";
import { FaUser } from "react-icons/fa";
import { TalentBoardModel } from "../../models";
import { ViewStrengthsIcon } from "../Icons/MyIcons";

export const AssignedToTalentBoard = ({ className, talentBoard }) => {
    const talentBoardModel = new TalentBoardModel(talentBoard);
    const linkedEmployees = talentBoardModel.getLinkedEmployees(true);
    const count = linkedEmployees.length;
    return (
        <div className={classNames(className, "d-flex")}>
            <FaUser className="me-2" />
            <h6>{`Assigned to ${count} role${count !== 1 ? "s" : ""}`}</h6>
        </div>
    );
};

export const LinkedToTalentBoard = ({ className, talentBoard }) => {
    const talentBoardModel = new TalentBoardModel(talentBoard);
    const linkedTraits = talentBoardModel.getTraits();
    const count = linkedTraits.length;
    return (
        <div className={classNames(className, "d-flex")}>
            <ViewStrengthsIcon size={18} className="me-2" />
            <h6>{`Linked to ${count} trait${count !== 1 ? "s" : ""}`}</h6>
        </div>
    );
};
