import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setHelpViewed, unsetHelpViewed } from "../../firebase/actions/user";
import { useLanguage } from "../../translations/LanguageContext";
import { HelpButton, RemoveButton } from "../Buttons/MyButtons";

export const Help = ({ children, helpId }) => {
    const viewedHelp = useSelector((state) => state.user.viewedHelp);
    const thisHasBeenViewed = viewedHelp.includes(helpId);
    const [changing, setChanging] = useState(false);
    const { t } = useLanguage();

    useEffect(() => {
        setChanging(false);
    }, [thisHasBeenViewed]);

    const buttonStyle = {
        position: "absolute",
        top: 10,
        right: 10,
    };

    const handleShow = () => {
        setChanging(true);
        unsetHelpViewed(helpId);
    };

    const handleDismiss = () => {
        setChanging(true);
        setHelpViewed(helpId);
    };

    if (thisHasBeenViewed)
        return (
            <div className="py-2">
                <HelpButton disabled={changing} onClick={handleShow} className="c-help float-end" size={24} />
            </div>
        );

    return (
        <div
            className={classNames("position-relative rounded bg-dark p-3 my-4", {
                "fully-hidden": thisHasBeenViewed,
                "fade-out": changing,
            })}
        >
            <div style={buttonStyle}>
                <RemoveButton disabled={changing} onClick={handleDismiss} icon size={26} />
            </div>
            <h6 className="c-help text-capitalize">{t("what_is_this")}</h6>
            {children}
        </div>
    );
};
