import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { lookTrait } from "../../../redux/utils/looks";
import BaseTable from "../BaseTable";
import PropTypes from "prop-types";
import classNames from "classnames";

// ratings format: [{ [empId] : {[traitId]: [0-100] }, ...]
const RatingComparisonTable = (props) => {
    const { ratings } = props;
    const traitIds = useMemo(() => [...new Set(Object.values(ratings).flatMap((obj) => Object.keys(obj)))], [ratings]);
    const selectedTraitId = useSelector((state) => state.talent.selectedTraitId);

    const preppedData = useMemo(() => {
        const preppedRatings = [];
        traitIds.forEach((traitId) => {
            const trait = lookTrait(traitId);
            if (trait) {
                let preppedRating = {
                    key: traitId,
                    traitName: trait.traitName,
                };
                Object.entries(ratings).forEach(([empId, empRatings]) => {
                    preppedRating[empId] = empRatings[traitId];
                });
                preppedRating["difference"] = preppedRating["manager"] - preppedRating["employee"];
                preppedRatings.push(preppedRating);
            }
        });
        return preppedRatings;
    }, [traitIds, ratings]);

    const columns = useMemo(() => {
        let addColumns = [
            {
                key: "traitName",
                title: "Trait",
                dataIndex: "traitName",
            },
        ];

        Object.keys(ratings).forEach((owner) => {
            addColumns.push({
                key: owner,
                title: owner,
                dataIndex: owner,
            });
        });

        addColumns.push({
            key: "difference",
            title: "+/-",
            dataIndex: "difference",
            render: (label, record, index) => (
                <span className={classNames({ "c-danger": record.difference < 0, "c-success": record.difference > 0 })}>
                    {record.difference}
                </span>
            ),
        });

        return addColumns;
    }, [ratings]);

    const handleRowClick = (empId) => {};

    const handleRowDoubleClick = (empId) => {};

    return (
        <BaseTable
            {...props}
            data={preppedData}
            selectedId={selectedTraitId}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            columns={columns}
            sortKey="difference"
        />
    );
};

RatingComparisonTable.propTypes = {
    ratings: PropTypes.object.isRequired,
};

RatingComparisonTable.defaultProps = {
    ratings: {},
};

export default RatingComparisonTable;
