import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BaseTable } from "../..";
import ActionModel from "../../../models/ActionModel";
import { SELECT_ACTION } from "../../../redux/appSlice";
import { Pill } from "../../BadgesAndPills/Pills";

const ActionsTable = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedActionId = useSelector((state) => state.app.selectedActionId);
    const { columnKeys, tooltipId } = props;
    const [includedColumns, setIncludedColumns] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        const columns = [
            {
                key: "actionName",
                title: "Action",
                dataIndex: "actionName",
                width: "80%",
            },
            {
                key: "actionStatus",
                title: "Status",
                dataIndex: "actionStatus",
                width: "10%",
                render: (label, action, index) => {
                    const actionModel = new ActionModel(action);
                    const status = actionModel.status();
                    return (
                        <Pill className={classNames({ active: status, inactive: !status })}>
                            {status ? "ACTIVE" : "INACTIVE"}
                        </Pill>
                    );
                },
            },
        ];
        const filteredColumns = columns.filter((column) => columnKeys.includes(column.key));
        setIncludedColumns(filteredColumns);
    }, [columnKeys, tooltipId]);

    const handleRowClick = (actionId) => {
        dispatch(SELECT_ACTION(actionId));
    };

    const handleRowDoubleClick = (actionId) => {
        if (actionId) {
            navigate(`/actions/${actionId}`);
        }
    };

    return (
        <BaseTable
            {...props}
            className="actions-table"
            columns={includedColumns}
            selectedId={selectedActionId}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            widePadding={props.widePadding}
            pageIndex={pageIndex}
            sortKey="actionName"
            onSetPageIndex={setPageIndex}
            checkbox
        />
    );
};

ActionsTable.propTypes = {
    columnKeys: PropTypes.array,
    disableRowClick: PropTypes.bool,
    disableRowDoubleClick: PropTypes.bool,
    widePadding: PropTypes.bool,
};

ActionsTable.defaultProps = {
    columnKeys: [],
};

export default ActionsTable;
