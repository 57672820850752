import React, { createContext, useEffect, useRef } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Fade } from "reactstrap";
import { EmptyState } from "../../../../components";
import { EmployeeModel } from "../../../../models";
import { selectRole } from "../../../../redux/utils/dispatches";
import { checkAccess } from "../../../../utils/userUtils";
import RoleViewToolbar from "./Toolbars/RoleViewToolbar";

const TABS = [
    { key: "employee", label: "Employee" },
    { key: "department", label: "Department" },
    { key: "talent-map", label: "Talent Map" },
    { key: "succession-planning", label: "Succession Planning" }
];

export const RoleViewContext = createContext(null);

const RoleView = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const talentMapRef = useRef();
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const employeeModel = selectedEmployee && new EmployeeModel(selectedEmployee);
    const userCanAssessEmployee = employeeModel && employeeModel.canYouAssessMe(userEmployeeId);
    const disableAssessmentEdits = !assessmentsOpen || !userCanAssessEmployee;
    const hasChildren = selectedRole?.childRoles?.length > 0;
    let activeTab = TABS.find((tab) => location.pathname.includes(tab.key));
    activeTab = activeTab ? activeTab.key : "";
    const activeRoleId = params.roleId;

    // Ensure correct entities are selected, or redirect if not authorised
    useEffect(() => {
        const allowedAccess = checkAccess("role", activeRoleId);
        if (allowedAccess) {
            selectRole(activeRoleId);
        } else {
            navigate("people/");
        }
    }, [navigate, activeRoleId]);

    const changeTab = (tab) => {
        const tabClean = tab.replace("#", "");
        if (tabClean && selectedRole && activeTab !== tabClean) {
            navigate(tabClean);
        }
    };

    const handleExportTalentMap = () => {
        exportComponentAsPNG(talentMapRef);
    };

    if (!selectedRole || !selectedEmployee) {
        return <EmptyState header="Role is Vacant" />;
    }

    return (
        <Fade className="inner-container d-flex align-items-stretch flex-column">
            <RoleViewToolbar
                {...props}
                tabs={TABS}
                disableAssessmentEdits={disableAssessmentEdits}
                hasChildren={hasChildren}
                activeTab={activeTab}
                changeTab={changeTab}
                exportTalentMap={handleExportTalentMap}
            />
            <div className="d-flex flex-column f-1 scrollable">
                <RoleViewContext.Provider value={talentMapRef}>
                    <Outlet />
                </RoleViewContext.Provider>
            </div>
        </Fade>
    );
};

export default RoleView;
