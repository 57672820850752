import classNames from "classnames";
import React from "react";
import { Badge } from "reactstrap";

export const CountBadge = (props) => {
    if (!props.count || props.count === 0) return null;
    const single = props.count < 10;
    const double = !single && props.count < 100;
    const triple = !double && props.count >= 100;
    return (
        <Badge className={classNames("count-badge", { single: single, double: double, triple: triple })}>
            <span>{props.count}</span>
        </Badge>
    );
};