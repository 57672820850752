import React, { useState } from "react";
import { getContrastColor, getUniqueId } from "../../utils/basicUtils";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import { FIELD_SIZE_LIMITS } from "../../constants";


const GridBox = ({ box, onUpdate, row, col }) => {
    const textColor = getContrastColor(box.color);
    const [color, setColor] = useState(box.color);

    const handleUpdateTitle = (title) => {
        if (title !== box.title) {
            let newBox = { ...box };
            newBox.title = title;
            onUpdate(col, row, newBox);
        }
    };

    const handleUpdateColor = (newColor) => {
        if (newColor !== box.color) {
            var reg = /^#([0-9a-f]{3}){1,2}$/i;
            if (reg.test(newColor)) {
                let newBox = { ...box };
                newBox.color = newColor;
                onUpdate(col, row, newBox);
            } else {
                toast.error("Not a valid color");
                setColor(box.color);
            }
        }
    };

    const style = { backgroundColor: box.color, color: textColor };
    const textBoxStyle = { ...style, border: "none", textAlign: "center", fontSize: ".8rem" };

    return (
        <div className="f-1 grid-config-box clickable" style={style}>
            <Input
                style={textBoxStyle}
                type="textarea"
                name="title"
                placeholder="title"
                maxLength={FIELD_SIZE_LIMITS.BOX_TITLE}
                onBlur={(e) => handleUpdateTitle(e.target.value)}
                defaultValue={box.title || ""}
                autoComplete="off"
            />
            <Input
                style={textBoxStyle}
                type="text"
                name="color"
                placeholder="color"
                maxLength={10}
                onBlur={(e) => handleUpdateColor(e.target.value)}
                onChange={(e) => setColor(e.target.value)}
                value={color}
                autoComplete="off"
            />
        </div>
    );
};

export const TalentGridConfig = ({ grid, setConfig }) => {
    const handleUpdateGrid = (col, row, newBox) => {
        let newGrid = { ...grid };
        let newCol = { ...newGrid[col] };
        newCol[row] = newBox;
        newGrid[col] = newCol;
        setConfig("grid", newGrid);
    };
    return (
        <div className="d-flex flex-column border-all">
            <div className="d-flex">
                {Object.values(grid).map((col, c) => (
                    <div key={getUniqueId(3)} className="f-1 d-flex flex-column-reverse align-items-stretch">
                        {/*<ToolbarPlusButton className="py-2" outline tooltip="Add Row" round={false} tooltipId={TOOLTIP_ID} />*/}
                        {Object.values(col).map((box, r) => {
                            return (
                                <GridBox
                                    key={getUniqueId(3)}
                                    box={box}
                                    onUpdate={handleUpdateGrid}
                                    col={c + 1}
                                    row={r + 1}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};