import { color } from "d3";
import { isEqual } from "lodash";
import { getNanoid } from "./nanoid";

export const getUniqueId = (length = 7) => {
    return getNanoid(length);
};

export function formatDate(rawDate) {
    // This seems stupid to me, but I didn't do it, chatGPT did. Don't argue with the robot.
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(rawDate);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
}

export function dateAdd(days, date) {
    var initialDate = new Date(date);
    var result = initialDate.setDate(initialDate.getDate() + days);
    return new Date(result);
}

export function percentage(val, max) {
    return (100 * val) / max;
}

export function getDefault(array) {
    const result = array.filter((obj) => {
        return obj.default;
    });
    return result ? result[0] : "";
}

export function dedupe(array) {
    return [...new Set(array)];
}

export function arrayContainsAll(arr, target) {
    return target.every((v) => arr.includes(v));
}

export function sortArrayOfObjects(sortThisArray, byThisField, sortDesc, parentKey) {
    const sortedArray = sortThisArray.slice().sort((a, b) => {
        let aValue = a[byThisField];
        let bValue = b[byThisField];
        if (parentKey) {
            const aParent = a[parentKey];
            const bParent = b[parentKey];
            aValue = aParent && aParent[byThisField];
            bValue = bParent && bParent[byThisField];
        }
        if (typeof aValue === "string" && typeof bValue === "string") {
            return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        } else if (typeof aValue === "number" && typeof bValue === "number") {
            return sortDesc ? bValue - aValue : aValue - bValue;
        } else {
            // If the data types don't match, we can't compare them directly.
            // In this case, we'll just treat the values as equal.
            return 0;
        }
    });
    return sortedArray;
}

export function sortByDateField(sortThis, fieldName, asc = true) {
    if (asc) {
        return sortThis.sort((objA, objB) => Number(objA[fieldName]) - Number(objB[fieldName]));
    } else {
        return sortThis.sort((objA, objB) => Number(objB[fieldName]) - Number(objA[fieldName]));
    }
}

export function removeUndefined(dataToClean) {
    if (Array.isArray(dataToClean)) {
        let cleaned = [];
        dataToClean.forEach((entry) => {
            if (entry !== undefined && entry !== "undefined") cleaned.push(entry);
        });
        return cleaned;
    } else {
        Object.keys(dataToClean).forEach((key) => dataToClean[key] === undefined && delete dataToClean[key]);
        return dataToClean;
    }
}

export function removeNulls(initialData) {
    const cleanedData = { ...initialData };
    Object.keys(cleanedData).forEach((key) => {
        if (cleanedData[key] === null) {
            delete cleanedData[key];
        } else if (typeof cleanedData[key] === "object" && !Array.isArray(cleanedData[key])) {
            removeNulls(cleanedData[key]);
        }
    });
    return cleanedData;
}

export function convertArrayToObject(array) {
    // Key must be id
    let obj = {};
    array.forEach((entry) => {
        const key = entry.id;
        let { id, ...newEntry } = entry;
        obj[key] = { ...newEntry };
    });
    return obj;
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function isAlphaNumeric(key) {
    const regex = "/^w+$/";
    return key.match(regex);
}

export function dateIsInTheFuture(date) {
    const compareTo = new Date(date);
    const today = new Date();
    today.setHours(23, 59, 59, 998);
    return compareTo > today;
}

export function dateIsXDaysAgo(checkDate, days) {
    const date = new Date(checkDate);
    const today = new Date();
    const daysAgo = new Date(today.setDate(today.getDate() - days));
    return date < daysAgo;
}

export function dateIsBefore(thisDate, isBeforeThisDate) {
    const parseDate = (date) => {
        if (typeof date === "string") {
            return new Date(date);
        } else {
            return date;
        }
    };
    const isThisDate = parseDate(thisDate);
    const beforeThisDate = parseDate(isBeforeThisDate);
    return isThisDate < beforeThisDate;
}

export function yearsAgo(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const yearsDifference = currentDate.getFullYear() - inputDate.getFullYear();

    // Check if the input date has not yet passed in the current year
    if (
        currentDate.getMonth() < inputDate.getMonth() ||
        (currentDate.getMonth() === inputDate.getMonth() && currentDate.getDate() < inputDate.getDate())
    ) {
        return yearsDifference - 1;
    }

    return yearsDifference;
}

export function displayDate(date) {
    return date ? new Date(date).toLocaleDateString("en-GB") : null;
}

export function toggleArrayContains(originalArray = [], id) {
    let array = [...originalArray];
    if (array.includes(id)) {
        const index = array.indexOf(id);
        if (index > -1) {
            array.splice(index, 1);
        }
    } else {
        array.push(id);
    }
    return array;
}

export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";

export const cLog = IS_DEV || IS_TEST ? console.log.bind(document) : () => {};

export function getInitials(name, singleInitial) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = [...name.matchAll(rgx)] || [];
    initials = ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
    return singleInitial ? initials.substring(0, 1) : initials;
}

export function getContrastColor(backgroundColor) {
    // Convert the background color to d3 RGB object
    const bgColor = color(backgroundColor);

    // Calculate the relative luminance using the W3C formula
    const getLuminance = (c) => {
        const colorValue = c / 255;
        return colorValue <= 0.03928 ? colorValue / 12.92 : Math.pow((colorValue + 0.055) / 1.055, 2.4);
    };

    const rLuminance = getLuminance(bgColor.r);
    const gLuminance = getLuminance(bgColor.g);
    const bLuminance = getLuminance(bgColor.b);

    const luminance = 0.2126 * rLuminance + 0.7152 * gLuminance + 0.0722 * bLuminance;

    // Determine the appropriate text color based on the background luminance
    return luminance > 0.4 ? "#32373b" : "white";
}

export function filterObjectKeys(obj = {}, includeKeys = []) {
    let filtered = {};
    Object.keys(obj).forEach((key) => {
        if (includeKeys.includes(key)) {
            filtered[key] = obj[key];
        }
    });
    return filtered;
}

export function removeFalseyValues(obj) {
    Object.keys(obj).forEach((key) => !obj[key] && delete obj[key]);
    return obj;
}

export function advIsEqual(prev, next, excludeNull) {
    let compThis = { ...prev };
    let toThis = { ...next };
    if (excludeNull) {
        removeNulls(compThis);
        removeNulls(toThis);
    }
    return isEqual(compThis, toThis);
}

// write a method that turns text written in camelCase into a string with spaces
export function camelCaseToSentenceCase(text) {
    return text.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

// Removes any key that isn't a number of an object/array
export function filterObjectByType(obj) {
    const filteredObj = {};
    Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === "number") {
            filteredObj[key] = value;
        } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            const nestedObj = filterObjectByType(value);
            if (Object.keys(nestedObj).length > 0) {
                filteredObj[key] = nestedObj;
            }
        }
    });

    return filteredObj;
}
