import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseTable } from "../..";
import { SELECT_TALENT_AREA, SET_SIDE_AREA } from "../../../redux/appSlice";
import { getRatingCounts, rateTalentAreas } from "../../../utils/snapshotUtils";
import { Pill } from "../../BadgesAndPills/Pills";
import { RemoveButton } from "../../Buttons/MyButtons";
import TraitPieChart from "../../Charts/TraitCharts/TraitPieChart";


const TalentAreasTable = (props) => {
    const dispatch = useDispatch();
    const { columnKeys, snapshots, data, tooltipId, iconSize, onRemove, includeRemove, onEditTalentArea, checkbox } =
        props;
    const [includedColumns, setIncludedColumns] = useState([]);
    const [talentAreaRatings, setTalentAreaRatings] = useState({});
    const [talentAreaRatingCounts, setTalentAreaRatingCounts] = useState({});
    const [pageIndex, setPageIndex] = useState(0);
    const pendingChangeIds = useSelector((state) => state.talent.pendingChanges);
    const selectedTalentAreaId = useSelector((state) => state.app.selectedTalentAreaId);

    // Prep the rating data for the table (all available assessment data)
    useEffect(() => {
        setTalentAreaRatings(rateTalentAreas(snapshots));
        let newTalentAreaRatingCounts = {};
        data.forEach((talentArea) => {
            const counts = getRatingCounts(snapshots, "talentAreas", talentArea.id);
            newTalentAreaRatingCounts[talentArea.id] = counts;
        });
        setTalentAreaRatingCounts(newTalentAreaRatingCounts);
    }, [snapshots, data]);

    useEffect(() => {
        let columns = [
            {
                key: "ratingCounts",
                title: "",
                width: "8%",
                render: (label, talentArea, index) => {
                    return (
                        <div
                            style={{ height: iconSize, width: iconSize, margin: "auto", overflow: "hidden" }}
                            className="d-flex justify-content-center"
                        >
                            <TraitPieChart ratingCounts={talentAreaRatingCounts[talentArea.id]} />
                        </div>
                    );
                },
            },
            {
                key: "talentAreaName",
                title: "Talent Area Name",
                dataIndex: "talentAreaName",
                width: "30%",
            },
            {
                key: "description",
                title: "Description",
                dataIndex: "description",
                width: "50%",
            },
            {
                key: "linkedTraitCount",
                title: "Traits Linked To",
                width: "20%",
                noSort: true,
                render: (label, talentArea, index) => {
                    return <Pill className="bg-fun">{`${talentArea.linkedTraitCount} Linked Traits`}</Pill>;
                },
            },
        ];

        columns = columns.filter((column) => columnKeys.includes(column.key));

        if (includeRemove) {
            columns.push({
                key: "remove",
                width: "1%",
                noSort: true,
                render: (label, employee, index) => {
                    return <RemoveButton round={false} size={18} icon onClick={() => onRemove(employee.id)} />;
                },
            });
        }
        setIncludedColumns(columns);
    }, [
        columnKeys,
        tooltipId,
        talentAreaRatings,
        talentAreaRatingCounts,
        iconSize,
        onEditTalentArea,
        includeRemove,
        onRemove,
    ]);

    const handleRowClick = (talentAreaId) => {
        dispatch(SELECT_TALENT_AREA(talentAreaId));
    };

    const handleRowDoubleClick = (talentAreaId) => {
        if (talentAreaId) {
            //navigate(`/talentAreas/${talentAreaId}`);
            dispatch(SET_SIDE_AREA("editTalentArea"));
        }
    };

    return (
        <BaseTable
            {...props}
            className="talentAreas-table"
            columns={includedColumns}
            selectedId={selectedTalentAreaId}
            pendingChangeIds={pendingChangeIds}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            pageIndex={pageIndex}
            onSetPageIndex={setPageIndex}
            sortKey="talentAreaName"
            selectableRows
            checkbox={checkbox}
        />
    );
};

TalentAreasTable.propTypes = {
    columnKeys: PropTypes.array,
    iconSize: PropTypes.number,
    rowsPerPage: PropTypes.number,
    disableRowClick: PropTypes.bool,
    checkbox: PropTypes.bool,
    disableRowDoubleClick: PropTypes.bool,
    onEditTalentArea: PropTypes.func,
    onRemove: PropTypes.func,
};

TalentAreasTable.defaultProps = {
    columnKeys: [],
    rowsPerPage: 30,
    iconSize: 36,
    onEditTalentArea: () => {},
    onRemove: () => {},
};

export default TalentAreasTable;
