import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { lookTalentArea } from "../../../redux/utils/looks";
import { getExactColor } from "../../../utils/snapshotUtils";
import RadialChart from "../BaseCharts/RadialChart";

const CustomTooltip = (props) => {
    const { active, payload } = props;
    const item = payload && payload[0] && payload[0].payload;
    if (active && payload && payload.length) {
        return (
            <div style={{ border: "none", outline: "none" }} className="chart-tooltip d-flex flex-column">
                <h5 className="fs-tiny">{item.label}</h5>
                <h6 className="fs-tiny">{item.value}</h6>
            </div>
        );
    }
    return null;
};

const TalentAreaRadialChart = (props) => {
    const { ratings, overallRating, disableTooltip } = props;
    const activeTalentAreaIds = useSelector((state) => state.talent.activeTalentAreaIds);
    const [preppedData, setPreppedData] = useState([]);

    useEffect(() => {
        if(ratings) {
            const overallRatingColor = getExactColor(overallRating);
            let newData = activeTalentAreaIds
                .filter((areaId) => {
                    const talentArea = lookTalentArea(areaId);
                    return !talentArea.excludeFromOverview;
                })
                .map((areaId) => {
                    const talentArea = lookTalentArea(areaId);
                    const ratingObject = ratings.find((rating) => rating.id === areaId);
                    const rating = ratingObject && ratingObject.rating;
                    const displayRating = rating ? rating : 0;
                    return {
                        id: areaId,
                        label: talentArea.talentAreaName,
                        value: displayRating,
                        fill: getExactColor(rating),
                    };
                });
            newData = newData.sort((a, b) => b.value - a.value);
            newData.unshift({ id:"overall", label: "Overall", value: 100, fill: overallRatingColor });
            setPreppedData(newData);
        }
    }, [activeTalentAreaIds, ratings, overallRating]);

    const size = props.width;

    return (
        <RadialChart data={preppedData} disableTooltip={disableTooltip} size={size} customTooltip={<CustomTooltip />} />
    );
};

TalentAreaRadialChart.propTypes = {
    ratings: PropTypes.array,
    overallRating: PropTypes.number,
    size: PropTypes.number,
    employeeId: PropTypes.string,
    cursor: PropTypes.string,
    legend: PropTypes.bool,
    disableTooltip: PropTypes.bool,
};

TalentAreaRadialChart.defaultProps = {
    ratings: [],
    cursor: "pointer",
    size: 100,
    legend: true,
};

export default TalentAreaRadialChart;
