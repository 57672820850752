import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { makeRatingsArray } from "../../../utils/snapshotUtils";
import { TalentAreaRadialChart } from "../../Charts";
import ChartContainer from "../../Charts/BaseCharts/ChartContainer";
import EmptyState from "../../EmptyState/EmptyState";

const EmployeeCard = (props) => {
    const { employeeId } = props;
    const [talentAreaRatings, setTalentAreaRatings] = useState();
    const employee = useSelector((state) => state.org.employees[employeeId]);
    const snap = useSelector((state) => state.talent.snapshots[employeeId]);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const isSelf = employeeId === userEmployeeId;
    let overallRating = snap && snap.overall;
    overallRating = isSelf ? 0 : overallRating;
    const talentAreas = snap && snap.talentAreas;

    useEffect(() => {
        if (isSelf) {
            setTalentAreaRatings([]);
        } else {
            if (talentAreas) {
                const ratingArray = makeRatingsArray(talentAreas);
                setTalentAreaRatings(ratingArray);
            }
        }
    }, [talentAreas, isSelf]);

    const handleViewEmployee = () => {
        props.onView(employee.roleId);
    };

    if (!employee) return <EmptyState />;

    return (
        <Card
            className="clickable employee-card d-flex justify-content-stretch align-items-stretch p-3"
            onClick={handleViewEmployee}
        >
            <ChartContainer padding={40}>
                <TalentAreaRadialChart ratings={talentAreaRatings} overallRating={overallRating} />
            </ChartContainer>
            <h6 className="text-center fs-tiny mt-3">{employee.displayName}</h6>
        </Card>
    );
};

EmployeeCard.propTypes = {
    employeeId: PropTypes.string,
    cardBorder: PropTypes.bool,
    onView: PropTypes.func,
};

EmployeeCard.defaultProps = {
    onView: () => {},
};

export default EmployeeCard;
