import { setDoc } from "firebase/firestore";
import { setPendingTalentChange } from "../../redux/utils/dispatches";
import { lookTalentArea } from "../../redux/utils/looks";
import { getUniqueId } from "../../utils/basicUtils";
import { getNewTalentInfoDocId, getTalentAreaDocRef } from "../firebaseUtils";

export async function setTalentArea(receivedData) {
    let { id, ...preppedData } = receivedData;
    id = id || getUniqueId();

    setPendingTalentChange(id);

    // Set the publicinfoDocId
    if (!preppedData.publicInfoDocId) {
        const currentTalentArea = lookTalentArea(id);
        const existingPublicInfoDocId = currentTalentArea && currentTalentArea.publicInfoDocId;
        preppedData.publicInfoDocId = existingPublicInfoDocId || getNewTalentInfoDocId();
    }

    const talentAreaDocRef = getTalentAreaDocRef(id);
    setDoc(talentAreaDocRef, preppedData, { merge: true });
    return id;
}
// write a unit test for the above method


export function disableTalentArea(talentAreaId) {
    // Cloud function removes this talentAreaId from any trait that includes it
    let talentArea = lookTalentArea(talentAreaId);
    const { id, publicInfoDocId } = talentArea;
    const update = { id: id, publicInfoDocId: publicInfoDocId, disabled: true };
    return setTalentArea(update);
}
