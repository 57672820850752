import { lookEmployee } from "../redux/utils/looks";
import { cLog, dateIsBefore } from "../utils/basicUtils";
import {
    getChildEmployeeIds,
    getChildRoleIds,
    getRoleOrgLevel,
    getRoleParentRole,
    getRoleParentRoles,
} from "../utils/roleUtils";

class RoleModel {
    constructor(role, calledBy) {
        if (!role || typeof role === "string") {
            cLog(calledBy, typeof role);
        } else {
            this.id = role.id;
            this._role = role;
        }
    }

    haveAssessmentsBeenRequested(workspaceRequestDate, roleRequestDate) {
        return roleRequestDate && dateIsBefore(workspaceRequestDate, roleRequestDate);
    }

    getIncumbent() {
        return lookEmployee(this._role.incumbentId);
    }

    getParentRole() {
        return getRoleParentRole(this.id);
    }

    getParentRoles() {
        return getRoleParentRoles(this.id);
    }

    getOrgLevel() {
        return getRoleOrgLevel(this.id);
    }

    /*
    getPath(topLevelRoleId) {
        let parentList = this.getParentRoles();
        if (topLevelRoleId) {
            const topRoleIndex = parentList.indexOf(topLevelRoleId) + 1;
            parentList = parentList.splice(0, topRoleIndex).reverse();
        }
        let path = "";
        parentList.forEach((dep) => {
            path = `${path}${dep}/`;
        });
        return `${path}${this.id}` || "";
    }
    */

    getDirectReports() {
        const directReportIds = getChildEmployeeIds(this.id, false);
        return directReportIds.map((empId) => lookEmployee(empId));
    }

    getAllSubordinateEmployees() {
        const directReportIds = getChildEmployeeIds(this.id, true);
        return directReportIds.map((empId) => lookEmployee(empId));
    }

    hasChildren() {
        return getChildRoleIds(this.id).length > 0;
    }
}

export default RoleModel;
