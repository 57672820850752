import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { TalentAreasTable } from "../../components";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import ResponsiveContainer from "../../components/ResponsiveContainer/ResponsiveContainer";
import TableSearch from "../../components/Search/TableSearch";
import { DEFAULT_ROUTE } from "../../constants";
import { disableTalentArea } from "../../firebase/actions/talentArea";
import { removeTalentAreaFromTrait } from "../../firebase/actions/trait";
import { TalentAreaModel } from "../../models";
import { SELECT_TALENT_AREA, SET_CHECKED_IDS, SET_MODAL, SET_SIDE_AREA } from "../../redux/appSlice";
import { checkAccess } from "../../utils/userUtils";

const includedColumns = ["talentAreaName", "description", "linkedTraitCount"];

const TABLE_ID = "talentAreas";

const TalentAreas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filteredTalentAreaIds, setFilteredTalentAreaIds] = useState(false);
    const talentAreas = useSelector((state) => state.talent.talentAreas);
    const traits = useSelector((state) => state.talent.traits);
    const checkedTalentAreaIds = useSelector((state) => state.app.checkedIds[TABLE_ID]);
    const [unfilteredData, setUnfilteredData] = useState([]);
    const snapshots = useSelector((state) => state.talent.snapshots);

    // Leave page if unauthorized to view it
    useEffect(() => {
        if (!checkAccess("talent")) {
            navigate(DEFAULT_ROUTE);
        }
    });

    // Prep the table data
    useEffect(() => {
        // traits isn't directly used in this effect, but it needs to run when the traits change due to getLinkedTraitIds
        let filtered = Object.values(talentAreas).map((talentArea) => {
            const talentAreaModel = new TalentAreaModel(talentArea);
            const linkedTraitIds = talentAreaModel.getLinkedTraitIds();
            return { ...talentArea, linkedTraitCount: linkedTraitIds.length, linkedTraitIds: linkedTraitIds };
        });
        setUnfilteredData(filtered);
    }, [talentAreas, traits]);

    const handleEditTalentArea = (talentAreaId) => {
        dispatch(SELECT_TALENT_AREA(talentAreaId));
        dispatch(SET_SIDE_AREA("editTalentArea"));
    };

    const handleDeleteCheckedTalentAreas = () => {
        const onConfirm = () => {
            checkedTalentAreaIds.forEach((areaId) => {
                const talentAreaRow = unfilteredData.find((area) => area.id === areaId);
                if (talentAreaRow) {
                    const unlinkTraits = talentAreaRow.linkedTraitIds;
                    unlinkTraits.forEach((traitId) => {
                        removeTalentAreaFromTrait(traitId, areaId);
                    });
                    disableTalentArea(areaId);
                    dispatch(SET_CHECKED_IDS({ [TABLE_ID]: [] }));
                }
            });
        };

        // Confirm before deleting
        if (checkedTalentAreaIds) {
            confirmModal("delete", onConfirm, "talentAreas");
        }
    };

    const handleCreateTalentArea = () => {
        dispatch(SET_MODAL("createTalentArea"));
    };

    const handleCreateTrait = () => {
        dispatch(SET_MODAL("createTrait"));
    };

    const talentAreasToolbar = () => {
        const checkedCount = checkedTalentAreaIds ? checkedTalentAreaIds.length : 0;
        return (
            <div className="toolbar">
                <TableSearch
                    items={unfilteredData}
                    searchKeys={["name", "talentAreaNames"]}
                    displayKey="talentAreaName"
                    placeholder="Filter talent areas"
                    onChange={setFilteredTalentAreaIds}
                    removeBorder
                />
                <div className="justify-end">
                    {checkedCount > 0 && (
                        <Button className="basic me-3" onClick={handleDeleteCheckedTalentAreas}>
                            {`DELETE ${checkedCount} TALENT AREA${checkedCount > 1 ? "S" : ""}`}
                        </Button>
                    )}
                    <Button className="basic me-3" onClick={handleCreateTalentArea}>
                        CREATE TALENT AREA
                    </Button>
                    <Button className="basic" onClick={handleCreateTrait}>
                        CREATE TRAIT
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="inner-container">
            {talentAreasToolbar()}
            <ResponsiveContainer>
                <TalentAreasTable
                    tableId={TABLE_ID}
                    iconSize={42}
                    data={unfilteredData}
                    filteredIds={filteredTalentAreaIds}
                    snapshots={snapshots}
                    columnKeys={includedColumns}
                    onEditTalentArea={handleEditTalentArea}
                    removeBorder
                    checkbox
                />
            </ResponsiveContainer>
        </div>
    );
};

export default TalentAreas;
