import classNames from "classnames";
import React, { useMemo } from "react";
import { MdAssessment } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { CountBadge } from "../components/BadgesAndPills/Badges";
import { CompleteAssessmentButton, SkipAssessmentButton } from "../components/Buttons/MyButtons";
import { AssessmentDoneIcon, AssessmentPendingIcon } from "../components/Icons/MyIcons";
import { COLORS } from "../constants";
import { dateIsBefore } from "../utils/basicUtils";
import { getChildEmployeeIds } from "../utils/roleUtils";

const AssessmentItem = (props) => {
    const { employeeId, complete, hideSkip, onEmployeeClick, disabled, self } = props;
    const thisEmployee = useSelector((state) => state.org.employees[employeeId]);

    if (!thisEmployee) return null;

    const label = self ? "Self-Assessment" : thisEmployee.displayName;

    const handleSkipAssessment = () => {
        console.log("IS HPAPNEING")
        props.onSkipAssessment();
    }

    return (
        <DropdownItem key={employeeId}>
            <div className="d-flex py-1">
                <div className="f-4 d-flex">
                    {complete ? <AssessmentDoneIcon /> : <AssessmentPendingIcon color={COLORS.PURPLE} size={22} />}
                    <span
                        className="ms-2 clickable"
                        onClick={() => {
                            onEmployeeClick(thisEmployee.roleId);
                        }}
                    >
                        {label}
                    </span>
                </div>
                <div className="f-1 d-flex">
                    <SkipAssessmentButton
                        size={22}
                        className={classNames("me-2", { hidden: hideSkip })}
                        square
                        disabled={disabled}
                        onClick={handleSkipAssessment}
                    />
                    <CompleteAssessmentButton
                        square
                        size={22}
                        onClick={props.onCompleteAssessment}
                        disabled={disabled}
                    />
                </div>
            </div>
        </DropdownItem>
    );
};

const AssessmentDropdown = (props) => {
    const { showSelf, showTeam } = props;
    const navigate = useNavigate();
    const userRoleId = useSelector((state) => state.user.claims.roleId);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const thresholdDate = useSelector((state) => state.assessments.settings.thresholdDate);
    const pendingChanges = useSelector((state) => state.talent.pendingChanges);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);

    const directReportGroups = useMemo(() => {
        // Get direct reports
        const directReportIds = getChildEmployeeIds(userRoleId);
        // Group direct reports by whether their assessment has expired or not
        let groups = { required: [], completed: [] };
        directReportIds.forEach((id) => {
            const snapshot = snapshots[id];
            if (!snapshot || dateIsBefore(snapshot?.date, thresholdDate)) {
                groups.required.push(id);
            } else {
                groups.completed.push(id);
            }
        });
        return groups;
    }, [userRoleId, snapshots, thresholdDate]);

    const { completed, required } = directReportGroups;
    const assessmentNotifyCount = required?.length + (showSelf ? 1 : 0);

    const handleEmployeeClick = (roleId) => {
        if (roleId) navigate(`/people/${roleId}`);
    };

    const renderSelf = () => {
        return (
            <>
                <DropdownItem header>Self-Assessment</DropdownItem>
                <AssessmentItem
                    key={userEmployeeId}
                    onCompleteAssessment={() => props.onSelfAssessment()}
                    employeeId={userEmployeeId}
                    self
                    hideSkip
                    onEmployeeClick={handleEmployeeClick}
                />
            </>
        );
    };

    const renderTeam = () => {
        return (
            <>
                <DropdownItem header>YOUR TEAM</DropdownItem>
                <DropdownItem divider />
                {required?.length > 0 && (
                    <>
                        <DropdownItem header>Due for assessment</DropdownItem>
                        {required?.map((employeeId) => {
                            return (
                                <AssessmentItem
                                    key={employeeId}
                                    disabled={pendingChanges.includes(employeeId)}
                                    onCompleteAssessment={() => props.onCompleteAssessment(employeeId)}
                                    onSkipAssessment={() => props.onSkipAssessment(employeeId)}
                                    employeeId={employeeId}
                                    onEmployeeClick={handleEmployeeClick}
                                />
                            );
                        })}
                    </>
                )}
                {completed?.length > 0 && (
                    <>
                        <DropdownItem header>Not due for assessment</DropdownItem>
                        {completed?.map((employeeId) => {
                            return (
                                <AssessmentItem
                                    onCompleteAssessment={() => props.onCompleteAssessment(employeeId)}
                                    onSkipAssessment={() => props.onSkipAssessment(employeeId)}
                                    hideSkip
                                    key={employeeId}
                                    complete
                                    employeeId={employeeId}
                                    onEmployeeClick={handleEmployeeClick}
                                />
                            );
                        })}
                    </>
                )}
            </>
        );
    };
    if (props.hide) return null;
    return (
        <UncontrolledDropdown className="me-4 assessment-dropdown">
            <DropdownToggle nav className="p-0">
                <div>
                    <MdAssessment size={26} />
                    <CountBadge count={assessmentNotifyCount} />
                </div>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu" style={{ margin: 0 }}>
                {showSelf && renderSelf()}
                {showTeam && renderTeam()}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default AssessmentDropdown;
