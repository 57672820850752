import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Tree, TreeNode } from "react-organizational-chart";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import OrgChartTour from "../../../../../components/AppTours/OrgChartTour";
import { SET_EXPANDED_NODES } from "../../../../../redux/orgSlice";
import { selectRole } from "../../../../../redux/utils/dispatches";
import { getChildRoleIds } from "../../../../../utils/roleUtils";
import { checkAccess } from "../../../../../utils/userUtils";
import OrgActions from "../Components/OrgActions";
import OrgToolbar from "../Components/OrgToolbar";
import "../org.scss";
import OrgChartAdminButtons from "./OrgChartAdminButtons";
import OrgChartItem from "./OrgChartItem";

const TOOLTIP_ID = "org-chart";

const OrgChartNode = (props) => {
    const { node, siblings, onToggleNodeExpanded, tooltipId, expandedNodes } = props;
    const toggleNodeExpanded = () => {
        onToggleNodeExpanded(node.roleId, siblings);
    };
    let expanded = expandedNodes[node.roleId] !== undefined && expandedNodes[node.roleId] !== false;
    return (
        <TreeNode
            label={
                <OrgChartItem
                    {...props}
                    node={node}
                    expanded={expanded}
                    toggleNodeExpanded={toggleNodeExpanded}
                    tooltipId={tooltipId}
                />
            }
            key={`org-chart-item-treenode-${node.roleId}`}
        >
            {node.nodes &&
                expandedNodes[node.roleId] &&
                node.nodes.map((childNode) => {
                    return (
                        <OrgChartNode
                            {...props}
                            key={`org-chart-item-node-${childNode.roleId}`}
                            siblings={node.nodes}
                            node={childNode}
                            expandedNodes={expandedNodes}
                            onToggleNodeExpanded={onToggleNodeExpanded}
                        />
                    );
                })}
        </TreeNode>
    );
};

const SelectedRolePanel = () => {
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const employee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const role = useSelector((state) => state.org.roles[selectedRoleId]);
    const displayName = employee && employee.displayName;
    const jobTitle = role && role.jobTitle;
    if (role) {
        return (
            <div className="org-chart-selected-employee">
                <h5 className="mb-2">{displayName || jobTitle}</h5>
                {displayName && <h6 className="fs-tiny">{jobTitle}</h6>}
            </div>
        );
    } else {
        return null;
    }
};

const OrgChart = (props) => {
    const dispatch = useDispatch();
    const { topLevelRoleId, selectedRoleId, orgActions, orgTree, tableView, setTableView } = props;
    const expandedNodes = useSelector((state) => state.org.expandedNodes);
    const autoCloseSiblings = useSelector((state) => state.user.localPreferences.autoCloseSiblings);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    // Expands nodes after new top level role created
    useEffect(() => {
        const levelOne = orgTree.nodes;
        const alreadyExpanded = Object.keys(expandedNodes).length > 0;
        if ((!alreadyExpanded, levelOne && levelOne.length === 1)) {
            if (!expandedNodes[orgTree.roleId]) {
                let newExpanded = { [orgTree.roleId]: true };
                levelOne.forEach((node) => {
                    newExpanded[node.roleId] = true;
                });
                dispatch(SET_EXPANDED_NODES(newExpanded));
            }
        }
    }, [dispatch, orgTree, expandedNodes]);

    const handleToggleNodeExpanded = (nodeId, siblings) => {
        let newExpandedNodes = { ...expandedNodes };
        // Collapse siblings if enabled

        if (autoCloseSiblings && siblings) {
            siblings.forEach((sibling) => {
                newExpandedNodes[sibling.roleId] = false;
            });
        }

        // Collapse all junior nodes when a node is collapsed
        if (expandedNodes[nodeId]) {
            const subRoles = getChildRoleIds(nodeId, true);
            subRoles.forEach((roleId) => {
                newExpandedNodes[roleId] = false;
            });
        }
        newExpandedNodes[nodeId] = !expandedNodes[nodeId];

        // Deselect role if it has been collapsed
        if (selectedRoleId && !newExpandedNodes[selectedRoleId]) {
            selectRole();
        }

        dispatch(SET_EXPANDED_NODES(newExpandedNodes));
    };

    const showAdminButtons = checkAccess("roleAdmin");

    return (
        <div
            className={classNames(
                props.className,
                "bg-darkish d-flex inner-container flex-column org-chart-container f-1"
            )}
        >
            <OrgChartTour />
            <OrgToolbar tableView={tableView} onSetTableView={setTableView} topLevelRoleId={topLevelRoleId} />
            <div className="f-1 d-flex flex-column inner-container scrollable">
                <SelectedRolePanel />
                <OrgActions tooltipId={TOOLTIP_ID} {...orgActions} />
                <div onClick={orgActions.onClearSelected} className="inner-container tour-orgchart">
                    <ScrollContainer className="org-chart-inner scrollable scrollable-container scroll-container pb-5 pt-3 px-5">
                        <Tree label={<div className="tree-start-point" />}>
                            <OrgChartNode
                                node={orgTree}
                                tooltipId={TOOLTIP_ID}
                                expandedNodes={expandedNodes}
                                onSelectNode={orgActions.onSelectNode}
                                onViewRole={orgActions.onViewRole}
                                onToggleNodeExpanded={handleToggleNodeExpanded}
                            />
                        </Tree>
                    </ScrollContainer>
                </div>
                {showAdminButtons && <OrgChartAdminButtons tooltipId={TOOLTIP_ID} {...orgActions} />}
                <ReactTooltip id={TOOLTIP_ID} place="top" delayShow={250} offset={{ left: 10, top: 1 }} />
            </div>
        </div>
    );
};

OrgChart.propTypes = {
    expandedNodes: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
};

OrgChart.defaultProps = {
    expandedNodes: {},
    onItemClick: () => {},
};

export default memo(OrgChart);
