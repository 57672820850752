import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, DropdownItem, Input, Label } from "reactstrap";
import {
    AddTableButton,
    CopyTableButton,
    EditTableButton
} from "../../../../../components/Buttons/MyButtons";
import IdPicker from "../../../../../components/Pickers/IdPickers/IdPicker";
import { READ_ONLY_TABLE_IDS } from "../../../../../constants";
import AppDropDown from "../../../../../containers/AppDropDown";
import { saveLocalPreferences } from "../../../../../firebase/actions/user";
import { SET_SIDE_AREA } from "../../../../../redux/appSlice";
import { SET_LOCAL_PREFERENCES } from "../../../../../redux/userSlice";
import { setLocalPreferences } from "../../../../../redux/utils/dispatches";
import { getUniqueId } from "../../../../../utils/basicUtils";
import { constructMailTo } from "../../../../../utils/employeeUtils";
import { getUpdatedWorkspaceTables } from "../../../../../utils/workspaceUtils";

const OrgTableToolbar = (props) => {
    const dispatch = useDispatch();
    const { onSetFiltered, tableId, tooltipId } = props;
    const [tableOptions, setTableOptions] = useState([]);
    const checkedEmployeeIds = useSelector((state) => state.app.checkedIds[tableId]);
    const orgTableConfig = useSelector((state) => state.org.orgTableConfig);
    const activeOrgTableId = useSelector((state) => state.user.localPreferences.activeOrgTableId);
    const checkedCount = checkedEmployeeIds ? checkedEmployeeIds.length : 0;
    const bulkActionsDisabled = checkedCount === 0;
    const readOnlyTable = READ_ONLY_TABLE_IDS.includes(activeOrgTableId);
    const { tables } = orgTableConfig;

    useEffect(() => {
        const options = Object.entries(tables).map(([id, table]) => {
            return {
                id: id,
                label: table.tableName,
            };
        });
        setTableOptions(options);
    }, [tables]);

    const handleEditTable = () => {
        dispatch(SET_SIDE_AREA("editOrgTable"));
    };

    const handleCreateTable = () => {
        dispatch(SET_SIDE_AREA("createOrgTable"));
    };

    const handleDuplicateTable = () => {
        const activeTable = tables[activeOrgTableId];
        let newTable = { ...activeTable, tableName: `${activeTable.tableName} (copy)` };
        const newId = getUniqueId(5);
        const newTables = getUpdatedWorkspaceTables(newId, newTable);
        const newConfig = { ...orgTableConfig, tables: newTables, activeOrgTableId: newId };
        setLocalPreferences({ orgTableConfig: newConfig }, true);
        setLocalPreferences({ activeOrgTableId: newId });
        saveLocalPreferences();
    };

    const createEmail = (contactManager) => {
        constructMailTo(checkedEmployeeIds, contactManager);
    };

    const handleChangeActiveTable = (id) => {
        const newConfig = { ...orgTableConfig, activeOrgTableId: id };
        setLocalPreferences({ orgTableConfig: newConfig }, true);
        dispatch(SET_LOCAL_PREFERENCES({ activeOrgTableId: id }));
    };

    return (
        <div className="f-1 justify-between">
            <ButtonGroup>
                <ButtonGroup className="border-x px-3 me-4">
                    <div className="d-flex align-items-center">
                        <Label className="fs-tiny me-2">Table: </Label>
                        <IdPicker
                            selectedIds={[activeOrgTableId]}
                            options={tableOptions}
                            className="me-3 min-w-280"
                            onChange={handleChangeActiveTable}
                        />
                        <EditTableButton
                            className="me-3 outline"
                            tooltipId={tooltipId}
                            tooltip="Edit Table"
                            onClick={handleEditTable}
                            disabled={readOnlyTable}
                        />
                        <CopyTableButton
                            className="me-3 outline"
                            tooltipId={tooltipId}
                            tooltip="Copy Table"
                            onClick={handleDuplicateTable}
                        />
                        <AddTableButton
                            className="outline"
                            tooltipId={tooltipId}
                            tooltip="Create Table"
                            onClick={handleCreateTable}
                        />
                    </div>
                </ButtonGroup>
                <ButtonGroup>
                    <div className="filter">
                        <Input
                            className="table-search-field"
                            type="text"
                            bsSize="sm"
                            autoComplete="off"
                            name="filter"
                            placeholder="Filter employees"
                            onChange={onSetFiltered}
                        />
                    </div>
                </ButtonGroup>
            </ButtonGroup>
            <div className="justify-end">
                <AppDropDown title={`BULK ACTIONS (${checkedCount})`} disabled={bulkActionsDisabled}>
                    <DropdownItem header>EMAIL</DropdownItem>
                    <DropdownItem onClick={() => createEmail()}>Email Employeees</DropdownItem>
                    <DropdownItem onClick={() => createEmail(true)}>Email Managers</DropdownItem>
                </AppDropDown>
            </div>
        </div>
    );
};

export default OrgTableToolbar;
