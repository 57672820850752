import { increment } from "firebase/firestore";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { setEmployee } from "../../../firebase/actions/employee";
import { setRole } from "../../../firebase/actions/role";
import { getBatch } from "../../../firebase/firebaseUtils";
import { EXPAND_NODE } from "../../../redux/orgSlice";
import { lookEmployee, lookRole } from "../../../redux/utils/looks";
import { useLanguage } from "../../../translations/LanguageContext";
import { getUniqueId } from "../../../utils/basicUtils";
import FormStepper from "../../FormStepper/FormStepper";
import EmployeeDetailsStep from "./Steps/EmployeeDetailsStep";
import IncumbentStep from "./Steps/IncumbentStep";
import InviteStep from "./Steps/InviteStep";

export const CreateOrFillRoleInnerForm = (props) => {
    const { setFieldValue, setStatus, toggle, values, status, validationSchemas, language } = props;
    const { existingEmployeeId, jobTitle } = values;
    const [fillWith, setFillWith] = useState("new");

    // Disables Next if no existing employee is selected when required
    const invalidOveride = fillWith === "current" && !existingEmployeeId;

    // Allows Submit if role will be Vacant and there the job title is complete
    const createVacant = fillWith === "vacant" && !!jobTitle;

    const handleSubmit = () => {
        props.onSubmit(values, createVacant);
    };

    const handleSetFillWith = (value) => {
        setFillWith(value);
        if (value !== "current") {
            handleClearEmployee();
        }
    };

    const handleClearEmployee = () => {
        setFieldValue("firstname", "");
        setFieldValue("surname", "");
        setFieldValue("employeeNumber", "");
        setFieldValue("existingEmployeeId", false);
        setStatus({ ...status, existingEmployee: false });
    };

    return (
        <FormStepper
            isOpen={status.isOpen}
            toggle={toggle}
            onSubmit={handleSubmit}
            validationSchemas={validationSchemas}
            values={values}
            headerText={status.headerText}
            isWaiting={status.isWaiting}
            invalidOveride={invalidOveride}
            submitNowOverride={createVacant}
            minHeight={560}
        >
            <IncumbentStep
                {...props}
                title={language.incumbent_step}
                language={language}
                setFillWith={handleSetFillWith}
                fillWith={fillWith}
            />
            <EmployeeDetailsStep
                {...props}
                title={language.employee_details_step}
                language={language}
                onClearEmployee={handleClearEmployee}
            />
            <InviteStep {...props} title={language.invite_step} />
        </FormStepper>
    );
};

const CreateOrFillRoleForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isOpen: props.isOpen,
            fillVacantRole: props.fillVacantRole,
            incumbentId: props.selectedRole && props.selectedRole.incumbentId,
            isWaiting: props.isWaiting,
            headerText: props.headerText,
            existingEmployee: false,
            existingEmail: false,
        };
    },
    mapPropsToValues: (props) => {
        return {
            ...props.defaultRoleValues,
            employeeNumberInUse: false,
        };
    },
    enableReinitialize: true,
})(CreateOrFillRoleInnerForm);

const CreateOrFillRole = (props) => {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const { toggle, fillVacantRole } = props;
    const [defaultRoleValues, setDefaultRoleValues] = useState({});
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const [thisRoleId] = useState(fillVacantRole ? selectedRoleId : getUniqueId());
    const thisRole = useSelector((state) => state.org.roles[thisRoleId]);
    const pendingChanges = useSelector((state) => state.org.pendingChanges);
    const parentRoleId = selectedRole.parentRoleId;
    const isWaitingForRole = pendingChanges.includes(thisRoleId);
    const isWaitingForEmployee = pendingChanges.includes(thisRoleId);
    const isWaiting = isWaitingForRole || isWaitingForEmployee;
    const childRoles = selectedRole && selectedRole.childRoles;
    const { t } = useLanguage();
    const language = t("create_or_fill_role", "forms");
    const headerText = props.fillVacantRole ? language.fill_role : language.create_role;

    // Waits for the parent to receive the new child before closing the form
    useEffect(() => {
        // Only run if the selectedRole exists, and the form is waiting
        if (submitted && !isWaiting) {
            // When creating a new role, wait for the new role to exist, and the selected role to have the new Id as a child
            if (!fillVacantRole) {
                if (childRoles && childRoles.includes(thisRoleId) && thisRole) {
                    toggle();
                    dispatch(EXPAND_NODE(selectedRoleId));
                }
            } else {
                // When filling a vacant role, just wait for it to have an incumbent
                toggle();
            }
        }
    }, [dispatch, fillVacantRole, submitted, isWaiting, childRoles, toggle, thisRoleId, thisRole, selectedRoleId]);

    useEffect(() => {
        const roleValues = lookRole(thisRoleId) || {};
        const { talentBoardIds, jobTitle } = roleValues;
        if (fillVacantRole) {
            setDefaultRoleValues({ jobTitle: jobTitle, talentBoardIds: talentBoardIds });
        } else {
            setDefaultRoleValues({ talentBoardIds: talentBoardIds });
        }
    }, [fillVacantRole, thisRoleId]);

    const validationSchemas = [
        {
            jobTitle: Yup.string()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.JOB_TITLE, t("length_max", "warnings")),
        },
        {
            firstname: Yup.string()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.FIRSTNAME, t("length_max", "warnings")),
            surname: Yup.string()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.SURNAME, t("length_max", "warnings")),
            employeeNumber: Yup.string().max(FIELD_SIZE_LIMITS.EMPLOYEE_NO, t("length_max", "warnings")),
            email: Yup.string()
                .email()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.EMAIL, t("length_max", "warnings")),
            emailInUse: Yup.boolean().oneOf([false], t("email_in_use", "warnings")),
            employeeNumberInUse: Yup.boolean().oneOf([false], t("employee_number_in_use", "warnings")),
        },
        {},
    ];

    const handleSubmit = (formValues, createVacant) => {
        setSubmitted(true);
        let batch = getBatch();
        const {
            canCreateAdmins,
            existingEmployeeId,
            jobTitle,
            employeeNumberInUse,
            talentBoardIds,
            emailInUse,
            roleId,
            ...employeeFormValues
        } = formValues;

        let employeeId = existingEmployeeId || getUniqueId();

        // Create/update an employee when the new role isn't being created vacant
        if (!createVacant) {
            const prevEmployeeValues = lookEmployee(existingEmployeeId) || {};
            let employeeValues = { ...prevEmployeeValues, ...employeeFormValues };

            // Trigger invite creation if the employee is being invited to the workspace
            const sendInvite = !prevEmployeeValues.isUser && employeeFormValues.isUser;
            if (sendInvite) {
                employeeValues.inviteCounter = increment(1);
            }

            batch = setEmployee(employeeId, { id: employeeId, ...employeeValues }, batch);
        }

        // Create or update the role
        const prevRoleValues = lookRole(thisRoleId) || {};
        let roleValues = {
            ...prevRoleValues,
            jobTitle: jobTitle,
            parentRoleId: props.fillVacantRole ? parentRoleId : selectedRoleId,
        };
        if (talentBoardIds) roleValues.talentBoardIds = talentBoardIds;
        if (!createVacant) {
            roleValues.incumbentId = employeeId;
        }

        batch = setRole(thisRoleId, roleValues, batch);

        batch
            .commit()
            .then(() => {
                if (!props.fillVacantRole) {
                    dispatch(EXPAND_NODE(selectedRoleId));
                }
            })
            .catch((error) => {
                toast.error(error.message);
                toggle();
            });
    };

    return (
        <CreateOrFillRoleForm
            {...props}
            language={language}
            validationSchemas={validationSchemas}
            isWaiting={submitted || isWaiting}
            onSubmit={handleSubmit}
            headerText={headerText}
            defaultRoleValues={defaultRoleValues}
        />
    );
};

export default CreateOrFillRole;
