import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { confirmModal } from "../../../../components/Dialogs/ConfirmAlert";
import { skipAssessment } from "../../../../firebase/actions/assessments";
import { deleteEmployee, removeEmployeeFromRole, resendInvite } from "../../../../firebase/actions/employee";
import { deleteRole } from "../../../../firebase/actions/role";
import { SET_MODAL, SET_SIDE_AREA } from "../../../../redux/appSlice";
import { SET_LOCAL_PREFERENCES } from "../../../../redux/userSlice";
import { selectRole } from "../../../../redux/utils/dispatches";
import { lookRole } from "../../../../redux/utils/looks";
import { getChildRoleIds } from "../../../../utils/roleUtils";
import OrgChart from "./OrgChart/OrgChart";
import OrgTable from "./OrgTable/OrgTable";

function createTreeNode(roleId, roles, path = "") {
    const role = roles[roleId];
    let node = {};
    if (role) {
        node = {
            key: roleId,
            roleId: roleId,
            rootRole: role.rootRole,
            incumbentId: role.incumbentId,
            jobTitle: role.jobTitle,
            path: `${path}${roleId}`,
            parentRoleId: role.parentRoleId,
        };
        if (role.childRoles) {
            const sortedChildRoles = [...role.childRoles].sort();
            node.nodes = sortedChildRoles.map((childRoleId) => {
                return createTreeNode(childRoleId, roles, `${path}${roleId}/`);
            });
        }
    }
    return node;
}

const EmptyOrg = (props) => {
    const dispatch = useDispatch();
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    return (
        <div className={classNames(props.activeRoleId && "fully-hidden", "f-1 d-flex py-5 justify-content-center")}>
            <div className="p-3 border-all text-center rounded bg-white w-50">
                <div className="round bg-light w-25 m-auto d-flex align-items-center justify-content-center mb-3">
                    <FaUserPlus size={64} />
                </div>
                <h3 className="fw-lighter text-center">Create your first role</h3>
                <p>Your workspace is current empty. Add your first employee to get going.</p>
                <p>We've created your personal employee record. You can add this anywhere in your organisation.</p>
                <div className="d-flex justify-content-center">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            selectRole(topLevelRoleId);
                            dispatch(SET_MODAL("createRole"));
                        }}
                        className="w-25"
                    >
                        Add Role
                    </Button>
                </div>
            </div>
        </div>
    );
};

const Org = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeRoleId = useOutletContext();
    const [orgTree, setOrgTree] = useState(false);
    const [visibleRoles, setVisibleRoles] = useState();
    const [toastMessage, setToastMessage] = useState(false);
    const pendingOrgChanges = useSelector((state) => state.org.pendingChanges);
    const roles = useSelector((state) => state.org.roles);
    const filteredTopLevelRoleId = useSelector((state) => state.user.localPreferences.filteredTopLevelRoleId);
    const defaultTopLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const tableView = useSelector((state) => state.user.localPreferences.tableView);
    const isSelf = selectedEmployeeId === userEmployeeId;
    const topLevelRoleId = filteredTopLevelRoleId || defaultTopLevelRoleId;
    const orgIsEmpty = !orgTree || (orgTree.rootRole && !orgTree.nodes);
    const activeSideArea = useSelector((state) => state.app.activeSideArea);

    // Notify user when changes have completed
    useEffect(() => {
        if (toastMessage && pendingOrgChanges.length === 0) {
            toast.success(toastMessage);
            setToastMessage(false);
        }
    }, [toastMessage, pendingOrgChanges]);

    // Setup the data visible in the OrgChart and OrgTable
    useEffect(() => {
        // OrgChart
        const newOrgTree = roles && createTreeNode(topLevelRoleId, roles);
        setOrgTree(newOrgTree);
        // OrgTable
        const topLevelRole = lookRole(topLevelRoleId);
        if (topLevelRole && !topLevelRole.rootRole) {
            const subordinateRoleIds = getChildRoleIds(topLevelRoleId, true);
            subordinateRoleIds.push(topLevelRoleId);
            setVisibleRoles(subordinateRoleIds);
        }
    }, [topLevelRoleId, roles]);

    const setTableView = (view) => {
        dispatch(SET_LOCAL_PREFERENCES({ tableView: view }));
    };

    const handleSelectNode = (node) => {
        const roleId = node && node.roleId;
        if (roleId !== selectedRoleId) selectRole(roleId);
    };

    const handleRemoveorFillRole = () => {
        const onConfirm = () => {
            removeEmployeeFromRole(selectedEmployeeId)
                .commit()
                .then(() => {
                    setToastMessage("Employee Removed From Role.");
                });
        };

        // Confirm before removing
        if (selectedEmployeeId) {
            confirmModal("remove_employee_from_role", onConfirm);
        } else {
            dispatch(SET_MODAL("fillVacantRole"));
        }
    };

    const handleChangeUserRole = () => {
        if (selectedEmployeeId) dispatch(SET_MODAL("changeUserRole"));
    };

    const handleViewRole = (node, incumbentId) => {
        const roleId = node && node.roleId;
        const hasChildren = node.nodes && node.nodes.length > 0;
        if (roleId !== selectedRoleId || incumbentId !== selectedEmployeeId) {
            handleSelectNode(roleId, incumbentId);
        }
        if (roleId) {
            if ((isSelf || !incumbentId) && hasChildren) {
                navigate(`/people/${roleId}/department`);
            } else {
                navigate(`/people/${roleId}/employee`);
            }
        }
    };

    const handleEditRole = () => {
        if (selectedRoleId) dispatch(SET_SIDE_AREA("roleAdminArea"));
    };

    const handleRequestAssessments = () => {
        if (selectedRoleId) dispatch(SET_SIDE_AREA("requestAssessments"));
    };

    const handleEditAssessment = () => {
        if (!assessmentsOpen) {
            if (selectedEmployeeId) toast.error("Assessments are locked.");
        } else {
            //if (selectedEmployeeId) dispatch(SET_MODAL("rateEmployee"));
            if (selectedEmployeeId) navigate(`/people/assessments/${selectedEmployeeId}`);
        }
    };

    const handleViewTalentMap = () => {
        if (selectedRoleId) navigate(`/people/${selectedRoleId}/talent-map`);
    };

    const handleViewHistory = () => {
        if (selectedEmployeeId) dispatch(SET_SIDE_AREA("assessmentHistory"));
    };

    const handleViewTimeline = () => {
        if (selectedEmployeeId) dispatch(SET_SIDE_AREA("employeeTimeline"));
    };

    const handleViewActions = () => {
        if (selectedEmployeeId) dispatch(SET_SIDE_AREA("employeeActions"));
    };

    const handleClearSelected = () => {
        if (!activeSideArea) {
            selectRole();
        }
    };

    const handleDeleteRole = () => {
        const onConfirm = async () => {
            await deleteRole(selectedRoleId);
            setToastMessage("Role Deleted");
        };

        if (selectedRoleId) {
            confirmModal("delete", onConfirm, "role");
        }
    };

    const handleMoveRole = () => {
        if (selectedRoleId) dispatch(SET_MODAL("moveRole"));
    };

    const handleAddChildRole = () => {
        if (selectedRoleId) dispatch(SET_MODAL("createRole"));
    };

    const handleDeleteEmployee = () => {
        const onConfirm = async () => {
            const deleteEmployeeBatch = await deleteEmployee(selectedEmployeeId);
            deleteEmployeeBatch.commit();
            setToastMessage("Employee Record Deleted.");
        };

        // Confirm before deleting
        if (selectedEmployeeId) {
            confirmModal("delete", onConfirm, "employee");
        }
    };

    const handleSkipAssessment = () => {
        const onConfirm = () => skipAssessment(selectedEmployeeId);

        // Confirm before skipping
        if (selectedEmployeeId) {
            confirmModal("skip_assessment", onConfirm);
        }
    };

    const handleResendInvite = () => {
        const onConfirm = () => resendInvite(selectedEmployeeId)
        // Confirm before resending
        if (selectedEmployeeId) {
            confirmModal("resend_invite", onConfirm);
        }
    };

    const orgActions = {
        onEditRole: handleEditRole,
        onEditAssessment: handleEditAssessment,
        onViewHistory: handleViewHistory,
        onViewTimeline: handleViewTimeline,
        onViewTalentMap: handleViewTalentMap,
        onViewActions: handleViewActions,
        onRequestAssessments: handleRequestAssessments,
        onSkipAssessment: handleSkipAssessment,
        onRemoveOrFillRole: handleRemoveorFillRole,
        changeUserRole: handleChangeUserRole,
        resendInvite: handleResendInvite,
        onViewRole: handleViewRole,
        onClearSelected: handleClearSelected,
        onSelectNode: handleSelectNode,
        onAddChildRole: handleAddChildRole,
        onDeleteRole: handleDeleteRole,
        onMoveRole: handleMoveRole,
        onDeleteEmployee: handleDeleteEmployee,
    };

    // If there are no employees, render the welcome page
    if (orgIsEmpty) {
        return <EmptyOrg activeRoleId={activeRoleId} topLevelRoleId={topLevelRoleId} />;
    }

    if (!tableView) {
        return (
            <OrgChart
                topLevelRoleId={topLevelRoleId}
                orgTree={orgTree}
                orgActions={orgActions}
                tableView={tableView}
                setTableView={setTableView}
            />
        );
    } else {
        return (
            <OrgTable
                topLevelRoleId={topLevelRoleId}
                filteredRoleIds={visibleRoles}
                orgActions={orgActions}
                tableView={tableView}
                setTableView={setTableView}
            />
        );
    }
};

export default Org;
