import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./reporting.scss";

const PrintComponent = React.forwardRef((props, ref) => {
    return <div ref={ref}>{props.children}</div>;
});

const ReportPrintModal = ({ isOpen, title, toggle, children }) => {
    const ref = useRef();
    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    return (
        <Modal className="report-modal" isOpen={isOpen} size="lg">
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <PrintComponent ref={ref}>{children}</PrintComponent>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handlePrint}>Print / Save</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ReportPrintModal;
