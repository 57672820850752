import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";

const ValueWidget = ({ value, primary, info, subInfo, size, bgColor, color }) => {
    const sizeRatio = 3.2;
    const minSize = 14;
    const infoSize = Math.max(minSize, size / sizeRatio);

    // Create a custom style for the widget card
    const widgetCardStyle = {
        value: {
            fontSize: size,
        },
        info: {
            paddingTop: primary ? "1rem" : 0,
            marginBottom: !primary ? "1rem" : 0,
            backgroundColor: bgColor,
            color: color,
            fontSize: infoSize,
        },
    };

    return (
        <div className="d-flex border-bottom border-right h-100" style={widgetCardStyle.card}>
            <div className="d-flex flex-column f-1">
                <div className="f-1 px-3 pt-3 pb-3">
                    <h2 style={widgetCardStyle.value}>{value}</h2>
                </div>
                <div
                    style={widgetCardStyle.info}
                    className="f-1 fw-bold px-3 d-flex flex-column align-items-start justiyf-content-start"
                >
                    <p className="mb-0 text-uppercase">{info}</p>
                    <p className="mb-0 fs-small text-capitalize">{subInfo}</p>
                </div>
            </div>
        </div>
    );
};

// Set propTypes for the WidgetCard component
ValueWidget.propTypes = {
    value: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
};

export default ValueWidget;