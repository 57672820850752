import { PropTypes } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import IdPicker from "./IdPicker";

const ActionPicker = (props) => {
    const { selectedIds, excludeIds, isMulti, isClearable } = props;
    const actions = useSelector((state) => state.talent.actions);

    const getLabel = (action) => {
        return action.actionName;
    };

    return (
        <IdPicker
            {...props}
            name="actions"
            getLabel={getLabel}
            options={actions}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

ActionPicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

ActionPicker.defaultProps = {
    selectedIds: [],
    excludeIds: [],
    isMulti: true,
    isClearable: true,
    onBlur: () => {},
    onChange: () => {},
};

export default ActionPicker;
