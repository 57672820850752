import { withFormik } from "formik";
import React from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { useLanguage } from "../../../translations/LanguageContext";

const JoinWorkspaceInnerForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    status,
    isValid,
}) => {
    const { t } = useLanguage();
    const language = t("all_workspace", "forms");
    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Col className="mb-3">
                    <Input
                        type="text"
                        name="firstname"
                        maxLength="22"
                        invalid={errors.firstname && touched.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("firstname")}
                        value={values.firstname}
                    />
                    <FormFeedback>{errors.firstname}</FormFeedback>
                </Col>
                <Col>
                    <Input
                        type="text"
                        name="surname"
                        maxLength="22"
                        invalid={errors.surname && touched.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("surname")}
                        value={values.surname}
                    />
                    <FormFeedback>{errors.surname}</FormFeedback>
                </Col>
            </FormGroup>
            <Button color="primary" type="submit" disabled={!isValid}>
                {t("join")}
            </Button>
            {status.userRole === "owner" && <Label className="text-center">{language.owner}</Label>}
        </Form>
    );
};

const JoinWorkspaceForm = withFormik({
    mapPropsToStatus: ({ invite }) => {
        return {
            userRole: invite.userRole,
        };
    },
    mapPropsToValues: ({ invite }) => {
        return {
            firstname: invite.firstname,
            surname: invite.surname,
        };
    },
    validationSchema: (props) => {
        // lets you validate what's been added to the form
        return Yup.object().shape(props.validationSchema);
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(JoinWorkspaceInnerForm);

const JoinWorkspace = (props) => {
    const { t } = useLanguage();
    const validationSchema = {
        firstname: Yup.string()
            .required(t("required", "warnings"))
            .max(FIELD_SIZE_LIMITS.FIRSTNAME, t("length_max", "warnings")),
        surname: Yup.string()
            .required(t("required", "warnings"))
            .max(FIELD_SIZE_LIMITS.SURNAME, t("length_max", "warnings")),
    };
    return <JoinWorkspaceForm {...props} validationSchema={validationSchema} />;
};

export default JoinWorkspace;
