import { withFormik } from "formik";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FormStepper } from "../..";
import { FIELD_SIZE_LIMITS, USER_ROLES } from "../../../constants";
import { changeWorkspaceClaims } from "../../../firebase/actions/employee";
import { revokeAccess } from "../../../firebase/actions/user";
import { lookEmployee } from "../../../redux/utils/looks";
import { useLanguage } from "../../../translations/LanguageContext";
import { filterObjectKeys } from "../../../utils/basicUtils";
import InviteStep from "./Steps/InviteStep";

const ChangeUserRoleInnerForm = (props) => {
    const { toggle, status, values, language, handleSubmit } = props;
    return (
        <FormStepper
            isOpen={status.isOpen}
            toggle={toggle}
            onSubmit={handleSubmit}
            validationSchemas={props.validationSchemas}
            values={values}
            size="md"
            headerText={language.form_header}
            minHeight={450}
            isWaiting={status.isWaiting}
        >
            <InviteStep
                {...props}
                title={language.step1}
                language={language}
                changeMode={status.alreadyAUser}
                showEmailField
            />
        </FormStepper>
    );
};

const ChangeUserRoleForm = withFormik({
    mapPropsToTouched: (props) => {
        return {
            userRole: true,
        };
    },
    mapPropsToStatus: (props) => {
        const employee = lookEmployee(props.employeeId);
        return {
            isWaiting: props.isWaiting,
            alreadyAUser: employee.isUser,
            isOpen: props.isOpen,
            createVacant: false,
            userRoleTooltip: USER_ROLES.manager.tooltip,
        };
    },
    mapPropsToValues: (props) => {
        const selectedEmployee = lookEmployee(props.employeeId);
        return {
            ...selectedEmployee,
            existingEmployeeId: props.employeeId,
            emailInUse: false,
        };
    },
    mapPropsToErrors: () => {
        return {};
    },
    enableReinitialize: true,
    validationSchema: (props) => {
        let mergedSchema = {};
        props.validationSchemas.forEach((schema) => {
            mergedSchema = { ...mergedSchema, ...schema };
        });
        return Yup.object().shape(mergedSchema);
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(ChangeUserRoleInnerForm);

const ChangeUserRole = (props) => {
    const [isWaiting, setIsWaiting] = useState(false);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const { t } = useLanguage();
    const language = t("change_user_role", "forms");

    const validationSchemas = [
        {
            email: Yup.string()
                .email()
                .required(language.emailWarning)
                .max(FIELD_SIZE_LIMITS.EMAIL, language.emailLengthWarning),
            userRole: Yup.string().required(language.roleWarning),
            emailInUse: Yup.boolean().oneOf([false], t("email_in_use", "warnings")),
        },
    ];

    const handleSubmit = (values) => {
        const claimKeys = ["userRole", "canCreateAdmins", "email"];
        const employee = lookEmployee(selectedEmployeeId);
        const currentClaims = filterObjectKeys(employee, claimKeys);
        let newClaims = filterObjectKeys(values, claimKeys);
        const claimsChanging = !isEqual(currentClaims, newClaims);
        const newUserRole = values.userRole && values.userRole !== "none";

        setIsWaiting(true);

        if (!claimsChanging) {
            // WHEN THERE ARE NO CHANGES
            props.toggle();
        } else if (!newUserRole) {
            // WHEN REVOKING ACCESS
            revokeAccess(selectedEmployeeId)
                .commit()
                .then(() => {
                    toast.success(t("user_access_revoked","toasts"));
                    props.toggle();
                });
        } else if (claimsChanging && newUserRole) {
            // Removes canCreateAdmins if user is not an admin or owner
            const isAdminLevelUser = values.userRole === "admin" || values.userRole === "owner";
            newClaims.canCreateAdmins = newClaims.canCreateAdmins && isAdminLevelUser;
            changeWorkspaceClaims(selectedEmployeeId, newClaims)
                .commit()
                .then(() => {
                    toast.success(t("user_access_changed","toasts"));
                    props.toggle();
                });
        }
    };

    return (
        <ChangeUserRoleForm
            validationSchemas={validationSchemas}
            onSubmit={handleSubmit}
            employeeId={selectedEmployeeId}
            language={language}
            {...props}
            isWaiting={isWaiting}
        />
    );
};

export default ChangeUserRole;
