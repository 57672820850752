import React from "react";

const EmployeeSettings = () => {
    return (
        <div>
            <h4 className="fs-4 fw-light">Employee Settings</h4>
            <hr />
            <h2 className="mb-4">Coming Soon</h2>
            <p>We're working on allowing the creation of custom fields for your employees.</p>
        </div>
    );
};

export default EmployeeSettings;
