import { lookCustomField, lookEmployee } from "../redux/utils/looks";
import { utils, writeFile } from "xlsx";

export function createXLSXFile(tableData, columns, filename = "export.xlsx") {
    const headerRow = columns.map((col) => col.titleText);
    const dataRows = tableData.map((entry) => {
        return columns.map((column) => {
            const { key, parentKey } = column;
            const lookIn = parentKey ? entry[parentKey] : entry;
            const value = lookIn && lookIn[key];
            return getValueLabel(parentKey, key, value);
        });
    });
    const exportData = [headerRow, ...dataRows];
    const worksheet = utils.aoa_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, filename);
}

function getValueLabel(parentKey, key, value) {
    switch (parentKey) {
        case "assessmentFields":
            const assessmentField = lookCustomField(key);
            const fieldType = assessmentField && assessmentField.type;
            if (fieldType === "employeePicker" && value) {
                let names = value
                    .filter((empId) => {
                        const emp = lookEmployee(empId);
                        return !!emp;
                    })
                    .map((empId) => {
                        const emp = lookEmployee(empId);
                        return emp.displayName;
                    });
                return names.join("; ");
            } else if (fieldType === "picker") {
                const options = assessmentField && assessmentField.options;
                const option = options && options.find((opt) => opt.value === value);
                return option && option.label;
            } else if (fieldType === "switch") {
                return value ? "Yes" : "No";
            } else {
                return value;
            }
        default:
            return value;
    }
}

export function createOrgTableExport(columns = [], tableData = []) {
    const headerRow = columns.map((col) => col.titleText);
    const dataRows = tableData.map((entry) => {
        return columns.map((column) => {
            const { key, parentKey } = column;
            const lookIn = parentKey ? entry[parentKey] : entry;
            const value = lookIn && lookIn[key];
            let label = getValueLabel(parentKey, key, value);
            if (typeof label === "string") {
                return label.replace(",", ";");
            } else {
                return label;
            }
        });
    });
    const exportData = [headerRow, ...dataRows];
    return exportData.map((e) => e.join(",")).join("\n");
}
