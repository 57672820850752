import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { EmployeeCards, TalentStrength } from "../../../../../components";
import { RoleModel } from "../../../../../models";
import { getDepartmentSnapshot, getDivisionSnapshot, makeRatingsArray } from "../../../../../utils/snapshotUtils";

const TABS = [
    { key: "team", label: "Team Strengths" },
    { key: "division", label: "Division Strengths" },
];

const DepartmentTab = () => {
    const [departmentTalentAreas, setDepartmentTalentAreas] = useState([]);
    const [departmentRatings, setDepartmentRatings] = useState([]);
    const [divisionTalentAreas, setDivisionTalentAreas] = useState([]);
    const [divisionRatings, setDivisionRatings] = useState([]);
    const [activeTab, setActiveTab] = useState("team");
    const [disableDivisionTab, setDisableDivisionTab] = useState(false);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedRole = useSelector((state) => state.org.roles[selectedRoleId]);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const roles = useSelector((state) => state.org.roles);

    useEffect(() => {
        const departmentSnapshot = getDepartmentSnapshot(selectedRoleId, snapshots, roles);
        const divisionSnapshot = getDivisionSnapshot(selectedRoleId, snapshots, roles);
        setDepartmentTalentAreas(makeRatingsArray(departmentSnapshot.talentAreas));
        setDepartmentRatings(makeRatingsArray(departmentSnapshot.ratings));
        setDivisionTalentAreas(makeRatingsArray(divisionSnapshot.talentAreas));
        setDivisionRatings(makeRatingsArray(divisionSnapshot.ratings));
        setDisableDivisionTab(isEqual(departmentSnapshot, divisionSnapshot));
    }, [selectedRoleId, snapshots, roles]);

    const directReports = useMemo(() => {
        const selectedRoleModel = new RoleModel(selectedRole, "DepartmentTab-2");
        return selectedRoleModel.getDirectReports();
    }, [selectedRole]);

    return (
        <div className="f-1 pb-3 d-flex align-items-stretch dashboard-headers p-4">
            <div className="f-5 d-flex flex-column me-3">
                <div className="mb-4">
                    <Nav tabs className="f-2 d-flex border-none">
                        {TABS.map((tab) => {
                            const { key, label } = tab;
                            const disabled = disableDivisionTab && key === "division";
                            return (
                                <NavItem key={`tab-${key}`} className="border-none">
                                    <NavLink
                                        className={classNames(
                                            {
                                                active: activeTab === key,
                                                disabled: disabled,
                                            },
                                            "border-none",
                                            "clickable"
                                        )}
                                        onClick={() => {
                                            setActiveTab(key);
                                        }}
                                        disabled={disabled}
                                    >
                                        {label}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                    <div className="f-1 d-flex flex-column me-3">
                        {activeTab === "team" && (
                            <TalentStrength
                                wordCloudId="departmentTabTeam"
                                className="overflow-hidden f-1 d-flex bg-white flex-column align-items-stretch"
                                talentAreaRatings={departmentTalentAreas}
                                ratings={departmentRatings}
                            />
                        )}
                        {activeTab === "division" && (
                            <TalentStrength
                                wordCloudId="departmentTabBranch"
                                className="overflow-hidden f-1 d-flex bg-white flex-column align-items-stretch"
                                talentAreaRatings={divisionTalentAreas}
                                ratings={divisionRatings}
                                globalOnly
                            />
                        )}
                    </div>
                </div>
                <h6>Team Performance</h6>
                <div className="d-flex f-1">
                    <EmployeeCards
                        employees={directReports}
                        className="f-1"
                        chartHeight={180}
                        xl={2}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                    />
                </div>
            </div>
        </div>
    );
};

DepartmentTab.propTypes = {
    activeRole: PropTypes.object,
    activeDivision: PropTypes.object,
};

export default DepartmentTab;
