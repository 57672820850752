import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { lookAction, lookEmployee, lookTalentBoard, lookTrait } from "../redux/utils/looks";
import { getRoleIncumbent } from "../utils/roleUtils";

const Breadcrumbs = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { roleId, employeeId, actionId, boardId, traitId } = params;

    let itemLabel;
    const renderPageBreadcrumb = () => {
        switch (props.activePage) {
            case "people":
                const selfAssessment = location.pathname.includes("self-assessment");
                if (employeeId) {
                    const employee = lookEmployee(employeeId);
                    itemLabel = employee && employee.displayName;
                } else if (roleId) {
                    const incumbent = getRoleIncumbent(roleId);
                    itemLabel = incumbent ? incumbent.displayName : "Vacant Role";
                } else if (selfAssessment) {
                    itemLabel = "Self Assessment";
                }
                break;
            case "actions":
                const action = lookAction(actionId);
                itemLabel = action && action.actionName;
                break;
            case "traits":
                const trait = lookTrait(traitId);
                itemLabel = trait && trait.traitName;
                break;
            case "talentboards":
                const board = lookTalentBoard(boardId);
                itemLabel = board && board.talentBoardName;
                break;
            default:
                itemLabel = false;
        }
        if (itemLabel) {
            if (itemLabel.length > 24) {
                itemLabel = `${itemLabel.substring(0, 24)}...`;
            }
            return (
                <BreadcrumbItem>
                    <span>{itemLabel}</span>
                </BreadcrumbItem>
            );
        }
    };

    const handleNavigate = (navigateTo) => {
        if (location.pathname !== navigateTo) {
            navigate(navigateTo);
        }
    };

    const activePage = props.activePage.replace(/-/g, " ");

    return (
        <Breadcrumb listTag="div">
            <BreadcrumbItem onClick={() => handleNavigate(`/${props.activePage}`)}>{activePage}</BreadcrumbItem>
            {renderPageBreadcrumb()}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
