import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fade } from "reactstrap";
import { requestSignInLink, userSignOut } from "../../firebase/auth";
import { useLocalStorage } from "../../hooks/hooks";
import { lookUser } from "../../redux/utils/looks";
import EmailForm from "./EmailForm";
import SignInPanel from "./SignInPanel";

const RequestSignIn = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useLocalStorage("email", "");
    const authId = useSelector((state) => state.user.authId);
    const [requestSent, setRequestSent] = useState(false);
    const activePage = window.location.href;

    const handleRequestLink = (email) => {
        setEmail(email);
        requestSignInLink(email, activePage);
        setRequestSent(true);
    };

    const handleSignOut = () => {
        userSignOut();
    };

    useEffect(() => {
        const user = lookUser();
        if (user.loggedIn) {
            navigate("/people");
        }
    }, [navigate]);

    if (authId && requestSent) {
        return (
            <Fade className="f-1 d-flex justify-content-center flex-column">
                <SignInPanel
                    title="You're signed in"
                    description="You can close this window now"
                    signInStatus="signed-in"
                    onBailOut={handleSignOut}
                ></SignInPanel>
            </Fade>
        );
    }
    if (!requestSent) {
        return (
            <Fade className="f-1 d-flex justify-content-center flex-column">
                <SignInPanel
                    title="Sign-in to Talent Mapper"
                    description="Enter your email to receive a link to access Talent Mapper."
                    signInStatus="not-requested"
                    hideBailOut
                >
                    <EmailForm {...props} formType="requestLink" onSubmit={handleRequestLink} />
                </SignInPanel>
            </Fade>
        );
    } else {
        return (
            <Fade className="f-1 d-flex justify-content-center flex-column">
                <SignInPanel
                    title="Sign-in Link Sent"
                    description={`We've sent an email to ${email}.`}
                    more="This email includes a Magic Link which will log you in to Talent Mapper securely, with no need for a password."
                    signInStatus="requested"
                    onBailOut={() => setRequestSent(false)}
                    bailOutText="Request link again"
                />
            </Fade>
        );
    }
};

export default RequestSignIn;
