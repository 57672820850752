import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingIndicator } from "../../../../../components";
import TalentMap from "../../../../../components/TalentMap/TalentMap";
import { lookEmployee } from "../../../../../redux/utils/looks";
import { getEmployeeRole } from "../../../../../utils/employeeUtils";
import { getChildEmployeeIds } from "../../../../../utils/roleUtils";
import { RoleViewContext } from "../RoleView";

function getAssessmentFilterMatches(filter, filteredSnapshotArray) {
    let updatedArray = [...filteredSnapshotArray];
    switch (filter.type) {
        case "talentBoard":
            updatedArray = filteredSnapshotArray.filter((entry) => {
                const employee = lookEmployee(entry.empId);
                const talentBoardIds = employee && employee.talentBoardIds;
                return talentBoardIds && talentBoardIds.includes(filter.fieldValue);
            });
            break;
        case "switch":
            updatedArray = filteredSnapshotArray.filter((entry) => {
                if (!filter.fieldValue) {
                    return !Object.keys(entry.snap).includes(filter.fieldId);
                } else {
                    return entry.snap[filter.fieldId] === filter.fieldValue;
                }
            });
            break;
        default:
            updatedArray = filteredSnapshotArray.filter((entry) => {
                return entry.snap[filter.fieldId] === filter.fieldValue;
            });
    }
    return updatedArray;
}

function getEmployeeFilterMatches(filter, filteredSnapshotArray) {
    let updatedArray = [...filteredSnapshotArray];
    updatedArray = filteredSnapshotArray.filter((entry) => {
        const employee = lookEmployee(entry.empId);
        if (!filter.fieldValue) {
            return !Object.keys(employee).includes(filter.fieldId);
        } else {
            const employeeFieldValue = employee[filter.fieldId];
            if (Array.isArray(employeeFieldValue)) {
                return employeeFieldValue.includes(filter.fieldValue);
            } else {
                return employeeFieldValue === filter.fieldValue;
            }
        }
    });
    return updatedArray;
}

const TalentMapTab = () => {
    const navigate = useNavigate();
    const params = useParams();
    const talentMapRef = useContext(RoleViewContext);
    const talentMapConfig = useSelector((state) => state.talent.talentMapConfig);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const segmentId = params.segmentId;

    // Filter the snapshots
    const filteredSnapshots = useMemo(() => {
        // Include only subordinates of the selectedRoleId
        const allSubordinates = getChildEmployeeIds(selectedRoleId, true);
        let filteredSnapshotArray = Object.entries(snapshots)
            .filter(([empId, snap]) => allSubordinates.includes(empId))
            .map(([empId, snap]) => {
                return { empId: empId, snap: snap };
            });

        // Apply custom filters
        const filters = talentMapConfig.filters || [];
        filters.forEach((filter) => {
            if (filter.category === "assessment") {
                filteredSnapshotArray = getAssessmentFilterMatches(filter, filteredSnapshotArray);
            }
            if (filter.category === "employee") {
                filteredSnapshotArray = getEmployeeFilterMatches(filter, filteredSnapshotArray);
            }
        });

        let showSnapshots = {};
        filteredSnapshotArray.forEach((entry) => {
            showSnapshots[entry.empId] = entry.snap;
        });
        return showSnapshots;
    }, [snapshots, selectedRoleId, talentMapConfig]);

    const handleItemHover = (tileId) => {};

    const handleItemClick = (employeeId) => {
        const role = getEmployeeRole(employeeId);
        if (role) {
            navigate(`/people/${role.id}/employee`);
        }
    };

    if (!talentMapConfig) return <LoadingIndicator />;

    return (
        <div className="talent-map-view f-1 d-flex flex-column py-4 pe-4">
            <TalentMap
                ref={talentMapRef}
                activeSegmentId={segmentId}
                config={talentMapConfig}
                snapshots={filteredSnapshots}
                onItemHover={handleItemHover}
                onItemClick={handleItemClick}
            />
        </div>
    );
};

export default TalentMapTab;
