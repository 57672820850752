import React from "react";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { EmployeePicker, RadioButtons, TalentBoardPicker } from "../../..";
import { FIELD_SIZE_LIMITS } from "../../../../constants";
import { lookEmployee } from "../../../../redux/utils/looks";
import { useLanguage } from "../../../../translations/LanguageContext";
import { FormStep } from "../../../FormStepper/FormStepper";

const IncumbentStep = (props) => {
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        setStatus,
        status,
        excludeVacant,
        fillWith,
        setFillWith,
        onClearEmployee,
        language,
    } = props;
    const { t } = useLanguage();
    const incumbentOptions = language.incumbent_options || [];
    const preppedOptions = incumbentOptions.filter((option) => !excludeVacant || option.value !== "vacant");
    return (
        <FormStep>
            <FormGroup>
                <Label for="jobTitle">{t("job_title")}</Label>
                <Input
                    type="text"
                    name="jobTitle"
                    maxLength={FIELD_SIZE_LIMITS.JOB_TITLE}
                    invalid={errors.jobTitle && touched.jobTitle}
                    placeholder={t("required")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jobTitle || ""}
                    autoComplete="off"
                />
                <FormFeedback>{errors.jobTitle}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="talentBoardIds">{t("talent_boards")}</Label>
                <TalentBoardPicker
                    isMulti
                    selectedIds={values.talentBoardIds || []}
                    onBlur={() => {
                        setFieldTouched("talentBoardIds", true);
                    }}
                    onChange={(selectedTalentBoardId) => {
                        setFieldValue("talentBoardIds", selectedTalentBoardId);
                    }}
                />
            </FormGroup>
            <FormGroup row>
                <Col md={5}>
                    <FormGroup>
                        <Label for="incumbent">{t("incumbent")}</Label>
                        <RadioButtons options={preppedOptions} onChange={setFillWith} selected={fillWith} vertical />
                    </FormGroup>
                </Col>
                <Col md={7}>
                    {fillWith === "current" && (
                        <FormGroup>
                            <Label for="jobTitle">{language.orphaned_employees}</Label>
                            <EmployeePicker
                                maxMenuHeight={120}
                                selectedIds={values.existingEmployeeId || []}
                                onlyOrphans
                                isClearable
                                excludeIds={[status.incumbentId]}
                                onBlur={() => {
                                    setFieldTouched("existingEmployeeId", true);
                                }}
                                onChange={(empId) => {
                                    const employee = lookEmployee(empId);
                                    if (employee) {
                                        setFieldValue("firstname", employee.firstname);
                                        setFieldValue("surname", employee.surname);
                                        setFieldValue("email", employee.email);
                                        setFieldValue("employeeNumber", employee.employeeNumber);
                                        setStatus({
                                            ...status,
                                            existingEmployee: true,
                                            existingEmail: !!employee.email,
                                        });
                                    } else {
                                        onClearEmployee();
                                    }
                                    setFieldValue("existingEmployeeId", empId);
                                }}
                            />
                        </FormGroup>
                    )}
                </Col>
            </FormGroup>
        </FormStep>
    );
};

export default IncumbentStep;
