import { writeBatch } from "firebase/firestore";
import { lookClaims } from "../../redux/utils/looks";
import { db } from "../firebase";
import { getNotificationDocRef } from "../firebaseUtils";

export function clearNotifications(notifications) {
    const claims = lookClaims();
    if (claims.roleId) {
        let batch = writeBatch(db);
        notifications.forEach((notification) => {
            const notificationRef = getNotificationDocRef(claims.roleId, notification.id);
            batch.set(notificationRef, { seen: true }, { merge: true });
        });
        batch.commit();
    }
}