import { isEqual } from "lodash";
import { lookClaims, lookTopLevelRoleId } from "../redux/utils/looks";
import { getChildEmployeeIds, getChildRoleIds } from "./roleUtils";

export function haveClaimsChanged(newClaims) {
    const prevClaims = lookClaims();
    return !isEqual(newClaims, prevClaims);
}

export function checkAccess(accessing, id) {
    const claims = lookClaims();
    const userRole = claims.userRole;
    const topLevelRoleId = lookTopLevelRoleId();

    switch (accessing) {
        case "developer":
            return claims.developer;
        case "developerSettings":
            return claims.developer;
        case "role":
        case "privateEmployeeTimeline":
            const accessibleRoles = getChildRoleIds(topLevelRoleId, true);
            accessibleRoles.push(topLevelRoleId);
            return accessibleRoles.includes(id);
        case "talentAssessment":
            const accessibleEmployees = getChildEmployeeIds(topLevelRoleId, true);
            return accessibleEmployees.includes(id);
        case "profileSettings":
            return true;
        case "canCreateAdmins":
            return claims.canCreateAdmins || claims.developer;
        default:
            const canAccessEverthing = userRole === "admin" || userRole === "owner" || claims.developer;
            return canAccessEverthing;
    }
}
