import React from "react";
import TourWelcome from "./TourWelcome";
import AppTour from "./AppTour";
import { useLanguage } from "../../translations/LanguageContext";


const DashboardTour = () => {
    const { t } = useLanguage();
    const language = t("dashboard_tour", "tours");
    const tourSteps = [
        {
            target: "body",
            placement: "center",
            content: <TourWelcome language={language} />,
        },
        {
            target: ".tour-team-ratings",
            placement: "auto",
            content: language.step1,
        },
        {
            target: ".tour-team-strengths",
            placement: "auto",
            content: language.step2,
        },
    ];
    return <AppTour tourId="dashboard" steps={tourSteps} />;
};

export default DashboardTour;
