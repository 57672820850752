import { arrayRemove, arrayUnion, setDoc, writeBatch } from "firebase/firestore";
import { removeTalentItem, setPendingTalentChange } from "../../redux/utils/dispatches";
import { lookTrait } from "../../redux/utils/looks";
import { getUniqueId } from "../../utils/basicUtils";
import { db } from "../firebase";
import { getNewTalentInfoDocId, getTraitDocRef } from "../firebaseUtils";

export function setTrait(receivedData) {
    let { id, linkedTalentBoardIds, importCount, ...preppedData } = receivedData;
    id = id || getUniqueId();

    // Set to pending if any global fields are changing
    const currentData = lookTrait(id);
    const newData = { ...currentData, ...receivedData };
    if (currentData) setPendingTalentChange(id, currentData, newData);

    // Set the public infoDocId
    if (!preppedData.publicInfoDocId) {
        const currentTrait = lookTrait(id);
        const existingPublicInfoDocId = currentTrait && currentTrait.publicInfoDocId;
        preppedData.publicInfoDocId = existingPublicInfoDocId || getNewTalentInfoDocId();
    }

    const traitDocRef = getTraitDocRef(id);
    return setDoc(traitDocRef, preppedData, { merge: true });
}

export function disableTrait(traitId) {
    // Cloud function removes this trait from any talentboards it features on
    let trait = lookTrait(traitId);
    const { id, publicInfoDocId } = trait;
    const update = { id: id, publicInfoDocId: publicInfoDocId, disabled: true };
    removeTalentItem("traits", traitId);
    return setTrait(update);
}

export function removeTalentAreaFromTrait(traitId, talentAreaId) {
    const trait = lookTrait(traitId);
    const { id, publicInfoDocId } = trait;
    const update = { id: id, publicInfoDocId: publicInfoDocId, talentAreaIds: arrayRemove(talentAreaId) };
    return setTrait(update);
}

export function addTalentAreaToTrait(traitId, talentAreaId) {
    const trait = lookTrait(traitId);
    const { id, publicInfoDocId } = trait;
    const update = { id: id, publicInfoDocId: publicInfoDocId, talentAreaIds: arrayUnion(talentAreaId) };
    return setTrait(update);
}

export function addActionToTraits(actionId, traitIds) {
    let batch = writeBatch(db);
    traitIds.forEach((traitId) => {
        const traitDocRef = getTraitDocRef(traitId);
        const traitUpdate = {
            actionIds: arrayUnion(actionId),
        };
        batch.set(traitDocRef, traitUpdate, { merge: true });
    });
    batch.commit();
}

export function removeActionFromTraits(actionId, traitIds) {
    let batch = writeBatch(db);
    traitIds.forEach((traitId) => {
        const traitDocRef = getTraitDocRef(traitId);
        const traitUpdate = {
            actionIds: arrayRemove(actionId),
        };
        batch.set(traitDocRef, traitUpdate, { merge: true });
    });
    batch.commit();
}
