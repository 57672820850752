import store from "../store";

// Use these methods to look at something in the store without reacting to changes
export function lookAction(actionId) {
    const action = store.getState().talent.actions[actionId];
    if (action) {
        return action;
    } else {
        return { id: actionId, disabled: true, actionName: "Action Discontinued - Expand for details" };
    }
}

export function lookAssessmentsOpen() {
    return store.getState().assessments.settings.assessmentsOpen;
}

export function lookCustomFields() {
    return store.getState().workspace.customFields;
}

export function lookCustomField(fieldId) {
    const customFields = lookCustomFields();
    const field = customFields.find((entry) => entry.id === fieldId);
    return field;
}

export function lookAssessmentSettings() {
    return store.getState().assessments.settings;
}

export function lookOverallRatingLabel() {
    const assessmentSettings = lookAssessmentSettings();
    return assessmentSettings.lookOverallRatingLabel || "Overall";
}

export function lookClaims() {
    return store.getState().user.claims;
}

export function lookEmployee(empId) {
    return store.getState().org.employees[empId];
}

export function lookEmployees() {
    return store.getState().org.employees;
}

export function lookColors() {
    return store.getState().assessments.settings.allColors;
}

export function lookEmployeeSnapshot(empId) {
    return store.getState().talent.snapshots[empId];
}

export function lookExpandedNodes() {
    return store.getState().org.expandedNodes;
}

export function lookOrgTableConfig() {
    return store.getState().org.orgTableConfig;
}

export function lookSavedPreferences() {
    return store.getState().user.savedPreferences;
}

export function lookLocalPreferences() {
    return store.getState().user.localPreferences;
}

export function lookRequestDates() {
    return store.getState().assessments.requestDates;
}

export function lookRole(roleId) {
    return store.getState().org.roles[roleId];
}

export function lookRoles() {
    return store.getState().org.roles;
}

export function lookSelectedEmployeeId() {
    return store.getState().app.selectedEmployeeId;
}

export function lookSelectedRoleId() {
    return store.getState().app.selectedRoleId;
}

export function lookSnapshots() {
    return store.getState().talent.snapshots;
}

export function lookSnapshotHistory() {
    return store.getState().assessments.settings.snapshotHistory;
}

export function lookSnapshotId() {
    return store.getState().assessments.settings.snapshotId;
}

export function lookTalent() {
    return store.getState().talent;
}

export function lookTalentArea(areaId) {
    return store.getState().talent.talentAreas[areaId];
}

export function lookTalentAreas() {
    return store.getState().talent.talentAreas;
}

export function lookTalentBoard(boardId) {
    return store.getState().talent.talentBoards[boardId];
}

export function lookTalentBoards() {
    return store.getState().talent.talentBoards;
}

export function lookTalentMapConfig() {
    return store.getState().talent.talentMapConfig;
}

export function lookThresholdDate() {
    return store.getState().assessments.settings.thresholdDate;
}

export function lookLateDate() {
    return store.getState().assessments.settings.lateDate;
}

export function lookTopLevelRoleId() {
    return store.getState().user.topLevelRoleId;
}

export function lookTrait(traitId) {
    return store.getState().talent.traits[traitId];
}

export function lookTraits() {
    return store.getState().talent.traits;
}

export function lookSavedAssessment(empId) {
    const allUnsaved = store.getState().assessments.unsavedProgress;
    return allUnsaved[empId];
}

export function lookUser() {
    return store.getState().user;
}

export function lookWorkspace() {
    return store.getState().workspace;
}

export function lookActiveWorkspaceId() {
    return store.getState().user.activeWorkspaceId;
}
