import React, { Suspense, useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fullnameLogo from "./assets/img/logo/small-fulltext-logo.png";
import { LoadingIndicator, SplitPane } from "./components";
import Loading from "./components/LoadingIndicator/Loading";
import AcceptInvite from "./components/Workspace/AcceptInvite";
import ConfigureWorkspace from "./components/Workspace/ConfigureWorkspace";
import WorkspaceSelector from "./components/Workspace/WorkspaceSelector";
import { ALT_TEXT, SIDE_AREA_HEADER_TEXT } from "./constants";
import { AppModals, AppNavBar, AppSideArea, AppSideBar, SignInLayout } from "./containers";
import { setActiveWorkspace } from "./firebase/actions/user";
import { userSignOut } from "./firebase/auth";
import {
    listenActiveUser,
    listenActiveWorkspace,
    listenAssessmentRequestDates,
    listenAuth,
    listenEmployee,
    listenEmployeeSnapshots,
    listenNotifications,
    listenOrgInfo,
    listenRoleSnapshots,
    listenTalentInfo,
    listenWorkspaceInvites,
} from "./firebase/listeners";
import {
    Actions,
    CompleteSignIn,
    Dashboard,
    Page404,
    People,
    RequestSignIn,
    Settings,
    TalentBoards,
    Traits,
} from "./pages";
import Action from "./pages/Actions/Action";
import { Org } from "./pages/People";
import RoleView from "./pages/People/Views/Role/RoleView";
import DepartmentTab from "./pages/People/Views/Role/Views/DepartmentTab";
import EmployeeTab from "./pages/People/Views/Role/Views/EmployeeTab";
import SuccessionPlanningTab from "./pages/People/Views/Role/Views/SuccessionPlanningTab";
import TalentMapTab from "./pages/People/Views/Role/Views/TalentMapTab";
import { ProfileSettings, SubscriptionSettings, WorkspaceSettings } from "./pages/Settings";
import DeveloperSettings from "./pages/Settings/DeveloperSettings";
import EmployeeSettings from "./pages/Settings/EmployeeSettings";
import SuccessionPlanning from "./pages/SuccessionPlanning/SuccessionPlanning";
import TalentAreas from "./pages/TalentAreas/TalentAreas";
import SelfAssessment from "./pages/TalentAssessment/SelfAssessment/SelfAssessment";
import TalentAssessment from "./pages/TalentAssessment/TalentAssessment";
import TalentBoard from "./pages/TalentBoards/TalentBoard";
import Trait from "./pages/Traits/Trait";
import { RESET_SELECTED, SET_MODAL, SET_SIDE_AREA } from "./redux/appSlice";
import { RESET_ASSESSMENTS } from "./redux/assessmentsSlice";
import { RESET_ORG, SET_ORG_TABLE_CONFIG, SET_SELF } from "./redux/orgSlice";
import { RESET_TALENT, SET_MAP_CONFIG } from "./redux/talentSlice";
import { setTimedOut, setTimedOutMessage } from "./redux/utils/dispatches";
import { linkTraits, prepOrg, setRoleSnapshots, setSnapshots } from "./redux/utils/thunks";
import { RESET_WORKSPACE } from "./redux/workspaceSlice";
import "./scss/style.scss";
import { cLog } from "./utils/basicUtils";

const DOWN_FOR_MAINTAINANCE = false;

const timeout = 30 * 60 * 1000;

const UserMessage = ({ title, message }) => {
    return (
        <div className="app-layout">
            <div className="inner-container d-flex align-items-stretch justify-content-center">
                <div className="pb-5 text-center">
                    <img className="mb-4" src={fullnameLogo} alt={ALT_TEXT.LOGO} />
                    <h2 className="c-light mb-2">{title}</h2>
                    <div className="d-flex">
                        <div className="f-1 md-show" />
                        <div className="f-2">
                            <p className="px-3">{message}</p>
                        </div>
                        <div className="f-1 md-show" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Workspace = () => {
    const dispatch = useDispatch();
    const activeSideArea = useSelector((state) => state.app.activeSideArea);
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const workspaceHasLoaded = useSelector((state) => state.workspace.workspaceHasLoaded);
    const workspaceConfigured = useSelector((state) => state.workspace.configured);
    const orgHasLoaded = useSelector((state) => state.org.loaded);
    const workspaceIsReady = workspaceHasLoaded && orgHasLoaded;
    const snapshotsHaveLoaded = useSelector((state) => !state.talent.loading);
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const roleId = useSelector((state) => state.user.claims.roleId);
    const employeeId = useSelector((state) => state.user.claims.employeeId);
    const unsavedProgress = useSelector((state) => state.assessments.unsavedProgress);
    const orgDataChanged = useSelector((state) => state.org.orgDataChanged);
    const unauthorized = useSelector((state) => state.user.unauthorized);
    const localPreferences = useSelector((state) => state.user.localPreferences);
    const snapshotDataChanged = useSelector((state) => state.talent.snapshotDataChanged);
    const traitDataChanged = useSelector((state) => state.talent.traitDataChanged);
    const traitsLinked = useSelector((state) => state.talent.traitsLinked);
    const talentBoardsLoaded = useSelector((state) => state.talent.talentBoardsLoaded);
    const roleSnapshotDataChanged = useSelector((state) => state.org.roleSnapshotDataChanged);
    const enableSelfAssessment = useSelector((state) => state.assessments.settings.enableSelfAssessment);
    const enabledModules = useSelector((state) => state.workspace.enabledModules);
    const { successionPlanning } = enabledModules;

    // Listen to the workspace & assessment dates
    useEffect(() => {
        const unsubscribeWorkspace = listenActiveWorkspace(activeWorkspaceId);
        const unsubscribeAssessmentDates = listenAssessmentRequestDates();
        return () => {
            dispatch(RESET_TALENT());
            cLog("RESET TALENT", "App");
            dispatch(RESET_ORG());
            cLog("RESET ORG", "App");
            dispatch(RESET_WORKSPACE());
            cLog("RESET WORKSPACE", "App");
            dispatch(RESET_ASSESSMENTS());
            cLog("RESET_ASSESSMENTS", "App");
            dispatch(RESET_SELECTED());
            cLog("RESET_SELECTED", "App");
            unsubscribeAssessmentDates();
            cLog("UNSUBSCRIBE ASSESSMENT DATES", "App");
            unsubscribeWorkspace();
            cLog("UNSUBSCRIBE WORKSPACE", "App");
        };
    }, [dispatch, activeWorkspaceId]);

    // Timeout if the workspace doesn't load
    useEffect(() => {
        if (!workspaceHasLoaded && activeWorkspaceId) {
            const timer = setTimeout(() => {
                cLog("Workspace Load Timeout");
                setTimedOut(!workspaceHasLoaded);
                setTimedOutMessage("Workspace failed to load. Please try again or contact support.");
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [workspaceHasLoaded, activeWorkspaceId]);

    useBeforeunload((event) => {
        if (Object.keys(unsavedProgress).length > 0) {
            const IS_DEV = process.env.NODE_ENV === "development";
            if (!IS_DEV) event.preventDefault();
        }
    });

    // If the user is unauthorized for this workspace, sign them out
    useEffect(() => {
        if (unauthorized) {
            userSignOut();
        }
    }, [unauthorized]);

    // Setup listeners for Talent data
    useEffect(() => {
        if (workspaceHasLoaded && activeWorkspaceId) {
            const unsubscribeTalentInfo = listenTalentInfo(activeWorkspaceId);
            return () => {
                unsubscribeTalentInfo();
            };
        }
    }, [workspaceHasLoaded, activeWorkspaceId]);

    // Setup org listeners once Talent Data has loaded
    useEffect(() => {
        if (talentBoardsLoaded) {
            const unsubscribeOrgInfo = listenOrgInfo(activeWorkspaceId);
            return () => unsubscribeOrgInfo();
        }
    }, [talentBoardsLoaded, activeWorkspaceId]);

    // Prep the Talent Map & Org Table config when the user's preferences change
    useEffect(() => {
        if (workspaceHasLoaded && activeWorkspaceId) {
            const workspacePreferences = localPreferences[activeWorkspaceId] || {};
            const { talentMapConfig, orgTableConfig } = workspacePreferences;
            dispatch(SET_MAP_CONFIG(talentMapConfig));
            dispatch(SET_ORG_TABLE_CONFIG(orgTableConfig));
        }
    }, [dispatch, activeWorkspaceId, workspaceHasLoaded, localPreferences]);

    // Create the Trait links when related data is stable for 0.5 seconds
    useEffect(() => {
        if (traitDataChanged) {
            const timer = setTimeout(() => {
                dispatch(linkTraits());
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [dispatch, traitDataChanged]);

    // Prep the assessment snapshots when related data is stable for 1 second
    useEffect(() => {
        if (snapshotDataChanged && traitsLinked && orgHasLoaded) {
            const timer = setTimeout(() => {
                dispatch(setSnapshots());
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [dispatch, snapshotDataChanged, traitsLinked, orgHasLoaded]);

    // Prep the roles snapshots when related data is stable for 1 second
    useEffect(() => {
        if (roleSnapshotDataChanged) {
            const timer = setTimeout(() => {
                dispatch(setRoleSnapshots());
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [dispatch, roleSnapshotDataChanged]);

    // Prep the roles when related data is stable for 1 second
    useEffect(() => {
        if (orgDataChanged) {
            const timer = setTimeout(() => {
                dispatch(prepOrg());
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [dispatch, orgDataChanged]);

    // Subscribe to role notifications
    useEffect(() => {
        if (roleId && activeWorkspaceId) {
            const unsubscribeNotifications = listenNotifications(roleId);
            return () => {
                unsubscribeNotifications();
            };
        }
    }, [roleId, activeWorkspaceId]);

    // Listen to the employee snapshots
    useEffect(() => {
        if (activeWorkspaceId && workspaceHasLoaded) {
            cLog("snapshots are loading", "App");
            const unsubscribeAssessments = listenEmployeeSnapshots(topLevelRoleId);
            return () => {
                unsubscribeAssessments();
            };
        }
    }, [activeWorkspaceId, workspaceHasLoaded, topLevelRoleId]);

    // List to the role snapshots
    useEffect(() => {
        if (successionPlanning && activeWorkspaceId && workspaceHasLoaded) {
            cLog("role snapshots are loading", "App");
            const unsubscribeRoleSnapshots = listenRoleSnapshots(topLevelRoleId);
            return () => {
                unsubscribeRoleSnapshots();
            };
        }
    }, [activeWorkspaceId, workspaceHasLoaded, topLevelRoleId, successionPlanning]);

    // If self assessment is enabled, listen to employee and dispatch to org.self
    useEffect(() => {
        if (enableSelfAssessment && employeeId) {
            const unsubscribeEmployee = listenEmployee(employeeId, (self) => dispatch(SET_SELF(self)));
            return () => {
                unsubscribeEmployee();
            };
        }
    }, [dispatch, enableSelfAssessment, employeeId]);

    const handleCloseSideArea = () => {
        dispatch(SET_SIDE_AREA(false));
    };

    if (workspaceHasLoaded && !workspaceConfigured) {
        return <ConfigureWorkspace />;
    }

    if (!workspaceIsReady) {
        return <LoadingIndicator fullscreen message="Preparing Org Chart" />;
    }

    if (!snapshotsHaveLoaded) {
        return <LoadingIndicator fullscreen message="Preparing Employee Data." />;
    }

    if (workspaceIsReady) {
        return (
            <div id="app-workspace" className="app-layout">
                <AppModals />
                <AppSideBar />
                <AppNavBar />
                <SplitPane
                    dimMode="none"
                    left={<Outlet />}
                    right={<AppSideArea onClose={handleCloseSideArea} />}
                    isOpen={!!activeSideArea}
                    onClose={handleCloseSideArea}
                    headerText={SIDE_AREA_HEADER_TEXT[activeSideArea]}
                />
                <ToastContainer
                    position="bottom-left"
                    autoClose={4000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }

    return <LoadingIndicator fullscreen message="Loading Workspace" />;
};

// Load the user's data to determine the app's state
const AuthApp = () => {
    const navigate = useNavigate();
    const [attemptingJoin, setAttemptingJoin] = useState(false);
    const userId = useSelector((state) => state.user.authId);
    const loggedIn = useSelector((state) => state.user.loggedIn);
    const email = useSelector((state) => state.user.email);
    const claims = useSelector((state) => state.user.claims);
    const userReady = useSelector((state) => state.user.userReady);
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const workspaceInvites = useSelector((state) => state.user.workspaceInvites);

    // If the user doesn't load correctly, sign out
    useEffect(() => {
        if (!userReady) {
            const timer = setTimeout(() => {
                cLog("Sign In Timeout");
                setTimedOut(!userReady);
                setTimedOutMessage("There was a problem signing in. Please contact your support team.");
            }, 8000);
            return () => clearTimeout(timer);
        }
    }, [userReady]);

    // If there is no user logged in, redirect to the sign-in page
    useEffect(() => {
        if (!loggedIn) {
            navigate("sign-in/request");
        }
    }, [navigate, loggedIn]);

    // Load the user and any workspace invites they may have
    useEffect(() => {
        if (userId && email) {
            const lCaseEmail = email && email.toLowerCase();
            const activeUserUnsubscribe = listenActiveUser(userId);
            const invitesUnsubscribe = listenWorkspaceInvites(lCaseEmail);
            return () => {
                activeUserUnsubscribe();
                invitesUnsubscribe();
            };
        }
    }, [userId, email]);

    // Delay render until the user document has loaded
    if (!userReady) {
        return <LoadingIndicator fullscreen message="Loading your User Account" />;
    }

    // If the user has a workspace selected, render the workspace component
    if (activeWorkspaceId) {
        return <Workspace />;
    }

    // If the user has no workspace selected
    if (!activeWorkspaceId) {
        const workspaceClaims = claims.workspaceClaims || {};
        const workspaceIdList = Object.keys(workspaceClaims);

        // If the user is a developer, show the workspace selector
        if (claims.developer) {
            return <WorkspaceSelector />;
        }

        // User has claims to only one workspace; select it
        if (workspaceIdList.length === 1) {
            setActiveWorkspace(workspaceIdList[0]);
            return true;
        }

        // Delay render while the user is joining a workspace
        if (attemptingJoin) {
            return <LoadingIndicator fullscreen message="Joining workspace" />;
        }

        // User has claims for more than one workspace; show the workspace selector
        if (workspaceIdList.length > 1) {
            return <WorkspaceSelector workspaceIds={workspaceIdList} />;
        }

        // User doesn't have any workspace claims, but does have invites
        if (workspaceInvites.length > 0) {
            return <AcceptInvite invite={workspaceInvites[0]} onAccept={setAttemptingJoin} />;
        }

        // If none of the above match, the user isn't authorized to use the app
        return <UserMessage title="No Workspace Access" message="You are not authorized to use this app." />;
    }
};

const App = () => {
    const dispatch = useDispatch();
    const authHasLoaded = useSelector((state) => state.app.authHasLoaded);
    const [isIdle, setIsIdle] = useState(false);
    const timedOut = useSelector((state) => state.app.timedOut);
    const timeOutMessage = useSelector((state) => state.app.timeOutMessage);

    // If anything has timed out, make sure the user is signed out
    useEffect(() => {
        if (timedOut) {
            userSignOut();
        }
    }, [timedOut]);

    // Listen to the auth user
    useEffect(() => {
        const firebaseAuthUnsubscribe = listenAuth();
        return () => {
            firebaseAuthUnsubscribe();
        };
    }, []);

    const onIdle = () => {
        dispatch(SET_MODAL(false));
        dispatch(SET_SIDE_AREA(false));
        setIsIdle(true);
    };

    // eslint-disable-next-line
    const idleTimer = useIdleTimer({ onIdle, timeout });

    if (DOWN_FOR_MAINTAINANCE) {
        return (
            <UserMessage
                title="Talent Mapper is currently unavailable."
                message="We're carrying out some maintenance. We'll be back shortly."
            />
        );
    }

    if (timedOut) {
        return <UserMessage title="Unable to sign-in." message={timeOutMessage} />;
    }

    if (isIdle)
        return (
            <UserMessage title="You've been inactive for more than 30 minutes." message="Please refresh the page." />
        );

    if (authHasLoaded) {
        return (
            <BrowserRouter basename="/">
                <Suspense fallback={<Loading fullscreen />}>
                    <Routes>
                        <Route path="sign-in" element={<SignInLayout />}>
                            <Route index element={<Navigate to="/sign-in/request" />} />
                            <Route path="request" element={<RequestSignIn />} />
                            <Route path="confirm" element={<CompleteSignIn />} />
                        </Route>
                        <Route path="*" element={<Navigate to="sign-in/request" />} />
                        <Route path="/" element={<AuthApp />}>
                            <Route index element={<Navigate to="people" />} />
                            <Route path="people" element={<People />}>
                                <Route index element={<Org />} />
                                <Route path="assessments">
                                    <Route index element={<Navigate to="/people" />} />
                                    <Route path=":employeeId" element={<TalentAssessment />} />
                                    <Route path="self-assessment" element={<SelfAssessment />} />
                                </Route>
                                <Route path=":roleId" element={<RoleView />}>
                                    <Route index element={<Navigate to="employee" />} />
                                    <Route path="employee" element={<EmployeeTab />} />
                                    <Route path="department" element={<DepartmentTab />} />
                                    <Route path="succession-planning" element={<SuccessionPlanningTab />} />
                                    <Route path="talent-map" element={<TalentMapTab />}>
                                        <Route path=":segmentId" element={<TalentMapTab />} />
                                    </Route>
                                </Route>
                            </Route>
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="settings" element={<Settings />}>
                                <Route index element={<Navigate to="profile" />} />
                                <Route path="employee" element={<EmployeeSettings />} />
                                <Route path="workspace" element={<WorkspaceSettings />} />
                                <Route path="profile" element={<ProfileSettings />} />
                                <Route path="subscription" element={<SubscriptionSettings />} />
                                <Route path="developer" element={<DeveloperSettings />} />
                            </Route>
                            <Route path="traits">
                                <Route index element={<Traits />} />
                                <Route path=":traitId" element={<Trait />} />
                            </Route>
                            <Route path="talentboards">
                                <Route index element={<TalentBoards />} />
                                <Route path=":boardId" element={<TalentBoard />} />
                            </Route>
                            <Route path="actions">
                                <Route index element={<Actions />} />
                                <Route path=":actionId" element={<Action />} />
                            </Route>
                            <Route path="talentareas">
                                <Route index element={<TalentAreas />} />
                            </Route>
                            <Route path="succession-planning">
                                <Route index element={<SuccessionPlanning />} />
                            </Route>
                        </Route>
                        <Route path="sign-in" element={<SignInLayout />}>
                            <Route index element={<Navigate to="/sign-in/request" />} />
                            <Route path="request" element={<RequestSignIn />} />
                            <Route path="confirm" element={<CompleteSignIn />} />
                        </Route>
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        );
    } else {
        return <LoadingIndicator message="Checking authentication status..." fullscreen />;
    }
};

export default App;
