import classNames from "classnames";
import React from "react";


const TourHeader = ({ tourClass, label }) => (
    <div>
        <h6 className={classNames("d-inline-block", tourClass)}>{label}</h6>
    </div>
);

export default TourHeader;
