import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AssessmentTour, LoadingIndicator } from "../../components";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import { scheduleActions } from "../../firebase/actions/action";
import { setEmployeeAssessment } from "../../firebase/actions/assessments";
import { fetchCurrentSelfAssessment, fetchEmployee, fetchLatestAssessment } from "../../firebase/fetch";
import { EmployeeModel } from "../../models";
import { CLEAR_ASSESSMENT_PROGRESS, SAVE_ASSESSMENT_PROGRESS } from "../../redux/assessmentsSlice";
import { RESET_PENDING_TALENT } from "../../redux/talentSlice";
import { selectEmployee } from "../../redux/utils/dispatches";
import { lookEmployee, lookSavedAssessment, lookSnapshotId } from "../../redux/utils/looks";
import { checkAccess } from "../../utils/userUtils";
import AssessmentStepper from "./AssessmentStepper";
import ExtraFields from "./FinalStep";
import { RatingStep } from "./RatingStep";

const TalentAssessment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const activeEmployeeId = params.employeeId;
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [actionsToSchedule, setActionsToSchedule] = useState([]);
    const [prevActionIds, setPrevActionIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allTraitsToAssess, setAllTraitsToAssess] = useState([]);
    const [stepIndex, setStepIndex] = useState(0);
    const [unratedIds, setUnratedIds] = useState([]);
    const [idToOverwrite, setIdToOverwrite] = useState();
    const [hasChanged, setHasChanged] = useState(false);
    const [assessment, setAssessment] = useState({ ratings: {}, otherFields: {} });
    const [selfRatings, setSelfRatings] = useState({});
    const [selfNotes, setSelfNotes] = useState("");
    const [prevAssessment, setPrevAssessment] = useState({ ratings: {}, otherFields: {} });
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const enableSelfAssessment = useSelector((state) => state.assessments.settings.enableSelfAssessment);
    const { ratings, otherFields } = assessment;
    const thisStep = allTraitsToAssess && allTraitsToAssess[stepIndex];
    const lastStep = stepIndex === allTraitsToAssess.length;
    const currentSnapshotId = lookSnapshotId();

    // Select the employee
    useEffect(() => {
        const allowedAccess = checkAccess("talentAssessment", activeEmployeeId);
        if (allowedAccess) {
            selectEmployee(activeEmployeeId);
        } else {
            navigate("people/");
        }
    }, [navigate, activeEmployeeId]);

    useEffect(() => {
        async function fetchAndSetSelfRatings() {
            const selfDoc = await fetchCurrentSelfAssessment(activeEmployeeId, currentSnapshotId);
            if (selfDoc) {
                const selfData = selfDoc.data();
                const { ratings, notes } = selfData;
                setSelfNotes(notes)
                setSelfRatings(ratings);
            }
        }

        async function fetchAndSetPrevActions() {
            const employeeData = await fetchEmployee(activeEmployeeId);
            setSelectedEmployee(employeeData);
            const { scheduledActionIds = [], completedActionIds = [] } = employeeData;
            const prevActionIds = [...scheduledActionIds, ...completedActionIds];
            setPrevActionIds(prevActionIds);
        }

        async function fetchData(traitsToAssess) {
            let assessmentValues = { ratings: {}, otherFields: {}, actionIds: [] };   
            const doc = await fetchLatestAssessment(activeEmployeeId);
            if (doc) {
                const data = doc.data();
                const { ratings, assessmentDate, ...otherFields } = data;
                assessmentValues.otherFields = otherFields;
                if (data.snapshotId && data.snapshotId === currentSnapshotId) {
                    setIdToOverwrite(doc.id);
                } else {
                    delete assessmentValues.otherFields.notes;
                }
                let traitRatings = {};
                if (ratings) {
                    traitsToAssess.forEach((trait) => {
                        if (ratings[trait.id]) {
                            traitRatings[trait.id] = ratings[trait.id];
                        }
                    });
                }
                assessmentValues.ratings = traitRatings;
            }
            setAssessment(assessmentValues);
            setPrevAssessment(assessmentValues);
        }

        async function prepareAndFetchData(traitsToAssess) {
            setIsLoading(true);
            await fetchAndSetSelfRatings();
            await fetchAndSetPrevActions();
            await fetchData(traitsToAssess);
            setIsLoading(false);
        }

        const emp = lookEmployee(activeEmployeeId);
        if (emp) {
            const empModel = new EmployeeModel(emp);
            const traitsToAssess = empModel.getTraitsToAssess();
            setAllTraitsToAssess(traitsToAssess);

            const savedAssessment = lookSavedAssessment(activeEmployeeId);
            if (!savedAssessment) {
                prepareAndFetchData(traitsToAssess);
            } else {
                const { actionIds, ...assessment } = savedAssessment;
                setSelectedEmployee(emp);
                setPrevActionIds(actionIds);
                setAssessment(assessment);
                setIsLoading(false);
            }
        }
    }, [dispatch, activeEmployeeId, enableSelfAssessment, currentSnapshotId]);

    // Determine if any changes have been made
    useEffect(() => {
        const { ratings, otherFields } = assessment;
        const ratingsHaveChanged = !isEqual(prevAssessment.ratings, ratings);
        const actionsHaveChanged = actionsToSchedule.length > 0;
        const otherFieldsHaveChanged = !isEqual(prevAssessment.otherFields, otherFields);
        const changed = ratingsHaveChanged || actionsHaveChanged || otherFieldsHaveChanged;
        if (changed) {
            dispatch(SAVE_ASSESSMENT_PROGRESS({ [activeEmployeeId]: assessment }));
        } else {
            dispatch(CLEAR_ASSESSMENT_PROGRESS(activeEmployeeId));
        }
        setHasChanged(changed);
    }, [dispatch, activeEmployeeId, actionsToSchedule, assessment, prevAssessment]);

    // Determine if the assessment is complete and can be submitted
    useEffect(() => {
        const unratedIds = ratings && allTraitsToAssess.filter((trait) => !ratings[trait.id]).map((trait) => trait.id);
        setUnratedIds(unratedIds);
    }, [allTraitsToAssess, ratings]);

    if (!selectedEmployee) return <LoadingIndicator />;

    // Checks if there's a rating for every trait that needs rating
    const checkUnfininished = () => {
        const undefinedRatings = Object.values(ratings).filter((rating) => rating === undefined);
        const noRatings = allTraitsToAssess.filter((trait) => !Object.keys(ratings).includes(trait.id));
        return undefinedRatings.length + noRatings.length > 0;
    };

    const handleSetRating = (traitId, rating) => {
        const newRatings = { ...ratings, [traitId]: rating };
        const editedAssessment = { ...assessment, ratings: newRatings };
        setAssessment(editedAssessment);
    };

    const handleSetAssessmentValue = (field, value) => {
        const newOtherFields = { ...otherFields, [field]: value };
        const editedAssessment = { ...assessment, otherFields: newOtherFields };
        setAssessment(editedAssessment);
    };

    const handleCheckAction = (actionId) => {
        const indexOf = actionsToSchedule.indexOf(actionId);
        let newActionIds = [...actionsToSchedule];
        if (indexOf >= 0) {
            newActionIds.splice(indexOf, 1);
        } else {
            newActionIds.push(actionId);
        }
        setActionsToSchedule(newActionIds);
    };

    const handleSubmit = async () => {
        const actionsHaveChanged = actionsToSchedule.length > 0;
        const ratingsHaveChanged = !isEqual(prevAssessment.ratings, ratings);
        const fieldsHaveChanged = !isEqual(prevAssessment.otherFields, otherFields);
        const newSnapshot = otherFields?.snapshotId !== currentSnapshotId;
        if (ratingsHaveChanged || fieldsHaveChanged || newSnapshot) {
            const preppedAssessment = { ...otherFields, ratings: ratings };
            await setEmployeeAssessment(activeEmployeeId, preppedAssessment, idToOverwrite)
                .commit()
                .catch((error) => {
                    dispatch(RESET_PENDING_TALENT());
                    toast.error(error.message);
                });
        }

        // Schedule any new actions
        if (actionsHaveChanged) {
            await scheduleActions(activeEmployeeId, actionsToSchedule)
                .commit()
                .catch((error) => {
                    toast.error(error.message);
                });
        }

        // Clear Leave the page
        dispatch(CLEAR_ASSESSMENT_PROGRESS(activeEmployeeId));
        navigate(`/people/${selectedRoleId}/employee`);
    };

    const handleChangeIndex = (idx) => {
        setStepIndex(idx);
    };

    const handleDiscardChanges = () => {
        const onConfirm = () => {
            dispatch(CLEAR_ASSESSMENT_PROGRESS(activeEmployeeId));
            navigate(-1);
        };

        // Confirm before discarding
        confirmModal("discard", onConfirm, "assessment");
    };

    if (isLoading) return <LoadingIndicator />;

    return (
        <div key={activeEmployeeId} className="d-flex inner-container flex-column f-1">
            <AssessmentTour hide={lastStep} />
            <AssessmentStepper
                headerText={selectedEmployee.displayName}
                onDiscardChanges={handleDiscardChanges}
                onSubmit={handleSubmit}
                onStepChange={handleChangeIndex}
                allowSkipToEnd={checkUnfininished()}
                employeeId={activeEmployeeId}
                unratedIds={unratedIds}
                hasChanged={hasChanged}
            >
                {allTraitsToAssess.map((trait) => {
                    const isActive = thisStep && thisStep.id === trait.id;
                    const traitRating = trait && ratings[trait.id];
                    const selfRating = trait && selfRatings[trait.id];
                    return (
                        <RatingStep
                            title={trait.traitName}
                            trait={trait}
                            id={trait.id}
                            active={isActive}
                            key={`rating-step-${trait.id}`}
                            traitRating={traitRating}
                            shadowRating={enableSelfAssessment && selfRating}
                            actionIds={actionsToSchedule}
                            prevActionIds={prevActionIds}
                            onCheckAction={handleCheckAction}
                            onSetRating={handleSetRating}
                        />
                    );
                })}
                <ExtraFields
                    hideSidePanel
                    id="final"
                    title="Finish"
                    onCheckAction={handleCheckAction}
                    actionIds={actionsToSchedule}
                    prevActionIds={prevActionIds}
                    preppedAssessment={{ ...otherFields, ...ratings }}
                    selfNotes={selfNotes}
                    onSetAssessmentValue={handleSetAssessmentValue}
                />
            </AssessmentStepper>
        </div>
    );
};

export default TalentAssessment;
