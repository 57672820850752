import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap";
import AssessmentIndicator from "../../../pages/People/Views/Org/Components/AssessmentIndicator";
import OrgActions from "../../../pages/People/Views/Org/Components/OrgActions";
import { selectEmployee } from "../../../redux/utils/dispatches";
import { lookCustomField, lookEmployee, lookTalentArea, lookTalentBoard, lookTrait } from "../../../redux/utils/looks";
import { getContrastColor } from "../../../utils/basicUtils";
import { getExactColor } from "../../../utils/snapshotUtils";
import { RemoveButton } from "../../Buttons/MyButtons";
import { CheckOrCrossIcon } from "../../Icons/MyIcons";
import { UserStatusIcon } from "../../Icons/SmartIcons";
import BaseTable from "../BaseTable";

const bulletStyle = { listStyleType: "none", padding: 0 };

const AssessmentBadge = ({ rating }) => {
    let style = {};
    if (rating) {
        const backgroundColor = getExactColor(rating);
        const color = getContrastColor(backgroundColor);
        style = { backgroundColor: backgroundColor, color: color };
    }
    return (
        <div style={style} className={classNames("rating-badge", { rated: !!rating })}>
            {rating || "-"}
        </div>
    );
};

const SuccessorField = ({ employeeId, value }) => {
    const [isOpen, setIsOpen] = useState(false);
    const count = value ? value.length : 0;
    const elementId = `successors-for-${employeeId}`;
    return (
        <div>
            <span
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                id={elementId}
            >{`${count} Successor${count !== 1 ? "s" : ""}`}</span>
            {count > 0 && (
                <Popover placement="bottom" isOpen={isOpen} target={elementId}>
                    <PopoverBody className="scrollable pb-0">
                        <ul style={bulletStyle}>
                            {value.map((successorId) => {
                                const successor = lookEmployee(successorId);
                                const successorName = successor && successor.displayName;
                                return (
                                    <li className="mb-2" key={successorId}>
                                        {successorName}
                                    </li>
                                );
                            })}
                        </ul>
                    </PopoverBody>
                </Popover>
            )}
        </div>
    );
};

const renderCustomField = (employeeId, fieldId, value) => {
    const customField = lookCustomField(fieldId);
    const { type } = customField;
    if (!value) return null;
    switch (type) {
        case "switch":
            return <CheckOrCrossIcon checked={value} size={18} />;
        case "picker":
            const fieldOptions = customField && customField.options;
            const option = fieldOptions && fieldOptions.find((option) => option.value === value);
            const label = option && option.label;
            return <div>{label || "-"}</div>;
        case "employeePicker":
            return <SuccessorField employeeId={employeeId} value={value} />;
        case "input":
            return <div>{value}</div>;
        default:
            return null;
    }
};

const EmployeesTable = (props) => {
    const navigate = useNavigate();
    const {
        data,
        columnKeys,
        traitIds,
        talentAreaIds,
        talentBoardIds,
        assessmentFieldIds,
        tooltipId,
        orgActions,
        iconSize,
        includeEdit,
        includeRemove,
        onRemove,
    } = props;
    const [pageIndex, setPageIndex] = useState(0);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const pendingChangeIds = useSelector((state) => state.org.pendingChanges);

    // Add any included snapshot data
    const preppedData = useMemo(() => {
        let newPreppedData = [];
        Object.values(data).forEach((employee) => {
            let employeeEntry = { ...employee };
            const snapshot = snapshots[employeeEntry.id];
            if (snapshot) {
                if (traitIds) {
                    employeeEntry.traitRatings = {};
                    traitIds.forEach((traitId) => {
                        employeeEntry.traitRatings[traitId] = snapshot?.ratings?.[traitId];
                    });
                }
                if (talentAreaIds) {
                    employeeEntry.talentAreaRatings = {};
                    talentAreaIds.forEach((talentAreaId) => {
                        employeeEntry.talentAreaRatings[talentAreaId] = snapshot?.talentAreas?.[talentAreaId];
                    });
                }
                if (talentBoardIds) {
                    employeeEntry.talentBoardRatings = {};
                    talentBoardIds.forEach((talentBoardId) => {
                        employeeEntry.talentBoardRatings[talentBoardId] = snapshot?.talentBoards?.[talentBoardId];
                    });
                }
                if (assessmentFieldIds) {
                    employeeEntry.assessmentFields = {};
                    assessmentFieldIds.forEach((fieldId) => {
                        employeeEntry.assessmentFields[fieldId] = snapshot[fieldId];
                    });
                }
                employeeEntry.overall = snapshot.overall;
            }
            newPreppedData.push(employeeEntry);
        });
        return newPreppedData;
    }, [data, snapshots, traitIds, talentAreaIds, talentBoardIds, assessmentFieldIds]);

    // Set the columns for the table
    const includedColumns = useMemo(() => {
        let columns = [
            {
                key: "userBadge",
                width: "40px",
                render: (label, employee, index) => {
                    const { isUser, pending } = employee;
                    return (
                        <div className="d-flex justify-content-start px-3">
                            <UserStatusIcon isUser={isUser} pending={pending} tooltipId={tooltipId} />
                        </div>
                    );
                },
            },
            {
                key: "assessmentIndicator",
                width: "60px",
                render: (label, employee, index) => {
                    const roleId = employee.roleId;
                    if (roleId) {
                        return (
                            <div
                                style={{ height: iconSize, width: iconSize, margin: "auto" }}
                                className="d-flex justify-content-center mx-3"
                            >
                                <AssessmentIndicator
                                    tableView
                                    activeRoleId={roleId}
                                    size={iconSize}
                                    tooltipId={tooltipId}
                                />
                            </div>
                        );
                    }
                },
            },
            {
                key: "displayName",
                title: "Name",
                dataIndex: "displayName",
                render: (label, employee, index) => (
                    <div className="cell-content" style={{ minWidth: 200 }}>
                        {employee.displayName}
                    </div>
                ),
            },
            {
                key: "employeeNumber",
                title: "Employee #",
                dataIndex: "employeeNumber",
                render: (label, employee, index) => (
                    <div className="cell-content" style={{ minWidth: 120 }}>
                        {employee.employeeNumber}
                    </div>
                ),
            },
            {
                key: "jobTitle",
                title: "Job Title",
                dataIndex: "jobTitle",
                render: (label, employee, index) => (
                    <div className="cell-content" style={{ minWidth: 120 }}>
                        {employee.jobTitle}
                    </div>
                ),
            },
            {
                key: "email",
                title: "Email",
                dataIndex: "email",
                render: (label, employee, index) => (
                    <div className="cell-content" style={{ minWidth: 120 }}>
                        {employee.email}
                    </div>
                ),
            },
            {
                key: "managerName",
                dataIndex: "managerName",
                title: "Manager",
                render: (label, employee, index) => (
                    <div className="cell-content" style={{ minWidth: 200 }}>
                        {employee.managerName}
                    </div>
                ),
            },
            {
                key: "overall",
                dataIndex: "overall",
                width: "60px",
                title: "Overall Rating",
                render: (label, employee, index) => <AssessmentBadge rating={employee.overall} />,
            },
            {
                key: "gridPosition",
                width: "60px",
                dataIndex: "gridPosition",
                title: "Grid Position",
            },
        ];

        // Include only the basic colums included in the chosen columnKey list
        columns = columns.filter((column) => columnKeys.includes(column.key));

        // Add all included snapshot columns
        if (traitIds) {
            traitIds.forEach((id) => {
                const trait = lookTrait(id);
                columns.push({
                    key: id,
                    title: trait && trait.traitName,
                    parentKey: "traitRatings",
                    render: (label, employee, index) => {
                        const allRatings = employee && employee.traitRatings;
                        const rating = allRatings && allRatings[id];
                        return <AssessmentBadge rating={rating} />;
                    },
                });
            });
        }
        if (talentAreaIds) {
            talentAreaIds.forEach((id) => {
                const talentArea = lookTalentArea(id);
                columns.push({
                    key: id,
                    title: talentArea && talentArea.talentAreaName,
                    parentKey: "talentAreaRatings",
                    render: (label, employee, index) => {
                        const allRatings = employee && employee.talentAreaRatings;
                        const rating = allRatings && allRatings[id];
                        return (
                            <div className="cell-content">
                                <AssessmentBadge rating={rating} />
                            </div>
                        );
                    },
                });
            });
        }
        if (talentBoardIds) {
            talentBoardIds.forEach((id) => {
                const talentBoard = lookTalentBoard(id);
                columns.push({
                    key: id,
                    title: talentBoard && talentBoard.talentBoardName,
                    parentKey: "talentBoardRatings",
                    render: (label, employee, index) => {
                        const allRatings = employee && employee.talentBoardRatings;
                        const rating = allRatings && allRatings[id];
                        return <AssessmentBadge rating={rating} />;
                    },
                });
            });
        }
        if (assessmentFieldIds) {
            assessmentFieldIds.forEach((id) => {
                const customField = lookCustomField(id);
                columns.push({
                    key: id,
                    title: customField && customField.label,
                    parentKey: "assessmentFields",
                    render: (label, employee, index) => {
                        const allRatings = employee && employee.assessmentFields;
                        const rating = allRatings && allRatings[id];
                        return (
                            <div className="cell-content" style={{ minWidth: 160 }}>
                                {renderCustomField(employee.id, id, rating)}
                            </div>
                        );
                    },
                });
            });
        }

        // Add edit/remove columns
        if (includeEdit) {
            columns.push({
                key: "edit",
                dataIndex: "edit",
                width: "60px",
                render: (label, employee, index) => {
                    return <OrgActions {...orgActions} menuMode />;
                },
            });
        }
        if (includeRemove) {
            columns.push({
                key: "remove",
                width: "60px",
                render: (label, employee, index) => {
                    return <RemoveButton round={false} size={18} icon onClick={() => onRemove(employee.id)} />;
                },
            });
        }

        return columns;
    }, [
        columnKeys,
        orgActions,
        traitIds,
        talentAreaIds,
        talentBoardIds,
        assessmentFieldIds,
        tooltipId,
        iconSize,
        includeEdit,
        includeRemove,
        onRemove,
    ]);

    const handleRowClick = (empId) => {
        selectEmployee(empId);
    };

    const handleRowDoubleClick = (empId) => {
        const employee = lookEmployee(empId);
        const roleId = employee && employee.roleId;
        if (empId && roleId) {
            navigate(`/people/${roleId}/employee`);
        }
    };

    return (
        <BaseTable
            {...props}
            data={preppedData}
            className={classNames(props.className)}
            selectedId={selectedEmployeeId}
            pendingChangeIds={pendingChangeIds}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            checkbox={props.checkbox}
            columns={includedColumns}
            pageIndex={pageIndex}
            sortKey="displayName"
            onSetPageIndex={setPageIndex}
        />
    );
};

EmployeesTable.propTypes = {
    data: PropTypes.array,
    disableRowClick: PropTypes.bool,
    columnKeys: PropTypes.array,
    iconSize: PropTypes.number,
    rowsPerPage: PropTypes.number,
    checkbox: PropTypes.bool,
    selectableRows: PropTypes.bool,
    disableRowDoubleClick: PropTypes.bool,
    includeEdit: PropTypes.bool,
    includeRemove: PropTypes.bool,
    traitIds: PropTypes.array,
    talentAreaIds: PropTypes.array,
    talentBoardIds: PropTypes.array,
    assessmentFieldIds: PropTypes.array,
};

EmployeesTable.defaultProps = {
    data: [],
    columnKeys: [],
    iconSize: 32,
    rowsPerPage: 30,
};

export default EmployeesTable;
