import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { Button } from "reactstrap";
import { BaseTable } from "../..";
import {
    completeScheduledAction,
    deleteScheduledAction,
    reopenScheduledAction,
} from "../../../firebase/actions/action";
import { fetchAction } from "../../../firebase/fetch";
import { lookEmployee } from "../../../redux/utils/looks";
import { Pill } from "../../BadgesAndPills/Pills";
import { CompleteTaskButton, DeleteButton, ReopenActionButton } from "../../Buttons/MyButtons";
import { RichTextDisplay } from "../../RichTextEditor/RichTextEditor";

const ActionDropdown = (props) => {
    const actionId = props.record.id;
    const disabled = props.record.disabled;
    const [fullAction, setFullAction] = useState();
    const [pendingDelete, setPendingDelete] = useState(false);
    const [pendingComplete, setPendingComplete] = useState(false);
    const expanded = props.expanded;

    useEffect(() => {
        async function fetchData() {
            const actionDoc = await fetchAction(actionId);
            setFullAction(actionDoc);
        }
        if (actionId && expanded && !fullAction) {
            fetchData();
        }
    }, [actionId, expanded, fullAction]);

    if (!fullAction) return null;

    const tooltipId = `scheduled-actions${actionId}`;
    const renderDetails = () => {
        return (
            <div>
                {!props.record.complete && (
                    <div className="d-flex justify-content-between ps-3 bg-white my-2 rounded-4 border-all">
                        <div style={{ height: 38 }}>
                            {pendingDelete && (
                                <div className="d-flex">
                                    <Button
                                        className="basic invert me-2"
                                        onClick={() => props.onDeleteAction(props.record)}
                                    >
                                        Confirm Delete
                                    </Button>
                                    <Button className="basic invert" onClick={() => setPendingDelete(false)}>
                                        Cancel Delete
                                    </Button>
                                </div>
                            )}
                            {pendingComplete && (
                                <div className="d-flex">
                                    <Button
                                        className="basic invert me-2"
                                        onClick={() => props.onCompleteAction(props.record)}
                                    >
                                        Confirm Complete
                                    </Button>
                                    <Button className="basic invert" onClick={() => setPendingComplete(false)}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-end">
                            <DeleteButton
                                disabled={pendingDelete}
                                className="me-2"
                                size={18}
                                icon
                                tooltip="Cancel Planned Action"
                                tooltipId={tooltipId}
                                onClick={() => setPendingDelete(true)}
                            />
                            <CompleteTaskButton
                                disabled={pendingComplete}
                                className="me-2"
                                size={18}
                                icon
                                tooltip="Complete Planned Action"
                                tooltipId={tooltipId}
                                onClick={() => setPendingComplete(true)}
                            />
                        </div>
                    </div>
                )}
                {props.record.complete && (
                    <div className="d-flex justify-content-end">
                        <ReopenActionButton
                            className="me-2"
                            size={18}
                            icon
                            tooltip="Reopen Action"
                            tooltipId={tooltipId}
                            onClick={() => props.onReopenAction(props.record)}
                        />
                    </div>
                )}
                <ReactTooltip id={tooltipId} place="top" delayShow={250} />
            </div>
        );
    };

    return (
        <div className="child-row d-flex flex-column" key={`child-row-${props.record.id}`}>
            <RichTextDisplay content={fullAction.details} readOnly minHeight={20} />
            {!disabled && renderDetails()}
        </div>
    );
};

const ScheduledActionsTable = (props) => {
    const { columnKeys, tooltipId, iconSize } = props;
    const [includedColumns, setIncludedColumns] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        let columns = [
            {
                key: "actionName",
                title: "Action",
                dataIndex: "actionName",
                render: (label, scheduledAction, index) => {
                    return (
                        <div className="dashboard-text cell-inner-container">
                            <div>{scheduledAction.actionName}</div>
                        </div>
                    );
                },
            },
            {
                key: "employeeName",
                title: "Employee",
                render: (label, scheduledAction, index) => {
                    const employee = lookEmployee(scheduledAction.employeeId);
                    let displayName = employee && employee.displayName;
                    displayName = displayName || "Employee has been removed";
                    return (
                        <div className="dashboard-text cell-inner-container">
                            <div>{displayName}</div>
                        </div>
                    );
                },
            },
            {
                key: "status",
                title: "Status",
                dataIndex: "status",
                render: (label, scheduledAction, index) => {
                    const { complete } = scheduledAction;
                    return (
                        <Pill className={classNames({ complete: !complete })}>
                            <span>{complete ? "DONE" : "PLANNED"}</span>
                        </Pill>
                    );
                },
            },
        ];
        const filteredColumns = columns.filter((column) => columnKeys.includes(column.key));
        setIncludedColumns(filteredColumns);
    }, [columnKeys, tooltipId, iconSize]);

    const handleDeleteAction = (scheduledAction) => {
        const { actionId, employeeId } = scheduledAction;
        deleteScheduledAction(actionId, employeeId).then(() => {
            toast.success("Action Deleted");
        });
    };

    const handleCompleteAction = (scheduledAction) => {
        const { actionId, employeeId } = scheduledAction;
        completeScheduledAction(actionId, employeeId).then(() => {
            toast.success("Action Completed");
        });
    };

    const handleReopenAction = (scheduledAction) => {
        const { actionId, employeeId } = scheduledAction;
        props.onHideAction(actionId);
        reopenScheduledAction(actionId, employeeId).then(() => {
            toast.success("Action Reopened");
        });
    };

    const expandedProps = {
        onCompleteAction: handleCompleteAction,
        onDeleteAction: handleDeleteAction,
        onReopenAction: handleReopenAction,
    };

    return (
        <BaseTable
            {...props}
            columns={includedColumns}
            checkbox={props.checkbox}
            pageIndex={pageIndex}
            onSetPageIndex={setPageIndex}
            expandable={props.expandable}
            expandedProps={expandedProps}
            expandComponent={ActionDropdown}
            removeBorder={props.removeBorder}
        />
    );
};

ScheduledActionsTable.propTypes = {
    data: PropTypes.array,
    columnKeys: PropTypes.array,
    className: PropTypes.string,
    hideHeader: PropTypes.bool,
    expandable: PropTypes.bool,
    includeEmployeeName: PropTypes.bool,
    removePadding: PropTypes.bool,
    scrollable: PropTypes.bool,
    removeBorder: PropTypes.bool,
    onSelectAction: PropTypes.func,
    onHideAction: PropTypes.func,
};

ScheduledActionsTable.defaultProps = {
    data: [],
    columnKeys: [],
    isChildTable: false,
    hideHeader: false,
    includeEmployeeName: false,
    scrollable: false,
    expandable: true,
    onSelectAction: () => {},
    onHideAction: () => {},
};

export default ScheduledActionsTable;
