import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_PREFERENCES, DEFAULT_WORKSPACE_PREFS, MANAGEMENT_BOARD_ID } from "../../constants";
import { removeNulls, yearsAgo } from "../../utils/basicUtils";
import { getSuccessionStrength } from "../../utils/roleUtils";
import { prepEmployeeSnapshot } from "../../utils/snapshotUtils";

// Add linkedTalentBoardIds to each trait & make active talentArea list
export const linkTraits = createAsyncThunk("talent/linkTraits", async (arg, thunkAPI) => {
    const state = thunkAPI.getState().talent;
    let activeTalentAreaIds = [];
    let newTraits = { ...state.traits };
    const talentBoards = state.talentBoards;
    const actions = state.actions;

    Object.entries(newTraits).forEach(([traitId, trait]) => {
        let newTrait = { ...trait };
        // Connect traits to Talent Boards
        activeTalentAreaIds = activeTalentAreaIds.concat(trait.talentAreaIds);
        const linkedTalentBoardIds = Object.values(talentBoards)
            .filter((talentBoard) => {
                return talentBoard.traitIds && talentBoard.traitIds.includes(trait.id);
            })
            .map((board) => board.id);
        newTrait = { ...newTrait, linkedTalentBoardIds: linkedTalentBoardIds };

        // Temp: remove inactive actionIds from traits (will move links to actions soon)
        let newActionIds = trait.actionIds && [...trait.actionIds];
        if (newActionIds) {
            newActionIds = newActionIds.filter((actionId) => Object.keys(actions).includes(actionId));
            newTrait = { ...newTrait, actionIds: newActionIds };
        }
        newTraits[traitId] = newTrait;
    });

    return {
        newTraits,
        activeTalentAreaIds: [...new Set(activeTalentAreaIds)],
    };
});

// Prep the snapshots
export const setSnapshots = createAsyncThunk("talent/setSnapshots", async (arg, thunkAPI) => {
    const talentState = thunkAPI.getState().talent;
    const orgState = thunkAPI.getState().org;
    const assessmentsState = thunkAPI.getState().assessments;
    const rawSnapshots = talentState.rawSnapshots;
    const talentBoards = talentState.talentBoards;
    const talentAreas = talentState.talentAreas;
    const employees = orgState.employees;
    const thresholdDate = assessmentsState?.settings?.thresholdDate;
    const changedSnapshotIds = talentState.changedSnapshotIds || [];
    let newSnapshots = { ...talentState.snapshots };
    changedSnapshotIds.forEach((empId) => {
        let snapshot = rawSnapshots[empId];
        const employee = employees[empId];
        const preppedSnapshot = prepEmployeeSnapshot(employee, thresholdDate, snapshot, talentAreas, talentBoards);
        if (preppedSnapshot) {
            newSnapshots[empId] = preppedSnapshot;
        }
    });
    // Remove snapshots that are no longer in the rawSnapshots
    Object.keys(newSnapshots).forEach((empId) => {
        if (!rawSnapshots[empId]) {
            delete newSnapshots[empId];
        }
    });

    return newSnapshots;
});

// Derive childRoles from parentRoleIds
// Derive roleIds on employees from incumbentIds on roles
// Set TalentBoardsIds
export const prepOrg = createAsyncThunk("org/prepOrg", async (arg, thunkAPI) => {
    const state = thunkAPI.getState().org;
    const talentState = thunkAPI.getState().talent;
    let newRoles = { ...state.rawRoles };
    let newEmployees = { ...state.rawEmployees };
    const activeTalentBoardsIds = Object.keys(talentState.talentBoards);

    // Remove any talentBoardsIds that are no longer active
    Object.entries(newRoles).forEach(([roleId, role]) => {
        let updatedRole = { ...role };
        if (updatedRole.talentBoardIds) {
            let talentBoardIds = updatedRole.talentBoardIds.filter((id) => activeTalentBoardsIds.includes(id));
            talentBoardIds = [...new Set(talentBoardIds)];
            updatedRole.talentBoardIds = talentBoardIds;
        }
        newRoles[roleId] = updatedRole;
    });

    Object.entries(newRoles).forEach(([roleId, role]) => {
        // Add the roleId && talentBoardIds to the employee
        if (role.incumbentId && newEmployees[role.incumbentId]) {
            let updatedIncumbent = {
                ...newEmployees[role.incumbentId],
                roleId: roleId,
                talentBoardIds: role.talentBoardIds,
            };
            newEmployees[role.incumbentId] = updatedIncumbent;
        }
        // Add the child roles for each role based on parentRoleIds
        let parent = newRoles[role.parentRoleId];
        if (parent) {
            let childRoles = parent.childRoles ? [...parent.childRoles] : [];
            childRoles.push(roleId);
            childRoles = [...new Set(childRoles)];
            const updatedParent = { ...parent, childRoles: childRoles };
            newRoles[role.parentRoleId] = updatedParent;
        }
        // Add the talentBoardIds the incumbent if there is one
        if (role.incumbentId && role.talentBoardIds) {
            let talentBoardIds = role.talentBoardIds;
            const updatedManager = { ...newEmployees[role.incumbentId], talentBoardIds: talentBoardIds };
            newEmployees[role.incumbentId] = updatedManager;
        }
    });

    // Adds the management talent board to any role (& incumbent) with child roles that hasn't manually removed it
    Object.values(newRoles)
        .filter((role) => !role.rootRole)
        .filter((role) => !role.noAutoAssignManagement)
        .filter((role) => role.childRoles && role.childRoles.length > 0)
        .forEach((role) => {
            let talentBoardIds = role.talentBoardIds ? [...role.talentBoardIds] : [];
            talentBoardIds.push(MANAGEMENT_BOARD_ID);
            talentBoardIds = [...new Set(talentBoardIds)];
            const updatedRole = { ...role, talentBoardIds: talentBoardIds };
            newRoles[role.id] = updatedRole;
            if (role.incumbentId) {
                const updatedManager = { ...newEmployees[role.incumbentId], talentBoardIds: talentBoardIds };
                newEmployees[role.incumbentId] = updatedManager;
            }
        });

    // Return the prepped values
    return {
        newRoles,
        newEmployees,
    };
});

export const setRoleSnapshots = createAsyncThunk("org/prepRoleSnapshots", async (arg, thunkAPI) => {
    const state = thunkAPI.getState().org;
    const { roleSnapshots, rawEmployees } = state;
    let allPlans = {};
    Object.entries(roleSnapshots).forEach(([roleId, snapshot]) => {
        const rawSuccessors = snapshot.successors || [];
        const successors = rawSuccessors.map((successor) => {
            // if successor d is at least 3 years ago
            const age = yearsAgo(successor.date);
            const employeeId = successor?.key;
            const employee = rawEmployees[employeeId];
            return {
                ...successor,
                yearsAgo: age,
                gender: employee?.gender,
            };
        });
        const femaleSuccessors = successors.filter((item) => item.gender === "f").length;
        let femaleRatio = Math.round((femaleSuccessors / successors.length) * 100);
        if (isNaN(femaleRatio)) {
            femaleRatio = 0;
        }
        const strength = getSuccessionStrength(successors);
        allPlans[roleId] = { strength: strength, successors: successors, femaleRatio: femaleRatio };
    });

    return {
        successionPlans: allPlans,
    };
});

// Add things as you go. Keeps the user settings aligned to the active workspace settings
// Relevant when switching workspaces, or when workspace features change
export const setSavedPreferences = createAsyncThunk("user/setSavedPreferences", async (arg, thunkAPI) => {
    const workspaceState = thunkAPI.getState().workspace;
    const userState = thunkAPI.getState().user;
    const { enabledModules, workspaceHasLoaded } = workspaceState;
    const { activeWorkspaceId } = userState;
    const allSavedPreferences = userState.preferences || {};

    let preppedPrefs = { ...DEFAULT_PREFERENCES, ...allSavedPreferences };

    // Ensure the nodeCategory is for an enabled module
    if (!enabledModules.successionPlanning) {
        preppedPrefs.nodeCategory = DEFAULT_PREFERENCES.nodeCategory;
    }

    // Ensure the activeOrgTableId is for the current workspace
    const workspacePrefs = preppedPrefs[activeWorkspaceId] || {};
    const updateWorkspacePrefs = { ...DEFAULT_WORKSPACE_PREFS, ...workspacePrefs };
    const orgTableConfig = updateWorkspacePrefs.orgTableConfig || {};
    let newOrgTableConfig = { ...orgTableConfig };
    const { tables = {}, activeOrgTableId } = newOrgTableConfig;
    const tableIds = Object.keys(tables);
    const tableOk = tableIds.includes(activeOrgTableId);
    if (tableOk) {
        preppedPrefs.activeOrgTableId = activeOrgTableId;
    } else {
        preppedPrefs.activeOrgTableId = DEFAULT_PREFERENCES.activeOrgTableId;
        newOrgTableConfig.activeOrgTableId = DEFAULT_PREFERENCES.activeOrgTableId;
        preppedPrefs[activeWorkspaceId] = updateWorkspacePrefs || {};
        preppedPrefs[activeWorkspaceId] = { ...updateWorkspacePrefs, orgTableConfig: newOrgTableConfig };
    }
    return {
        alignedToWorkspace: workspaceHasLoaded,
        preferences: removeNulls(preppedPrefs),
    };
});
