import React from "react";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import { createRoot } from "react-dom/client";
import { LanguageProvider } from "./translations/LanguageContext.js";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <LanguageProvider>
            <App />
        </LanguageProvider>
    </Provider>
);

reportWebVitals();
