import { lookEmployees, lookRoles, lookTrait } from "../redux/utils/looks";
import { cLog } from "../utils/basicUtils";
import { getUniqueTalentAreaIds, groupTraitsByTalentArea } from "../utils/talentUtils";

class TalentBoardModel {
    constructor(talentBoard) {
        if (!talentBoard || typeof talentBoard === "string") {
            cLog("TalentBoardModel received invalid value:", typeof talentBoard);
        } else {
            this.id = talentBoard.id;
            this._talentBoard = talentBoard;
        }
    }

    getTraits() {
        const traitIds = this._talentBoard.traitIds || [];
        return traitIds.map((traitId) => {
            return lookTrait(traitId);
        });
    }

    getLinkedRoles(idsOnly) {
        const allRoles = lookRoles();
        return Object.entries(allRoles)
            .filter(([roleId, role]) => {
                const talentBoardIds = role.talentBoardIds || [];
                return talentBoardIds.includes(this.id);
            })
            .map(([roleId, role]) => {
                return idsOnly ? roleId : role;
            });
    }

    getLinkedEmployees(idsOnly) {
        const allRoles = lookRoles();
        return Object.entries(allRoles)
            .filter(([roleId, role]) => {
                const talentBoardIds = role.talentBoardIds || [];
                const incumbentId = role.incumbentId;
                return incumbentId && talentBoardIds.includes(this.id);
            })
            .map(([roleId, role]) => {
                const empId = role.incumbentId;
                const employee = lookEmployees(empId);
                return idsOnly ? empId : employee;
            });
    }

    getTalentAreaIdsInBoard() {
        const allTraits = this.getTraits();
        return getUniqueTalentAreaIds(allTraits);
    }

    getTraitsGroupedByTalentArea() {
        const includedTraits = this.getTraits();
        const groupedByTalentArea = groupTraitsByTalentArea(includedTraits);
        return groupedByTalentArea;
    }
}

export default TalentBoardModel;
