import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTraitsToTalentBoard, removeTraitsFromTalentBoard } from "../../../firebase/actions/talentBoard";
import { setTrait } from "../../../firebase/actions/trait";
import { fetchTrait } from "../../../firebase/fetch";
import { TraitModel } from "../../../models";
import { lookTalentBoard } from "../../../redux/utils/looks";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import TraitForm from "./TraitForm";
import * as Yup from "yup";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { useLanguage } from "../../../translations/LanguageContext";

const EditTrait = (props) => {
    const [isWaiting, setIsWaiting] = useState(false);
    const selectedTraitId = useSelector((state) => state.app.selectedTraitId);
    const selectedTrait = useSelector((state) => state.talent.traits[selectedTraitId]);
    const [initialValues, setInitialValues] = useState();
    const { t } = useLanguage();

    useEffect(() => {
        fetchTrait(selectedTraitId).then((traitDoc) => {
            const traitModel = new TraitModel(traitDoc);
            const talentBoardIds = traitModel.featuredInBoards();
            setInitialValues({ ...traitDoc, talentBoardIds: talentBoardIds });
        });
    }, [selectedTraitId]);

    const handleSubmit = (values) => {
        setIsWaiting(true);
        const { talentBoardIds, ...traitUpdate } = values;
        const prevTalentBoardIds = selectedTrait.linkedTalentBoardIds || [];

        setTrait(traitUpdate)
            .then(() => {
                // Add traits to talentBoards if needed
                if (talentBoardIds && talentBoardIds.length > 0) {
                    talentBoardIds
                        .filter((boardId) => {
                            const talentBoard = lookTalentBoard(boardId);
                            const traitsAlreadyOnBoard = talentBoard.traitIds || [];
                            return !traitsAlreadyOnBoard.includes(selectedTraitId);
                        })
                        .forEach((boardId) => {
                            addTraitsToTalentBoard(boardId, [selectedTraitId]);
                        });
                }

                // Remove traits from talentBoards if needed
                prevTalentBoardIds
                    .filter((boardId) => !talentBoardIds.includes(boardId))
                    .forEach((boardId) => {
                        removeTraitsFromTalentBoard(boardId, [selectedTraitId]);
                    });

                // Close the modal
                props.toggle();
            })
            .catch((error) => {
                toast.error(t("trait_edit_failed", "toasts"));
            });
    };

    if (!initialValues) return <LoadingIndicator />;

    const validationSchemas = [
        {
            traitName: Yup.string()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.TRAIT_NAME, t("length_max", "warnings")),
            traitAlreadyExists: Yup.boolean().oneOf([false], t("name_in_use", "warnings")),
            talentAreaIds: Yup.array().min(1, t("talent_area_required", "warnings")),
        },
    ];

    return (
        <TraitForm
            {...props}
            isWaiting={isWaiting}
            validationSchemas={validationSchemas}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            header={selectedTrait.traitName}
        />
    );
};

export default EditTrait;
