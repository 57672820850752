import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaDna } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { EmptyState, TalentBoardPicker } from "..";
import { setLocalPreferences } from "../../redux/utils/dispatches";
import { lookTalentArea, lookTalentBoard, lookTalentBoards, lookTrait } from "../../redux/utils/looks";
import { getExactColor } from "../../utils/snapshotUtils";
import { RemovePill } from "../BadgesAndPills/Pills";
import AppButton, { HelpButton } from "../Buttons/MyButtons";
import { CrossIcon } from "../Icons/MyIcons";
import { CheckBox } from "../Inputs/Inputs";
import TraitWordCloud from "../WordClouds/TraitWordCloud";
import "./talent-strength.scss";

const BoardFilterModal = (props) => {
    const { ratings, talentBoardFilters, showModal, setShowModal, onSetFilters } = props;
    const [excludeBoardIds, setExcludeBoardIds] = useState([]);

    // Only allow filtering of talent boards that have trait ratings to show
    useEffect(() => {
        const allBoards = lookTalentBoards();
        let boardIds = [];

        for (const rating of ratings) {
            const trait = lookTrait(rating.id) || {};
            const incBoardIds = trait.linkedTalentBoardIds || [];
            boardIds = boardIds.concat(incBoardIds);
        }

        boardIds = [...new Set(boardIds)];

        const excludeIds = Object.values(allBoards)
            .filter((board) => !boardIds.includes(board.id))
            .map((board) => board.id);

        setExcludeBoardIds(excludeIds);
    }, [ratings]);

    return (
        <Modal centered isOpen={showModal} toggle={() => setShowModal(false)} size="md">
            <div className="d-flex flex-column shadow rounded">
                <div
                    className={classNames(
                        props.className,
                        "f-1 bg-white px pt-4 px-4 d-flex flex-column align-items-center justify-content-center border-bottom"
                    )}
                >
                    <FaDna size={32} className="c-primary " />
                    <h5 className="mt-2 mb-3">Trait Filters</h5>
                    <p className="text-center">
                        Traits that are included in any of the boards selected here will be included in the Word Cloud.
                    </p>
                </div>
                <div className={classNames("f-2 bg-light p-3 d-flex flex-column align-items-between")}>
                    <TalentBoardPicker
                        selectedIds={talentBoardFilters}
                        onChange={onSetFilters}
                        excludeIds={excludeBoardIds}
                    />
                </div>
                <div className="d-flex justify-content-end p-3">
                    <Button onClick={() => setShowModal(false)}>Done</Button>
                </div>
            </div>
        </Modal>
    );
};

const InfoPanel = ({ setShowInfo, goodColor, badColor }) => (
    <div className="f-1" style={{ position: "relative" }}>
        <AppButton
            onClick={() => setShowInfo(false)}
            className="outline"
            style={{ position: "absolute", right: 0, top: -3 }}
        >
            <CrossIcon />
        </AppButton>
        <h6>What does this mean?</h6>
        <hr />
        <ul className="bullet-points">
            <li className="mb-3">
                Word <span className="fw-bold">size</span> indicates{" "}
                <span className="fst-italic fw-bold">relative</span> strength. For example:
                <ul className="bullet-points mt-1">
                    <li>
                        If all ratings are between 50 - 70, a Trait rated 70 will be{" "}
                        <span className="fw-bold">large</span>
                    </li>
                    <li>
                        If all ratings are between 70 - 100, a Trait rated 70 will be{" "}
                        <span className="fw-bold">small</span>
                    </li>
                </ul>
            </li>
            <li>
                Word <span className="fw-bold">color</span> indicates{" "}
                <span className="fst-italic fw-bold">absolute</span> strength:
                <ul className="bullet-points mt-1">
                    <li>
                        Ratings of 1-10 will always show as <span style={{ color: badColor }}>Red</span>
                    </li>
                    <li>
                        Ratings of 90-100 will always show as <span style={{ color: goodColor }}>Green</span>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
);

const TalentAreaSection = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const { talentAreaId, rating, selected } = props;
    const talentArea = talentAreaId && lookTalentArea(talentAreaId);

    const ratingColor = getExactColor(rating);

    const bannerStyle = {
        height: 10,
        backgroundColor: ratingColor,
    };

    const tabStyle = {
        backgroundColor: selected ? "#E9E9E9" : isHovered ? "#f5f5f5" : "white",
    };

    return (
        <Col
            sm={3}
            className={classNames("talent-area-selector pt-3 px-0 clickable d-flex align-items-center", {
                selected: selected,
                hovered: isHovered,
            })}
            style={tabStyle}
            onClick={props.onSelect}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="d-flex flex-column justify-content-between f-1 h-100">
                <div className="px-1 d-flex align-items-center">
                    <h6 className="f-1 mb-3 fs-tiny fw-bold text-center">{talentArea.talentAreaName}</h6>
                </div>
                <div style={bannerStyle} />
            </div>
        </Col>
    );
};

const TalentStrength = (props) => {
    const { talentAreaRatings, ratings, wordCloudId } = props;
    const [filteredRatings, setFilteredRatings] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [selectedTalentAreaId, setSelectedTalentAreaId] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [talentBoardFilters, setTalentBoardFilters] = useState([]);
    const [globalOnly, setGlobalOnly] = useState(props.globalOnly);
    const wordCloudPrefs = useSelector((state) => state.user.localPreferences.wordClouds);
    const savedFilters = wordCloudPrefs[wordCloudId];
    const goodColor = getExactColor(100);
    const badColor = getExactColor(1);

    // Ensure the saved filters relate to live boards
    useEffect(() => {
        const liveFilters = savedFilters.filter((boardId) => {
            const board = lookTalentBoard(boardId);
            return !!board;
        });
        setTalentBoardFilters(liveFilters);
    }, [savedFilters]);

    useEffect(() => {
        let filteredRatings = [...ratings];

        // Filter talent board if filters have been added
        if (talentBoardFilters && talentBoardFilters.length > 0) {
            let includedTraits = [];
            talentBoardFilters.forEach((filter) => {
                const talentBoard = lookTalentBoard(filter);
                if (talentBoard) {
                    includedTraits = includedTraits.concat(talentBoard.traitIds);
                }
            });
            filteredRatings = filteredRatings.filter((rating) => {
                return includedTraits.includes(rating.id);
            });
        } else if (globalOnly) {
            filteredRatings = filteredRatings.filter((rating) => {
                const trait = lookTrait(rating.id);
                return trait && !trait.boardsOnly;
            });
        }

        if (selectedTalentAreaId) {
            filteredRatings = filteredRatings.filter((rating) => {
                const trait = lookTrait(rating.id);
                return trait && trait.talentAreaIds.includes(selectedTalentAreaId);
            });
        }
        setFilteredRatings(filteredRatings);
    }, [ratings, talentBoardFilters, selectedTalentAreaId, globalOnly]);

    const handleSelectTalentArea = (talentAreaId) => {
        if (talentAreaId === selectedTalentAreaId) {
            setSelectedTalentAreaId(false);
        } else {
            setSelectedTalentAreaId(talentAreaId);
        }
    };

    const handleSetFilters = (newFilters) => {
        setLocalPreferences({ wordClouds: { ...wordCloudPrefs, [wordCloudId]: newFilters } });
    };

    const handleRemoveFilter = (filter) => {
        const newFilters = talentBoardFilters.filter((f) => f !== filter);
        setLocalPreferences({ wordClouds: { ...wordCloudPrefs, [wordCloudId]: newFilters } });
    };

    const isEmpty = Object.keys(talentAreaRatings).length === 0;

    talentAreaRatings.sort((a, b) => b.rating - a.rating);

    const renderEmptyState = () => (
        <EmptyState
            className="border-all"
            header="No Rating Data"
            subHeader="Data will be shown when your first assessments have been collected."
            {...props}
        />
    );

    const renderWordCloud = () => (
        <TraitWordCloud isPending={props.isPending} ratings={filteredRatings} maxSize={props.maxWordSize} />
    );

    const renderInfoPanel = () => <InfoPanel setShowInfo={setShowInfo} goodColor={goodColor} badColor={badColor} />;

    return (
        <div
            className={classNames(props.className, "overflow-y talent-strength f-1 d-flex", {
                "flex-column": !props.sideView,
            })}
            style={{ minHeight: props.minHeight }}
        >
            <BoardFilterModal
                talentBoardFilters={talentBoardFilters}
                showModal={showModal}
                setShowModal={setShowModal}
                onSetFilters={handleSetFilters}
                ratings={ratings}
            />
            <Container fluid>
                <Row>
                    {talentAreaRatings.map((entry) => (
                        <TalentAreaSection
                            key={entry.id}
                            rating={entry.rating}
                            talentAreaId={entry.id}
                            selected={selectedTalentAreaId === entry.id}
                            onSelect={() => handleSelectTalentArea(entry.id)}
                            rowView={!props.column}
                        />
                    ))}
                </Row>
            </Container>
            <div className="border-all ratings-container f-5 d-flex align-items-center justify-content-center p-3">
                {isEmpty && renderEmptyState()}
                {!isEmpty && !showInfo && renderWordCloud()}
                {!isEmpty && showInfo && renderInfoPanel()}
            </div>
            <div className="d-flex py-2 px-3 fs-tiny bg-light fw-bold c-light d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    {talentBoardFilters.map((boardId) => {
                        const board = lookTalentBoard(boardId);
                        const boardName = board ? board.talentBoardName : "Unknown";
                        return (
                            <RemovePill
                                key={boardId}
                                className="border-all me-2"
                                onClose={() => handleRemoveFilter(boardId)}
                            >
                                {boardName}
                            </RemovePill>
                        );
                    })}
                    {talentBoardFilters.length === 0 && (
                        <CheckBox
                            className="me-2"
                            checked={globalOnly}
                            onClick={setGlobalOnly}
                            label="Exclude Board Traits"
                        />
                    )}
                </div>
                <div className="d-flex align-items-center">
                    <Button
                        className="text-only fs-tiny"
                        style={{ width: 160 }}
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        ADD BOARD FILTER
                    </Button>
                    <HelpButton size={18} onClick={() => setShowInfo(!showInfo)} />
                </div>
            </div>
        </div>
    );
};

TalentStrength.propTypes = {
    talentAreaRatings: PropTypes.array,
    ratings: PropTypes.array,
    sideView: PropTypes.bool,
    maxWordSize: PropTypes.number,
    className: PropTypes.string,
    minHeight: PropTypes.number,
};

TalentStrength.defaultProps = {
    talentAreaRatings: {},
    minHeight: 320,
    ratings: {},
    maxWordSize: 32,
};

export default TalentStrength;
