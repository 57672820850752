import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { LoadingIconDots, LoadingIconPuff, LoadingIconTriangle } from "../Icons/MyIcons";
import "./loading-indicator.scss";

const Loading = (props) => {
    return (
        <div
            className={classNames("loading-indicator", props.className, { fullscreen: props.fullscreen })}
            data-testid="loading"
            role="progressbar"
        >
            {props.type === "triangle" && <LoadingIconTriangle />}
            {props.type === "puff" && <LoadingIconPuff />}
            {props.type === "dots" && <LoadingIconDots />}
            {props.type === "loadingProgress" && <LoadingIconDots />}
            {props.message && <span className="fw-lighter fs-4 text-center">{props.message}</span>}
        </div>
    );
};

Loading.propTypes = {
    type: PropTypes.oneOf(["triangle", "puff", "dots", "loadingProgress"]),
    message: PropTypes.string,
};

Loading.defaultProps = {
    type: "triangle",
};

export default Loading;
