import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import "./floating-action-buttons.scss";
import { OrgButton } from "../Buttons/MyButtons";

const FloatingActionButtons = (props) => {
    return (
        <nav className={classNames("floating-action-button-container", props.className)}>
            {React.Children.map(props.children, (child) =>
                React.cloneElement(child, {
                    className: classNames("floating-action-button", {
                        disabled: props.disabled,
                        "fully-hidden": child.props.hide || child.props.disabled,
                    }),
                    size: props.size,
                    tooltipId: props.tooltipId,
                })
            )}
            <OrgButton
                className={classNames("floating-action-button", { disabled: props.disabled })}
                size={props.size}
            />
        </nav>
    );
};

FloatingActionButtons.propTypes = {
    size: PropTypes.number,
    tooltipId: PropTypes.string,
};

FloatingActionButtons.defaultProps = {
    size: 18,
};

export default FloatingActionButtons;
