import React from "react";
import { useSelector } from "react-redux";
import { setFirstEmployee } from "../../firebase/actions/employee";
import { updateUserDoc } from "../../firebase/actions/user";
import { configureWorkspace } from "../../firebase/actions/workspace";
import SignInPanel from "../../pages/SignIn/SignInPanel";
import { CreateWorkspace } from "../Forms";

const ConfigureWorkspace = () => {
    const userEmail = useSelector((state) => state.user.email);
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const workspace = useSelector((state) => state.workspace);
    const handleConfigureWorkspace = (formValues) => {
        const userInfo = {
            email: userEmail,
            firstname: formValues.firstname,
            surname: formValues.surname,
            preferences: {
                [activeWorkspaceId]: {
                    nodeView: {
                        fieldId: "overall",
                        label: "Overall",
                        value: "overall",
                    },
                },
            },
        };
        updateUserDoc(userInfo);
        setFirstEmployee(workspace.ownerId, {
            firstname: formValues.firstname,
            surname: formValues.surname,
        });
        configureWorkspace(formValues.workspaceName);
    };

    return (
        <div className="d-flex sign-in-layout bg-light py-5">
            <div className="f-1" />
            <div className="f-5 d-flex bg-white shadow rounded py-3 px-5">
                <SignInPanel
                    title="Configure Your Workspace"
                    description="Please enter your name and the name of your workspace."
                    signInStatus="signed-in"
                >
                    <CreateWorkspace onSubmit={handleConfigureWorkspace} />
                </SignInPanel>
            </div>
            <div className="f-1" />
        </div>
    );
};

export default ConfigureWorkspace;
