import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import SuccessionBoard from "../../../../../components/SuccessionBoard/SuccessionBoard";
import { getChildRoleIds } from "../../../../../utils/roleUtils";

const SuccessionPlanningTab = () => {
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const successionPlans = useSelector((state) => state.org.successionPlans);
    const roleIds = useMemo(() => getChildRoleIds(selectedRoleId, true), [selectedRoleId]);
    return (
        <div className="inner-container">
            <SuccessionBoard
                boardTopRoleId={selectedRoleId}
                roleIds={roleIds}
                successionPlans={successionPlans}
                departmentView
                excludeOptionIds={[selectedEmployeeId]}
            />
        </div>
    );
};

export default SuccessionPlanningTab;
