import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { RoleModel } from "../../../../../models";
import { getContrastColor } from "../../../../../utils/basicUtils";
import { getChildRoleIds } from "../../../../../utils/roleUtils";
import { getExactColor } from "../../../../../utils/snapshotUtils";
import "../org.scss";

const SuccessionIndicator = ({ roleId, size, tooltipId }) => {
    const [rating, setRating] = useState(false);
    const localPreferences = useSelector((state) => state.user.localPreferences);
    const successionPlans = useSelector((state) => state.org.successionPlans);
    const thisRole = useSelector((state) => state.org.roles[roleId]);
    const preferences = useSelector((state) => state.user.localPreferences);
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const successionView = preferences?.[activeWorkspaceId]?.successionView;
    const roleModel = new RoleModel(thisRole, "AssessmentIndicator-1");
    const { ratingFilter } = localPreferences;
    const hasChildren = roleModel.hasChildren();
    const branchView = ratingFilter === "branch";
    const showAverage = branchView && hasChildren;

    // Set the rating color
    useEffect(() => {
        if (!showAverage) {
            const successionPlan = successionPlans[roleId];
            // When in Employee View, or node has no children
            if (successionPlan) {
                setRating(successionPlan[successionView]);
            } else {
                setRating(1);
            }
        } else {
            // When in Brnach View and Node has children
            const subordinateRoles = getChildRoleIds(roleId, true);
            let allValues = [];
            subordinateRoles.forEach((subordinateRoleId) => {
                const subSuccessionPlan = successionPlans[subordinateRoleId];
                if (subSuccessionPlan) {
                    allValues.push(subSuccessionPlan[successionView]);
                } else {
                    allValues.push(1);
                }
            });

            // average of the values
            const sum = allValues.reduce((a, b) => a + b, 0);
            const avg = sum / allValues.length || 1;
            const rounded = Math.round(avg);
            setRating(rounded);
        }
    }, [roleId, showAverage, hasChildren, successionPlans, successionView]);

    const ratingColor = getExactColor(rating);
    const contrastColor = getContrastColor(ratingColor);
    const style = { backgroundColor: ratingColor, color: contrastColor };

    return (
        <div
            data-html={true}
            data-for={tooltipId}
            className={classNames("rating-progress-indicator box shadow-sm")}
            style={{ width: size, height: size }}
        >
            <div className="indicator-child-container">
                <div className={classNames("indicator-child fs-tiny")} style={style}>
                    {rating === 1 ? 0 : rating}
                </div>
            </div>
        </div>
    );
};

SuccessionIndicator.propTypes = {
    activeRole: PropTypes.object,
    ratingFilter: PropTypes.string,
    ratingView: PropTypes.object,
    isSelected: PropTypes.bool,
    tableView: PropTypes.bool,
    showProgress: PropTypes.bool,
    size: PropTypes.number,
};

SuccessionIndicator.defaultProps = {
    size: 20,
};

export default memo(SuccessionIndicator);
