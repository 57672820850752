import classNames from "classnames";
import PropTypes from "prop-types";
import SummaryTile from "./SummaryTile";
import SegmentView from "../../../pages/People/Views/Role/Views/SegmentView";
import { getExactColor } from "../../../utils/snapshotUtils";
import { getContrastColor } from "../../../utils/basicUtils";
import { useResizeDetector } from "react-resize-detector";
import { useEffect, useState } from "react";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";

const GridTile = ({ segment, bigTile, onItemClick, onSegmentClick }) => {
    const { height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const { tile, cohort } = segment;
    const [maxEmployees, setMaxEmployees] = useState();
    const backgroundColor = getExactColor(tile?.color);
    const color = getContrastColor(backgroundColor);

    useEffect(() => {
        // wait a split second before recalculating the max employees
        // this is to prevent the grid from being recalculated on every resize
        const timeout = setTimeout(() => {
            const itemHeight = 30;
            const itemsPerRow = 3;
            const numberOfRows = Math.floor(height / itemHeight);
            setMaxEmployees(numberOfRows * itemsPerRow);
        }, 250);

        return () => clearTimeout(timeout);
    }, [height]);

    const showSummary = cohort.length > maxEmployees;

    const headerStyle = {
        backgroundColor: backgroundColor,
        color: color,
        fontWeight: "bold",
    };

    const renderContent = () => {
        if (!maxEmployees) return <LoadingIndicator />;
        if (showSummary) return <SummaryTile bigTile={bigTile} segment={segment} onClick={onSegmentClick} />;
        return <SegmentView simpleList segment={segment} onItemClick={onItemClick} />;
    };

    return (
        <div ref={ref} className="summary-tile rounded-4 overflow-hidden f-1 flex-col">
            <div className="header p-2" style={headerStyle}>
                {`${tile.title} (${cohort.length})`}
            </div>
            {renderContent()}
        </div>
    );
};

const GridColumn = ({ column, onItemClick, onSegmentClick }) => {
    const bigTile = column.length === 1;
    return (
        <div className={classNames("talent-map-column")}>
            {column.map((segment, i) => {
                const { x, y } = segment;
                return (
                    <GridTile
                        bigTile={bigTile}
                        key={`${x}-${y}`}
                        segment={segment}
                        onItemClick={onItemClick}
                        onSegmentClick={onSegmentClick}
                    />
                );
            })}
        </div>
    );
};

const CustomGrid = ({ grid, onItemClick, onSegmentClick }) => {
    return (
        <div className="talent-map-grid">
            {grid.map((col, i) => (
                <GridColumn key={`fbg-c-${i}`} column={col} onItemClick={onItemClick} onSegmentClick={onSegmentClick} />
            ))}
        </div>
    );
};

CustomGrid.propTypes = {
    grid: PropTypes.array,
    filter: PropTypes.string,
    config: PropTypes.object,
    snapshots: PropTypes.object,
};

CustomGrid.defaultProps = {
    grid: [],
    config: {},
    snapshots: {},
};

export default CustomGrid;
