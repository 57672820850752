import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Input, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { COLORS } from "../../constants";
import { InfoIcon } from "../Icons/MyIcons";
import { EmployeePicker } from "../Pickers";
import IdPicker from "../Pickers/IdPickers/IdPicker";
import Switch from "../Switch/Switch";
import "./custom-field.scss";

const bulletStyle = { listStyleType: "none" };

const CustomField = ({ field, value, className, excludeFromOptions, vertical, disabled, flat, onChange }) => {
    const { type } = field;
    const [showInfo, setShowInfo] = useState(false);
    const [infoBlocks, setInfoBlocks] = useState([]);
    const [fieldInfo, setFieldInfo] = useState([]);

    useEffect(() => {
        const options = field.options;
        const fieldInfo = field.fieldInfo;
        if (options) {
            let newInfoBlocks = options.map((option) => {
                return option.infoBlocks;
            });
            newInfoBlocks = newInfoBlocks.filter((block) => block !== undefined);
            setInfoBlocks(newInfoBlocks);
        } else if (fieldInfo) {
            setFieldInfo(fieldInfo);
        }
    }, [field]);

    const handleChange = (update) => {
        onChange(field.id, update);
    };

    const renderField = () => {
        switch (type) {
            case "switch":
                return (
                    <Switch
                        disabled={disabled}
                        onColor={field.onIsBad ? COLORS.BAD : COLORS.GOOD}
                        neutral
                        label={field.label}
                        checked={value}
                        onChange={handleChange}
                        vertical={vertical}
                        flat={flat}
                    />
                );
            case "picker":
                return (
                    <IdPicker
                        idField="value"
                        label={field.label}
                        options={field.options}
                        selectedIds={Array.isArray(value) ? value : [value]}
                        onChange={handleChange}
                        disabled={disabled}
                        unsorted
                    />
                );
            case "employeePicker":
                return (
                    <EmployeePicker
                        label={field.label}
                        isMulti
                        includeAll
                        includeOrphans
                        excludeIds={excludeFromOptions}
                        maxMenuHeight={140}
                        selectedIds={value || []}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                );
            case "input":
                return (
                    <Input
                        type={field.format}
                        name={field.id}
                        onChange={(e) => handleChange(e.target.value)}
                        value={value}
                        autoComplete="off"
                    />
                );
            default:
                return null;
        }
    };

    const renderPopover = () => (
        <>
            <InfoIcon
                id={field.id}
                className="clickable c-primary"
                size={12}
                onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
            />
            <Popover
                className="custom-picker-popover"
                placement="bottom"
                isOpen={showInfo}
                target={field.id}
                toggle={() => setShowInfo((prev) => !prev)}
            >
                <PopoverHeader>{`${field.label} Info`}</PopoverHeader>
                <PopoverBody className="scrollable">
                    {infoBlocks.map((optionBlock, i) => {
                        return (
                            <div key={`info-group-${i}`}>
                                <h6 className="fs-small">{field.options[i].label}</h6>
                                <ul style={bulletStyle} className="fs-tiny">
                                    {optionBlock.map((infoBlock, ii) => (
                                        <li key={`info-block-${i}-${ii}`} style={bulletStyle}>
                                            {infoBlock}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
                    <ul>
                        {fieldInfo.map((block, i) => {
                            return (
                                <li style={{ listStyleType: "disc" }} key={`info-group-${i}`}>
                                    {block}
                                </li>
                            );
                        })}
                    </ul>
                </PopoverBody>
            </Popover>
        </>
    );

    return (
        <div className={classNames(className, "custom-field d-flex")}>
            {renderField()}
            {(infoBlocks.length > 0 || field.fieldInfo) && renderPopover()}
        </div>
    );
};

export default CustomField;
