import classNames from "classnames";
import PropTypes from "prop-types";
import Table from "rc-table";
import React, { useEffect, useMemo, useState } from "react";
import { FaTable } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { EmptyState } from "..";
import { SET_CHECKED_IDS } from "../../redux/appSlice";
import { sortArrayOfObjects, toggleArrayContains } from "../../utils/basicUtils";
import { createXLSXFile } from "../../utils/exportUtils";
import { BackToStartButton, DownloadButton, ExpandButton, SkipToEndButton, SortButton } from "../Buttons/MyButtons";
import { CheckBox } from "../Inputs/Inputs";
import "./tables.scss";

const LastColumnHeader = ({ tableData, columns, tooltipId }) => {
    const excludeColumns = ["userBadge", "edit", "checkbox"];
    const handleClick = () => {
        const exportableColumns = columns.filter((col) => !excludeColumns.includes(col.key));
        createXLSXFile(tableData, exportableColumns, "org-table-export.xlsx");
    };
    return <DownloadButton onClick={handleClick} className="me-3" tooltipId={tooltipId} tooltip="Export" />;
};

const SortHeader = ({ column, sortKey, sortDesc, onSetKey, onSetDesc, onSetParentKey }) => {
    const { noSort, title, key, parentKey } = column;
    const selected = sortKey === key;
    if (title && !noSort) {
        return (
            <div
                onClick={() => {
                    onSetDesc(selected ? !sortDesc : false);
                    onSetKey(key);
                    onSetParentKey(parentKey);
                }}
                className={classNames("d-flex justify-content-between clickable", { "c-primary": selected })}
            >
                <span>{title}</span>
                <SortButton
                    size={18}
                    className={classNames("ms-1 c-primary", { "fully-hidden": !selected })}
                    checked={sortDesc && selected}
                />
            </div>
        );
    } else {
        return <div>{title}</div>;
    }
};

const CheckAllField = ({ tableId, visibleData }) => {
    const [checked, setChecked] = useState();
    const [visibleIds, setVisibleIds] = useState([]);
    const dispatch = useDispatch();
    const checkedIds = useSelector((state) => state.app.checkedIds[tableId]);

    useEffect(() => {
        const visibleIds = visibleData.map((entry) => entry.id);
        const unchecked = visibleIds.filter((id) => !checkedIds || !checkedIds.includes(id));
        setVisibleIds(visibleIds);
        setChecked(checkedIds && unchecked.length === 0);
    }, [visibleData, checkedIds]);

    return (
        <CheckBox
            checked={checked}
            onClick={() => {
                const newChecked = checked ? [] : visibleIds;
                dispatch(SET_CHECKED_IDS({ [tableId]: newChecked }));
            }}
        />
    );
};

const CheckBoxField = ({ tableId, recordId }) => {
    const dispatch = useDispatch();
    const checkedIds = useSelector((state) => state.app.checkedIds[tableId]);
    return (
        <CheckBox
            checked={checkedIds && checkedIds.includes(recordId)}
            onClick={() => {
                const newChecked = toggleArrayContains(checkedIds, recordId);
                dispatch(SET_CHECKED_IDS({ [tableId]: newChecked }));
            }}
        />
    );
};

const Pagination = (props) => {
    const dispatch = useDispatch();
    const { tableId, pageCount, pageIndex } = props;
    const enablePreviousButton = pageIndex > 0;
    const enableNextButton = pageIndex < pageCount - 1;
    let pageNumbers = [];
    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }
    const startIdx = Math.max(0, pageIndex - 2);
    const endIdx = Math.min(pageCount, startIdx + 5);

    let visiblePageNumbers = pageNumbers.slice(startIdx, endIdx);

    const handleButtonClick = (newPageIndex) => {
        dispatch(SET_CHECKED_IDS({ [tableId]: [] }));
        props.onRowClick();
        props.onSelectPage(newPageIndex);
    };

    return (
        <div className={classNames("pagination py-2 d-flex justify-content-center")}>
            <BackToStartButton outline disabled={!enablePreviousButton} onClick={() => handleButtonClick(0)} />
            <div className="px-3 d-flex justify-content-center">
                {visiblePageNumbers.map((pageNumber) => {
                    return (
                        <Button
                            key={`pagination-${pageNumber}`}
                            onClick={() => handleButtonClick(pageNumber)}
                            outline
                            className={classNames("pagination-button", { selected: pageIndex === pageNumber })}
                        >
                            {pageNumber + 1}
                        </Button>
                    );
                })}
            </div>
            <SkipToEndButton outline disabled={!enableNextButton} onClick={() => handleButtonClick(pageCount - 1)} />
        </div>
    );
};

const BaseTable = (props) => {
    let {
        columns,
        tableId,
        tooltipId,
        checkbox,
        sortable,
        selectedId,
        data,
        rowsPerPage,
        filteredIds,
        pendingChangeIds,
        pageIndex,
        onSetPageIndex,
        exportable,
    } = props;
    const [expanded, setExpanded] = useState(props.defaultExpandedRowKeys);
    const [sortDesc, setSortDesc] = useState(false);
    const [sortKey, setSortKey] = useState(props.sortKey);
    const [parentKey, setParentKey] = useState();
    const checkedIds = useSelector((state) => state.app.checkedIds[tableId]);

    // Filter the data to include only items in the filteredId or checkedId list
    const tableData = useMemo(() => {
        let sortedData = [];
        let filteredData = data
            .filter(
                (item) => !filteredIds || filteredIds.includes(item.id) || (checkedIds && checkedIds.includes(item.id))
            )
            .map((item) => {
                return { ...item, key: item.key || item.id };
            });
        if (sortKey) {
            sortedData = sortArrayOfObjects(filteredData, sortKey, sortDesc, parentKey);
        }
        return sortKey ? sortedData : filteredData;
    }, [data, filteredIds, checkedIds, sortKey, sortDesc, parentKey]);

    // Workout how many pages there will be based on the filtered data and rows per page
    const pageCount = useMemo(() => {
        return Math.ceil(tableData.length / rowsPerPage);
    }, [tableData, rowsPerPage]);

    // Set the active page data.
    const visibleData = useMemo(() => {
        const startIdx = Math.floor(pageIndex * rowsPerPage);
        const endIdx = startIdx + rowsPerPage;
        var visibleRange = tableData.slice(startIdx, endIdx);
        return visibleRange;
    }, [tableData, pageIndex, rowsPerPage]);

    // Prep columns
    const tableColumns = useMemo(() => {
        // Add Checkboxes is selected
        let newColumns = [...columns];
        if (checkbox) {
            newColumns.unshift({
                title: (
                    <div>
                        <CheckAllField tableId={tableId} visibleData={visibleData} />
                    </div>
                ),
                key: "checkbox",
                dataIndex: "checkbox",
                noSort: true,
                width: "1%",
                render: (label, record, index) => <CheckBoxField tableId={tableId} recordId={record.id} />,
            });
        }

        // Add sort headers if selected
        if (sortable) {
            newColumns = newColumns.map((column) => {
                const titleText = column.title;
                return {
                    ...column,
                    titleText: titleText,
                    title: (
                        <SortHeader
                            column={column}
                            sortDesc={sortDesc}
                            sortKey={sortKey}
                            onSetKey={setSortKey}
                            onSetDesc={setSortDesc}
                            onSetParentKey={setParentKey}
                        />
                    ),
                };
            });
        }

        // Add export menu is exportable
        if (exportable) {
            const lastColumn = newColumns.pop();
            newColumns.push({
                ...lastColumn,
                width: "1%",
                title: <LastColumnHeader tableData={tableData} columns={newColumns} tooltipId={tooltipId} />,
            });
        }
        return newColumns;
    }, [columns, tableData, checkbox, tableId, tooltipId, sortable, exportable, sortDesc, sortKey, visibleData]);

    // If there is a selectedItem, move to the page that contains it
    useEffect(() => {
        if (selectedId) {
            const selectedIsVisible = visibleData.findIndex((item) => item.id === selectedId);
            if (selectedIsVisible < 0) {
                const indexOfSelected = tableData.findIndex((item) => item.id === selectedId);
                let newPageIndex = Math.ceil(indexOfSelected / rowsPerPage) - 1;
                onSetPageIndex(Math.max(0, newPageIndex), true);
            }
        }
    }, [tableData, visibleData, selectedId, rowsPerPage, onSetPageIndex]);

    const isExpanded = (recordId) => {
        return expanded.includes(recordId);
    };

    const CustomExpandIcon = (props) => {
        const { record, onExpand } = props;
        return (
            <ExpandButton
                className="mx-3"
                round={false}
                onClick={() => onExpand(record)}
                size={20}
                checked={isExpanded(record.key)}
            />
        );
    };

    const onExpand = (isExpanded, record) => {
        if (!props.allowMultipleExpand) {
            setExpanded(isExpanded ? [record.key] : []);
        } else {
            const position = expanded.indexOf(record.key);
            let newExpanded = expanded.slice();
            if (position !== -1) {
                newExpanded.splice(position, 1);
            } else {
                newExpanded = [...expanded, record.key];
            }
            setExpanded(newExpanded);
        }
    };

    const handleRowClick = (record, index) => {
        const hasPendingChanges = pendingChangeIds && pendingChangeIds.includes(record.id);
        if (!hasPendingChanges) props.onRowClick(record.id);
    };

    const handleRowDoubleClick = (record, index) => {
        const hasPendingChanges = pendingChangeIds && pendingChangeIds.includes(record.id);
        if (!hasPendingChanges) props.onRowDoubleClick(record.id);
    };

    const handleSelectPage = (pageIndex) => {
        props.onSetPageIndex(pageIndex);
    };

    const handleGetRowClassName = (record, index) => {
        const awaiting = pendingChangeIds && pendingChangeIds.includes(record.id);
        const disabled = props.isDisabled(record) || awaiting;
        return classNames(props.rowClassName, {
            "selected-row": props.selectableRows && props.selectedId === record.id,
            disabled,
            awaiting,
            clickable: props.selectableRows,
        });
    };

    const showPagination = tableData.length > rowsPerPage;
    const { height, width } = props.style || {};
    let containerStyle = {};
    let tableStyle = {};
    if (!isNaN(height) && !isNaN(width)) {
        containerStyle = { height, width };
        tableStyle = { height: height - 50, width };
    }

    return (
        <div className="table-container" style={containerStyle}>
            <Table
                className={classNames("base-table", props.className, {
                    "wide-padding": props.widePadding,
                    "remove-border": props.removeBorder,
                })}
                style={tableStyle}
                columns={tableColumns}
                data={visibleData}
                showHeader={!props.hideHeader}
                expandable={
                    props.expandable && {
                        expandedRowRender: (record) => (
                            <props.expandComponent
                                record={record}
                                expanded={expanded.includes(record.key)}
                                {...props.expandedProps}
                            />
                        ),
                        defaultExpandAllRows: props.defaultExpandAllRows,
                        onExpand,
                        expandIcon: (record) => CustomExpandIcon(record),
                    }
                }
                expandedRowKeys={expanded}
                onRow={(record, index) => ({
                    onClick: handleRowClick.bind(null, record, index),
                    onDoubleClick: handleRowDoubleClick.bind(null, record, index),
                })}
                rowClassName={handleGetRowClassName}
                emptyText={() => (
                    <EmptyState header={props.emptyStateMessage} noPadding={props.noEmptyStatePadding}>
                        <FaTable size={42} />
                    </EmptyState>
                )}
            />
            {showPagination && (
                <Pagination
                    tableId={tableId}
                    tableData={tableData}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    onSelectPage={handleSelectPage}
                    onRowClick={props.onRowClick}
                />
            )}
        </div>
    );
};

BaseTable.propTypes = {
    tableId: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array,
    filteredIds: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    pendingChangeIds: PropTypes.array,
    checkedIds: PropTypes.array,
    widePadding: PropTypes.bool,
    selectedId: PropTypes.string,
    expandable: PropTypes.bool,
    checkbox: PropTypes.bool,
    hideHeader: PropTypes.bool,
    pagination: PropTypes.bool,
    sortKey: PropTypes.string,
    sortable: PropTypes.bool,
    sortDesc: PropTypes.bool,
    selectableRows: PropTypes.bool,
    allowMultipleExpand: PropTypes.bool,
    rowsPerPage: PropTypes.number,
    pageIndex: PropTypes.number,
    expandComponent: PropTypes.elementType,
    scrollable: PropTypes.bool,
    removeBorder: PropTypes.bool,
    emptyStateMessage: PropTypes.string,
    rowClassName: PropTypes.string,
    onExpand: PropTypes.func,
    onRowClick: PropTypes.func,
    onToggleChecked: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    isDisabled: PropTypes.func,
    onSetPageIndex: PropTypes.func,
    onDeselect: PropTypes.func,
};

BaseTable.defaultProps = {
    tableId: "table",
    data: [],
    columns: [],
    checkedIds: [],
    pendingChangeIds: [],
    pageIndex: 0,
    rowsPerPage: 30,
    defaultExpandedRowKeys: [],
    sortable: true,
    emptyStateMessage: "No Data",
    rowClassName: "",
    onExpand: () => {},
    onRowClick: () => {},
    onRowDoubleClick: () => {},
    onToggleChecked: () => {},
    isDisabled: () => {},
    onSetPageIndex: () => {},
    onDeselect: () => {},
};

export default BaseTable;
