import React from "react";
import { withFormik } from "formik";
import { Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { updateUserDoc } from "../../firebase/actions/user";
import { useSelector } from "react-redux";
import { setEmployee } from "../../firebase/actions/employee";

const ProfileInnerForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    dirty,
}) => {
    return (
        <Form onSubmit={handleSubmit} className="f-1 d-flex flex-column justify-content-between">
            <div className="form-container">
                <h3>Your Profile</h3>
                <div className="divider" />
                <FormGroup>
                    <Label for="boardName">Email</Label>
                    <Input type="text" name="email" value={values.email || ""} autoComplete="off" readOnly />
                </FormGroup>
                <FormGroup>
                    <Label for="firstname">Firstname</Label>
                    <Input
                        type="text"
                        name="firstname"
                        maxLength="80"
                        invalid={errors.firstname && touched.firstname}
                        placeholder="firstname.."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname || ""}
                    />
                    <FormFeedback>{errors.firstname}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="boardName">Surname</Label>
                    <Input
                        type="text"
                        name="surname"
                        maxLength="80"
                        invalid={errors.surname && touched.surname}
                        placeholder="surname..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.surname || ""}
                        autoComplete="off"
                    />
                    <FormFeedback>{errors.surname}</FormFeedback>
                </FormGroup>
            </div>
            <div className="form-footer">
                <Button className="float-right" type="submit" disabled={!isValid || !dirty}>
                    Save
                </Button>
            </div>
        </Form>
    );
};

const ProfileSettingsForm = withFormik({
    mapPropsToValues: (props) => {
        return props.user;
    },
    enableReinitialize: true,
    validationSchema: () => {
        // lets you validate what's been added to the form
        return Yup.object().shape({
            firstname: Yup.string()
                .required("Firstname is required.")
                .max(80, "Firstname can be a maximum 80 characters."),
            surname: Yup.string().required("Surname is required.").max(80, "Surname can be a maximum 80 characters."),
        });
    },
    handleSubmit: (formValues, { props }) => {
        const updateValues = {
            firstname: formValues.firstname,
            surname: formValues.surname,
        };
        props.onSubmit(updateValues);
    },
})(ProfileInnerForm);

const ProfileSettings = (props) => {
    const user = useSelector((state) => state.user);
    const claims = useSelector((state) => state.user.claims);
    const userEmployeeId = claims.employeeId;

    const handleSubmit = (updateValues) => {
        updateUserDoc(updateValues);
        if (userEmployeeId) {
            setEmployee(userEmployeeId, updateValues).commit();
        }
    };

    return <ProfileSettingsForm user={user} onSubmit={handleSubmit} />;
};

export default ProfileSettings;
