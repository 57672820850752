import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { RadioButtons, TalentMapFilterPicker } from "../../../components";
import SnapshotFieldPicker from "../../../components/Pickers/FieldPickers/SnapshotFieldPicker";
import { saveLocalPreferences } from "../../../firebase/actions/user";
import { setLocalPreferences } from "../../../redux/utils/dispatches";
import { TalentGridConfig } from "../../../components/TalentMap/TalentGridConfig";

const CHART_OPTIONS = ["grid", "scatter"];

const TalentMapConfigArea = () => {
    const talentMapConfig = useSelector((state) => state.talent.talentMapConfig);

    const handleSetConfig = (field, option) => {
        let newConfig = { ...talentMapConfig };
        newConfig[field] = option;
        setLocalPreferences({ talentMapConfig: newConfig }, true);
        saveLocalPreferences();
    };
    const scatterChartView = talentMapConfig.chartType === "scatter";
    const hideAxisPickers = talentMapConfig.singleAxis && !scatterChartView;

    const handleAxisChange = (axis, option) => {
        let newConfig = { ...talentMapConfig };
        if (!option) {
            newConfig[axis] = false;
        } else {
            let newAxis = {
                fieldId: option.fieldId,
                fieldValue: option.fieldValue || null,
            };
            newConfig[axis] = { ...newAxis };
        }
        setLocalPreferences({ talentMapConfig: newConfig }, true);
        saveLocalPreferences();
    };

    const resetToDefault = () => {
        setLocalPreferences({ talentMapConfig: null }, true);
    };

    return (
        <div className="side-area no-scroll px-0 h-100 d-flex flex-column align-items-stretch bg-transparent">
            <div className="scrollable mb-3 f-1 px-3 py-2">
                <div className="mb-3">
                    <h6>Chart Type</h6>
                    <RadioButtons
                        options={CHART_OPTIONS}
                        selected={talentMapConfig.chartType}
                        onChange={(option) => handleSetConfig("chartType", option)}
                    />
                </div>
                <div>
                    <div className="mb-4">
                        <div className="mb-3">
                            <h6>Filter</h6>
                            <TalentMapFilterPicker />
                        </div>
                        <div className="mb-3">
                            <h6>Tile Pie Chart</h6>
                            <SnapshotFieldPicker
                                excludeUnrated
                                onlyAxisFields
                                selected={talentMapConfig.z}
                                onChange={(option) => handleAxisChange("z", option)}
                            />
                        </div>
                        <hr />
                        {!hideAxisPickers && (
                            <>
                                <div className="mb-3">
                                    <h6>x Axis</h6>
                                    <SnapshotFieldPicker
                                        onlyAxisFields
                                        excludeGridPosition
                                        selected={talentMapConfig.x}
                                        onChange={(option) => handleAxisChange("x", option)}
                                    />
                                </div>
                                <div>
                                    <h6>y Axis</h6>
                                    <SnapshotFieldPicker
                                        onlyAxisFields
                                        excludeGridPosition
                                        selected={talentMapConfig.y}
                                        onChange={(option) => handleAxisChange("y", option)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {!scatterChartView && (
                        <div className="mb-4">
                            <div className="d-flex justify-content-between mb-2">
                                <h6>Grid Config</h6>
                                {/*<ToolbarPlusButton outline tooltip="Add Column" tooltipId={TOOLTIP_ID} />*/}
                            </div>
                            <TalentGridConfig grid={talentMapConfig.grid} setConfig={handleSetConfig} />
                        </div>
                    )}
                </div>
            </div>
            <div className="px-3 pt-3 border-top">
                <div className="float-end">
                    <Button className="me-2 fs-tiny" onClick={resetToDefault}>
                        Reset to Default
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TalentMapConfigArea;
