import { groupBy, isNull } from "lodash";
import { EmployeeModel } from "../models";
import TalentAreaModel from "../models/TalentAreaModel";
import store from "../redux/store";
import {
    lookAssessmentSettings,
    lookCustomField,
    lookEmployee,
    lookTalentArea,
    lookTalentAreas,
    lookTalentBoard,
    lookTalentBoards,
    lookTalentMapConfig,
    lookTrait,
    lookTraits,
} from "../redux/utils/looks";
import { dateIsBefore } from "./basicUtils";
import { getChildEmployeeIds } from "./roleUtils";

function ratingAverage(ratings) {
    const ratingTotal = ratings.reduce((acc, curr) => acc + curr, 0);
    const maxPossibleRating = ratings.length * 100;
    const rating = Math.round((ratingTotal / maxPossibleRating) * 100);
    return rating;
}

export function getExactColor(rating) {
    if (typeof rating === "string" && rating.charAt(0) === "#") return rating;
    if (isNull(rating) || isNaN(rating) || rating === undefined || rating === 0) return "#d3d3d3";
    const allColors = store.getState().assessments.settings.allColors;
    return allColors[Math.max(0, rating - 1)];
}

export function makeRatingsArray(ratings) {
    return Object.entries(ratings ?? {})
        .map(([id, rating]) => ({ id, rating }))
        .filter(({ rating }) => !isNaN(rating));
}

export function getRatingCounts(snapshots, ratingView = "overall", id) {
    let ratingCounts = {};
    Object.values(snapshots).reduce((counts, snapshot) => {
        const rating = id ? snapshot?.[ratingView]?.[id] : snapshot[ratingView];
        if (typeof rating !== "undefined" && !isNaN(rating)) {
            counts[rating] = (counts[rating] || 0) + 1;
        }
        return counts;
    }, ratingCounts);
    delete ratingCounts.NaN;
    delete ratingCounts.undefined;
    return ratingCounts;
}

// SINGLE SNAPSHOT METHODS
// Return the average score for an array of ratings (whatever they are)
// Full Employee Snapshot Prep
export function prepEmployeeSnapshot(employee, thresholdDate, snapshot, talentAreas = lookTalentAreas(), talentBoards) {
    if (employee) {
        let preppedSnapshot = { ...snapshot };
        const assessedDate = preppedSnapshot && preppedSnapshot.date;
        preppedSnapshot.expired = !dateIsBefore(thresholdDate, assessedDate);

        // Remove any ratings that the employee isn't assessed against
        const employeeModel = new EmployeeModel(employee, "prepEmployeeSnapshot");
        const traitIdsToAssess = employeeModel.getTraitIdsToAssess();
        let preppedRatings = {};
        for (const traitId of traitIdsToAssess) {
            if (preppedSnapshot?.ratings?.[traitId]) {
                preppedRatings[traitId] = preppedSnapshot.ratings[traitId];
            }
        }
        preppedSnapshot.ratings = preppedRatings;

        let extraValues = {
            overall: getOverallRating(preppedSnapshot, traitIdsToAssess),
            talentAreas: rateTalentAreas(preppedSnapshot, talentAreas, true),
        };

        if (talentBoards) {
            extraValues.talentBoards = rateTalentBoards(preppedSnapshot, talentBoards);
        }

        preppedSnapshot = { ...preppedSnapshot, ...extraValues };

        return preppedSnapshot;
    }
}

// Return the overall rating of a snapshot, against a list of ratings that should have been assessed
// These could be traitIds of a talent area, a talent board, or the traits and employee is assessed against
export function getOverallRating(snapshot, traitsToAssess = [], excludeUnrated) {
    if (!snapshot) return 0;
    if (traitsToAssess.length === 0) return 0;
    let allRatings = [];
    for (const traitId of traitsToAssess) {
        const traitIsLive = !!lookTrait(traitId);
        if (traitIsLive) {
            const snapshotRatings = snapshot.ratings || {};
            const traitRating = snapshotRatings[traitId] || 0;
            if (traitRating > 0 || !excludeUnrated) {
                allRatings.push(traitRating);
            }
        }
    }
    return ratingAverage(allRatings);
}

// SNAPSHOT GROUP METHODS:
// Return a snapshot showing the average values across a collection snapshots

// Filtering
export function filterSnapshotsByRole(roleId, wholeBranch, snapshots, roles) {
    const includedSubordinates = getChildEmployeeIds(roleId, wholeBranch, roles);
    return Object.entries(snapshots).reduce((filtered, [empId, snapshot]) => {
        if (includedSubordinates.includes(empId) && !!snapshots[empId]) {
            filtered[empId] = { ...snapshot };
        }
        return filtered;
    }, {});
}
export function filterSnapshotsByTalentBoard(snapshots, boardId) {
    return Object.entries(snapshots).reduce((filtered, [empId, snapshot]) => {
        const employee = lookEmployee(empId);
        let linkedBoardIds = employee && employee.talentBoardIds;
        if (linkedBoardIds && linkedBoardIds.includes(boardId)) {
            filtered[empId] = { ...snapshot };
        }
        return filtered;
    }, {});
}

export function rateTalentAreas(snapshot, talentAreas = {}, excludeUnrated) {
    let talentAreaRatings = {};
    Object.values(talentAreas).forEach((talentArea) => {
        const talentAreaModel = new TalentAreaModel(talentArea);
        const traitsToAssess = talentAreaModel.getLinkedTraitIds();
        const areaRating = getOverallRating(snapshot, traitsToAssess, excludeUnrated);
        if (areaRating) talentAreaRatings[talentArea.id] = areaRating;
    });
    return talentAreaRatings;
}

// Returns the aggregate rating of every talentBoard across a collection of snapshots
export function rateTalentBoards(snapshot, talentBoards, excludeUnrated) {
    let boardRatings = {};
    Object.values(talentBoards).forEach((talentBoard) => {
        const traitsToAssess = talentBoard.traitIds || [];
        const boardRating = getOverallRating(snapshot, traitsToAssess, excludeUnrated);
        if (boardRating) boardRatings[talentBoard.id] = boardRating;
    });
    return boardRatings;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////////

export function getDepartmentSnapshot(roleId, snapshots, roles, traits = lookTraits()) {
    const filteredSnapshots = filterSnapshotsByRole(roleId, false, snapshots, roles);
    return getAverageSnapshot(filteredSnapshots, traits);
}

export function getDivisionSnapshot(roleId, snapshots, roles, traits = lookTraits()) {
    const filteredSnapshots = filterSnapshotsByRole(roleId, true, snapshots, roles);
    return getAverageSnapshot(filteredSnapshots, traits);
}

export function getAverageSnapshot(
    snapshots,
    traits = lookTraits(),
    talentBoards = lookTalentBoards(),
    talentAreas = lookTalentAreas()
) {
    let ratings = {};
    Object.keys(traits).forEach((traidId) => {
        const traitRating = getAverageTraitRatings(snapshots, traidId);
        if (traitRating) {
            ratings[traidId] = traitRating;
        }
    });
    let snapshot = {
        ratings: ratings,
    };
    snapshot.talentBoards = rateTalentBoards(snapshot, talentBoards);
    snapshot.talentAreas = rateTalentAreas(snapshot, talentAreas, true);
    return snapshot;
}

function getAverageTraitRatings(snapshots, traitId) {
    const ratings = [];
    // eslint-disable-next-line
    for (const [id, { ratings: snapshotRatings = {} }] of Object.entries(snapshots)) {
        if (snapshotRatings.hasOwnProperty(traitId)) {
            const rating = snapshotRatings[traitId];
            if (rating) {
                ratings.push(rating);
            }
        }
    }
    return ratingAverage(ratings);
}

function getAverageTalentAreaRating(snapshotArray, talentAreaId) {
    let ratings = [];
    snapshotArray.forEach((snapshot) => {
        const snapshotAreaRatings = snapshot?.talentAreas?.[talentAreaId];
        if (snapshotAreaRatings) {
            ratings.push(snapshotAreaRatings);
        }
    });
    return ratingAverage(ratings);
}

export function getAverageTalentAreaRatings(snapshots, talentAreas = lookTalentAreas()) {
    let talentAreaRatings = {};
    const snapshotArray = Object.values(snapshots);
    // Iterate every Talent Area to build the ratings
    Object.values(talentAreas).forEach((talentArea) => {
        talentAreaRatings[talentArea.id] = getAverageTalentAreaRating(snapshotArray, talentArea.id);
    });
    return talentAreaRatings;
}

export function getAxisValue(snap, axis) {
    const val = axis.fieldValue ? snap[axis.fieldId][axis.fieldValue] : snap[axis.fieldId];
    return val;
}

export function getFieldLabel(field) {
    const { fieldId, fieldValue } = field;
    switch (fieldId) {
        case "ratings":
            const trait = lookTrait(fieldValue);
            return trait.traitName;
        case "talentAreas":
            const talentArea = lookTalentArea(fieldValue);
            return talentArea.talentAreaName;
        case "talentBoards":
            const talentBoard = lookTalentBoard(fieldValue);
            return talentBoard.talentBoardName;
        case "overall":
            const assessmentSettings = lookAssessmentSettings();
            return assessmentSettings.overallRatingLabel || "Overall Rating";
        case "gridPosition":
            return "Grid Position";
        default:
            const field = lookCustomField(fieldId);
            if (field) return field.label;
            return fieldId && fieldId.charAt(0).toUpperCase() + fieldId.slice(1);
    }
}

export function getGridMemberDetails(empId, snap, config, gridThresholds) {
    if (gridThresholds) {
        return getCustomEmployeeGridPosition(empId, snap, config, gridThresholds);
    } else {
        const { grid, x, y, z } = config;
        const cols = Object.keys(grid).length;
        const xRange = Math.ceil(100 / cols);
        const zVal = getAxisValue(snap, z);
        const xVal = getAxisValue(snap, x);
        const xBox = Math.ceil(xVal / xRange);
        let returnValue = null;
        if (xBox) {
            const rows = Object.values(grid[xBox]).length;
            const yVal = getAxisValue(snap, y);
            const yRange = Math.ceil(100 / rows);
            const yBox = Math.ceil(yVal / yRange);
            if (yBox) {
                const gridColor = getGridColor(xBox, yBox);
                let entry = { employeeId: empId, x: xBox, y: yBox, z: zVal, gridColor: gridColor };
                if (z.fieldId === "gridPosition") {
                    entry.z = gridColor;
                }
                returnValue = entry;
            } else {
                returnValue = null;
            }
        } else {
            returnValue = null;
        }
        return returnValue;
    }
}

export function getGridPositionCounts(snapshots, config) {
    const gridDetails = Object.entries(snapshots).map(([empId, snap]) => {
        return getGridMemberDetails(empId, snap, config);
    });
    const colorGroups = groupBy(gridDetails, "gridColor");
    return Object.entries(colorGroups).map(([color, cohort]) => {
        return { count: cohort.length, color: color };
    });
}

export function getGridColor(y, x) {
    const config = lookTalentMapConfig();
    const grid = config && config.grid;
    const boxInfo = grid[y][x];
    const result = boxInfo && boxInfo.color;
    return result;
}

export function createGrid(gridPlacements, config) {
    const { grid } = config;
    let gridColumns = Object.entries(grid).map(([col, rows]) => {
        const colCohort = gridPlacements.filter((entry) => entry.x && entry.x.toString() === col);
        return Object.entries(rows).map(([row, box]) => {
            const rowCohort = colCohort.filter((entry) => entry.y && entry.y.toString() === row);
            let percent = Math.round((rowCohort.length / gridPlacements.length) * 100);
            percent = isNaN(percent) ? 0 : percent;
            return { x: col, y: row, cohort: rowCohort, tile: box, percent: percent };
        });
    });
    return gridColumns;
}

export function prepGridData(snapshots, config) {
    const placements = Object.entries(snapshots).map(([empId, snap]) => {
        return getGridMemberDetails(empId, snap, config);
    });
    return placements.filter((entry) => entry);
}

// Single axis Grid is a retired feature only enabled for legacy workspaces
export function prepSingleAxisGridData(snapshots, config, gridThresholds) {
    if (gridThresholds) {
        return prepCustomThresholdGrid(snapshots, config, gridThresholds);
    } else {
        // Legacy: not used by any workspace anymore
        const { z, grid } = config;
        let flatGrid = [];
        Object.values(grid).forEach((rows, iCol) => {
            Object.values(rows).forEach((box, iRow) => {
                flatGrid.push({ requiredField: box.requiredField, x: iCol + 1, y: iRow + 1 });
            });
        });

        // Get the statically placed positions
        let placements = [];
        flatGrid
            .filter((box) => !!box.requiredField)
            .forEach((box) => {
                const requiredField = box.requiredField;
                Object.entries(snapshots)
                    .filter(([empId, snap]) => !!snap[requiredField])
                    .forEach(([empId, snap]) => {
                        const zVal = getAxisValue(snap, z);
                        placements.push({ employeeId: empId, ...box, z: zVal });
                    });
            });

        // Get the dynamically placed positions
        const boxes = flatGrid.filter((box) => !box.requiredField).length;
        const boxRange = Math.ceil(100 / boxes);
        Object.entries(snapshots).forEach(([empId, snap]) => {
            if (!placements.find((entry) => entry.employeeId === empId)) {
                const zVal = getAxisValue(snap, z);
                const boxIndex = Math.ceil(zVal / boxRange);
                let box = flatGrid[boxIndex - 1];
                placements.push({ employeeId: empId, ...box, z: zVal });
            }
        });
        return placements;
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//// WORKAROUND FOR HARD CODED GRID POSITIONS
/// Custom Threshold Grid

export function prepCustomThresholdGrid(snapshots, config, gridThresholds) {
    let placements = Object.entries(snapshots).map(([empId, snap]) => {
        return getCustomEmployeeGridPosition(empId, snap, config, gridThresholds);
    });

    return placements;
}

export function getCustomEmployeeGridPosition(empId, snap, config, gridThresholds) {
    const { grid, z } = config;
    const zVal = getAxisValue(snap, z);
    let flatGrid = [];
    Object.values(grid).forEach((rows, iCol) => {
        Object.values(rows).forEach((box, iRow) => {
            const segmentThresholds = gridThresholds.find(
                (thresholds) => thresholds.row === iRow + 1 && thresholds.col === iCol + 1
            );
            const { min, max } = segmentThresholds || {};
            flatGrid.push({
                requiredField: box.requiredField,
                gridColor: box.color,
                x: iCol + 1,
                y: iRow + 1,
                min: min,
                max: max,
            });
        });
    });

    // Temp - TopTalent Flag
    const isTop = snap["f4"];
    let employeeBox = {};
    if (isTop) {
        employeeBox = flatGrid.find((box) => box.requiredField === "f4");
    } else {
        employeeBox = flatGrid.find((box) => {
            const isWithinRange = zVal >= box.min && zVal <= box.max;
            return isWithinRange;
        });
    }

    if (!employeeBox) return {};

    const { min, max, ...cleaned } = employeeBox;
    return {
        ...cleaned,
        employeeId: empId,
        z: zVal,
    };
}
