import React from "react";
import { Outlet } from "react-router-dom";

const SignInLayout = (props) => {
    return (
        <div className="d-flex sign-in-layout bg-light py-5">
            <div className="f-1" />
            <div className="f-5 d-flex bg-white shadow rounded py-3 px-5">
                <Outlet />
            </div>
            <div className="f-1" />
        </div>
    );
};

export default SignInLayout;