import { AiOutlineCloudDownload, AiOutlineUserAdd, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiCog, BiWindowOpen } from "react-icons/bi";
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaBorderAll,
    FaCheck,
    FaCheckCircle,
    FaChevronDown,
    FaChevronLeft,
    FaChevronRight,
    FaDna,
    FaEllipsisV,
    FaFilter,
    FaInfoCircle,
    FaLock,
    FaLockOpen,
    FaMapMarkerAlt,
    FaPlus,
    FaRegTrashAlt,
    FaSave,
    FaSitemap,
    FaTable,
    FaTasks,
    FaUndoAlt,
    FaUserAltSlash,
    FaUserCheck,
    FaUserClock,
} from "react-icons/fa";
import { FiCamera, FiCameraOff, FiEdit3, FiMove, FiUserMinus, FiUserPlus } from "react-icons/fi";
import { ImBlocked } from "react-icons/im";
import {
    MdKeyboardBackspace,
    MdOutlineAssignment,
    MdOutlineAssignmentInd,
    MdOutlineAssignmentLate,
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineCalendarToday,
    MdOutlineCheckBox,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineClose,
    MdOutlineCompareArrows,
    MdOutlineControlPointDuplicate,
    MdOutlineFullscreen,
    MdOutlineHelp,
    MdOutlineHistory,
    MdOutlineWatchLater,
    MdPeople,
} from "react-icons/md";
import { GiRadarSweep } from "react-icons/gi";
import { RiSortDesc } from "react-icons/ri";
import { TiWarningOutline } from "react-icons/ti";
import { VscPass } from "react-icons/vsc";
import { BallTriangle, Oval, Puff, Rings, ThreeDots } from "react-loading-icons";

const LOADING_ICON_PROPS = {
    stroke: "#4878ff",
    strokeOpacity: 0.325,
    speed: 0.75,
};

const DEFAULT_SIZE = 24;

// Org Actions
export const RevokeIcon = (props) => <ImBlocked size={DEFAULT_SIZE} {...props} />;
export const InviteIcon = (props) => <AiOutlineUsergroupAdd size={DEFAULT_SIZE} {...props} />;
export const VacateRoleIcon = (props) => <FiUserMinus size={DEFAULT_SIZE} {...props} />;
export const FillRoleIcon = (props) => <FiUserPlus size={DEFAULT_SIZE} {...props} />;
export const ViewRoleIcon = (props) => <BiWindowOpen size={DEFAULT_SIZE} {...props} />;
export const ViewTalentMapIcon = (props) => <FaMapMarkerAlt size={DEFAULT_SIZE} {...props} />;
export const ViewStrengthsIcon = (props) => <FaDna size={DEFAULT_SIZE} {...props} />;
export const CreateRoleIcon = (props) => <AiOutlineUserAdd size={DEFAULT_SIZE} {...props} />;
export const ViewActionsIcon = (props) => <FaTasks size={DEFAULT_SIZE} {...props} />;
export const MoveRoleIcon = (props) => <FiMove size={DEFAULT_SIZE} {...props} />;
export const OrgIcon = (props) => <FaSitemap size={DEFAULT_SIZE} {...props} />;
export const TableIcon = (props) => <FaTable size={DEFAULT_SIZE} {...props} />;
export const RatingIcon = (props) => <FaDna size={DEFAULT_SIZE} {...props} />;

// Assessments
export const AssessmentPendingIcon = (props) => <MdOutlineAssignmentLate size={DEFAULT_SIZE} {...props} />;
export const AssessmentDoneIcon = (props) => <MdOutlineAssignmentTurnedIn size={DEFAULT_SIZE} {...props} />;
export const AssessmentLateIcon = (props) => <MdOutlineWatchLater size={DEFAULT_SIZE} {...props} />;
export const RequestAssessmentIcon = (props) => <MdOutlineAssignmentReturned size={DEFAULT_SIZE} {...props} />;
export const CompleteAssessmentIcon = (props) => <MdOutlineAssignmentInd size={DEFAULT_SIZE} {...props} />;
export const SkipAssessmentIcon = (props) => <VscPass size={DEFAULT_SIZE} {...props} />;
export const ViewAssessmentIcon = (props) => <MdOutlineAssignment size={DEFAULT_SIZE} {...props} />;
export const OpenAssessmentIcon = (props) => <FaLockOpen size={DEFAULT_SIZE} {...props} />;
export const ClosedAssessmentIcon = (props) => <FaLock size={DEFAULT_SIZE} {...props} />;
export const SnapshotEnabledIcon = (props) => <FiCamera size={DEFAULT_SIZE} {...props} />;
export const SnapshotDisabledIcon = (props) => <FiCameraOff size={DEFAULT_SIZE} {...props} />;
export const TalentBoardIcon = (props) => <FaBorderAll size={DEFAULT_SIZE} {...props} />;
export const TraitRatedIcon = (props) => <FaCheckCircle size={DEFAULT_SIZE} {...props} />;
export const TalentAreaIcon = (props) => <GiRadarSweep size={DEFAULT_SIZE} {...props} />;

export const AssessmentStatusIcon = (props) => {
    const { tooltipId, isAssessed, isSkipped, isExpired, ...passProps } = props;
    if (isExpired) return <AssessmentLateIcon {...passProps} size={22} />;
    if (!isAssessed) return null;
    return <CheckIcon size={DEFAULT_SIZE} {...passProps} />;
};

// Loading
export const LoadingIconOval = (props) => (
    <Oval strokeWidth={6} {...LOADING_ICON_PROPS} height={props.size} width={props.size} {...props} />
);
export const LoadingIconRings = (props) => (
    <Rings {...LOADING_ICON_PROPS} height={props.size} width={props.size} {...props} />
);
export const LoadingIconPuff = (props) => (
    <Puff {...LOADING_ICON_PROPS} height={props.size} width={props.size} {...props} />
);
export const LoadingIconTriangle = (props) => <BallTriangle {...LOADING_ICON_PROPS} {...props} />;
export const LoadingIconDots = (props) => <ThreeDots {...LOADING_ICON_PROPS} {...props} />;

// Generic
export const CheckIcon = (props) => <FaCheck size={DEFAULT_SIZE} {...props} />;
export const WarningIcon = (props) => <TiWarningOutline size={DEFAULT_SIZE} {...props} />;
export const CalendarIcon = (props) => <MdOutlineCalendarToday size={DEFAULT_SIZE} {...props} />;
export const EllipsisIcon = (props) => <FaEllipsisV size={DEFAULT_SIZE} {...props} />;
export const SettingsIcon = (props) => <BiCog size={DEFAULT_SIZE} {...props} />;
export const ViewIcon = (props) => <MdOutlineFullscreen size={DEFAULT_SIZE} {...props} />;
export const FilterIcon = (props) => <FaFilter size={DEFAULT_SIZE} {...props} />;
export const InfoIcon = (props) => <FaInfoCircle size={DEFAULT_SIZE} {...props} />;
export const DownloadIcon = (props) => <AiOutlineCloudDownload size={DEFAULT_SIZE} {...props} />;
export const UserIcon = (props) => <FaUserCheck size={DEFAULT_SIZE} {...props} />;
export const PendingUserIcon = (props) => <FaUserClock size={DEFAULT_SIZE} {...props} />;
export const NotUserIcon = (props) => <FaUserAltSlash size={DEFAULT_SIZE} {...props} />;
export const PeopleIcon = (props) => <MdPeople size={DEFAULT_SIZE} {...props} />;
export const CheckOrCrossIcon = (props) => {
    if (props.checked) {
        return <FaCheck size={DEFAULT_SIZE} {...props} />;
    } else {
        return <CrossIcon size={DEFAULT_SIZE} {...props} />;
    }
};
export const DuplicateIcon = (props) => <MdOutlineControlPointDuplicate size={DEFAULT_SIZE} {...props} />;
export const CompareIcon = (props) => <MdOutlineCompareArrows size={DEFAULT_SIZE} {...props} />;
export const HistoryIcon = (props) => <MdOutlineHistory size={DEFAULT_SIZE} {...props} />;

// Controls
export const CheckBoxCheckedIcon = (props) => <MdOutlineCheckBox size={DEFAULT_SIZE} {...props} />;
export const CheckBoxUncheckedIcon = (props) => <MdOutlineCheckBoxOutlineBlank size={DEFAULT_SIZE} {...props} />;
export const DownChevronIcon = (props) => <FaChevronDown size={DEFAULT_SIZE} {...props} />;
export const LeftChevronIcon = (props) => <FaChevronLeft size={DEFAULT_SIZE} {...props} />;
export const RightChevronIcon = (props) => <FaChevronRight size={DEFAULT_SIZE} {...props} />;
export const BackIcon = (props) => <MdKeyboardBackspace size={DEFAULT_SIZE} {...props} />;
export const EditIcon = (props) => <FiEdit3 size={DEFAULT_SIZE} {...props} />;
export const DeleteIcon = (props) => <FaRegTrashAlt size={DEFAULT_SIZE} {...props} />;
export const PlusIcon = (props) => <FaPlus size={DEFAULT_SIZE} {...props} />;
export const UndoIcon = (props) => <FaUndoAlt size={DEFAULT_SIZE} {...props} />;
export const CrossIcon = (props) => <MdOutlineClose size={DEFAULT_SIZE} {...props} />;
export const BackToStartIcon = (props) => <FaAngleDoubleLeft size={DEFAULT_SIZE} {...props} />;
export const SkipToEndIcon = (props) => <FaAngleDoubleRight size={DEFAULT_SIZE} {...props} />;
export const ReopenIcon = (props) => <FaUndoAlt size={DEFAULT_SIZE} {...props} />;
export const SortIcon = (props) => <RiSortDesc size={DEFAULT_SIZE} {...props} />;
export const SaveIcon = (props) => <FaSave size={DEFAULT_SIZE} {...props} />;

//Other
export const TourIcon = (props) => <MdOutlineHelp size={DEFAULT_SIZE} {...props} />;
