import { createSlice, current } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { REQUIRED_TALENT_BOARDS } from "../constants";
import { cLog } from "../utils/basicUtils";
import { linkTraits, setSnapshots } from "./utils/thunks";

function isStillPending(pendingChanges, prevState, newState) {
    return pendingChanges.filter((id) => {
        const prevVals = prevState[id];
        const newVals = newState[id];
        return isEqual(prevVals, newVals);
    });
}

function isTraitStillPending(pendingChanges, prevTraits, newTraits) {
    return pendingChanges.filter((id) => {
        const prevTrait = prevTraits[id] || {};
        const { linkedTalentBoardIds, ...prevVals } = prevTrait;
        const newVals = newTraits[id];
        return isEqual(prevVals, newVals);
    });
}

const initialState = {
    actions: {},
    talentAreas: {},
    activeTalentAreaIds: [],
    talentBoards: REQUIRED_TALENT_BOARDS,
    traits: {},
    rawSnapshots: {},
    snapshots: {},
    changedSnapshotIds: [],
    changedTalentBoardLinks: [],
    snapshotDataChanged: null,
    snapshotsUpdating: false,
    snapshotsUpdated: null,
    talentBoardsLoaded: false,
    traitDataChanged: false,
    traitsLinked: false,
    traitLinksUpdating: false,
    snapshotsPrepped: false,
    loading: true,
    loadingProgress: 0,
    pendingUpdate: false,
    pendingChanges: [],
    defaultTalentMapConfig: {},
    talentMapConfig: {},
};

function changedSnapshotIds(prev, next) {
    let changedIds = [];
    Object.entries(next).forEach(([empId, snapshot]) => {
        if (!isEqual(prev[empId], snapshot)) {
            changedIds.push(empId);
        }
    });
    return changedIds;
}

const talentSlice = createSlice({
    name: "talent",
    initialState,
    reducers: {
        SET_ACTIONS(state, action) {
            const stillPending = isStillPending(state.pendingChanges, state.actions, action.payload);
            state.actions = action.payload;
            state.pendingChanges = stillPending;
        },
        SET_TALENT_AREAS(state, action) {
            const stillPending = isStillPending(state.pendingChanges, state.talentAreas, action.payload);
            state.talentAreas = action.payload;
            state.pendingChanges = stillPending;
        },
        SET_TALENT_BOARDS(state, action) {
            const stillPending = isStillPending(state.pendingChanges, state.talentBoards, action.payload);
            state.talentBoards = { ...REQUIRED_TALENT_BOARDS, ...action.payload };
            state.pendingChanges = stillPending;
            state.talentBoardsLoaded = true;
            state.traitDataChanged = Date.now();
        },
        SET_TRAITS(state, action) {
            const stillPending = isTraitStillPending(state.pendingChanges, state.traits, action.payload);
            state.traits = action.payload;
            state.pendingChanges = stillPending;
            state.traitDataChanged = Date.now();
        },
        SET_TRAIT(state, action) {
            const { id, update } = action.payload;
            state.traits[id] = { ...state.traits[id], ...update };
        },
        REMOVE_TALENT_ITEM(state, action) {
            const { type, id } = action.payload;
            delete state[type][id];
        },
        SET_SNAPSHOT_PENDING(state, action) {
            state.pendingChanges.push(action.payload);
        },
        SET_TALENT_CHANGE_PENDING(state, action) {
            state.pendingChanges.push(action.payload);
        },
        SET_TALENT_LOADING(state, action) {
            state.loading = action.payload;
        },
        SET_EMPLOYEE_SNAPSHOTS(state, action) {
            state.changedSnapshotIds = changedSnapshotIds(state.rawSnapshots, action.payload);
            state.rawSnapshots = action.payload;
            state.snapshotDataChanged = Date.now();
        },
        SET_EMPLOYEE_SNAPSHOT(state, action) {
            const { empId, update } = action.payload;
            state.snapshots[empId] = { ...state.snapshots[empId], ...update };
        },
        REMOVE_FROM_STATE(state, action) {
            const { type, ids } = action.payload;
            ids.forEach((id) => {
                delete state[type][id];
            });
        },
        SET_MAP_CONFIG(state, action) {
            if (action.payload) {
                state.talentMapConfig = { ...current(state.talentMapConfig), ...action.payload };
            } else {
                state.talentMapConfig = state.defaultTalentMapConfig;
            }
        },
        SET_DEFAULT_MAP_CONFIG(state, action) {
            if (!action.payload) {
                state.talentMapConfig = state.defaultTalentMapConfig;
            } else {
                let newConfig = { ...action.payload };
                state.defaultTalentMapConfig = newConfig;
                state.talentMapConfig = { ...newConfig, ...current(state.talentMapConfig) };
            }
        },
        RESET_PENDING_TALENT: (state) => {
            state.pendingChanges = [];
        },
        RESET_TALENT: (state) => (state = initialState),
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSnapshots.pending, (state, action) => {
                state.snapshotsUpdating = true;
            })
            .addCase(setSnapshots.fulfilled, (state, action) => {
                state.snapshots = action.payload;
                state.pendingChanges = state.pendingChanges.filter((id) => !state.changedSnapshotIds.includes(id));
                state.changedSnapshotIds = [];
                state.snapshotsPrepped = true;
                state.snapshotsUpdated = Date.now();
                state.loading = false;
                state.snapshotsUpdating = false;
            })
            .addCase(setSnapshots.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.snapshotsUpdating = false;
                cLog(action.error.message, "ERROR MESSAGE");
            })
            .addCase(linkTraits.pending, (state, action) => {
                state.traitLinksUpdating = true;
            })
            .addCase(linkTraits.fulfilled, (state, action) => {
                const { newTraits, activeTalentAreaIds } = action.payload;
                const stillPending = isStillPending(state.pendingChanges, state.traits, newTraits);
                state.activeTalentAreaIds = activeTalentAreaIds;
                state.traits = newTraits;
                state.traitsLinked = true;
                state.pendingChanges = stillPending;
                state.traitLinksUpdating = false;
                // Trigger an update of the snapshot data if it's already been prepped
                if (state.snapshotsPrepped) {
                    state.snapshotDataChanged = Date.now();
                }
            })
            .addCase(linkTraits.rejected, (state, action) => {
                state.traitLinksUpdating = false;
                state.error = action.error.message;
                cLog(action.error.message, "ERROR MESSAGE");
            });
    },
});

const { actions, reducer } = talentSlice;

export const {
    SET_ACTIONS,
    SET_TALENT_AREAS,
    SET_TALENT_BOARDS,
    SET_TRAITS,
    SET_TRAIT,
    SET_SNAPSHOT_PENDING,
    SET_EMPLOYEE_SNAPSHOTS,
    SET_EMPLOYEE_SNAPSHOT,
    SET_TALENT_CHANGE_PENDING,
    REMOVE_TALENT_ITEM,
    CREATE_SNAPSHOTS,
    SET_TALENT_LOADING,
    SET_MAP_CONFIG,
    PREP_SNAPSHOTS,
    SET_DEFAULT_MAP_CONFIG,
    RESET_PENDING_TALENT,
    REMOVE_FROM_STATE,
    RESET_TALENT,
} = actions;

export default reducer;

/*
        SET_EMPLOYEE_SNAPSHOTS: {
            // Test this - it should be a way to reformat dates before they go into the state
            reducer: (state, action) => {
                state.rawSnapshots = action.payload;
            },
            prepare: (firebaseSnapshots) => {
                return { payload: firebaseSnapshots };
            },
        },
        */
