export const SIDE_AREA_HEADER_TEXT = {
    editTalentArea: "Edit Talent Area",
    editAction: "Edit Action",
    editTrait: "Edit Trait",
    lateAssessments: "Expired Assessments",
    editTalentBoardTraits: "Add Traits",
    editTalentBoardMembers: "Add Members",
    roleAdminArea: "Edit",
    requestAssessments: "Assessment Status",
    employeeTimeline: "Employee Timeline",
    assessmentHistory: "Assessment History",
    employeeActions: "Action History",
    talentMapConfig: "Configure Talent Map",
    editOrgTable: "Edit Table",
    createOrgTable: "Create Table",
    compareRatings: "Compare Ratings",
};

 export const RATING_COLORS = {
    firstColor: "#FF1414", 
    midColor: "#edeb12", 
    lastColor: "#13cc10"
 }

export const USER_ROLES_NEW = {
    none: { id: "none", label: "None", tooltip: "No access to the workspace." },
    admin: {
        id: "admin",
        label: "Admin",
        tooltip: "Can view & edit the entire org chart.",
        tooltip2: "Can view & edit all assessment data",
        tooltip3: "Can view & edit talent data",
        claims: {
            canViewOrgChart: true,
            canEditOrgChart: true,
            canViewAssessments: true,
            canEditAssessments: true,
            canViewTalent: true,
            canEditTalent: true,
        },
    },
    orgchartViewer: {
        id: "orgchartViewer",
        label: "Org Chart Viewer",
        tooltip: "Can view the entire org chart.",
        tooltip2: "Cannot view or edit any assessment data",
        tooltip3: "Cannot view or edit talent data",
        claims: {
            canViewOrgChart: true,
            canEditOrgChart: false,
            canViewAssessments: false,
            canEditAssessments: false,
            canViewTalent: false,
            canEditTalent: false,
        },
    },
    manager: {
        id: "manager",
        label: "Manager",
        tooltip: "Can view the org chart for their branch.",
        tooltip2: "Can view assessment data for employees in their branch.",
        tooltip3: "Cannot view or edit talent data",
        claims: {
            canViewOrgChart: true,
            canEditOrgChart: false,
            canViewAssessments: false,
            canEditAssessments: false,
            canViewTalent: false,
            canEditTalent: false,
        },
    },
};

export const USER_ROLES = {
    none: { id: "none", label: "None", tooltip: "Employee will not have access to the workspace." },
    admin: { id: "admin", label: "Admin", tooltip: "Employee will be able to view and edit everything." },
    manager: {
        id: "manager",
        label: "Manager",
        tooltip:
            "Employee will be able to view assessment data for employees in their branch. They will not be able to edit the Org structure or Talent data.",
    },
};

export const ORG_TABLE_COLUMNS = [
    { id: "userBadge", label: "User Status" },
    { id: "employeeNumber", label: "Employee Number" },
    { id: "displayName", label: "Name" },
    { id: "firstname", label: "First Name" },
    { id: "surname", label: "Surname" },
    { id: "email", label: "Email" },
    { id: "jobTitle", label: "Job Title" },
    { id: "department", label: "Department" },
    { id: "managerName", label: "Manager" },
    { id: "overall", label: "Overall Rating" },
];

export const DEFAULT_TABLE_ID = "allemp";
export const READ_ONLY_TABLE_IDS = ["allemp", "allUsr", "actEmp"];

export const DEFAULT_USER_ROLE = "manager";

export const DEFAULT_PREFERENCES = {
    tableView: false,
    autoCloseSiblings: false,
    showName: true,
    showJobTitle: false,
    activeOrgTableId: "allemp",
    ratingFilter: "employee",
    nodeCategory: "talent",
    wordClouds: {
        dashboard: [],
        employeeTab: [],
        departmentTabTeam: [],
        departmentTabBranch: [],
    },
};

export const DEFAULT_WORKSPACE_PREFS = {
    successionView: "strength",
};

export const EMPLOYEE_SEARCH_SETTINGS = {
    keys: ["name", "jobTitle", "department", "employeeNumber", "displayName", "email"],
    threshold: 0.1,
};

export const RATING_VIEW_OPTIONS = ["employee", "branch"];

export const FIELD_SIZE_LIMITS = {
    FIRSTNAME: 40,
    SURNAME: 40,
    JOB_TITLE: 80,
    DEPARTMENT_NAME: 40,
    EMPLOYEE_NO: 40,
    EMAIL: 80,
    ACTION_NAME: 80,
    TRAIT_NAME: 40,
    TALENT_BOARD_NAME: 40,
    TALENT_AREA_NAME: 40,
    DESCRIPTION: 320,
    WORKSPACE_NAME: 40,
    SEGMENT: 30,
    BOX_TITLE: 32,
    TABLE_NAME: 80,
};

export const MAP_STATUSES = {
    NOT_STARTED: "not_started",
    IN_PROGRESS: "in_progress",
    COMPLETE: "complete",
};

export const TRAIT_SCOPE_OPTIONS = ["everyone", "managers"];

export const TRAIT_FAMILY_OPTIONS = ["all", "matched"];

export const COLORS = {
    BAD: "#FF1414",
    GOOD: "#13cc10",
    GREEN: "#34c25f",
    RED: "#fe5514",
    BLUE: "#4878ff",
    YELLOW: "#fec135",
    GREY: "#4B3B47",
    PURPLE: "#9856c9",
};

export const DEFAULT_ROUTE = "/people";

export const ALT_TEXT = {
    LOGO: "logo showing a person as a map pointer",
};

export const MANAGEMENT_BOARD_ID = "mMGmnt";

export const REQUIRED_TALENT_BOARDS = {
    [MANAGEMENT_BOARD_ID]: {
        id: MANAGEMENT_BOARD_ID,
        talentBoardName: "Management",
        description: "This board is auto-assigned to any Employee who has direct reports.",
        traitIds: [],
    },
};
