import { isEqual } from "lodash";
//import { EXCLUDED_FIELDS_ALL, EXCLUDED_FIELDS_BY_COLLECTION } from "shared";
import { cLog } from "../../utils/basicUtils";
import { getRoleParentRoles } from "../../utils/roleUtils";
import { SELECT_EMPLOYEE, SELECT_ROLE, SET_TIMED_OUT, SET_TIMED_OUT_MESSAGE } from "../appSlice";
import { REMOVE_ROLE, SET_EMPLOYEE, SET_EXPANDED_NODES, SET_PENDING_CHANGES } from "../orgSlice";
import store from "../store";
import {
    REMOVE_TALENT_ITEM,
    RESET_PENDING_TALENT,
    SET_EMPLOYEE_SNAPSHOT,
    SET_SNAPSHOT_PENDING,
    SET_TALENT_CHANGE_PENDING,
} from "../talentSlice";
import { RESET_USER, SET_LOCAL_PREFERENCES } from "../userSlice";
import { RESET_WORKSPACE } from "../workspaceSlice";
import {
    lookEmployee,
    lookExpandedNodes,
    lookLocalPreferences,
    lookRole,
    lookSelectedEmployeeId,
    lookSelectedRoleId,
    lookWorkspace
} from "./looks";
import { EXCLUDED_FIELDS_ALL, EXCLUDED_FIELDS_BY_COLLECTION } from "../../utils/shared";

export function selectEmployee(empId) {
    const selectedEmployeeId = lookSelectedEmployeeId();
    if (empId !== selectedEmployeeId) {
        const employee = lookEmployee(empId);
        const roleId = employee && employee.roleId;
        store.dispatch(SELECT_EMPLOYEE(empId));
        selectRole(roleId);
    }
}

export function selectRole(roleId) {
    const selectedRoleId = lookSelectedRoleId();
    const expandedNodes = lookExpandedNodes();
    if (roleId !== selectedRoleId) {
        const role = lookRole(roleId);
        const incumbentId = role && role.incumbentId;
        store.dispatch(SELECT_ROLE(roleId));
        store.dispatch(SELECT_EMPLOYEE(incumbentId));
        const isExpanded = expandedNodes[roleId];
        if (roleId && !isExpanded) {
            let newExpanded = { ...expandedNodes };
            const parentRoleIds = getRoleParentRoles(roleId);
            parentRoleIds.forEach((id) => {
                newExpanded[id] = true;
            });
            store.dispatch(SET_EXPANDED_NODES(newExpanded));
        }
    }
}

export function setPendingTalentChange(id, prevValues, newValues) {
    if (!prevValues || !newValues) {
        store.dispatch(SET_TALENT_CHANGE_PENDING(id));
    } else {
        const removeKeys = [
            "id",
            "authRoles",
            "childRoles",
            "guidance",
            "description",
            "details",
            "publicInfoDocId",
            "importCount",
            "linkedTalentBoardIds",
        ];
        let newPublic = { ...newValues };
        let prevPublic = { ...prevValues };
        removeKeys.forEach((key) => {
            delete newPublic[key];
            delete prevPublic[key];
        });
        if (!isEqual(prevPublic, newPublic)) {
            cLog("set to pending");
            store.dispatch(SET_TALENT_CHANGE_PENDING(id));
        }
    }
}

export function setPendingOrgChanges(id, prevValues, newValues) {
    if (!prevValues || !newValues) {
        store.dispatch(SET_PENDING_CHANGES(id));
    } else {
        // Remove any fields that aren't meant to be public
        let removeKeys = [...EXCLUDED_FIELDS_ALL];
        removeKeys = removeKeys.concat(EXCLUDED_FIELDS_BY_COLLECTION["roles"]);
        removeKeys = removeKeys.concat(EXCLUDED_FIELDS_BY_COLLECTION["employees"]);

        let newPublic = { ...newValues };
        let prevPublic = { ...prevValues };

        removeKeys.forEach((key) => {
            delete newPublic[key];
            delete prevPublic[key];
        });
        if (!isEqual(prevPublic, newPublic)) {
            store.dispatch(SET_PENDING_CHANGES(id));
        }
    }
}

export function resetAllState() {
    store.dispatch(RESET_WORKSPACE());
}

export function setPendingSnapshot(employeeId) {
    store.dispatch(SET_SNAPSHOT_PENDING(employeeId));
}

export function resetPendingTalent() {
    store.dispatch(RESET_PENDING_TALENT());
}

export function resetUser() {
    store.dispatch(RESET_USER());
}

export function resetWorkspace() {
    store.dispatch(RESET_WORKSPACE());
}

export function setTimedOut(timedOut) {
    store.dispatch(SET_TIMED_OUT(timedOut));
}

export function setTimedOutMessage(failMessage) {
    store.dispatch(SET_TIMED_OUT_MESSAGE(failMessage));
}

export function setLocalPreferences(update, isWorkspacePreference) {
    const prevPrefs = lookLocalPreferences();
    let newPrefs = { ...prevPrefs };
    if (isWorkspacePreference) {
        const workspaceId = lookWorkspace().id;
        let workspacePrefs = newPrefs[workspaceId] || {};
        newPrefs[workspaceId] = { ...workspacePrefs, ...update };
    } else {
        newPrefs = { ...newPrefs, ...update };
    }
    store.dispatch(SET_LOCAL_PREFERENCES(newPrefs));
}

export function removeRole(roleId) {
    store.dispatch(REMOVE_ROLE(roleId));
}

export function removeTalentItem(type, id) {
    store.dispatch(REMOVE_TALENT_ITEM({ type: type, id: id }));
}

export function updateEmployee(empId, update) {
    const { displayName, timeline, dateLastAssessed, disabled, roleId, uid, publicInfoDocId, ...cleanedUpdate } =
        update;
    const newDisplayName = `${cleanedUpdate.firstname} ${cleanedUpdate.lastname}`;
    store.dispatch(SET_EMPLOYEE({ empId: empId, update: { ...cleanedUpdate, displayName: newDisplayName } }));
}

export function updateSnapshot(empId, update) {
    store.dispatch(SET_EMPLOYEE_SNAPSHOT({ empId: empId, update: update }));
}
