import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IdPicker from "./IdPicker";

const CustomFieldPicker = (props) => {
    const { selectedIds, fieldCategory, isMulti, isClearable } = props;
    const [excludeIds, setExcludeIds] = useState([]);
    const customFields = useSelector((state) => state.workspace.customFields);

    const getLabel = (customField) => {
        return customField.label;
    };

    // Determine the additional ids to exclude based on the props provided
    useEffect(() => {
        let newExcludeIds = [];
        if (fieldCategory) {
            newExcludeIds = customFields.filter((field) => field.category === fieldCategory);
        }
        setExcludeIds(newExcludeIds);
    }, [customFields, fieldCategory]);

    return (
        <IdPicker
            {...props}
            name="customFields"
            getLabel={getLabel}
            options={customFields}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds.concat(props.excludeIds)}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

CustomFieldPicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    fieldCategory: PropTypes.oneOf(["assessment", "employee", "role"]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

CustomFieldPicker.defaultProps = {
    selectedIds: [],
    excludeIds: [],
    isClearable: true,
    onBlur: () => {},
    onChange: () => {},
};

export default CustomFieldPicker;
