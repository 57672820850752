import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import UncontrolledCollapseBlock from "../../../components/CollapseBlock/CollapseBlock";
import { confirmModal } from "../../../components/Dialogs/ConfirmAlert";
import { Help } from "../../../components/Help/Help";
import TableSearch from "../../../components/Search/TableSearch";
import { requestAssessments, skipAssessment } from "../../../firebase/actions/assessments";
import { SET_SIDE_AREA } from "../../../redux/appSlice";
import { lookEmployee, lookRole } from "../../../redux/utils/looks";
import { listOverdueAssessments } from "../../../utils/assessmentUtils";
import { dateIsXDaysAgo, delay, formatDate, sortArrayOfObjects } from "../../../utils/basicUtils";
import { checkAccess } from "../../../utils/userUtils";

const HELP_ID = "late-assessments";

const Manager = ({ managerEntry, checked, onToggleChecked, onSkip }) => {
    const [skipped, setSkipped] = useState([]);
    const [highlightRequest, setHighlightRequest] = useState(false);
    const { overdueEmployees, displayName, requestDate, roleId } = managerEntry;
    const pendingChanges = useSelector((state) => state.talent.pendingChanges);

    const filteredEmployees = useMemo(() => {
        return overdueEmployees.filter((emp) => !skipped.includes(emp.employeeId));
    }, [overdueEmployees, skipped]);

    const reminderEnabled = useMemo(() => dateIsXDaysAgo(requestDate, 14), [requestDate], [requestDate]);

    useEffect(() => {
        pendingChanges.forEach((empId) => {
            setSkipped((prev) => [...prev, empId]);
        });
    }, [pendingChanges]);

    const handleSkip = (empId) => {
        onSkip(empId);
    };

    const handleToggleChecked = async () => {
        if (reminderEnabled) {
            onToggleChecked(roleId);
        } else {
            toast.warn("You must wait at least 14 days between sending reminders.");
            setHighlightRequest(true);
            await delay(2000);
            setHighlightRequest(false);
        }
    };

    const renderTitle = () => (
        // add the request date to this
        // className={classNames("fs-tiny c-light me-3", { "transition-blink": highlightRequest })}
        <div className="d-flex flex-column py-1">
            <div className="f-1">{displayName}</div>
            <div>
                {requestDate && (
                    <span
                        className={classNames("fs-tiny c-light me-3", { "transition-blink": highlightRequest })}
                    >{`Last Requested: ${formatDate(requestDate)}`}</span>
                )}
                <span className="text-muted">{`Employees: ${overdueEmployees.length}`}</span>
            </div>
        </div>
    );

    return (
        <UncontrolledCollapseBlock
            title={renderTitle()}
            collapsedClassName="border-bottom"
            type="checkbox"
            checked={checked}
            smallHeader
            onAction={() => handleToggleChecked(roleId)}
        >
            {filteredEmployees.map((emp) => {
                const assessedDate = emp.assessedDate ? new Date(emp.assessedDate).toLocaleDateString("en-GB") : null;
                return (
                    <div key={emp.employeeId} className="d-flex justify-content-between align-items-center">
                        <span className="mb-0 fw-bold">{`${emp.displayName} (${assessedDate || "Unassessed"})`}</span>
                        <Button className="basic invert" onClick={() => handleSkip(emp.employeeId)}>
                            <span>SKIP</span>
                        </Button>
                    </div>
                );
            })}
            <hr />
        </UncontrolledCollapseBlock>
    );
};

const LateAssessmentArea = () => {
    const dispatch = useDispatch();
    const [checkedRoleIds, setCheckedRoleIds] = useState([]);
    const [filteredIds, setFilteredIds] = useState();
    const snapshots = useSelector((state) => state.talent.snapshots);
    const employees = useSelector((state) => state.org.employees);
    const canAccess = checkAccess("manageAssessmentRequests");
    const requestDates = useSelector((state) => state.assessments.requestDates);
    const lateDate = useSelector((state) => state.assessments.settings.lateDate);
    const lateDateStr = useMemo(() => {
        return lateDate ? new Date(lateDate).toLocaleDateString("en-GB") : null;
    }, [lateDate]);

    // List all the overdue employees
    const allOverdueEmployees = useMemo(() => {
        return listOverdueAssessments(employees, snapshots, lateDate, true);
    }, [snapshots, employees, lateDate]);

    // Group overdueEmployees by parentRoleId
    const overdueManagers = useMemo(() => {
        let rolesWithOverdue = {};
        allOverdueEmployees.forEach((emp) => {
            if (rolesWithOverdue[emp.parentRoleId]) {
                rolesWithOverdue[emp.parentRoleId].push(emp);
            } else {
                rolesWithOverdue[emp.parentRoleId] = [emp];
            }
        });
        const unsorted = Object.entries(rolesWithOverdue).map(([roleId, empDetails]) => {
            const role = lookRole(roleId);
            const manager = lookEmployee(role.incumbentId);
            const managerName = manager && manager.displayName;
            const requestDate = requestDates[roleId];
            return { roleId: roleId, displayName: managerName, overdueEmployees: empDetails, requestDate: requestDate };
        });

        // sort sorted by request date
        return sortArrayOfObjects(unsorted, "requestDate", "desc");
    }, [allOverdueEmployees, requestDates]);

    useEffect(() => {
        if (!canAccess) {
            dispatch(SET_SIDE_AREA());
        }
    }, [dispatch, canAccess]);

    const handleToggleCheckedRole = (roleId) => {
        if (checkedRoleIds.includes(roleId)) {
            setCheckedRoleIds(checkedRoleIds.filter((id) => id !== roleId));
        } else {
            setCheckedRoleIds(checkedRoleIds.concat(roleId));
        }
    };

    const handleSendReminders = async () => {
        const remindersToSend = overdueManagers.filter((manager) => checkedRoleIds.includes(manager.roleId));
        const roles = remindersToSend.map((manager) => {
            const roleId = manager.roleId;
            return lookRole(roleId);
        });
        const isReminder = true;
        await requestAssessments(roles, isReminder);
        setCheckedRoleIds([]);
    };

    const handleSkipAssessment = (employeeId) => {
        const onConfirm = () => skipAssessment(employeeId);
        if (employeeId) {
            confirmModal("skip_assessment", onConfirm);
        }
    };

    return (
        <div className="h-100 d-flex inner-container flex-column align-items-stretch justify-content-between bg-transparent ps-3">
            <div className="pe-3">
                <Help helpId={HELP_ID} className="help">
                    <p className="mb-2">
                        {`This area lists managers who have employees with Talent Assessments that were last updated before ${lateDateStr}.`}
                    </p>
                    <p>This is based on the assessment cycle settings for your workspace.</p>
                </Help>
            </div>
            <div className="border-all me-3 mt-2 p-2">
                <TableSearch
                    items={overdueManagers}
                    onChange={setFilteredIds}
                    idKey="roleId"
                    searchKeys={["displayName", "managerName"]}
                    displayKey="displayName"
                    placeholder="Filter"
                />
            </div>
            <div className="pe-3 f-1 scrollable">
                {overdueManagers
                    .filter((manager) => !filteredIds || filteredIds.includes(manager.roleId))
                    .map((managerEntry) => {
                        const { roleId } = managerEntry;
                        return (
                            <Manager
                                key={roleId}
                                managerEntry={managerEntry}
                                checked={checkedRoleIds.includes(roleId)}
                                onSkip={handleSkipAssessment}
                                onToggleChecked={handleToggleCheckedRole}
                            />
                        );
                    })}
            </div>
            <div className="pt-3 px-3 border-top">
                <Button className="float-end" disabled={checkedRoleIds.length === 0} onClick={handleSendReminders}>
                    {`Send Reminders (${checkedRoleIds.length})`}
                </Button>
            </div>
        </div>
    );
};

export default LateAssessmentArea;
