/* 
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonGroup, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { ActionPicker, LoadingIndicator, RichTextEditor, TalentAreaPicker } from "../../components";
import TraitScope from "../../components/TraitScope/TraitScope";
import { FIELD_SIZE_LIMITS } from "../../constants";
import { setTrait } from "../../firebase/actions/trait";
import { fetchTrait } from "../../firebase/fetch";
import { SELECT_TRAIT } from "../../redux/appSlice";
// Add this to the toolbar
            <FormStep title="Relationships" fieldHelp={FIELD_HELP.RELATIONSHIPS}>
                <FormGroup>
                    <Label for="talentAreaId">Talent Areas</Label>
                    <TalentAreaPicker
                        talentAreas={talentAreas}
                        selectedIds={values.talentAreaIds}
                        onBlur={() => {
                            setFieldTouched("talentAreaIds", true);
                        }}
                        onChange={(selectedTalentAreas) => {
                            setFieldValue("talentAreaIds", selectedTalentAreas);
                        }}
                    />
                    <FormFeedback>{errors.talentAreaIds}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="talentAreaId">Actions</Label>
                    <ActionPicker
                        actions={actions}
                        selectedIds={values.actionIds}
                        onBlur={() => {
                            setFieldTouched("actionIds", true);
                        }}
                        onChange={(selectedActions) => {
                            setFieldValue("actionIds", selectedActions);
                        }}
                    />
                </FormGroup>
            </FormStep>

const TraitToolbar = (props) => {
    return (
        <div className="px-3 py-2 border-bottom d-flex align-items-center justify-content-between">
            <Label className="fw-bold">Scheduled to Complete</Label>
            <ButtonGroup></ButtonGroup>
        </div>
    );
};

const Trait = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const activeTraitId = params.traitId;
    const [prevValues, setPrevValues] = useState();
    const [newValues, setNewValues] = useState({});
    const [traitNames, setTraitNames] = useState([]);
    const [errors, setErrors] = useState({});
    const [hasChanged, setHasChanged] = useState(false);
    const talentAreas = useSelector((state) => state.talent.talentAreas);
    const traits = useSelector((state) => state.talent.traits);
    const actions = useSelector((state) => state.talent.actions);
    const { traitName, description, boardsOnly, managersOnly, guidance, actionIds, talentAreaIds } = newValues;

    // Get a list of other trait names to check for duplicates
    useEffect(() => {
        setTraitNames(Object.values(traits).map((trait) => trait.traitName));
    }, [traits]);

    // Load the active trait and save the original values for change comparison
    useEffect(() => {
        fetchTrait(activeTraitId).then((trait) => {
            setPrevValues(trait);
            setNewValues(trait);
            dispatch(SELECT_TRAIT(activeTraitId));
        });
    }, [dispatch, activeTraitId]);

    // Check if any values have changed
    useEffect(() => {
        setHasChanged(!isEqual(prevValues, newValues));
    }, [prevValues, newValues]);

    // Sets the field value but doesn't immediately save the changes
    const setFieldValue = (field, value) => {
        setNewValues({ ...newValues, [field]: value });
    };

    const handleEditTrait = async () => {
        if (hasChanged) {
            await setTrait(newValues).catch((error) => {
                toast.error("There was a problem editing the Trait.");
            });
            setPrevValues(newValues);
        }
    };

    if (Object.keys(newValues).length === 0) return <LoadingIndicator />;

    return (
        <div className="d-flex bg-white">
            <div className="f-2 d-flex flex-column justify-content-between px-3">
                <div className="bg-white d-flex flex-column py-3 px-2">
                    <div className="tile mb-3">
                        <FormGroup className="mb-4">
                            <Label for="traitName">Trait Name</Label>
                            <Input
                                type="text"
                                name="traitName"
                                maxLength={FIELD_SIZE_LIMITS.TRAIT_NAME}
                                minLength={1}
                                onChange={(e) => {
                                    setFieldValue("traitName", e.target.value);
                                    if (hasChanged && traitNames.includes(e.target.value)) {
                                        setErrors({ ...errors, traitName: "A Trait with this name already exists." });
                                    }
                                }}
                                onBlur={handleEditTrait}
                                value={traitName}
                                autoComplete="off"
                            />
                            <FormFeedback>{errors.traitName}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                style={{ minHeight: 140 }}
                                maxLength={FIELD_SIZE_LIMITS.DESCRIPTION}
                                placeholder="(optional)"
                                onChange={(e) => setFieldValue("description", e.target.value)}
                                onBlur={handleEditTrait}
                                value={description || ""}
                                autoComplete="off"
                            />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="tile mb-3">
                        <Label for="guidance">Guidance</Label>
                        <RichTextEditor
                            minHeight={180}
                            content={guidance || ""}
                            onBlur={handleEditTrait}
                            onChange={(rawContent) => {
                                setFieldValue("guidance", rawContent);
                            }}
                        />
                    </div>
                    <div className="tile mb-3">
                        <TraitScope
                            managersOnly={managersOnly}
                            boardsOnly={boardsOnly}
                            onSetManagersOnly={(value) => setFieldValue("managersOnly", value)}
                            onSetBoardsOnly={(value) => setFieldValue("boardsOnly", value)}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-white f-5">
                <TraitToolbar />
            </div>
        </div>
    );
};

export default Trait;

*/

const Trait = () => <div></div>;

export default Trait;
