import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timedOut: false,
    timeOutMessage: "",
    activeModal: false,
    activeSideArea: false,
    authHasLoaded: false,
    notifications: [],
    userNotifications: [],
    sidebarShow: true,
    runTour: false,
    allowTour: false,
    publicInfoUpdating: null,
    unsavedSideArea: false,
    appName: "Talent Mapper",
    selectedRoleId: null,
    selectedEmployeeId: null,
    selectedTalentAreaId: null,
    selectedActionId: null,
    selectedTraitId: null,
    checkedTraitIds: [],
    checkedIds: {},
    ratingView: null,
    loadingProgress: 0,
    ratingFilter: "employee",
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        SET_TIMED_OUT(state, action) {
            state.timedOut = action.payload;
        },
        SET_TIMED_OUT_MESSAGE(state, action) {
            state.timeOutMessage = action.payload;
        },
        SET_AUTH_HAS_LOADED(state, action) {
            state.authHasLoaded = action.payload;
        },
        SET_UNSAVED_SIDE_AREA(state, action) {
            state.unsavedSideArea = action.payload;
        },
        SET_SIDEBAR_SHOW(state, action) {
            state.sidebarShow = action.payload;
        },
        SET_MODAL(state, action) {
            state.activeModal = action.payload;
        },
        SET_SIDE_AREA(state, action) {
            state.activeSideArea = action.payload;
        },
        UPDATE_NOTIFICATIONS(state, action) {
            state.notifications = action.payload;
        },
        UPDATE_USER_NOTIFICATIONS(state, action) {
            state.userNotifications = action.payload;
        },
        SET_RUN_TOUR(state, action) {
            state.runTour = action.payload;
        },
        SET_ALLOW_TOUR(state, action) {
            state.allowTour = action.payload;
        },
        SELECT_EMPLOYEE(state, action) {
            state.selectedEmployeeId = action.payload;
        },
        SELECT_ACTION(state, action) {
            state.selectedActionId = action.payload;
        },
        SELECT_TRAIT(state, action) {
            state.selectedTraitId = action.payload;
        },
        SELECT_TALENT_BOARD(state, action) {
            state.selectedTalentBoardId = action.payload;
        },
        SELECT_TALENT_AREA(state, action) {
            state.selectedTalentAreaId = action.payload;
        },
        SELECT_ROLE(state, action) {
            state.selectedRoleId = action.payload;
        },
        SELECT_TRAITS(state, action) {
            state.checkedTraitIds = action.payload;
        },
        SET_CHECKED_IDS(state, action) {
            state.checkedIds = { ...state.checkedIds, ...action.payload };
        },
        SET_RATING_VIEW(state, action) {
            state.ratingView = action.payload;
        },
        SET_ACTIVE_SEGMENT(state, action) {
            state.activeSegment = action.payload;
        },
        RESET_APP: (state, action) => {
            state = { ...initialState, authHasLoaded: true };
        },
        RESET_SELECTED: (state, action) => {
            state.selectedRoleId = null;
            state.selectedEmployeeId = null;
            state.selectedTalentAreaId = null;
            state.selectedActionId = null;
            state.selectedTraitId = null;
            state.checkedTraitIds = [];
            state.checkedIds = {};
            state.ratingView = null;
        },
    },
});

const { actions, reducer } = appSlice;

export const {
    SET_TIMED_OUT,
    SET_TIMED_OUT_MESSAGE,
    SET_AUTH_HAS_LOADED,
    SET_SIDEBAR_SHOW,
    SET_UNSAVED_SIDE_AREA,
    SET_MODAL,
    SET_SIDE_AREA,
    SET_BLOCKED_EXIT,
    UPDATE_NOTIFICATIONS,
    UPDATE_USER_NOTIFICATIONS,
    SET_RUN_TOUR,
    SET_ALLOW_TOUR,
    SET_ROLE_VIEW_STATE,
    SELECT_ROLE,
    SELECT_TALENT_AREA,
    SELECT_ACTION,
    SELECT_TRAIT,
    SELECT_EMPLOYEE,
    SELECT_TALENT_BOARD,
    SELECT_TRAITS,
    SET_CHECKED_IDS,
    SET_RATING_VIEW,
    SET_ACTIVE_SEGMENT,
    SOFT_RESET_APP,
    RESET_APP,
    RESET_SELECTED,
} = actions;

export default reducer;
