import classNames from "classnames";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonGroup, DropdownItem, FormGroup, Input, Label, Nav, NavItem, NavLink } from "reactstrap";
import { LoadingIndicator, RolePicker, TalentAreaRadialChart, TraitPicker, TraitsTable } from "../../components";
import UncontrolledCollapseBlock from "../../components/CollapseBlock/CollapseBlock";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import ResponsiveContainer from "../../components/ResponsiveContainer/ResponsiveContainer";
import BoardMembersTable from "../../components/Tables/TalentBoardTables/BoardMembersTable";
import { AssignedToTalentBoard, LinkedToTalentBoard } from "../../components/TalentBoards/TalentBoardSnippets";
import { FIELD_SIZE_LIMITS, MANAGEMENT_BOARD_ID } from "../../constants";
import AppDropDown from "../../containers/AppDropDown";
import { addTalentBoardToRoles, removeTalentBoardFromRole } from "../../firebase/actions/role";
import { disableTalentBoard, setTalentBoard } from "../../firebase/actions/talentBoard";
import { fetchTalentBoard } from "../../firebase/fetch";
import { getBatch } from "../../firebase/firebaseUtils";
import { TalentBoardModel } from "../../models";
import { SELECT_ROLE, SELECT_TALENT_BOARD, SET_CHECKED_IDS } from "../../redux/appSlice";
import { lookEmployee, lookRole, lookTalentBoard, lookTraits } from "../../redux/utils/looks";
import {
    filterSnapshotsByTalentBoard,
    getAverageTalentAreaRatings,
    getDivisionSnapshot,
    makeRatingsArray,
} from "../../utils/snapshotUtils";

const TABS = {
    traits: "Traits",
    members: "Linked Roles",
};

const ICON_SIZE = 32;
const CHART_SIZE = 160;
const MEMBER_TABLE_ID = "MEM";
const TRAIT_TABLE_ID = "TRT";

const memberColumns = ["jobTitle", "displayName", "managerName"];
const traitColumns = ["traitName", "talentAreaNames"];

const TabBar = (props) => {
    const dispatch = useDispatch();
    const { changeTab, activeTab, checkedCount, memberTableId, traitTableId, onRemoveTraits, onRemoveRoles } = props;

    const handleChangeTab = (tab) => {
        if (tab === "members") {
            dispatch(SET_CHECKED_IDS({ [traitTableId]: [] }));
        } else {
            dispatch(SET_CHECKED_IDS({ [memberTableId]: [] }));
        }
        changeTab(tab);
    };

    return (
        <div className="pt-2 d-flex justify-content-between bg-transparent align-items-center px-3 border-bottom">
            <div className="d-flex justify-content-between bg-transparent">
                <Nav tabs className="f-3 d-flex fullpage-tabs justify-content-between bg-transparent">
                    <div className="d-flex">
                        {Object.keys(TABS).map((tab) => {
                            return (
                                <NavItem key={`tab-${tab}`}>
                                    <NavLink
                                        className={classNames({
                                            active: props.activeTab === tab,
                                        })}
                                        onClick={() => {
                                            handleChangeTab(tab);
                                        }}
                                    >
                                        {TABS[tab]}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </div>
                </Nav>
            </div>
            <div className="d-flex pb-2">
                <ButtonGroup className="me-3">
                    <AppDropDown caret title="Bulk Actions" disabled={checkedCount === 0}>
                        {activeTab === "traits" ? (
                            <DropdownItem onClick={onRemoveTraits}>
                                {`Remove ${checkedCount} Traits from Board`}
                            </DropdownItem>
                        ) : (
                            <DropdownItem onClick={onRemoveRoles}>
                                {`Remove ${checkedCount} Members from Board`}
                            </DropdownItem>
                        )}
                    </AppDropDown>
                </ButtonGroup>
            </div>
        </div>
    );
};

const TalentBoard = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const activeBoardId = params.boardId;
    const [activeTab, setActiveTab] = useState("traits");
    const [linkedRoleIds, setLinkedRoleIds] = useState([]);
    const [roleTableData, setRoleTableData] = useState([]);
    const [linkedRoleIdsToAdd, setLinkedRoleIdsToAdd] = useState([]);
    const [linkedTraitIds, setLinkedTraitIds] = useState([]);
    const [traitIdsToAdd, setTraitIdsToAdd] = useState([]);
    const [prevValues, setPrevValues] = useState();
    const [newValues, setNewValues] = useState({});
    const [hasChanged, setHasChanged] = useState(false);
    const [boardRatings, setBoardRatings] = useState([]);
    const [filteredSnapshots, setFilteredSnapshots] = useState({});
    const [overallRating, setOverallRating] = useState();
    const traits = useSelector((state) => state.talent.traits);
    const roles = useSelector((state) => state.org.roles);
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const { talentBoardName, description } = newValues;
    const memberTableId = `${activeBoardId}-${MEMBER_TABLE_ID}`;
    const traitTableId = `${activeBoardId}-${TRAIT_TABLE_ID}`;
    const managementBoard = activeBoardId === MANAGEMENT_BOARD_ID;
    const checkedRoleIds = useSelector((state) => state.app.checkedIds[memberTableId]);
    const checkedTraitIds = useSelector((state) => state.app.checkedIds[traitTableId]);
    const checkedIds = activeTab === "members" ? checkedRoleIds : checkedTraitIds;
    const checkedCount = checkedIds ? checkedIds.length : 0;

    // Load Board data
    useEffect(() => {
        fetchTalentBoard(activeBoardId).then((board) => {
            board = board || lookTalentBoard(activeBoardId);
            const boardModel = new TalentBoardModel(board);
            const newLinkedIds = boardModel.getLinkedRoles(true);
            setLinkedRoleIds(newLinkedIds);
            setPrevValues(board || {});
            setNewValues(board || {});
            dispatch(SELECT_TALENT_BOARD(activeBoardId));
        });
    }, [dispatch, activeBoardId]);

    // Load the rating data
    useEffect(() => {
        const traits = lookTraits();
        const fullTreeSnapshot = getDivisionSnapshot(topLevelRoleId, snapshots, roles, traits);
        const filteredSnapshots = filterSnapshotsByTalentBoard(snapshots, activeBoardId);
        const talentAreaRatings = makeRatingsArray(getAverageTalentAreaRatings(filteredSnapshots));
        const overallRating = fullTreeSnapshot?.talentBoards?.[activeBoardId]?.overallRating ?? 0;
        setFilteredSnapshots(filteredSnapshots);
        setBoardRatings(talentAreaRatings);
        setOverallRating(overallRating);
    }, [activeBoardId, topLevelRoleId, snapshots, roles, traits]);

    // Check if any board values have changed
    useEffect(() => {
        setHasChanged(!isEqual(prevValues, newValues));
        const traitIds = newValues.traitIds || [];
        setLinkedTraitIds(traitIds);
    }, [prevValues, newValues]);

    // Prep linked roles table
    useEffect(() => {
        let newTableData = Object.values(linkedRoleIds).map((roleId) => {
            const role = lookRole(roleId);
            let tableRow = { ...role };
            const employee = role && lookEmployee(role.incumbentId);
            const parentRole = role && lookRole(role.parentRoleId);
            const manager = parentRole && lookEmployee(parentRole.incumbentId);
            const managerName = manager ? manager.displayName : "Vacant";
            tableRow.displayName = employee ? employee.displayName : "Vacant";
            tableRow.managerName = managerName;
            return tableRow;
        });
        setRoleTableData(newTableData);
    }, [linkedRoleIds]);

    const setFieldValue = (field, value) => {
        setNewValues({ ...newValues, [field]: value });
    };

    const handleSaveTalentBoard = async () => {
        if (hasChanged) {
            await setTalentBoard(newValues).catch((error) => {
                toast.error("There was a problem editing the Talent Board.");
            });
            setPrevValues(newValues);
        }
    };

    const handleLinkNewRoleIds = () => {
        addTalentBoardToRoles(linkedRoleIdsToAdd, activeBoardId)
            .commit()
            .then(() => {
                setLinkedRoleIds((prev) => prev.concat(linkedRoleIdsToAdd));
                setLinkedRoleIdsToAdd([]);
            })
            .catch((error) => {
                setLinkedRoleIdsToAdd([]);
                toast.error("There was a problem connecting these roles to the Talent Board.");
            });
    };

    const handleRemoveRolesFromBoard = () => {
        const onConfirm = () => {
            let batch = getBatch();
            checkedRoleIds.forEach((roleId) => {
                batch = removeTalentBoardFromRole(roleId, activeBoardId, batch);
            });
            batch.commit().then(() => {
                dispatch(SELECT_ROLE());
                setLinkedRoleIds((prev) => prev.filter((id) => !checkedRoleIds.includes(id)));
                dispatch(SET_CHECKED_IDS({ [memberTableId]: [] }));
            });
        };

        // Confirm before removing
        if (checkedRoleIds && checkedRoleIds.length > 0) {
            confirmModal("remove_roles_from_board", onConfirm);
        }
    };

    const handleRemoveCheckedTraits = () => {
        const onConfirm = () => {
            const newTraitIds = linkedTraitIds.filter((id) => !checkedTraitIds.includes(id));
            dispatch(SET_CHECKED_IDS({ [traitTableId]: [] }));
            handleSetTraits(newTraitIds);
        };

        // Confirm before removing
        if (checkedTraitIds && checkedTraitIds.length > 0) {
            confirmModal("remove_traits_from_board", onConfirm);
        }
    };

    const handleSetTraits = (newTraitIds) => {
        const updatedBoard = { ...newValues, traitIds: newTraitIds };
        setTraitIdsToAdd([]);
        setTalentBoard(updatedBoard);
        setNewValues(updatedBoard);
        setPrevValues(updatedBoard);
    };

    // eslint-disable-next-line no-use-before-define
    const handleDisableTalentBoard = () => {
        const onConfirm = async () => {
            await disableTalentBoard(activeBoardId);
            navigate(-1);
        };

        // Confirm before removing
        confirmModal("delete", onConfirm, "talentBoard");
    };

    const renderTable = () => {
        if (activeTab === "members") {
            if (roleTableData.length === 0) {
                return null;
            } else {
                return (
                    <BoardMembersTable
                        checkbox
                        tableId={memberTableId}
                        columnKeys={memberColumns}
                        data={roleTableData}
                        iconSize={ICON_SIZE}
                        selectableRows
                        removeBorder
                        rowsPerPage={400}
                    />
                );
            }
        } else {
            if (linkedTraitIds.length === 0) {
                return null;
            }
            return (
                <TraitsTable
                    checkbox
                    onRemoveTraits={handleRemoveCheckedTraits}
                    onRemoveRoles={handleRemoveRolesFromBoard}
                    tableId={traitTableId}
                    columnKeys={traitColumns}
                    snapshots={filteredSnapshots}
                    data={Object.values(traits)}
                    filteredIds={linkedTraitIds}
                    iconSize={ICON_SIZE - 6}
                    removeBorder
                    rowsPerPage={400}
                />
            );
        }
    };

    const renderAddTile = () => {
        if (activeTab === "members") {
            return (
                <UncontrolledCollapseBlock
                    title="Link More Roles"
                    usePlusIcon
                    onCollapse={() => setLinkedRoleIdsToAdd([])}
                >
                    <div className="pt-2">
                        <RolePicker
                            excludeRootRole
                            excludeIds={linkedRoleIds}
                            selectedIds={linkedRoleIdsToAdd}
                            maxMenuHeight={320}
                            onChange={setLinkedRoleIdsToAdd}
                            isMulti
                        />
                        <Button
                            disabled={linkedRoleIdsToAdd.length === 0}
                            className="basic mt-3 float-end"
                            onClick={handleLinkNewRoleIds}
                        >
                            SAVE
                        </Button>
                    </div>
                </UncontrolledCollapseBlock>
            );
        } else {
            return (
                <UncontrolledCollapseBlock title="Link More Traits" usePlusIcon onCollapse={() => setTraitIdsToAdd([])}>
                    <div className="pt-2">
                        <TraitPicker
                            excludeIds={linkedTraitIds}
                            selectedIds={traitIdsToAdd}
                            maxMenuHeight={320}
                            onChange={setTraitIdsToAdd}
                            isMulti
                        />
                        <Button
                            disabled={traitIdsToAdd.length === 0}
                            className="basic mt-3 float-end"
                            onClick={() => handleSetTraits([...linkedTraitIds, ...traitIdsToAdd])}
                        >
                            SAVE
                        </Button>
                    </div>
                </UncontrolledCollapseBlock>
            );
        }
    };

    if (!prevValues) return <LoadingIndicator />;

    return (
        <div className="inner-container flex-row align-items-stretch bg-white">
            <div className="f-2 px-4 d-flex flex-column justify-content-center border-right scrollable">
                <div className="py-3">
                    <div className="charts d-flex justify-content-start">
                        <div
                            style={{ height: CHART_SIZE, width: CHART_SIZE }}
                            className="d-flex justify-content-center"
                        >
                            <TalentAreaRadialChart
                                ratings={boardRatings}
                                overallRating={overallRating}
                                width={CHART_SIZE}
                            />
                        </div>
                    </div>
                    <h3 className="mb-3">{newValues.talentBoardName}</h3>
                    <div>
                        <AssignedToTalentBoard talentBoard={newValues} className="mb-2" />
                        <LinkedToTalentBoard talentBoard={newValues} className="mb-2" />
                    </div>
                </div>
                <div className="f-5 d-flex">
                    <div className="f-2 d-flex flex-column scrollable pb-5">
                        <div className="tile mb-3">
                            <FormGroup className="mb-4">
                                <Label for="talentBoardName">Talent Board Name</Label>
                                <Input
                                    type="text"
                                    name="talentBoardName"
                                    maxLength={FIELD_SIZE_LIMITS.TALENT_BOARD_NAME}
                                    minLength={1}
                                    onChange={(e) => {
                                        setFieldValue("talentBoardName", e.target.value);
                                    }}
                                    value={talentBoardName || ""}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label for="description">Description</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    maxLength={FIELD_SIZE_LIMITS.DESCRIPTION}
                                    style={{ minHeight: 120 }}
                                    placeholder="(optional)"
                                    onChange={(e) => {
                                        setFieldValue("description", e.target.value);
                                    }}
                                    value={description || ""}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <Button
                                disabled={!hasChanged || !newValues.talentBoardName}
                                className="basic mt-3 float-end"
                                onClick={handleSaveTalentBoard}
                            >
                                SAVE
                            </Button>
                        </div>
                        <div className="tile">{renderAddTile()}</div>
                        <div className="tile">
                            {managementBoard && (
                                <p className="fs-small text-muted">The Management Talent Board cannot be deleted.</p>
                            )}
                            <Button disabled={managementBoard} onClick={handleDisableTalentBoard}>
                                DELETE BOARD
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-container f-5 justify-content-between">
                <TabBar
                    activeTab={activeTab}
                    activeBoardId={activeBoardId}
                    changeTab={setActiveTab}
                    onRemoveTraits={handleRemoveCheckedTraits}
                    onRemoveRoles={handleRemoveRolesFromBoard}
                    memberTableId={memberTableId}
                    traitTableId={traitTableId}
                    checkedCount={checkedCount}
                />
                <ResponsiveContainer>{renderTable()}</ResponsiveContainer>
            </div>
        </div>
    );
};

export default TalentBoard;
