import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { TalentBoardModel } from "../../../models";
import { lookTalentBoard, lookTrait } from "../../../redux/utils/looks";
import TraitPolarAreaChart from "../../Charts/TraitCharts/TraitPolarAreaChart";
import "./gauge-cards.scss";

const RatingsTable = ({ ratings, selected, setSelected, disableAutoScroll }) => {
    const rowsRef = useRef({});
    useEffect(() => {
        const selectedRow = rowsRef.current[selected];
        if (selectedRow && !disableAutoScroll) {
            selectedRow.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [selected, disableAutoScroll]);

    const rows = useMemo(() => {
        return Object.entries(ratings)
            .sort(([, ratingA], [, ratingB]) => ratingB - ratingA)
            .map(([traitId, rating]) => {
                const trait = lookTrait(traitId);
                return (
                    <tr
                        ref={(el) => (rowsRef.current[traitId] = el)}
                        className={classNames("clickable", { "c-primary": selected === traitId })}
                        key={traitId}
                        onMouseEnter={() => setSelected(traitId)}
                        onMouseLeave={() => setSelected()}
                    >
                        <td>{trait?.traitName}</td>
                        <td>{rating}</td>
                    </tr>
                );
            });
    }, [setSelected, ratings, selected]);

    return (
        <table className="fs-tiny breakdown-table">
            <thead>
                <tr>
                    <th>Trait Name</th>
                    <th>Rating</th>
                </tr>
            </thead>
            <tbody className="scrollable" style={{ height: 300 }}>
                {rows}
            </tbody>
        </table>
    );
};

const BreakdownModal = (props) => {
    const [tableIsActive, setTableIsActive] = useState(false);
    const [hovered, setHovered] = React.useState(null);
    const { boardId, snapshot = {} } = props;
    const ratings = useMemo(() => {
        if (!boardId) {
            return snapshot.ratings;
        } else {
            let filteredRatings = {};
            const talentBoard = lookTalentBoard(boardId);
            const talentBoardModel = talentBoard && new TalentBoardModel(talentBoard);
            const boardTraits = talentBoardModel && talentBoardModel.getTraits();
            boardTraits.forEach((trait) => {
                filteredRatings[trait.id] = snapshot.ratings[trait.id];
            });
            return filteredRatings;
        }
    }, [boardId, snapshot]);

    const handleSetSelected = (id) => {
        setHovered(id);
    };

    return (
        <Modal isOpen={props.isOpen} size="lg" centered>
            <ModalHeader toggle={props.toggle}>{props.header}</ModalHeader>
            <ModalBody className="d-flex p-1">
                <div className="f-1 d-flex align-items-center p-3">
                    <TraitPolarAreaChart
                        ratings={ratings}
                        padding={10}
                        selected={hovered}
                        onSegmentHover={handleSetSelected}
                    />
                </div>
                <div
                    className="f-1 p-2 d-flex flex-column overflow-hide"
                    style={{ height: 360 }}
                    onMouseEnter={() => setTableIsActive(true)}
                    onMouseLeave={() => setTableIsActive(false)}
                >
                    <RatingsTable
                        disableAutoScroll={tableIsActive}
                        ratings={ratings}
                        selected={hovered}
                        setSelected={handleSetSelected}
                    />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default BreakdownModal;
