import classNames from "classnames";
import ordinal from "ordinal-numbers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import UncontrolledCollapseBlock from "../../../components/CollapseBlock/CollapseBlock";
import { Help } from "../../../components/Help/Help";
import { CheckBox } from "../../../components/Inputs/Inputs";
import { requestAssessments } from "../../../firebase/actions/assessments";
import { changeWorkspaceClaims } from "../../../firebase/actions/employee";
import { RoleModel } from "../../../models";
import { SET_PENDING_ASSESSMENTS } from "../../../redux/assessmentsSlice";
import { lookEmployee, lookRole } from "../../../redux/utils/looks";
import { dateIsBefore } from "../../../utils/basicUtils";
import { getChildRoleIds, getRoleIncumbent, getRoleOrgLevel } from "../../../utils/roleUtils";

const HELP_ID = "request-assessments";

const HelpSection = () => (
    <Help helpId={HELP_ID}>
        <p className="mb-3">This area allows you to notify managers to complete Talent Assessments for their team.</p>
        <h6 className="mb-2">When you send a request to a Manager:</h6>
        <ul className="bullet-points">
            <li>
                <span className="fw-bold">If they have joined Talent Mapper:</span> they will receive an email asking
                them to complete their assessments
            </li>
            <li>
                <span className="fw-bold">If they haven't joined Talent Mapper:</span> they will receive an invite to
                Talent Mapper via their email.
            </li>
        </ul>
        <h6>Note</h6>
        <ul className="bullet-points">
            <li>Employees with no direct reports will not appear in this list.</li>
            <li>Managers without any out of date employee assessments will not appear in this list</li>
        </ul>
    </Help>
);

const Level = ({ title, orgLevel, managerRoleIds, checkedRoleIds, requestedRoleIds, onClick, onRoleClick }) => {
    const [roleIds, setRoleIds] = useState([]);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const cohort = managerRoleIds.filter((roleId) => orgLevel === getRoleOrgLevel(roleId));
        setRoleIds(cohort);
    }, [managerRoleIds, orgLevel]);

    useEffect(() => {
        const uncheckedCount = roleIds
            .filter((roleId) => !checkedRoleIds.includes(roleId))
            .filter((roleId) => !requestedRoleIds.includes(roleId))
            .filter((roleId) => {
                const role = lookRole(roleId);
                const incumbent = role && lookEmployee(role.incumbentId);
                return incumbent && !!incumbent.email;
            }).length;
        setChecked(roleIds.length > 0 && uncheckedCount === 0);
    }, [checkedRoleIds, requestedRoleIds, roleIds]);

    return (
        <UncontrolledCollapseBlock
            title={title}
            type="checkbox"
            checked={checked}
            onAction={() => onClick(orgLevel, !checked)}
        >
            <div className="ps-4">
                {roleIds.map((roleId) => {
                    const incumbent = getRoleIncumbent(roleId);
                    const missingEmail = !incumbent || !incumbent.email;
                    const incumbentName = incumbent ? incumbent.displayName : "Vacant Role";
                    const isChecked = checkedRoleIds.includes(roleId) || requestedRoleIds.includes(roleId);
                    const requestedAlready = requestedRoleIds.includes(roleId);
                    return (
                        <CheckBox
                            className={classNames("my-2")}
                            key={roleId}
                            disabled={requestedAlready || missingEmail}
                            label={incumbentName}
                            checked={isChecked}
                            onClick={(newChecked) => onRoleClick(roleId, newChecked)}
                        />
                    );
                })}
            </div>
        </UncontrolledCollapseBlock>
    );
};

const RequestAssessmentArea = () => {
    const dispatch = useDispatch();
    const [managerRoleIds, setManagerRoleIds] = useState([]);
    const [checkedRoleIds, setCheckedRoleIds] = useState([]);
    const [levels, setLevels] = useState([]);
    const [requestedRoleIds, setRequestedRoleIds] = useState({});
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const requestDates = useSelector((state) => state.assessments.requestDates);
    const pendingUpdates = useSelector((state) => state.assessments.pendingUpdates);
    const thresholdDate = useSelector((state) => state.assessments.settings.thresholdDate);
    const selectedRole = lookRole(selectedRoleId);
    const selectedRoleModel = new RoleModel(selectedRole);
    const selectedRoleRequestDate = requestDates[selectedRoleId];
    const selectedRoleRequested = selectedRoleModel.haveAssessmentsBeenRequested(selectedRoleRequestDate);
    const incumbent = selectedRoleModel.getIncumbent();

    useEffect(() => {
        // Get org info for the selected role
        const selectedRoleLevel = getRoleOrgLevel(selectedRoleId);
        const subordinateRoleIds = getChildRoleIds(selectedRoleId, true);

        // Remove any role that doesn't have child roles
        const managersOnly = subordinateRoleIds.filter((roleId) => {
            const role = lookRole(roleId);
            const roleModel = new RoleModel(role);
            return roleModel.hasChildren();
        });
        setManagerRoleIds(managersOnly);

        // Set levels
        const uniqueLevels = [...new Set(managersOnly.map((roleId) => getRoleOrgLevel(roleId)))];
        const newLevels = uniqueLevels.map((level, idx) => {
            let newLevel = { id: level };
            if (selectedRoleLevel === level - 1) {
                newLevel.title = "Direct Reports";
            } else {
                const ordinalNumber = ordinal(idx + 1);
                newLevel.title = `${ordinalNumber} level`;
            }
            return newLevel;
        });
        setLevels(newLevels);

        // Set ids of roles that have already been requested
        const newRequestedIds = managersOnly.filter((roleId) => {
            const date = requestDates[roleId];
            return date && dateIsBefore(thresholdDate, date);
        });
        setRequestedRoleIds(newRequestedIds);
    }, [thresholdDate, requestDates, selectedRoleId]);

    const handleToggleLevelChecked = (orgLevel, checked) => {
        let newChecked = [];
        const roleIdsAtlevel = managerRoleIds
            .filter((roleId) => getRoleOrgLevel(roleId) === orgLevel)
            .filter((roleId) => {
                const role = lookRole(roleId);
                const incumbent = role && lookEmployee(role.incumbentId);
                return incumbent && !!incumbent.email;
            });
        if (checked) {
            newChecked = checkedRoleIds.concat(roleIdsAtlevel);
        } else {
            newChecked = checkedRoleIds.filter((roleId) => !roleIdsAtlevel.includes(roleId));
        }
        setCheckedRoleIds([...new Set(newChecked)]);
    };

    const handleRoleChecked = (roleId, checked) => {
        if (!checked) {
            setCheckedRoleIds(checkedRoleIds.filter((id) => id !== roleId));
        } else {
            setCheckedRoleIds(checkedRoleIds.concat(roleId));
        }
    };

    const handleRequestAssessments = async () => {
        // List all the roles that requests are going to receive a request
        const roles = checkedRoleIds.map((roleId) => {
            return lookRole(roleId);
        });

        // Send the assessment requests (only users will get notification emails)
        const success = await requestAssessments(roles);
        if (success) {
            dispatch(SET_PENDING_ASSESSMENTS(checkedRoleIds));
            const plural = roles.length > 1 ? "s" : "";
            setCheckedRoleIds([]);
            toast.success(`Assessment${plural} Requested`);
        } else {
            toast.error("Error sending requests");
        }

        // Invite the users who need inviting
        roles.forEach((role) => {
            const { incumbentId } = role;
            const incumbent = lookEmployee(incumbentId);
            const isUser = incumbent && incumbent.isUser;
            if (incumbentId && !isUser) {
                const newClaims = { userRole: "manager" };
                changeWorkspaceClaims(incumbentId, newClaims).commit();
            }
        });
    };

    return (
        <div className="d-flex inner-container flex-column align-items-stretch justify-content-between bg-transparent">
            <div className="scrollable px-3 pb-5">
                <HelpSection />
                <CheckBox
                    className="my-2 fs-large"
                    key={selectedRoleId}
                    disabled={selectedRoleRequested}
                    label={incumbent.displayName}
                    checked={checkedRoleIds.includes(selectedRoleId) || selectedRoleRequested}
                    onClick={(newChecked) => handleRoleChecked(selectedRoleId, newChecked)}
                />
                <hr />
                {levels.map((level) => {
                    return (
                        <Level
                            title={level.title}
                            key={`level-${level.id}`}
                            orgLevel={level.id}
                            onClick={handleToggleLevelChecked}
                            onRoleClick={handleRoleChecked}
                            managerRoleIds={managerRoleIds}
                            checkedRoleIds={checkedRoleIds}
                            requestedRoleIds={requestedRoleIds}
                        />
                    );
                })}
            </div>
            <div className="pt-3 px-3 border-top">
                <Button
                    className="float-end"
                    disabled={checkedRoleIds.length === 0 || pendingUpdates.length > 0}
                    onClick={handleRequestAssessments}
                >
                    {`Send Requests (${checkedRoleIds.length})`}
                </Button>
            </div>
        </div>
    );
};

export default RequestAssessmentArea;
