import React, { useState } from "react";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { useLanguage } from "../../../../translations/LanguageContext";
import { FIELD_SIZE_LIMITS } from "../../../../constants";
import { emailInUseAlready, employeeNumberInUseAlready } from "../../../../utils/employeeUtils";
import { FormStep } from "../../../FormStepper/FormStepper";

const EmployeeDetailsStep = (props) => {
    const { values, touched, handleChange, handleBlur, setFieldValue, status, language } = props;
    const [errors, setErrors] = useState({});
    const { t } = useLanguage();

    const checkEmail = (email) => {
        if (email) {
            const emailInUse = emailInUseAlready(email, values.id);
            setFieldValue("emailInUse", emailInUse);
            if (emailInUse) {
                setErrors({ ...errors, email: t("email_in_use", "warnings") });
            } else {
                let { email, ...newErrors } = errors;
                setErrors(newErrors);
            }
        }
    };

    const checkEmployeeNumber = (email) => {
        if (email) {
            const employeeNumberInUse = employeeNumberInUseAlready(email, values.existingEmployeeId);
            setFieldValue("employeeNumberInUse", employeeNumberInUse);
            if (employeeNumberInUse) {
                setErrors({ ...errors, employeeNumber: t("employee_number_in_use", "warnings") });
            } else {
                let { employeeNumber, ...newErrors } = errors;
                setErrors(newErrors);
            }
        }
    };

    return (
        <FormStep title={language.employee_details_step}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="firstname">{t("firstname")}</Label>
                        <Input
                            type="text"
                            name="firstname"
                            readOnly={status.existingEmployee}
                            maxLength={FIELD_SIZE_LIMITS.FIRSTNAME}
                            invalid={errors.firstname && touched.firstname}
                            placeholder={t("required")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstname || ""}
                            autoComplete="off"
                        />
                        {status.existingEmployee && (
                            <p className="mb-0 c-warning fs-tiny text-capitalize">{t("read_only")}</p>
                        )}
                        <FormFeedback>{errors.firstname}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="surname">{t("firstname")}</Label>
                        <Input
                            type="text"
                            name="surname"
                            readOnly={status.existingEmployee}
                            maxLength={FIELD_SIZE_LIMITS.SURNAME}
                            invalid={errors.surname && touched.surname}
                            placeholder={t("required")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.surname || ""}
                            autoComplete="off"
                        />
                        {status.existingEmployee && (
                            <p className="mb-0 c-warning fs-tiny text-capitalize">{t("read_only")}</p>
                        )}
                        <FormFeedback>{errors.surname}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="email">{t("email")}</Label>
                <Input
                    type="text"
                    name="email"
                    maxLength={FIELD_SIZE_LIMITS.EMAIL}
                    readOnly={status.existingEmail}
                    value={values.email || ""}
                    invalid={(errors.email || errors.emailInUse) && touched.email}
                    placeholder={t("required")}
                    onChange={(e) => {
                        setFieldValue("email", e.target.value);
                        checkEmail(e.target.value);
                    }}
                    onBlur={handleBlur}
                    autoComplete="on"
                />
                {status.existingEmail && <p className="mb-0 c-warning fs-tiny text-capitalize">{t("read_only")}</p>}
                <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="employeeNumber">{t("employee_number")}</Label>
                <Input
                    type="text"
                    name="employeeNumber"
                    maxLength={FIELD_SIZE_LIMITS.EMPLOYEE_NO}
                    invalid={(errors.employeeNumber || errors.employeeNumberInUse) && touched.employeeNumber}
                    placeholder={t("optional")}
                    onChange={(e) => {
                        setFieldValue("employeeNumber", e.target.value);
                        checkEmployeeNumber(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.employeeNumber || ""}
                    autoComplete="off"
                />
                <FormFeedback>{errors.employeeNumber}</FormFeedback>
            </FormGroup>
        </FormStep>
    );
};

export default EmployeeDetailsStep;
