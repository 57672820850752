import { createSlice, current } from "@reduxjs/toolkit";
import { DEFAULT_PREFERENCES } from "../constants";
import { cLog } from "../utils/basicUtils";
import { setSavedPreferences } from "./utils/thunks";

const initialState = {
    loggedIn: false,
    uid: false,
    userReady: false,
    unauthorized: false,
    firstname: false,
    surname: false,
    displayName: false,
    email: false,
    topLevelRoleId: false,
    rootRoleId: false,
    claimsHaveLoaded: false,
    activeWorkspaceId: false,
    invitesChecked: false,
    preferencesInitialized: false,
    workspaceInvites: [],
    viewedHelp: [],
    viewedTours: [],
    localPreferences: DEFAULT_PREFERENCES,
    savedPreferences: false,
    claims: {},
    id: "",
};

function formatName(userData) {
    const { firstname, surname } = userData;
    if (firstname && surname) {
        return `${firstname} ${surname}`;
    } else {
        return "Name Missing";
    }
}

function setClaims(claims, workspaceId) {
    if (claims && workspaceId) {
        let { workspaceClaims } = claims;
        const activeClaims = workspaceClaims ? workspaceClaims[workspaceId] : {};
        const newClaims = { ...claims, ...activeClaims };
        return newClaims;
    } else {
        return claims;
    }
}

function setTopLevelRoleId(rootRoleId, claims) {
    if (claims.topLevelAccess || claims.developer) {
        return rootRoleId;
    } else {
        return claims.roleId;
    }
}

function checkUnauthorized(claims = {}, invitesChecked, workspaceInvites = []) {
    // If the user is a developer they can use the app no matter what
    if (claims.developer) {
        return false;
    }

    // If the invites haven't been checked they might be able to use the app
    if (!invitesChecked) {
        return false;
    }

    // If the user has no workspaceClaims and no invites, they can't use the app
    const { workspaceClaims = {} } = claims;
    const joinedWorkspaces = Object.keys(workspaceClaims).length;
    if (joinedWorkspaces === 0 && workspaceInvites.length === 0) {
        return true;
    }

    return false;
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        SET_ROOT_ROLE_ID(state, action) {
            state.rootRoleId = action.payload;
            state.topLevelRoleId = setTopLevelRoleId(action.payload, state.claims);
        },
        SET_USER(state, action) {
            const userData = action.payload;
            state.displayName = formatName(userData);
            Object.entries(userData).forEach(([key, value]) => {
                state[key] = value;
            });

            // Confirm claims have loaded and check if the user is authorized to use the app
            if (userData.claims) {
                const workspaceInvites = current(state.workspaceInvites);
                state.unauthorized = checkUnauthorized(userData.claims, state.invitesChecked, workspaceInvites);

                // Confirm the user is ready only once claims have loaded
                state.userReady = true;
            }

            // If the user has a workspaceId, set the claims to the active workspace
            if (state.activeWorkspaceId) {
                state.claims = setClaims(userData.claims, state.activeWorkspaceId);
                state.topLevelRoleId = setTopLevelRoleId(state.rootRoleId, state.claims);
            }
        },
        SET_LOCAL_PREFERENCES(state, action) {
            state.localPreferences = { ...state.localPreferences, ...action.payload };
        },
        SET_WORKSPACE_INVITES(state, action) {
            state.workspaceInvites = action.payload;
            state.invitesChecked = true;
            if (state.claimsHaveLoaded) {
                const claims = current(state.claims);
                state.unauthorized = checkUnauthorized(claims, true, action.payload);
            }
        },
        SET_ACTIVE_WORKSPACE_ID(state, action) {
            const activeWorkspaceId = action.payload;
            state.activeWorkspaceId = activeWorkspaceId;
            const claims = current(state.claims);
            state.claims = setClaims(claims, activeWorkspaceId);
        },
        SET_SIGNIN_USER(state, action) {
            const user = action.payload || {};
            const { authId, email } = user;
            state.authId = authId;
            state.email = email;
            state.loggedIn = !!authId;
        },
        SET_VIEWED_HELP(state, action) {
            state.helpViewed = action.payload;
        },
        SET_VIEWED_TOURS(state, action) {
            state.viewedTours = action.payload;
        },
        RESET_USER: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSavedPreferences.fulfilled, (state, action) => {
                const { alignedToWorkspace, preferences } = action.payload;
                state.savedPreferences = preferences;
                // On first load, set the local preferences to match saved
                if (!state.preferencesInitialized) {
                    state.localPreferences = preferences;
                }

                // Ensure the local nodeCategory is correct
                state.localPreferences.nodeCategory = preferences.nodeCategory;

                // Ensures the saved preferences don't overwrite unsaved local preferences if other user data changes
                if (alignedToWorkspace) {
                    state.preferencesInitialized = true;
                }
            })
            .addCase(setSavedPreferences.rejected, (state, action) => {
                state.error = action.error.message;
                cLog(action.error.message, "ERROR MESSAGE");
            });
    },
});

const { actions, reducer } = userSlice;

export const {
    SET_ROOT_ROLE_ID,
    SET_USER,
    SET_AUTHORIZED,
    SET_LOCAL_PREFERENCES,
    SET_WORKSPACE_INVITES,
    SET_ACTIVE_WORKSPACE_ID,
    SET_SIGNIN_USER,
    SET_HELP_VIEWED,
    SET_VIEWED_TOURS,
    SET_VIEWED_HELP,
    RESET_USER,
} = actions;

export default reducer;
