import { arrayRemove, arrayUnion, setDoc } from "firebase/firestore";
import { setPendingTalentChange } from "../../redux/utils/dispatches";
import { lookTalentBoard } from "../../redux/utils/looks";
import { getUniqueId, removeUndefined } from "../../utils/basicUtils";
import { getNewTalentInfoDocId, getTalentBoardDocRef } from "../firebaseUtils";

export async function setTalentBoard(receivedData) {
    let { id, importCount, ...preppedData } = receivedData;
    id = id || getUniqueId();

    preppedData = removeUndefined(preppedData);

    // don't do anything if there are no values to update
    if (Object.keys(preppedData).length === 0) return false;

    // Set to pending if any global fields are changing
    const currentData = lookTalentBoard(id);
    if (currentData) setPendingTalentChange(id, currentData, receivedData);

    // Set the public infoDocId
    if (!preppedData.publicInfoDocId) {
        const currentTalentBoard = lookTalentBoard(id);
        const existingPublicInfoDocId = currentTalentBoard && currentTalentBoard.publicInfoDocId;
        preppedData.publicInfoDocId = existingPublicInfoDocId || getNewTalentInfoDocId();
    }

    const talentBoardDocRef = getTalentBoardDocRef(id);
    await setDoc(talentBoardDocRef, preppedData, { merge: true });
    return id;
}

export async function disableTalentBoard(talentBoardId) {
    let talentBoard = lookTalentBoard(talentBoardId);
    const { id, publicInfoDocId } = talentBoard;
    const update = { id: id, publicInfoDocId: publicInfoDocId, traitIds: [], disabled: true };
    return setTalentBoard(update);
}

export async function addTraitsToTalentBoard(talentBoardId, addIds = []) {
    const talentBoard = lookTalentBoard(talentBoardId);
    const prevTraitIds = talentBoard.traitIds || [];
    const traitsToAdd = addIds.filter((id) => !prevTraitIds.includes(id));
    if (traitsToAdd.length > 0) {
        const update = { id: talentBoardId, traitIds: arrayUnion(...traitsToAdd) };
        return setTalentBoard(update);
    }
}

export async function removeTraitsFromTalentBoard(talentBoardId, removeIds = []) {
    const talentBoard = lookTalentBoard(talentBoardId);
    const prevTraitIds = talentBoard.traitIds || [];
    const traitsToRemove = removeIds.filter((id) => prevTraitIds.includes(id));
    if (traitsToRemove.length > 0) {
        const update = { id: talentBoardId, traitIds: arrayRemove(...traitsToRemove) };
        return setTalentBoard(update);
    }
}
