import React from "react";
import { acceptInvite } from "../../firebase/actions/invites";
import SignInPanel from "../../pages/SignIn/SignInPanel";
import { JoinWorkspace } from "../Forms";

const AcceptInvite = ({ invite, onAccept }) => {
    const handleAcceptInvite = (formValues) => {
        acceptInvite(invite, formValues).commit();
        onAccept(true);
    };

    return (
        <div className="d-flex sign-in-layout bg-light py-5">
            <div className="f-1" />
            <div className="f-5 d-flex bg-white shadow rounded py-3 px-5 justify-content-center">
                <SignInPanel
                    title={`Join ${invite.workspaceName}`}
                    description={`You've been invited to join this workspace by ${invite.invitedByName}`}
                    signInStatus="signed-in"
                >
                    <JoinWorkspace invite={invite} onSubmit={handleAcceptInvite} />
                </SignInPanel>
            </div>
            <div className="f-1" />
        </div>
    );
};

export default AcceptInvite;
