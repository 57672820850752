import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DropdownItem } from "reactstrap";
import IdPicker from "../Pickers/IdPickers/IdPicker";
import { setActiveWorkspace } from "../../firebase/actions/user";
import { fetchWorkspace, fetchWorkspaceList } from "../../firebase/fetch";
import SignInPanel from "../../pages/SignIn/SignInPanel";
import { resetWorkspace } from "../../redux/utils/dispatches";

const WorkspaceSelector = ({ preventLoad, workspaceIds, menuItem }) => {
    const [workspaceList, setWorkspaceList] = useState([]);
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState();
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const isDeveloper = useSelector((state) => state.user.claims.developer);

    useEffect(() => {
        async function fetchList() {
            const workspacesObj = await fetchWorkspaceList();
            if (workspacesObj) {
                const newList = Object.entries(workspacesObj).map(([id, name]) => {
                    return { id: id, label: name };
                });
                setWorkspaceList(newList);
            }
        }

        async function fetchSelected() {
            const newList = await Promise.all(
                workspaceIds.map(async (id) => {
                    const workspaceDoc = await fetchWorkspace(id);
                    return { id: workspaceDoc.id, label: workspaceDoc.workspaceName };
                })
            );
            setWorkspaceList(newList);
        }
        if (!preventLoad) {
            if (isDeveloper) {
                fetchList();
            } else {
                fetchSelected();
            }
        }
    }, [preventLoad, isDeveloper, workspaceIds]);

    const handleSetWorkspace = (id) => {
        resetWorkspace();
        setSelectedWorkspaceId(id);
    };
    const handleSignIn = () => {
        setActiveWorkspace(selectedWorkspaceId);
    };

    const handleSwitch = (id) => {
        if (id === activeWorkspaceId) return;
        setActiveWorkspace(id);
    };

    if (menuItem) {
        return (
            <>
                {workspaceList.map((workspace) => (
                    <DropdownItem key={workspace.id} onClick={() => handleSwitch(workspace.id)}>
                        <span className={classNames({ "fw-bold": activeWorkspaceId === workspace.id })}>
                            {workspace.label}
                        </span>
                    </DropdownItem>
                ))}
            </>
        );
    } else {
        return (
            <div className="d-flex sign-in-layout bg-light py-5">
                <div className="f-1" />
                <div className="f-5 d-flex bg-white shadow rounded py-3 px-5">
                    <SignInPanel
                        title="Select Workspace"
                        description={"Select the Workspace you would like to sign-in to"}
                        signInStatus="signed-in"
                    >
                        <div className="d-flex flex-column justify-content-center">
                            <div className="mb-5">
                                <IdPicker
                                    options={workspaceList}
                                    onChange={handleSetWorkspace}
                                    selectedIds={selectedWorkspaceId}
                                />
                            </div>
                            <Button className="mt-5" onClick={handleSignIn}>
                                Select
                            </Button>
                        </div>
                    </SignInPanel>
                </div>
                <div className="f-1" />
            </div>
        );
    }
};

export default WorkspaceSelector;
