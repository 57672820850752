import React from "react";
import { Button, FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { FIELD_SIZE_LIMITS, USER_ROLES } from "../../../../constants";
import { lookEmployee } from "../../../../redux/utils/looks";
import { useLanguage } from "../../../../translations/LanguageContext";
import { emailInUseAlready } from "../../../../utils/employeeUtils";
import { FormStep } from "../../../FormStepper/FormStepper";
import { CheckBox } from "../../../Inputs/Inputs";
import { UserRolePicker } from "../../../Pickers";
import { checkAccess } from "../../../../utils/userUtils";
import { revokeAccess } from "../../../../firebase/actions/user";

const InviteStep = (props) => {
    const { values, setFieldValue, errors, touched, handleBlur, hideNoRoleOption, showEmailField, changeMode } = props;
    const { existingEmployeeId, userRole, canCreateAdmins } = values;
    const fullRoleDetails = USER_ROLES[userRole];
    const roleInfo = fullRoleDetails && fullRoleDetails.tooltip;
    const isAdmin = userRole === "admin";
    const existingEmployee = lookEmployee(existingEmployeeId);
    const alreadyInvited = existingEmployee && existingEmployee.isUser;
    const { t } = useLanguage();
    const language = t("invite_step", "forms");
    const isDeveloper = checkAccess("developer");

    const checkEmail = (email) => {
        if (email) {
            const emailInUse = emailInUseAlready(email, values.id);
            setFieldValue("emailInUse", emailInUse);
        }
    };

    if (alreadyInvited && !changeMode) {
        return (
            <FormStep>
                <div className="pt-3 f-1 d-flex flex-column justify-content-center align-items-center">
                    <h5>{t("existing_member", "warnings")}</h5>
                    <p className="c-light">{t("existing_memberb", "warnings")}</p>
                </div>
            </FormStep>
        );
    }

    // Added to clean up the fallout of a bug
    const handleForceRevoke = async () => {
        await revokeAccess(existingEmployeeId).commit()
    };

    return (
        <FormStep>
            {showEmailField && (
                <FormGroup>
                    <Label for="email">{t("email")}</Label>
                    <Input
                        type="text"
                        name="email"
                        readOnly={changeMode}
                        maxLength={FIELD_SIZE_LIMITS.EMAIL}
                        value={values.email || ""}
                        invalid={(errors.email || errors.emailInUse) && touched.email}
                        placeholder={t("required")}
                        onChange={(e) => {
                            setFieldValue("email", e.target.value);
                            checkEmail(e.target.value);
                        }}
                        onBlur={handleBlur}
                        autoComplete="on"
                    />
                    <FormFeedback>{errors.email || errors.emailInUse}</FormFeedback>
                </FormGroup>
            )}
            <FormGroup>
                <Label for="userRole">{t("access")}</Label>
                <UserRolePicker
                    selectedIds={userRole || "none"}
                    hideNoRoleOption={hideNoRoleOption}
                    onChange={(userRole) => {
                        const isUser = userRole !== "none";
                        setFieldValue("userRole", userRole);
                        setFieldValue("isUser", isUser);
                        setFieldValue("canCreateAdmins", !isUser ? false : canCreateAdmins);
                    }}
                />
                <FormText>{roleInfo}</FormText>
                {isAdmin && <h6 className="c-danger">{t("admin_access", "warnings")}</h6>}
            </FormGroup>
            {isAdmin && (
                <FormGroup>
                    <Label for="userRole">{language.additional_permissions}</Label>
                    <CheckBox
                        label={language.can_create_admins}
                        checked={canCreateAdmins}
                        onClick={(val) => setFieldValue("canCreateAdmins", val)}
                    />
                </FormGroup>
            )}
            {isDeveloper && existingEmployeeId && (
                <FormGroup>
                    <Button onClick={handleForceRevoke}>Force Revoke</Button>
                </FormGroup>
            )}
        </FormStep>
    );
};

export default InviteStep;
