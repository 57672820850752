import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Fade, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { LoadingIndicator } from "..";
import { useLanguage } from "../../translations/LanguageContext";
import "./form-stepper.scss";

const FormStep = (props) => {
    return (
        <div className="step-container" style={props.style}>
            <Fade in={true} className={classNames(props.className, "step-body")}>
                {props.children}
            </Fade>
        </div>
    );
};
FormStep.propTypes = {
    sidePanel: PropTypes.elementType,
};

const FormStepper = (props) => {
    const { validationSchemas, invalidOveride, submitNowOverride, values, isOpen, onSubmit } = props;
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const [validationSchema, setValidationSchema] = useState();
    const Steps = React.Children.toArray(props.children);
    const CurrentStep = Steps && Steps[activeStepIndex];
    const isFirstStep = activeStepIndex === 0;
    const isLastStep = activeStepIndex === Steps.length - 1;
    const {t} = useLanguage();

    useEffect(() => {
        const validationSchemaBase = validationSchemas[activeStepIndex];
        setValidationSchema(Yup.object().shape(validationSchemaBase));
    }, [activeStepIndex, validationSchemas]);

    useEffect(() => {
        if (validationSchema) {
            validationSchema.isValid(values).then((valid) => {
                setIsValid(valid && !invalidOveride);
            });
        } else {
            setIsValid(!invalidOveride);
        }
    }, [values, validationSchema, invalidOveride]);

    if (!isOpen) return null;

    const handleNext = () => {
        const steps = React.Children.toArray(props.children);
        setActiveStepIndex((prevIdx) => Math.min(prevIdx + 1, steps.length - 1));
    };

    const handlePrevious = () => {
        setActiveStepIndex((prevIdx) => Math.max(0, prevIdx - 1));
    };

    const handleSubmit = () => {
        onSubmit();
    };

    const renderStepProgress = (Steps) => {
        if (Steps.length < 2 || props.hideStepProgress) return null;
        return (
            <div className="stepper-nav bg-dark">
                <div className="stepper-nav-items">
                    {Steps.map((Step, idx) => {
                        const isStepDone = idx < activeStepIndex;
                        return (
                            <div
                                key={`stepper-nav-item-${idx}`}
                                className={classNames(
                                    "stepper-nav-item",
                                    "border-light-bottom",
                                    { "stepper-nav-item-current": activeStepIndex === idx },
                                    { "stepper-nav-item-done": isStepDone }
                                )}
                            >
                                <div className="stepper-nav-item-label">{Step.props.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const style = {
        minHeight: props.minHeight,
        maxHeight: props.maxHeight,
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={props.toggle}
            onClosed={props.onClosed}
            className={props.className}
            centered={props.centered}
            backdrop={props.backdrop}
            size={props.size}
        >
            <div className={classNames(props.className, "form-stepper")} style={style}>
                <ModalHeader toggle={props.toggle}>{props.headerText}</ModalHeader>
                <div className="stepper-inner-container">
                    <div className="d-flex flex-column modal-split-pane-left">
                        <ModalBody className="f-1 d-flex flex-column">
                            {renderStepProgress(Steps)}
                            {props.isWaiting ? (
                                <LoadingIndicator flex type="spokes" />
                            ) : (
                                <div className={classNames("stepper f-1")}>{React.cloneElement(CurrentStep)}</div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            {!isFirstStep && (
                                <Button disabled={props.isWaiting} onClick={handlePrevious} outline>
                                    {t("previous")}
                                </Button>
                            )}
                            {!isLastStep && !submitNowOverride && (
                                <Button disabled={!isValid || props.isWaiting} outline onClick={handleNext}>
                                    {t("next")}
                                </Button>
                            )}
                            {(isLastStep || submitNowOverride) && (
                                <Button disabled={!isValid || props.isWaiting} outline onClick={handleSubmit}>
                                    {t("done")}
                                </Button>
                            )}
                        </ModalFooter>
                    </div>
                    {CurrentStep && CurrentStep.props.sidePanel && (
                        <div className="modal-split-pane-right bg-light">
                            <CurrentStep.Props.SidePanel {...CurrentStep.props} />
                        </div>
                    )}
                    {CurrentStep && !CurrentStep.props.sidePanel && props.sidePanel && (
                        <div className="modal-split-pane-right bg-light">
                            <props.sidePanel {...CurrentStep.props} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

FormStepper.propTypes = {
    isOpen: PropTypes.bool,
    isWaiting: PropTypes.bool,
    values: PropTypes.object,
    toggle: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
    centered: PropTypes.bool,
    backdrop: PropTypes.string,
    updatingId: PropTypes.string,
    minHeight: PropTypes.number,
    size: PropTypes.string,
    stepInfo: PropTypes.object,
    hideStepProgress: PropTypes.bool,
    invalidOveride: PropTypes.bool,
    submitNowOverride: PropTypes.bool,
    sidePanel: PropTypes.elementType,
    validationSchemas: PropTypes.array,
};

FormStepper.defaultProps = {
    isOpen: false,
    values: {},
    onClosed: () => {},
    className: "",
    centered: true,
    backdrop: "static",
    minHeight: 400,
    maxHeight: 800,
    stepInfo: {},
    hideStepProgress: false,
    validationSchemas: [],
    size: "lg",
    children: function (props, propName, componentName) {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, function (child) {
            if (!(child.type.prototype instanceof FormStep)) {
                error = new Error("`" + componentName + "` children should be of type `FormStep`.");
            }
        });
        return error;
    },
};

export { FormStep };

export default FormStepper;
