import React, { useEffect, useState } from "react";
import { RichTextEditor } from "../../../components";
import UncontrolledCollapseBlock from "../../../components/CollapseBlock/CollapseBlock";
import ActionItem from "../ActionItem";
import { AssessmentStep } from "../AssessmentStepper";

const ScheduledActions = ({ actionIds, onCheckAction, prevActionIds }) => {
    const [actionsToSchedule, setActionsToSchedule] = useState([]);

    useEffect(() => {
        setActionsToSchedule(actionIds.filter((id) => !prevActionIds.includes(id)));
    }, [actionIds, prevActionIds]);

    return (
        <div className="f-1 border-all py-2 px-3 rounded-4">
            <UncontrolledCollapseBlock title="New Actions to Schedule" defaultOpen>
                <p className="fs-small">
                    These are the actions that you have chosen to scheduled during this assessment.
                </p>
                <hr />
                {actionsToSchedule.map((actionId) => (
                    <div className="mb-3" key={actionId}>
                        <ActionItem actionId={actionId} onCheckAction={onCheckAction} checked={true} type="remove" />
                    </div>
                ))}
                {actionsToSchedule.length === 0 && <p className="pt-3">No New Actions to Schedule</p>}
            </UncontrolledCollapseBlock>
        </div>
    );
};

const SelfAssessmentSummary = (props) => {
    const { preppedAssessment, actionIds, prevActionIds = [], onCheckAction } = props;
    return (
        <AssessmentStep hideSidePanel className="p-0 d-flex flex-column inner-container body-padding">
            <div className="p-5 border-bottom">
                <h3>Self-Assessment Complete</h3>
                <p>
                    This assessment will be shared with your manager for them to review. You can add notes below which
                    will also be shared. You can edit your self-asessment via your dashboard at any time.
                </p>
            </div>
            <div className="d-flex f-1">
                <div className="f-1 d-flex flex-column p-5">
                    <h3>Notes</h3>
                    <RichTextEditor
                        onChange={(rawContent) => {
                            props.onSetNotes(rawContent);
                        }}
                        maxHeight={340}
                        className="f-1"
                        content={preppedAssessment.notes}
                    />
                </div>
                <div className="f-1 border-left scrollable p-5">
                    <h3 className="mb-4">Actions</h3>
                    <ScheduledActions
                        actionIds={actionIds}
                        prevActionIds={prevActionIds}
                        onCheckAction={onCheckAction}
                    />
                </div>
            </div>
        </AssessmentStep>
    );
};

export default SelfAssessmentSummary;
