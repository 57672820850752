import React from "react";
import { withFormik } from "formik";
import { Button, Col, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import * as Yup from "yup";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { useLanguage } from "../../../translations/LanguageContext";

const CreateWorkspaceInnerForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => {
    const { t } = useLanguage();
    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Col>
                    <Input
                        type="text"
                        name="workspaceName"
                        maxLength={FIELD_SIZE_LIMITS.WORKSPACE_NAME}
                        invalid={errors.workspaceName && touched.workspaceName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("company_name")}
                        value={values.workspaceName}
                    />
                    <FormFeedback>{errors.workspaceName}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col className="mb-3">
                    <Input
                        type="text"
                        name="firstname"
                        maxLength={FIELD_SIZE_LIMITS.FIRSTNAME}
                        invalid={errors.firstname && touched.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("firstname")}
                        value={values.firstname}
                    />
                    <FormFeedback>{errors.firstname}</FormFeedback>
                </Col>
                <Col>
                    <Input
                        type="text"
                        name="surname"
                        maxLength={FIELD_SIZE_LIMITS.SURNAME}
                        invalid={errors.surname && touched.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("surname")}
                        value={values.surname}
                    />
                    <FormFeedback>{errors.surname}</FormFeedback>
                </Col>
            </FormGroup>
            <Button color="primary" type="submit" disabled={!isValid}>
                {t("create_workspace")}
            </Button>
        </Form>
    );
};

const CreateWorkspaceForm = withFormik({
    mapPropsToValues: ({ ...props }) => {
        return {
            firstname: "",
            surname: "",
            workspaceName: "",
            jobTitle: "",
        };
    },
    validationSchema: (props) => {
        return Yup.object().shape(props.validationSchema);
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(CreateWorkspaceInnerForm);

const CreateWorkspace = (props) => {
    const { t } = useLanguage();
    const validationSchema = {
        workspaceName: Yup.string()
            .required(t("required", "warnings"))
            .max(FIELD_SIZE_LIMITS.WORKSPACE_NAME, t("length_max", "warnings")),
        firstname: Yup.string()
            .required(t("required", "warnings"))
            .max(FIELD_SIZE_LIMITS.FIRSTNAME, t("length_max", "warnings")),
        surname: Yup.string()
            .required(t("required", "warnings"))
            .max(FIELD_SIZE_LIMITS.SURNAME, t("length_max", "warnings")),
    };
    return <CreateWorkspaceForm {...props} validationSchema={validationSchema} />;
};

export default CreateWorkspace;
