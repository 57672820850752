import React from "react";
import fullnameLogo from "../../assets/img/logo/fullname-logo.png";
import { useLanguage } from "../../translations/LanguageContext";
import "./app-tour.scss";

const TourWelcome = ({ language, hideFooter }) => {
    const { t } = useLanguage();
    return (
        <div className="tour-page welcome-page d-flex flex-column">
            <img className="w-50 m-auto" src={fullnameLogo} alt="Talent Mapper logo with map pin" />
            <hr />
            <h5 className="mb-2 text-capitalise">{language.welcome_title}</h5>
            <div>
                <p>{language.welcome_text1}</p>
                <p>{language.welcome_text2}</p>
            </div>
            {!hideFooter && (
                <div className="text-muted">
                    <hr />
                    {t("rewatch_tour")}
                </div>
            )}
        </div>
    );
};

export default TourWelcome;
