import { NotUserIcon, PendingUserIcon, UserIcon } from "./MyIcons";


export const UserStatusIcon = ({ isUser, pending, tooltipId, size = 26 }) => {
    function renderIcon() {
        if (isUser && pending) {
            return <PendingUserIcon size={size} className="c-yellow" />;
        } else if (isUser && !pending) {
            return <UserIcon size={size} className="c-success" />;
        } else {
            return <NotUserIcon size={size} className="faded" />;
        }
    }
    const tooltipText = !isUser ? "Not yet invited" : pending ? "Yet to accept invite" : "Joined";
    return (
        <span data-tip={tooltipText} data-for={tooltipId}>
            {renderIcon()}
        </span>
    );
};