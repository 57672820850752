import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { EmployeePicker, Switch, TalentAreaPicker, TalentBoardPicker, TraitPicker } from "../../../../../components";
import { Pill } from "../../../../../components/BadgesAndPills/Pills";
import UncontrolledCollapseBlock, { CollapseBlock } from "../../../../../components/CollapseBlock/CollapseBlock";
import { CrossIcon } from "../../../../../components/Icons/MyIcons";
import IdPicker from "../../../../../components/Pickers/IdPickers/IdPicker";
import RatingSlider from "../../../../../components/Sliders/RatingSlider";
import { ORG_TABLE_COLUMNS } from "../../../../../constants";
import { lookEmployee, lookTalentArea, lookTalentBoard, lookTrait } from "../../../../../redux/utils/looks";
import { getUniqueId } from "../../../../../utils/basicUtils";

function getTalentName(type, id) {
    switch (type) {
        case "ratings":
            const trait = lookTrait(id);
            return trait && trait.traitName;
        case "talentAreas":
            const talentArea = lookTalentArea(id);
            return talentArea && talentArea.talentAreaName;
        case "talentBoards":
            const talentBoard = lookTalentBoard(id);
            return talentBoard && talentBoard.talentBoardName;
        default:
            return id;
    }
}

function getConditionOptions(fieldName) {
    switch (fieldName) {
        case "employeeStatus":
            return [
                { id: "isUser", label: "Is User" },
                { id: "roleId", label: "Has Role" },
            ];
        case "reportingLine":
            return [
                { id: "reportsTo", label: "Reports To" },
                { id: "subordinateOf", label: "Subordinate Of" },
            ];
        case "ratings":
        case "talentAreas":
        case "talentBoards":
            return [
                { id: "isAbove", label: "Greater Than" },
                { id: "isBelow", label: "Less Than" },
            ];
        case "f3": // Successors
            return [{ id: "includes", label: "Successor is" }];
        case "hasSuccessors":
            return [{ id: "hasSuccessors", label: "Is" }];
        case "missingField":
            return [{ id: "isEmpty", label: "is empty" }];
        default:
            return [
                { id: "is", label: "Is" },
                { id: "isNot", label: "Is Not" },
            ];
    }
}

const AddFilter = ({ filterOptions, onAddFilter }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [conditionOptions, setConditionOptions] = useState([]);
    const [disableAdd, setDisableAdd] = useState(true);
    const [selectedFieldOption, setSelectedFieldOption] = useState();
    const [fieldName, setFieldName] = useState();
    const [fieldId, setFieldId] = useState();
    const [filterValue, setFilterValue] = useState();
    const [condition, setCondition] = useState("is");

    // Clear fieldId & filterValue if the fieldType changes
    useEffect(() => {
        setFieldId();
        setFilterValue();
    }, [fieldName]);

    // Set the field option, clear filterValue & set conditons when the fieldName changes
    useEffect(() => {
        const field = filterOptions.find((opt) => fieldName === opt.id);
        const conditionOptions = getConditionOptions(fieldName);
        const firstOption = conditionOptions && conditionOptions[0];
        const firstOptionId = firstOption && firstOption.id;
        setConditionOptions(conditionOptions);
        setCondition(firstOptionId);
        setSelectedFieldOption(field);
        setFilterValue();
    }, [filterOptions, fieldName]);

    // Disable save button if needed informatiom is missing
    useEffect(() => {
        // Check field Id and array length when needed, otherwise just check filterValue
        switch (fieldName) {
            case "ratings":
            case "talentAreas":
            case "talentBoards":
                setDisableAdd(!filterValue || !fieldId || fieldId.length === 0);
                break;
            default:
                // Ignore value when not needed
                switch (condition) {
                    case "isUser":
                    case "roleId":
                    case "hasSuccessors":
                        setDisableAdd(false);
                        break;
                    default:
                        setDisableAdd(!filterValue);
                }
        }
    }, [fieldName, fieldId, condition, filterValue]);

    const handleAddFilter = () => {
        const category = selectedFieldOption.category;
        const filterId = getUniqueId(5);
        let filter = {
            fieldName: fieldName,
            condition: condition,
            filterId: filterId,
            filterValue: filterValue || false,
        };
        if (category) filter.category = category;
        if (fieldId) filter.fieldId = fieldId;
        onAddFilter(filter);
        setIsOpen(false);
    };

    const renderFieldIdPicker = () => {
        switch (fieldName) {
            case "ratings":
                return <TraitPicker isMulti selectedIds={fieldId} label="Traits" onChange={setFieldId} />;
            case "talentAreas":
                return <TalentAreaPicker isMulti selectedIds={fieldId} label="Talent Areas" onChange={setFieldId} />;
            case "talentBoards":
                return <TalentBoardPicker isMulti selectedIds={fieldId} label="Talent Boards" onChange={setFieldId} />;
            default:
                return null;
        }
    };

    const renderFilterValueInput = () => {
        const { type } = selectedFieldOption;
        switch (type) {
            case "slider":
                return (
                    <div className="f-1 d-flex align-items-center bg-white h-100 px-4 rounded border-all">
                        <RatingSlider value={filterValue} onChange={setFilterValue} />
                        <span className="ms-4">{filterValue}</span>
                    </div>
                );
            case "switch":
                return <Switch className="align-items-center d-flex" checked={filterValue} onChange={setFilterValue} />;
            case "employeePicker":
                return (
                    <EmployeePicker
                        topLevelRoleId
                        className="f-1"
                        selectedIds={filterValue}
                        onChange={setFilterValue}
                    />
                );
            case "columnPicker":
                return (
                    <IdPicker
                        options={ORG_TABLE_COLUMNS}
                        excludeIds={["managerName", "userBadge", "displayName"]}
                        selectedIds={filterValue}
                        onChange={setFilterValue}
                    />
                );
            default:
                return (
                    <IdPicker
                        idField="value"
                        options={selectedFieldOption.options}
                        selectedIds={filterValue}
                        onChange={setFilterValue}
                        unsorted
                    />
                );
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CollapseBlock title="Add Filter" usePlusIcon onToggle={handleToggle} isOpen={isOpen}>
            <div className="pt-2 d-flex flex-column children-mb-3">
                <IdPicker
                    selectedIds={fieldName}
                    options={filterOptions}
                    label="What do you want to filter?"
                    onChange={setFieldName}
                />
                {renderFieldIdPicker()}
                <div className="d-flex f-1">
                    <IdPicker
                        minWidth={220}
                        className="me-3"
                        selectedIds={condition}
                        options={conditionOptions}
                        onChange={setCondition}
                    />
                    <div className="f-1 align-items-center d-flex">
                        {selectedFieldOption && renderFilterValueInput()}
                    </div>
                </div>
                <Button disabled={disableAdd} onClick={handleAddFilter}>
                    Add Filter
                </Button>
            </div>
        </CollapseBlock>
    );
};

const DisplayFilter = ({ filterOption, filter, onDeleteFilter }) => {
    const [filterLabels, setFilterLabels] = useState({});

    useEffect(() => {
        const { fieldName, fieldId, condition, filterValue } = filter;
        let conditionOptions = getConditionOptions(fieldName);
        let valueLabelOverride;
        if (filterOption && filterOption.category === "assessment") {
            const customFieldOptions = filterOption && filterOption.options;
            const customFieldOption = customFieldOptions && customFieldOptions.find((opt) => opt.value === filterValue);
            valueLabelOverride = customFieldOption && customFieldOption.label;
        }
        let conditionOption = conditionOptions && conditionOptions.find((opt) => opt.id === condition);
        const conditionLabel = conditionOption && conditionOption.label;
        let newFilterLabels = {
            condition: conditionLabel,
            filterValue: valueLabelOverride || filterValue,
            fieldName: filterOption && filterOption.label,
        };

        switch (fieldName) {
            case "ratings":
            case "talentAreas":
            case "talentBoards":
                if (Array.isArray(fieldId)) {
                    let label = "";
                    fieldId.forEach((id, i) => {
                        if (i > 0) {
                            label = `${label} & `;
                        }
                        const itemName = getTalentName(fieldName, id);
                        label = `${label} ${itemName}`;
                    });
                    if (fieldId.length > 1) {
                        newFilterLabels.condition = `are ${conditionLabel}`;
                    } else {
                        newFilterLabels.condition = `is ${conditionLabel}`;
                    }
                    newFilterLabels.fieldName = label;
                }
                break;
            case "employeeStatus":
                newFilterLabels.fieldName = "Employee";
                if (!filterValue) {
                    delete newFilterLabels.condition;
                    newFilterLabels.filterValue = condition === "isUser" ? "Is Not User" : "Has No Role";
                }
                break;
            case "reportingLine":
                newFilterLabels.fieldName = "Employee";
                break;
            case "missingField":
                const columnOption = ORG_TABLE_COLUMNS.find((col) => col.id === filterValue);
                const label = columnOption && columnOption.label;
                newFilterLabels.fieldName = `${label} is missing`;
                newFilterLabels.condition = "";
                newFilterLabels.filterValue = "";
                break;
            default:
        }
        const optionType = filterOption && filterOption.type;
        switch (optionType) {
            case "employeePicker":
                const employee = lookEmployee(filterValue);
                newFilterLabels.filterValue = employee && employee.displayName;
                break;
            case "switch":
                delete newFilterLabels.condition;
                newFilterLabels.filterValue = filterValue ? "Is True" : "Is False";
                break;
            default:
        }

        setFilterLabels(newFilterLabels);
    }, [filter, filterOption]);

    return (
        <Pill className="bg-fun my-1 d-flex align-items-cente justify-content-between">
            <div className="d-flex align-items-center">
                <span className="me-1">{filterLabels.fieldName}</span>
                {filterLabels.condition && <span className="me-1 fw-bold">{filterLabels.condition}</span>}
                <span>{filterLabels.filterValue}</span>
            </div>
            <Button className="basic invert" onClick={() => onDeleteFilter(filter.filterId)}>
                <CrossIcon size={22} />
            </Button>
        </Pill>
    );
};

const TableFilters = ({ onDeleteFilter, filters = [], onAddFilter }) => {
    const [filterOptions, setFilterOptions] = useState([]);
    const allCustomFields = useSelector((state) => state.workspace.customFields);

    useEffect(() => {
        let assessmentFields = allCustomFields.filter((field) => field.category === "assessment");
        let newFilterOptions = [
            { id: "employeeStatus", label: "Employee Status", type: "switch" },
            { id: "reportingLine", label: "Reporting Line", type: "employeePicker" },
            { id: "hasSuccessors", label: "Has Successors", type: "switch" },
            { id: "ratings", label: "Trait Ratings", type: "slider" },
            { id: "talentAreas", label: "Talent Area Ratings", type: "slider" },
            { id: "talentBoards", label: "Talent Board Ratings", type: "slider" },
            { id: "missingField", label: "Missing Data", type: "columnPicker" },
        ];
        newFilterOptions = newFilterOptions.concat(assessmentFields);
        setFilterOptions(newFilterOptions);
    }, [allCustomFields]);

    return (
        <div>
            <UncontrolledCollapseBlock title="Filters" defaultOpen>
                <div className="mb-3">
                    {filters.map((filter) => {
                        const filterOption = filterOptions.find((option) => option.id === filter.fieldName);
                        const uniq = getUniqueId(5);
                        return (
                            <DisplayFilter
                                key={uniq}
                                filterOption={filterOption}
                                filter={filter}
                                onDeleteFilter={onDeleteFilter}
                            />
                        );
                    })}
                </div>
                <div className="tile">
                    <AddFilter filterOptions={filterOptions} onAddFilter={onAddFilter} />
                </div>
            </UncontrolledCollapseBlock>
        </div>
    );
};

export default TableFilters;
