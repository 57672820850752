import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { lookEmployee, lookRole, lookWorkspace } from "../../../redux/utils/looks";
import { getChildRoleIds } from "../../../utils/roleUtils";
import IdPicker from "./IdPicker";

const RolePicker = (props) => {
    const {
        selectedIds,
        directReportsOnly,
        isMulti,
        isClearable,
        filterBasisRoleId,
        excludeJuniors,
        excludeTeam,
        excludeSelf,
        excludeParent,
        excludeRootRole,
    } = props;
    const [excludeIds, setExcludeIds] = useState([]);
    const roles = useSelector((state) => state.org.roles);
    const topLevelRoleId = useSelector((state) => state.user.topLevelRoleId);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const basisRoleId = filterBasisRoleId || topLevelRoleId;

    // Determine the additional ids to exclude based on the props provided
    useEffect(() => {
        const roleList = Object.values(roles);
        let includeIds = getChildRoleIds(basisRoleId, !directReportsOnly);
        let extraExcluded = roleList.filter((role) => !includeIds.includes(role.id));
        if (excludeJuniors || excludeTeam) {
            extraExcluded = extraExcluded.concat(getChildRoleIds(basisRoleId, excludeJuniors));
        }
        if (excludeSelf) {
            extraExcluded.push(basisRoleId);
        }
        if (excludeRootRole) {
            extraExcluded.push(rootRoleId);
        }
        if (excludeParent) {
            const basisRole = lookRole(basisRoleId);
            const parentRoleId = basisRole && basisRole.parentRoleId;
            if (parentRoleId) {
                extraExcluded.push(parentRoleId);
            }
        }
        setExcludeIds(extraExcluded);
    }, [roles, basisRoleId, directReportsOnly, excludeRootRole, rootRoleId, excludeJuniors, excludeParent, excludeSelf, excludeTeam]);

    const getLabel = (role) => {
        if (role) {
            if (role.rootRole) {
                const workspace = lookWorkspace();
                return workspace.workspaceName;
            } else {
                const jobTitle = role.jobTitle || "No Job Title";
                const incumbent = lookEmployee(role.incumbentId);
                if (incumbent) {
                    return `${incumbent.displayName}, ${jobTitle}`;
                } else {
                    return `${jobTitle}`;
                }
            }
        }
    };

    return (
        <IdPicker
            {...props}
            name="roles"
            getLabel={getLabel}
            options={roles}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds.concat(props.excludeIds)}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

RolePicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    directReportsOnly: PropTypes.bool,
    isClearable: PropTypes.bool,
    filterBasisRoleId: PropTypes.string,
    excludeJuniors: PropTypes.bool,
    excludeRootRole: PropTypes.bool,
    excludeTeam: PropTypes.bool,
    excludeSelf: PropTypes.bool,
    excludeParent: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

RolePicker.defaultProps = {
    selectedIds: [],
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
};

export default RolePicker;
