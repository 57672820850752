import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EditActionArea from "../pages/Actions/SideAreas/EditActionArea";
import { AssessmentHistoryArea, RequestAssessmentArea, RoleEditArea } from "../pages/People";
import ComparisonArea from "../pages/People/SideAreas/ComparisonArea";
import EmployeeActionsArea from "../pages/People/SideAreas/EmployeeActionsArea";
import EmployeeTimelineArea from "../pages/People/SideAreas/EmployeeTimelineArea";
import LateAssessmentArea from "../pages/People/SideAreas/LateAssessmentArea";
import TalentMapConfigArea from "../pages/People/SideAreas/TalentMapConfigArea";
import { CreateOrgTable, EditOrgTable } from "../pages/People/Views/Org/OrgTable/OrgTableSettings";
import EditTalentArea from "../pages/TalentAreas/SideAreas/EditTalentArea";
import { SET_SIDE_AREA } from "../redux/appSlice";

const AppSideArea = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const activeSideArea = useSelector((state) => state.app.activeSideArea);

    // Closes side area if the url path changes
    React.useEffect(() => {
        dispatch(SET_SIDE_AREA(false));
    }, [dispatch, location]);

    switch (activeSideArea) {
        case "editTalentArea":
            return <EditTalentArea onClose={props.onClose} />;
        case "roleAdminArea":
            return <RoleEditArea isOpen={true} onClose={props.onClose} />;
        case "requestAssessments":
            return <RequestAssessmentArea isOpen={true} onClose={props.onClose} />;
        case "editAction":
            return <EditActionArea isOpen={true} onClose={props.onClose} />;
        case "employeeTimeline":
            return <EmployeeTimelineArea isOpen={true} onClose={props.onClose} />;
        case "assessmentHistory":
            return <AssessmentHistoryArea isOpen={true} onClose={props.onClose} />;
        case "employeeActions":
            return <EmployeeActionsArea isOpen={true} onClose={props.onClose} />;
        case "talentMapConfig":
            return <TalentMapConfigArea isOpen={true} onClose={props.onClose} />;
        case "lateAssessments":
            return <LateAssessmentArea isOpen={true} onClose={props.onClose} />;
        case "editOrgTable":
            return <EditOrgTable isOpen={true} onClose={props.onClose} />;
        case "createOrgTable":
            return <CreateOrgTable isOpen={true} onClose={props.onClose} />;
        case "compareRatings":
            return <ComparisonArea isOpen={true} onClose={props.onClose} />;
        default:
            return null;
    }
};

AppSideArea.propTypes = {
    onClose: PropTypes.func,
};

AppSideArea.defaultProps = {
    onClose: () => {},
};

export default AppSideArea;
