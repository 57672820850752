import PropTypes from "prop-types";
import React from "react";
import { FaUserAltSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { EmployeeCard, EmptyState } from "../..";

const EmployeeCards = (props) => {
    const { employees, xxl, xl, lg, md, sm, xs } = props;
    const navigate = useNavigate();

    const handleViewEmployee = (roleId) => {
        if (roleId) {
            navigate(`/people/${roleId}/employee`);
        }
    };

    if (employees.length === 0) {
        return (
            <EmptyState header="No Employees">
                <FaUserAltSlash size={42} />
            </EmptyState>
        );
    }

    return (
        <Container fluid>
            <Row>
                {employees.map((employee) => {
                    return (
                        <Col key={`$employee-card-${employee.id}`} xxl={xxl} xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
                            <EmployeeCard
                                cardBorder={props.cardBorder}
                                employeeId={employee.id}
                                onView={handleViewEmployee}
                            />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};

EmployeeCards.propTypes = {
    employees: PropTypes.array,
    className: PropTypes.string,
    cardBorder: PropTypes.bool,
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
};

EmployeeCards.defaultProps = {
    employees: [],
    xl: 3,
    lg: 3,
    md: 4,
    sm: 6,
    xs: 12,
};

export default EmployeeCards;
