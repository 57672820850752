import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { toast } from "react-toastify";
import { Button, Fade } from "reactstrap";
import useDynamicRefs from "use-dynamic-refs";
import { TraitRatedIcon } from "../../components/Icons/MyIcons";
import { delay } from "../../utils/basicUtils";
import { getExactColor } from "../../utils/snapshotUtils";
import "./assessments.scss";

const AssessmentStep = (props) => {
    return (
        <div className="step-container">
            <Fade in={true} className={classNames(props.className, "step-body")}>
                {props.children}
            </Fade>
        </div>
    );
};

const StepProgress = (props) => {
    const [getRef, setRef] = useDynamicRefs();
    const { activeStepIndex, steps, highlightUnrated, blinkUnrated, unratedIds } = props;
    const lastIndex = steps.length - 1;
    const LastStep = steps[lastIndex];

    useEffect(() => {
        // Get ref for specific ID
        const activeStep = steps[activeStepIndex];
        const stepId = activeStep.props.id;
        const thisRef = getRef(stepId);
        if (thisRef) {
            thisRef.current.focus();
            thisRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [getRef, activeStepIndex, steps]);

    const excludeLast = steps.slice(0, -1);

    return (
        <div className="f-1 scrollable stepper-nav db-dark scrollable-container border-right">
            <div className="stepper-nav-items f-1 flex-column">
                {excludeLast.map((AssessmentStep, idx) => {
                    const traitId = AssessmentStep.props.id;
                    const traitRating = AssessmentStep.props.traitRating;
                    const color = getExactColor(traitRating);
                    const isStepDone = !!traitRating;
                    const isStepActive = activeStepIndex === idx;
                    const blink = blinkUnrated && unratedIds.includes(traitId);
                    const highlight = highlightUnrated && unratedIds.includes(traitId);

                    return (
                        <div
                            ref={setRef(AssessmentStep.props.id)}
                            key={`stepper-nav-item-${idx}`}
                            className={classNames("stepper-nav-item d-flex", {
                                "stepper-nav-item-current": isStepActive,
                                "stepper-nav-item-done": isStepDone,
                                clickable: isStepDone,
                                "transition-blink": blink,
                            })}
                            onClick={() => props.onSetStepIndex(idx)}
                        >
                            <div
                                className={classNames("stepper-nav-item-label", {
                                    "c-danger": highlight,
                                })}
                            >
                                {AssessmentStep.props.title}
                            </div>
                            <div className="float-end">{isStepDone && <TraitRatedIcon color={color} />}</div>
                        </div>
                    );
                })}
            </div>
            <div className="stepper-nav-items">
                <div
                    ref={setRef(LastStep.props.id)}
                    key={`stepper-nav-item-${lastIndex}`}
                    className={classNames("d-flex stepper-nav-item last-step clickable", {
                        "stepper-nav-item-current": activeStepIndex === lastIndex,
                    })}
                    onClick={() => props.onSetStepIndex(lastIndex)}
                >
                    <div className="stepper-nav-item-label">{LastStep.props.title}</div>
                </div>
            </div>
        </div>
    );
};

const AssessmentStepper = (props) => {
    const { onSubmit, employeeId, onDiscardChanges, isNewAssessment, unratedIds, hasChanged } = props;
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [furthestStep, setFurthestStep] = useState(0);
    const [style, setStyle] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [highlightUnrated, setHighlightUnrated] = useState();
    const [blinkUnrated, setBlinkedUnrated] = useState();
    const { height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 2000 });
    const Steps = React.Children.toArray(props.children);
    const CurrentStep = Steps && Steps[activeStepIndex];
    const isFirstStep = activeStepIndex === 0;
    const isLastStep = activeStepIndex === Steps.length - 1;
    const minHeight = props.minHeight;

    useEffect(() => {
        setSubmitting(false);
    }, [employeeId]);

    useEffect(() => {
        if (height > minHeight) {
            setStyle({ minHeight: height });
        }
    }, [height, minHeight]);

    const handleNext = () => {
        const steps = React.Children.toArray(props.children);
        const isLastStep = activeStepIndex === steps.length - 1;
        if (!isLastStep) {
            setActiveStepIndex(activeStepIndex + 1);
            props.onStepChange(activeStepIndex + 1);
        }
        setFurthestStep(Math.max(activeStepIndex + 1, furthestStep));
    };

    const handlePrevious = () => {
        const isFirstStep = activeStepIndex === 0;
        if (!isFirstStep) {
            setActiveStepIndex(activeStepIndex - 1);
            props.onStepChange(activeStepIndex - 1);
        }
    };

    const handleSetStepIndex = (idx) => {
        setActiveStepIndex(idx);
        props.onStepChange(idx);
    };

    const handleSkipToEnd = () => {
        const lastStepIndex = Steps.length - 1;
        setActiveStepIndex(lastStepIndex);
    };

    const handleSubmit = async () => {
        if (unratedIds.length > 0) {
            setHighlightUnrated(true);
            setBlinkedUnrated(true);
            toast.error("Some Traits are still unrated. They're highlighted in red.");
            await delay(2000);
            setBlinkedUnrated(false);
        } else {
            setSubmitting(true);
            onSubmit();
        }
    };

    const renderFooter = () => (
        <div className="stepper-footer">
            {hasChanged && (
                <Button onClick={onDiscardChanges} outline>
                    Discard Changes
                </Button>
            )}
            {!isLastStep && (
                <Button disabled={props.allowSkipToEnd} onClick={handleSkipToEnd} outline>
                    Skip to End
                </Button>
            )}
            {!isFirstStep && (
                <Button onClick={handlePrevious} outline>
                    Previous
                </Button>
            )}
            {!isLastStep && <Button onClick={handleNext}>Next</Button>}
            {isLastStep && (
                <Button disabled={submitting} outline onClick={handleSubmit}>
                    Done
                </Button>
            )}
        </div>
    );

    return (
        <div className="f-1 assessment-stepper d-flex inner-container" ref={ref} style={style}>
            <div className="d-flex flex-column f-1 inner-container body-padding">
                <div className="f-1 d-flex flex-row d-flex inner-container">
                    <StepProgress
                        steps={Steps}
                        unratedIds={unratedIds}
                        blinkUnrated={blinkUnrated}
                        highlightUnrated={highlightUnrated}
                        onSetStepIndex={handleSetStepIndex}
                        furthestStep={furthestStep}
                        activeStepIndex={activeStepIndex}
                        isNewAssessment={isNewAssessment}
                    />
                    <div className={classNames("stepper f-4")}>{CurrentStep}</div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
};

AssessmentStepper.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
    centered: PropTypes.bool,
    backdrop: PropTypes.string,
    size: PropTypes.string,
    unratedIds: PropTypes.array,
    highlightUnrated: PropTypes.bool,
    fullstreen: PropTypes.bool,
    allowSkipToEnd: PropTypes.bool,
    hideStepProgress: PropTypes.bool,
    sidePanel: PropTypes.elementType,
    onStepChange: PropTypes.func,
};

AssessmentStepper.defaultProps = {
    isOpen: false,
    className: "",
    centered: true,
    backdrop: "static",
    hideStepProgress: false,
    fullscreen: false,
    size: "lg",
    onStepChange: () => {},
    onClosed: () => {},
    children: function (props, propName, componentName) {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, function (child) {
            if (!(child.type.prototype instanceof AssessmentStep)) {
                error = new Error("`" + componentName + "` children should be of type `AssessmentStep`.");
            }
        });
        return error;
    },
};

export { AssessmentStep };

export default AssessmentStepper;
