/* eslint-disable default-case */
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Spring } from "react-spring";
import autoprefix from "./autoprefix";
import "./Dock.scss";

const RESIZER_WIDTH = 10;

function autoprefixes(styles) {
    // eslint-disable-next-line no-sequences
    return Object.keys(styles).reduce((obj, key) => ((obj[key] = autoprefix(styles[key])), obj), {});
}

const styles = autoprefixes({
    wrapper: {
        position: "fixed",
        width: 0,
        height: 0,
        top: 0,
        left: 0,
    },

    dim: {
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 0,
        background: "rgba(0, 0, 0, 0.2)",
        opacity: 1,
    },

    dimAppear: {
        opacity: 0,
    },

    dimTransparent: {
        pointerEvents: "none",
    },

    dimHidden: {
        opacity: 0,
    },

    dock: {
        position: "fixed",
        zIndex: 1,
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
        background: "white",
        right: 0,
        top: 0,
        width: "100%",
        height: "100%",
    },

    dockHidden: {
        opacity: 0,
    },

    dockResizing: {
        transition: "none",
    },

    dockContent: {
        width: "100%",
        height: "100%",
        //overflow: "auto",
    },

    resizer: {
        position: "absolute",
        zIndex: 2,
        opacity: 0,
    },
});

function getTransitions(duration) {
    return ["right", "top", "width", "height"].map((p) => `${p} ${duration / 1000}s ease-out`);
}

function getDockStyles({ fluid, dockStyle, dockHiddenStyle, duration, position, isVisible }, { size, isResizing, fullWidth, fullHeight }) {
    let posStyle;
    const absSize = fluid ? size * 100 + "%" : size + "px";

    function getRestSize(fullSize) {
        return fluid ? 100 - size * 100 + "%" : fullSize - size + "px";
    }

    switch (position) {
        case "left":
            posStyle = {
                width: absSize,
                left: isVisible ? 0 : "-" + absSize,
            };
            break;
        case "right":
            posStyle = {
                // left: isVisible ? getRestSize(fullWidth) : "100%",
                right: isVisible ? 0 : "-100%",
                width: absSize,
            };
            break;
        case "top":
            posStyle = {
                top: isVisible ? 0 : "-" + absSize,
                height: absSize,
            };
            break;
        case "bottom":
            posStyle = {
                top: isVisible ? getRestSize(fullHeight) : fullHeight,
                height: absSize,
            };
            break;
    }

    const transitions = getTransitions(duration);

    const result = [
        styles.dock,
        autoprefix({
            transition: [...transitions, !isVisible && `opacity 0.01s linear ${duration / 1000}s`].filter((t) => t).join(","),
        }),
        dockStyle,
        autoprefix(posStyle),
        isResizing && styles.dockResizing,
        !isVisible && styles.dockHidden,
        !isVisible && dockHiddenStyle,
    ];

    return result;
}

function getDimStyles({ dimMode, dimStyle, duration, isVisible }, { isTransitionStarted }) {
    return [
        styles.dim,
        autoprefix({
            transition: `opacity ${duration / 1000}s ease-out`,
        }),
        dimStyle,
        dimMode === "transparent" && styles.dimTransparent,
        !isVisible && styles.dimHidden,
        isTransitionStarted && isVisible && styles.dimAppear,
        isTransitionStarted && !isVisible && styles.dimDisappear,
    ];
}

function getResizerStyles(position, state) {
    let resizerStyle;
    const size = RESIZER_WIDTH;

    switch (position) {
        case "left":
            resizerStyle = {
                right: -size / 2,
                width: size,
                top: 0,
                height: "100%",
                cursor: "col-resize",
            };
            break;
        case "right":
            resizerStyle = {
                width: size,
                left: 0,
                opacity: 1,
                top: 0,
                height: "100%",
                cursor: "col-resize",
            };
            break;
        case "top":
            resizerStyle = {
                bottom: -size / 2,
                height: size,
                left: 0,
                width: "100%",
                cursor: "row-resize",
            };
            break;
        case "bottom":
            resizerStyle = {
                top: -size / 2,
                height: size,
                left: 0,
                width: "100%",
                cursor: "row-resize",
            };
            break;
    }

    return [styles.resizer, autoprefix(resizerStyle)];
}

const OUTOFBOUNDS_SIDE = {
    LEFT: "left",
    RIGHT: "right",
};

function getFullSize(position, fullWidth, fullHeight) {
    return position === "left" || position === "right" ? fullWidth : fullHeight;
}

export default class Dock extends Component {
    constructor(props) {
        super(props);
        this.resizerRef = React.createRef();
        this.dockRef = React.createRef();
        this.state = {
            isControlled: typeof props.size !== "undefined",
            size: props.size || props.defaultSize,
            isDimHidden: !props.isVisible,
            fullWidth: typeof window !== "undefined" && window.innerWidth,
            fullHeight: typeof window !== "undefined" && window.innerHeight,
            isTransitionStarted: false,
            isWindowResizing: false,
            isResizing: false,
            outOfBounds: undefined,
        };
    }

    static propTypes = {
        position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
        zIndex: PropTypes.number,
        fluid: PropTypes.bool,
        size: PropTypes.number,
        defaultSize: PropTypes.number,
        dimMode: PropTypes.oneOf(["none", "transparent", "opaque"]),
        isVisible: PropTypes.bool,
        isResizable: PropTypes.bool,
        onVisibleChange: PropTypes.func,
        onSizeChange: PropTypes.func,
        dimStyle: PropTypes.object,
        dockStyle: PropTypes.object,
        duration: PropTypes.number,
        minResizeWith: PropTypes.number,
        maxResizeWith: PropTypes.number,
    };

    static defaultProps = {
        position: "left",
        zIndex: 1040,
        fluid: true,
        defaultSize: 0.3,
        dimMode: "opaque",
        duration: 200,
        isResizable: true,
        minResizeWith: 0.3,
        maxResizeWith: 0.8,
    };

    componentDidMount() {
        window.addEventListener("touchend", this.handleMouseUp);
        window.addEventListener("mouseup", this.handleMouseUp);
        window.addEventListener("touchmove", this.handleMouseMove);
        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("resize", this.handleResize);

        if (!window.fullWidth) {
            this.updateWindowSize();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("touchend", this.handleMouseUp);
        window.removeEventListener("mouseup", this.handleMouseUp);
        window.removeEventListener("touchmove", this.handleMouseMove);
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("resize", this.handleResize);
    }

    updateSize(props) {
        const { fullWidth, fullHeight } = this.state;

        this.setState({
            size: props.fluid
                ? this.state.size / getFullSize(props.position, fullWidth, fullHeight)
                : getFullSize(props.position, fullWidth, fullHeight) * this.state.size,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.isVisible !== prevProps.isVisible) {
            if (!this.props.isVisible) {
                window.setTimeout(() => this.hideDim(), this.props.duration);
            } else {
                this.setState({ isDimHidden: false });
            }

            window.setTimeout(() => this.setState({ isTransitionStarted: false }), 0);
        }
    }

    transitionEnd = () => {
        this.setState({ isTransitionStarted: false });
    };

    hideDim = () => {
        if (!this.props.isVisible) {
            this.setState({ isDimHidden: true });
        }
    };

    removePercents = (obj) => {
        for (let key of Object.keys(obj)) {
            const value = obj[key].replace("%", "");
            obj[key] = value;
        }

        return obj;
    };

    addPercents = (obj) => {
        for (let key of Object.keys(obj)) {
            obj[key] = obj[key] + "%";
        }

        return obj;
    };

    render() {
        const { children, zIndex, dimMode, position, isVisible, isResizable } = this.props;
        const { isResizing, size, isDimHidden, outOfBounds } = this.state;

        const dimStyles = Object.assign({}, ...getDimStyles(this.props, this.state));
        const dockStyles = Object.assign({}, ...getDockStyles(this.props, this.state));
        const resizerStyles = Object.assign({}, ...getResizerStyles(position, this.state));

        const springFrom = this.removePercents({
            // left: dockStyles.left,
            width: dockStyles.width,
        });
        const springTo = this.removePercents(
            !isResizing && outOfBounds
                ? {
                      //   left:
                      //       (1 -
                      //           (outOfBounds === OUTOFBOUNDS_SIDE.LEFT
                      //               ? this.props.maxResizeWith
                      //               : this.props.minResizeWith)) *
                      //           100 +
                      //       "%",
                      width: (outOfBounds === OUTOFBOUNDS_SIDE.LEFT ? this.props.maxResizeWith : this.props.minResizeWith) * 100 + "%",
                  }
                : {
                      //   left: dockStyles.left,
                      width: dockStyles.width,
                  }
        );

        return (
            <div style={Object.assign({}, styles.wrapper, { zIndex })}>
                {dimMode !== "none" && !isDimHidden && <div style={dimStyles} onClick={this.handleDimClick} />}
                <Spring
                    from={springFrom}
                    to={springTo}
                    config={{ tension: 300, friction: 20, precision: 0.1 }}
                    immediate={!isResizing}
                    onRest={(args) => {
                        if (!isResizing && outOfBounds) {
                            this.setState({
                                outOfBounds: undefined,
                                size: outOfBounds === OUTOFBOUNDS_SIDE.LEFT ? this.props.maxResizeWith : this.props.minResizeWith,
                            });
                        }
                    }}
                >
                    {(props) => {
                        const propsWithPercentsAdded = this.addPercents(props);
                        const newStyles = !isResizing && outOfBounds ? { ...dockStyles, ...propsWithPercentsAdded } : dockStyles;

                        return (
                            <div className="dock-wrapper" style={newStyles} ref={this.dockRef}>
                                <div className="dock-content" style={styles.dockContent}>
                                    {isResizable && (
                                        <div
                                            ref={this.resizerRef}
                                            className={`resizer-${position}`}
                                            style={resizerStyles}
                                            onMouseDown={this.handleMouseDown}
                                            onTouchStart={this.handleMouseDown}
                                        />
                                    )}
                                    {typeof children === "function"
                                        ? children({
                                              position,
                                              isResizing,
                                              size,
                                              isVisible,
                                          })
                                        : children}
                                </div>
                            </div>
                        );
                    }}
                </Spring>
            </div>
        );
    }

    handleDimClick = (e) => {
        e.stopPropagation();

        if (this.props.dimMode === "opaque") {
            this.props.onVisibleChange && this.props.onVisibleChange(false);
        }
    };

    handleResize = () => {
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(this.updateWindowSize.bind(this, true));
        } else {
            this.updateWindowSize(true);
        }
    };

    updateWindowSize = (windowResize) => {
        const sizeState = {
            fullWidth: window.innerWidth,
            fullHeight: window.innerHeight,
        };

        if (windowResize) {
            this.setState({
                ...sizeState,
                isResizing: true,
                isWindowResizing: windowResize,
            });

            this.debouncedUpdateWindowSizeEnd();
        } else {
            this.setState(sizeState);
        }
    };

    updateWindowSizeEnd = () => {
        this.setState({
            isResizing: false,
            isWindowResizing: false,
        });
    };

    debouncedUpdateWindowSizeEnd = debounce(this.updateWindowSizeEnd, 30);

    handleWrapperLeave = () => {
        this.setState({ isResizing: false });
    };

    handleMouseDown = () => {
        if (!this.state.isResizing) {
            this.setState({ isResizing: true });
        }
    };

    handleMouseUp = () => {
        if (this.state.isResizing) {
            this.setState({ isResizing: false });
        }
    };


    handleMouseMove = (e) => {
        if (!this.state.isResizing || this.state.isWindowResizing) return;

        if (!e.touches) e.preventDefault();

        const { position, fluid } = this.props;
        const { fullWidth, fullHeight, isControlled } = this.state;
        let { clientX: x, clientY: y } = e;

        if (e.touches) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }
        if (x < 0) {
            return;
        }

        let size;

        switch (position) {
            case "left":
                size = fluid ? x / fullWidth : x;
                break;
            case "right":
                size = fluid ? (fullWidth - x) / fullWidth : fullWidth - x;
                break;
            case "top":
                size = fluid ? y / fullHeight : y;
                break;
            case "bottom":
                size = fluid ? (fullHeight - y) / fullHeight : fullHeight - y;
                break;
        }

        let outOfBounds = false;

        if (size < this.props.minResizeWith) {
            outOfBounds = OUTOFBOUNDS_SIDE.RIGHT;
        }

        if (size > this.props.maxResizeWith) {
            outOfBounds = OUTOFBOUNDS_SIDE.LEFT;
        }

        this.props.onSizeChange && this.props.onSizeChange(size);

        if (!isControlled) {
            let newState = { size: x <= 0 ? 1 : size };
            if (this.state.outOfBounds !== outOfBounds) {
                newState = { ...newState, outOfBounds };
            }
            this.setState(newState);
        }
    };
}
