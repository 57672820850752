import React, { useEffect, useMemo, useState } from "react";
import { FaBell } from "react-icons/fa";
import Loading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    UncontrolledDropdown,
} from "reactstrap";
import { GlobalSearchField, UserAvatar } from "../components";
import { CountBadge } from "../components/BadgesAndPills/Badges";
import { AssessmentLateButton, BackButton, HelpButton } from "../components/Buttons/MyButtons";
import { confirmModal } from "../components/Dialogs/ConfirmAlert";
import { skipAssessment } from "../firebase/actions/assessments";
import { acceptInvite } from "../firebase/actions/invites";
import { clearNotifications } from "../firebase/actions/notifications";
import { userSignOut } from "../firebase/auth";
import { RoleModel } from "../models";
import WorkspaceSelector from "../components/Workspace/WorkspaceSelector";
import { SET_RUN_TOUR, SET_SIDE_AREA } from "../redux/appSlice";
import { lookEmployee } from "../redux/utils/looks";
import { useLanguage } from "../translations/LanguageContext";
import { listOverdueAssessments } from "../utils/assessmentUtils";
import { dateIsBefore, formatDate } from "../utils/basicUtils";
import { checkAccess } from "../utils/userUtils";
import AssessmentDropdown from "./AppAssessmentDropdown";
import Breadcrumbs from "./Breadcrumbs";

const MENU_STYLE = { width: 320, zIndex: 9999 };
const TOOLTIP_ID = "app-navbar-tooltip";

const InviteNotification = ({ invite }) => {
    const { workspaceId, workspaceName } = invite;
    const [waiting, setWaiting] = useState(false);
    const firstname = useSelector((state) => state.user.firstname);
    const surname = useSelector((state) => state.user.surname);
    const { t } = useLanguage();

    const handleAcceptInvite = async (invite) => {
        const userDetails = {
            firstname: firstname,
            surname: surname,
        };
        setWaiting(true);
        await acceptInvite(invite, userDetails).commit();
        setWaiting(false);
    };

    if (waiting) return <Loading type="dots" />;
    return (
        <DropdownItem key={workspaceId}>
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="dashboard-text mb-0">{workspaceName}</h5>
                <span className="btn outline text-uppercase" onClick={() => handleAcceptInvite(invite)}>
                    {t("join")}
                </span>
            </div>
        </DropdownItem>
    );
};

const Notifications = (props) => {
    const { notifications } = props;
    const [isOpen, setIsOpen] = useState(false);
    const invites = useSelector((state) => state.user.workspaceInvites);
    const claims = useSelector((state) => state.user?.claims);
    const workspaceClaims = claims?.workspaceClaims || {};

    // Excludes workspaces with no name, or workspaces the user is already a member of
    // Handles some abnormalities with the developer accounts
    const workspaceInvites = invites.filter((invite) => {
        return !!invite.workspaceName && !Object.keys(workspaceClaims || {}).includes(invite.workspaceId);
    });

    const { t } = useLanguage();

    const handleToggle = () => {
        setIsOpen(!isOpen);
        if (!isOpen) clearNotifications(notifications);
    };

    const renderWorkspaceInvites = () => {
        if (workspaceInvites.length > 0) {
            return (
                <>
                    <DropdownItem header>Workspace Invites</DropdownItem>
                    {workspaceInvites.map((invite) => {
                        return <InviteNotification key={invite.id} invite={invite} />;
                    })}
                </>
            );
        }
    };

    const renderRoleNotifications = () => {
        const labels = t("notificationLabels");
        if (notifications.length > 0) {
            notifications.map((notification) => {
                return (
                    <DropdownItem key={notification.id}>
                        <div>
                            <h5 className="dashboard-text">{labels[notification.type]}</h5>
                            <span className="dashboard-text">{formatDate(notification.received)}</span>
                        </div>
                    </DropdownItem>
                );
            });
        } else {
            return (
                <DropdownItem>
                    <div>{t("no_notifications")}</div>
                </DropdownItem>
            );
        }
    };

    return (
        <UncontrolledDropdown isOpen={isOpen} className="me-4" toggle={handleToggle}>
            <DropdownToggle nav className="p-0">
                <div>
                    <FaBell size="24" />
                    <CountBadge count={notifications.length + workspaceInvites.length} />
                </div>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu" style={MENU_STYLE}>
                {renderWorkspaceInvites()}
                <DropdownItem header>{t("notifications")}</DropdownItem>
                {renderRoleNotifications()}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const UnsavedAssessments = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const unsavedProgress = useSelector((state) => state.assessments.unsavedProgress);
    const employeeIds = Object.keys(unsavedProgress);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const { t } = useLanguage();
    if (location.pathname.includes("assessments")) return null;
    if (employeeIds.length === 0) return null;

    return (
        <UncontrolledDropdown isOpen={isOpen} className="me-4" toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle nav className="p-0">
                <Button className="nav-bar warning">{`Unsaved Assessments (${employeeIds.length})`}</Button>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu" style={{ margin: 0 }}>
                <DropdownItem header>{t("employees")}</DropdownItem>
                {employeeIds.map((empId) => {
                    const employee = lookEmployee(empId);
                    const isSelf = empId === userEmployeeId;
                    const page = isSelf ? "self-assessment" : empId;
                    const display = isSelf ? "Self Assessment" : employee?.displayName;
                    return (
                        <DropdownItem onClick={() => navigate(`/people/assessments/${page}`)} key={empId}>
                            {display}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const Settings = () => {
    const [isOpen, setIsOpen] = useState(false);
    const displayName = useSelector((state) => state.user.displayName);
    const claims = useSelector((state) => state.user.claims);
    const workspaceClaims = claims?.workspaceClaims || {};
    const workspacesArray = Object.keys(workspaceClaims);
    const { t } = useLanguage();

    const handleSignOut = () => {
        userSignOut();
    };

    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle nav className="p-0">
                <UserAvatar displayName={displayName} disableTooltip />
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu" style={MENU_STYLE}>
                <DropdownItem header>{displayName}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>{t("workspaces")}</DropdownItem>
                <WorkspaceSelector workspaceIds={workspacesArray} preventLoad={!isOpen} menuItem />
                <DropdownItem divider />
                <DropdownItem>
                    <Link to="settings/profile">{t("settings")}</Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleSignOut}>Sign-out</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

const AppNavBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [overdueCount, setOverdueCount] = useState([]);
    const managesAssessmentRequests = checkAccess("manageAssessmentRequests");
    const roleId = useSelector((state) => state.user.claims.roleId);
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const userRole = useSelector((state) => state.org.roles[roleId]);
    const allowTour = useSelector((state) => state.app.allowTour);
    const notifications = useSelector((state) => state.app.notifications);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const employees = useSelector((state) => state.org.employees);
    const lateDate = useSelector((state) => state.assessments.settings.lateDate);
    const pathname = window.location.pathname;
    const cutoffIndex = pathname.indexOf("/", 2) >= 0 ? pathname.indexOf("/", 2) : pathname.length;
    const activePage = pathname.substring(1, cutoffIndex);
    const roleModel = userRole && new RoleModel(userRole);
    const hasChildren = roleModel && roleModel.hasChildren();
    const currentPageIsAnAssessment = location.pathname.includes("assessments");
    const enableSelfAssessment = useSelector((state) => state.assessments.settings.enableSelfAssessment);
    const userEmployee = useSelector((state) => state.org.self);
    const userEmployeeId = userEmployee.id;
    const dateLastSelfAssessed = userEmployee?.dateLastSelfAssessed;

    const selfAssessmentDue = useMemo(() => {
        if (!enableSelfAssessment) return false;
        if (!userEmployeeId) return false;
        const thresholdDate = new Date();
        return !dateLastSelfAssessed || dateIsBefore(thresholdDate, dateLastSelfAssessed);
    }, [enableSelfAssessment, dateLastSelfAssessed, userEmployeeId]);

    const tooltipId = "app-navbar";
    const { t } = useLanguage();

    useEffect(() => {
        const overdue = listOverdueAssessments(employees, snapshots, lateDate, true);
        const uniqueParentRoleIds = [...new Set(overdue.map((item) => item.parentRoleId))];
        setOverdueCount(uniqueParentRoleIds.length);
    }, [employees, snapshots, lateDate]);

    const handleCompleteAssessment = (employeeId) => {
        if (!assessmentsOpen) {
            toast.error("Assessments are locked.");
        } else {
            navigate(`/people/assessments/${employeeId}`);
        }
    };

    const handleCompleteSelfAssessment = () => {
        if (!assessmentsOpen) {
            toast.error("Assessments are locked.");
        } else {
            navigate(`/people/assessments/self-assessment`);
        }
    };

    const handleSkipAssessment = (employeeId) => {
        const onConfirm = () => {
            skipAssessment(employeeId);
        };
        if (employeeId) {
            confirmModal("skip_assessment", onConfirm);
        }
    };

    const handleToggleEnableTour = () => {
        dispatch(SET_RUN_TOUR(true));
    };

    const handleViewLateAssessments = () => {
        dispatch(SET_SIDE_AREA("lateAssessments"));
    };

    const shouldShowAssessments = () => {
        if (!assessmentsOpen) return false;
        if (currentPageIsAnAssessment) return false;
        if (selfAssessmentDue) return true;
        if (hasChildren) return true;
        return false;
    };
    const showAssessments = shouldShowAssessments();

    return (
        <Navbar className="app-navbar">
            <Nav className="app-navbar-inner-container">
                <div className="d-flex">
                    <BackButton className="me-2" size={32} onClick={() => navigate(-1)} />
                    <Breadcrumbs activePage={activePage} />
                </div>
                <div className="d-flex align-items-center">
                    <UnsavedAssessments />
                    {allowTour && <HelpButton onClick={handleToggleEnableTour} className="me-3" size={18} />}
                    <div className="me-4">
                        <GlobalSearchField placeholder={t("search")} />
                    </div>
                    <AssessmentDropdown
                        hide={!showAssessments}
                        onCompleteAssessment={handleCompleteAssessment}
                        onSelfAssessment={handleCompleteSelfAssessment}
                        onSkipAssessment={handleSkipAssessment}
                        showTeam={hasChildren}
                        showSelf={selfAssessmentDue}
                        roleId={roleId}
                    />
                    {assessmentsOpen && managesAssessmentRequests && overdueCount > 0 && (
                        <AssessmentLateButton
                            onClick={handleViewLateAssessments}
                            count={overdueCount}
                            className="me-3"
                            data-for={tooltipId}
                            data-tip="Overdue Assessments"
                        />
                    )}
                    <Notifications notifications={notifications} />
                    <Settings />
                </div>
            </Nav>
            <ReactTooltip id={TOOLTIP_ID} place="left" delayShow={250} />
        </Navbar>
    );
};

export default AppNavBar;
