import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const RadioButtons = (props) => {
    const { selected, options, vertical, smallText, outline, disabled, onChange } = props;
    const [preppedOptions, setPreppedOptions] = useState([]);

    useEffect(() => {
        const newOptions = options.map((option) => {
            if (typeof option === "string") {
                return {
                    value: option,
                    label: option,
                };
            } else {
                return option;
            }
        });
        setPreppedOptions(newOptions);
    }, [options]);

    const handleClickItem = (val) => {
        if (!disabled) onChange(val);
    };

    return (
        <ul
            className={classNames("d-flex justify-content-center mb-0", props.className, {
                "flex-column align-items-stretch": vertical,
                "align-items-center": !vertical,
                "fs-tiny": smallText,
                "p-1 rounded border-all bg-light": !outline,
            })}
        >
            {preppedOptions.map((option) => {
                return (
                    <li
                        key={option.value}
                        className={classNames("radio-button px-2 f-1", {
                            selected: selected === option.value,
                            "fs-tiny": smallText,
                            "text-center": !vertical,
                            "py-2": vertical,
                            disabled,
                        })}
                        onClick={() => handleClickItem(option.value)}
                    >
                        {option.label}
                    </li>
                );
            })}
        </ul>
    );
};

RadioButtons.propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    onChange: PropTypes.func,
    smallText: PropTypes.bool,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioButtons.defaultProps = {
    options: [],
    smallText: false,
    onChange: () => {},
};

export default RadioButtons;
