import { interpolate, range } from "d3";
import { RATING_COLORS } from "../constants";
import { lookColors, lookEmployee, lookRequestDates, lookRole } from "../redux/utils/looks";
import { sortArrayOfObjects } from "../utils/basicUtils";

export function getAllColors() {
    const { firstColor, midColor, lastColor } = RATING_COLORS;
    const firstHalf = interpolate(firstColor, midColor);
    const part1 = range(0, 1, 0.02).map((t) => firstHalf(t));
    const secondHalf = interpolate(midColor, lastColor);
    const part2 = range(0, 1, 0.02).map((t) => secondHalf(t));
    const allColors = part1.concat(part2);
    return allColors;
}

export function getColors(steps) {
    const colors = lookColors();
    const stepSize = colors.length / steps;
    const colorArray = [];
    for (let i = 0; i < steps; i++) {
        const index = Math.floor(i * stepSize);
        colorArray.push(colors[index]);
    }
    return colorArray;
}

export function getAssessmentProgress(allSubordinateIds, snapshots) {
    const done = allSubordinateIds.filter((empId) => {
        const snap = snapshots[empId];
        return !!snap && !snap?.expired;
    });
    const totalCount = allSubordinateIds.length;
    const incompleteCount = done.length;

    return Math.ceil((incompleteCount / totalCount) * 100);
}

export function listOverdueAssessments(employees, snapshots, excludeUnrequested) {
    // Filter snapshots
    const assessmentDates = lookRequestDates();
    let overdueEmployees = Object.values(employees)
        .filter((employee) => {
            const role = lookRole(employee.roleId);
            const parentRole = lookRole(role?.parentRoleId);
            const manager = lookEmployee(parentRole?.incumbentId);
            const requested = !excludeUnrequested || !!assessmentDates[role?.parentRoleId];
            const hasManager = parentRole?.incumbentId;
            const managerIsUser = manager?.isUser;
            const hasParent = role?.parentRoleId;
            const isRoot = role?.rootRole;
            const doNotAssess = role?.doNotAssess;
            return role && hasManager && managerIsUser && hasParent && !isRoot && !doNotAssess && requested;
        })
        .map((employee) => {
            const role = lookRole(employee.roleId);
            const parentRole = lookRole(role.parentRoleId);
            const manager = lookEmployee(parentRole?.incumbentId);
            const managerName = manager?.displayName;
            const snapshot = snapshots[employee.id];
            const { date, expired } = snapshot || {};
            return {
                employeeId: employee.id,
                displayName: employee.displayName,
                managerName: managerName,
                assessedDate: date,
                expired: expired,
                parentRoleId: role?.parentRoleId,
            };
        })
        .filter((employee) => {
            return employee.expired;
        });

    overdueEmployees = sortArrayOfObjects(overdueEmployees, "assessedDate", true);

    return overdueEmployees;
}
