import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { EmployeeCards, ScheduledActionsTable, TalentStrength } from "../../components";
import DashboardTour from "../../components/AppTours/DashboardTour";
import TourHeader from "../../components/AppTours/TourHeader";
import AppButton from "../../components/Buttons/MyButtons";
import { DownloadIcon } from "../../components/Icons/MyIcons";
import ReportPrintModal from "../../components/Reporting/ReportPrintModal";
import EmployeeActionReport from "../../components/Reporting/Reports/EmployeeActionReport";
import Tabs, { Tab } from "../../components/Tabs/Tabs";
import { RoleModel } from "../../models";
import { lookAction, lookEmployee, lookRole } from "../../redux/utils/looks";
import { useLanguage } from "../../translations/LanguageContext";
import { getDivisionSnapshot, makeRatingsArray } from "../../utils/snapshotUtils";
import TimeLine from "../../components/Timeline/Timeline";
import { listenSelfAssessment } from "../../firebase/listeners";
import TraitWordCloud from "../../components/WordClouds/TraitWordCloud";
import { RichTextDisplay } from "../../components/RichTextEditor/RichTextEditor";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";

const tabs = [
    { key: "actions", label: "Actions" },
    { key: "timeline", label: "Timeline" },
    { key: "self-ratings", label: "Self Assessment" },
];

const SelfSection = ({ userEmployee, setReportsOpen, incompleteActions, selfRatings, selfNotes }) => {
    const navigate = useNavigate();
    const assessmentsOpen = useSelector((state) => state.assessments.settings.assessmentsOpen);
    const handleEditSelfAssessment = () => {
        navigate("/people/assessments/self-assessment");
    };
    return (
        <Tabs tabs={tabs} className="border-left f-1 d-flex flex-column">
            <Tab tabKey="actions" label="Actions" className="p-4">
                <div className="f-1 mb-5">
                    <div className="d-flex justify-content-between">
                        <h6>Action Plan</h6>
                        <AppButton
                            onClick={() => setReportsOpen(true)}
                            outline
                            disabled={incompleteActions.length === 0}
                        >
                            <DownloadIcon size={22} />
                        </AppButton>
                    </div>
                    <div className="f-1">
                        <ScheduledActionsTable
                            hideHeader
                            data={incompleteActions}
                            columnKeys={["actionName"]}
                            rowsPerPage={15}
                            removeBorder
                        />
                    </div>
                </div>
            </Tab>
            <Tab tabKey="timeline" label="Timeline" className="p-4">
                <TimeLine timeline={userEmployee?.timeline} timelineId={userEmployee?.id} />
            </Tab>
            <Tab tabKey="self-ratings" label="Self Assessment" className="p-4">
                <div className="f-1 d-flex flex-column justify-content-between">
                    <div className="f-1 scrollable">
                        <TraitWordCloud ratings={selfRatings} />
                        <h6 className="mt-5">Your Notes</h6>
                        <RichTextDisplay content={selfNotes} />
                    </div>
                    <div>
                        <Button disabled={!assessmentsOpen} className="float-end" onClick={handleEditSelfAssessment}>
                            Edit
                        </Button>
                    </div>
                </div>
            </Tab>
        </Tabs>
    );
};

const TeamSection = ({ directReports }) => {
    const roleId = useSelector((state) => state.user.claims.roleId);
    const roles = useSelector((state) => state.org.roles);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const [talentAreaRatings, setTalentAreaRatings] = useState([]);
    const [ratings, setRatings] = useState([]);
    useEffect(() => {
        const divisionSnapshot = getDivisionSnapshot(roleId, snapshots, roles);
        setTalentAreaRatings(makeRatingsArray(divisionSnapshot.talentAreas));
        setRatings(makeRatingsArray(divisionSnapshot.ratings));
    }, [roleId, snapshots, roles]);

    return (
        <div>
            <div className="pe-4 f-1 flex-grow-2 d-flex flex-column mb-5">
                <TourHeader tourClass="tour-team-strengths" label="Team Strengths" />
                <TalentStrength
                    wordCloudId="dashboard"
                    className="rounded-4 overflow-hidden f-1 d-flex bg-white flex-column align-items-stretch"
                    talentAreaRatings={talentAreaRatings}
                    ratings={ratings}
                />
            </div>
            <TourHeader tourClass="tour-team-ratings" label="Your Team" />
            <div className="f-1 d-flex">
                <EmployeeCards employees={directReports} className="f-1" />
            </div>
        </div>
    );
};

const Dashboard = () => {
    const [reportsOpen, setReportsOpen] = useState(false);
    const [selfRatings, setSelfRatings] = useState([]);
    const [selfNotes, setSelfNotes] = useState("");
    const enableSelfAssessment = useSelector((state) => state.assessments.settings.enableSelfAssessment);
    const userEmployee = useSelector((state) => state.org.self);
    const userEmployeeId = userEmployee?.id;
    const directReports = useMemo(() => {
        const employee = lookEmployee(userEmployeeId);
        const role = lookRole(employee?.roleId);
        const userRoleModel = new RoleModel(role, "Dashboard-1");
        return userRoleModel.getDirectReports();
    }, [userEmployeeId]);
    const showSelf = enableSelfAssessment && userEmployeeId;
    const { t } = useLanguage();

    useEffect(() => {
        if (!userEmployeeId || !showSelf) return;
        const unsubscribe = listenSelfAssessment(userEmployeeId, (assessment) => {
            const data = assessment?.data() ?? {};
            let ratingsObj = data.ratings ?? [];
            setSelfRatings(makeRatingsArray(ratingsObj));
            setSelfNotes(data.notes ?? "");
        });
        return () => unsubscribe();
    }, [userEmployeeId, showSelf]);

    const incompleteActions = useMemo(() => {
        const actionIds = userEmployee?.scheduledActionIds ?? [];
        return actionIds.map((id) => {
            const action = lookAction(id);
            return { ...action, actionId: id, employeeId: userEmployee?.id, complete: false };
        });
    }, [userEmployee]);

    return (
        <div className="bg-white inner-container flex-row">
            <DashboardTour />
            <ReportPrintModal title="Employee Actions" isOpen={reportsOpen} toggle={() => setReportsOpen(!reportsOpen)}>
                <EmployeeActionReport employeeId={userEmployeeId} plannedActions={incompleteActions} t={t} />
            </ReportPrintModal>
            <div className="f-2 p-4 d-flex flex-column scrollable">
                <TeamSection directReports={directReports} />
            </div>
            {showSelf && (
                <div className="f-1 d-flex flex-column">
                    <TourHeader tourClass="tour-self p-3 mb-0 border-left" label="Your Details" />
                    <SelfSection
                        userEmployee={userEmployee}
                        incompleteActions={incompleteActions}
                        setReportsOpen={setReportsOpen}
                        selfRatings={selfRatings}
                        selfNotes={selfNotes}
                    />
                </div>
            )}
        </div>
    );
};

Dashboard.propTypes = {
    activeRole: PropTypes.object,
    activeDivision: PropTypes.object,
};

export default Dashboard;
