import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseTable } from "../..";
import { SELECT_TRAIT, SET_MODAL } from "../../../redux/appSlice";
import { lookTalentArea, lookTalentBoard } from "../../../redux/utils/looks";
import { getRatingCounts } from "../../../utils/snapshotUtils";
import { Pill, TraitScopePill } from "../../BadgesAndPills/Pills";
import { RemoveButton } from "../../Buttons/MyButtons";
import TraitPieChart from "../../Charts/TraitCharts/TraitPieChart";
import ShowMore from "../../ShowMore/ShowMore";

const TraitsTable = (props) => {
    const dispatch = useDispatch();
    const { columnKeys, snapshots, data, tooltipId, iconSize, onRemove, includeRemove, onEditTalentArea, checkbox } =
        props;
    const [includedColumns, setIncludedColumns] = useState([]);
    const [traitRatingCounts, setTraitRatingCounts] = useState({});
    const [pageIndex, setPageIndex] = useState(0);
    const pendingChangeIds = useSelector((state) => state.talent.pendingChanges);
    const selectedTraitId = useSelector((state) => state.app.selectedTraitId);

    // Prep the rating data for the table (all available assessment data)
    useEffect(() => {
        let newTraitRatingCounts = {};
        data.forEach((trait) => {
            const counts = getRatingCounts(snapshots, "ratings", trait.id);
            newTraitRatingCounts[trait.id] = counts;
        });
        setTraitRatingCounts(newTraitRatingCounts);
    }, [snapshots, data]);

    useEffect(() => {
        let columns = [
            {
                key: "ratingCounts",
                title: "",
                width: 40,
                render: (label, trait, index) => {
                    return (
                        <div
                            style={{ height: iconSize, width: iconSize, margin: "auto" }}
                            className="d-flex justify-content-center me-3"
                        >
                            <TraitPieChart ratingCounts={traitRatingCounts[trait.id]} />
                        </div>
                    );
                },
            },
            {
                key: "traitName",
                title: "Trait",
                dataIndex: "traitName",
                width: 280,
            },
            {
                key: "talentAreaIds",
                title: "Talent Areas",
                dataIndex: "talentAreaIds",
                noSort: true,
                render: (label, trait, index) => {
                    const talentAreaIds = trait.talentAreaIds || [];
                    return (
                        <ShowMore itemLimit={2}>
                            {talentAreaIds.map((id) => {
                                const talentArea = lookTalentArea(id);
                                const talentAreaName = talentArea && talentArea.talentAreaName;
                                return (
                                    <Pill className="me-2 outline" key={id} onClick={() => onEditTalentArea(id)}>
                                        {talentAreaName || "Unknown"}
                                    </Pill>
                                );
                            })}
                        </ShowMore>
                    );
                },
            },
            {
                key: "linkedTalentBoardIds",
                title: "Talent Boards",
                dataIndex: "linkedTalentBoardIds",
                noSort: true,
                render: (label, trait, index) => {
                    const talentBoardIds = trait.linkedTalentBoardIds || [];
                    return (
                        <ShowMore itemLimit={1}>
                            {talentBoardIds.map((id) => {
                                const talentBoard = lookTalentBoard(id);
                                const talentBoardName = talentBoard && talentBoard.talentBoardName;
                                return (
                                    <Pill key={id} className="outline me-2">
                                        {talentBoardName}
                                    </Pill>
                                );
                            })}
                        </ShowMore>
                    );
                },
            },
            {
                key: "scope",
                title: "Scope",
                width: 240,
                noSort: true,
                render: (label, trait, index) => {
                    return (
                        <TraitScopePill
                            boardsOnly={trait.boardsOnly}
                            managersOnly={trait.managersOnly}
                            label={trait.scope}
                        />
                    );
                },
            },
        ];

        columns = columns.filter((column) => columnKeys.includes(column.key));

        if (includeRemove) {
            columns.push({
                key: "remove",
                width: "1%",
                noSort: true,
                render: (label, employee, index) => {
                    return <RemoveButton round={false} size={18} icon onClick={() => onRemove(employee.id)} />;
                },
            });
        }
        setIncludedColumns(columns);
    }, [columnKeys, tooltipId, traitRatingCounts, iconSize, onEditTalentArea, includeRemove, onRemove]);

    const handleRowClick = (traitId) => {
        dispatch(SELECT_TRAIT(traitId));
    };

    const handleRowDoubleClick = (traitId) => {
        //navigate(`/traits/${traitId}`);
        dispatch(SET_MODAL("editTrait"));
    };

    return (
        <BaseTable
            {...props}
            className="traits-table"
            columns={includedColumns}
            selectedId={selectedTraitId}
            pendingChangeIds={pendingChangeIds}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            pageIndex={pageIndex}
            onSetPageIndex={setPageIndex}
            sortKey="traitName"
            selectableRows
            checkbox={checkbox}
        />
    );
};

TraitsTable.propTypes = {
    columnKeys: PropTypes.array,
    iconSize: PropTypes.number,
    rowsPerPage: PropTypes.number,
    disableRowClick: PropTypes.bool,
    checkbox: PropTypes.bool,
    disableRowDoubleClick: PropTypes.bool,
    onEditTalentArea: PropTypes.func,
    onRemove: PropTypes.func,
};

TraitsTable.defaultProps = {
    columnKeys: [],
    rowsPerPage: 30,
    iconSize: 36,
    onEditTalentArea: () => {},
    onRemove: () => {},
};

export default TraitsTable;
