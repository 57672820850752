import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResponsiveContainer } from "recharts";
import { LoadingIndicator, ScheduledActionsTable, TalentStrength } from "../../../../../components";
import AppButton from "../../../../../components/Buttons/MyButtons";
import Gauges from "../../../../../components/Cards/GaugeCards/GaugeCards";
import CustomFieldDisplay from "../../../../../components/CustomField/CustomFieldDisplay";
import { DownloadIcon } from "../../../../../components/Icons/MyIcons";
import ReportPrintModal from "../../../../../components/Reporting/ReportPrintModal";
import EmployeeActionReport from "../../../../../components/Reporting/Reports/EmployeeActionReport";
import { RichTextDisplay } from "../../../../../components/RichTextEditor/RichTextEditor";
import { listenEmployee, listenLatestAssessment } from "../../../../../firebase/listeners";
import { EmployeeModel } from "../../../../../models";
import { lookAction } from "../../../../../redux/utils/looks";
import { useLanguage } from "../../../../../translations/LanguageContext";
import { makeRatingsArray } from "../../../../../utils/snapshotUtils";
import Dashboard from "../../../../Dashboard/Dashboard";
import { formatDate } from "../../../../../utils/basicUtils";

const MIN_PANEL_HEIGHT = 240;

const ACTION_COLS = ["actionName"];

const EmployeeTab = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [snapshot, setSnapshot] = useState({});
    const [reportsOpen, setReportsOpen] = useState(false);
    const [incompleteActions, setIncompleteActions] = useState([]);
    const [assessment, setAssessment] = useState({});
    const [isPending, setIsPending] = useState(false);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector((state) => state.org.employees[selectedEmployeeId]);
    const employeeTalentBoardIds = selectedEmployee ? selectedEmployee.talentBoardIds : [];
    const customFields = useSelector((state) => state.workspace.customFields);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const employeeModel = selectedEmployee && new EmployeeModel(selectedEmployee, "EmployeeTab-1");
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const isSelf = selectedEmployeeId === userEmployeeId;
    const snap = snapshot || {};
    const talentAreas = makeRatingsArray(snap.talentAreas);
    const ratings = makeRatingsArray(snap.ratings);
    const assessmentFields = customFields.filter((field) => field.category === "assessment");
    const pendingSnapshots = useSelector((state) => state.talent.pendingChanges);
    const { t } = useLanguage();

    // Setup the listeners when the employee changes
    useEffect(() => {
        setLoaded(false);
        if (!isSelf && selectedEmployeeId) {
            // Listen for the full employee doc
            const unsubscribeEmployee = listenEmployee(selectedEmployeeId, (employee) => {
                const actionIds = employee.scheduledActionIds || [];
                const actions = actionIds.map((id) => {
                    const action = lookAction(id);
                    return { ...action, actionId: id, employeeId: selectedEmployeeId, complete: false };
                });
                setIncompleteActions(actions);
            });

            // Listen for the full manager assessment
            const unsubscribeAssessment = listenLatestAssessment(selectedEmployeeId, (doc) => {
                if (doc) {
                    setAssessment(doc.data());
                } else {
                    setAssessment({});
                }
                setLoaded(true);
            });

            // drop listeners
            return () => {
                unsubscribeEmployee();
                unsubscribeAssessment();
            };
        }
    }, [selectedEmployeeId, isSelf]);

    // Set tab to waiting when a snapshot is pending
    useEffect(() => {
        const isPending = pendingSnapshots && pendingSnapshots.includes(selectedEmployeeId);
        setIsPending(isPending);
    }, [pendingSnapshots, selectedEmployeeId]);

    // Update the tab when it receives a new snapshot
    useEffect(() => {
        if (selectedEmployeeId && snapshots) {
            const snap = snapshots[selectedEmployeeId];
            setSnapshot(snap);
        }
    }, [selectedEmployeeId, snapshots]);

    function renderEmployeeDetails() {
        const name = selectedEmployee.displayName;
        const jobTitle = employeeModel.getJobTitle();
        if (name && jobTitle) {
            return `${name} - ${jobTitle}`;
        } else if (name) {
            return name;
        } else if (jobTitle) {
            return jobTitle;
        } else {
            return "Missing Employee Details";
        }
    }

    function renderLastAssessed() {
        const date = snapshot?.date
        if (!date) {
            return "Not yet assessed";
        } else {
            return `Last assessed: ${formatDate(date)}`;
        }
    }

    if (isSelf) return <Dashboard />;

    if (!loaded) return <LoadingIndicator />;
    return (
        <div className="f-1 d-flex flex-column dashboard-headers p-4">
            <ReportPrintModal title="Employee Actions" isOpen={reportsOpen} toggle={() => setReportsOpen(!reportsOpen)}>
                <EmployeeActionReport employeeId={selectedEmployeeId} plannedActions={incompleteActions} t={t} />
            </ReportPrintModal>
            {assessment && (
                <div className="mb-4">
                    <div className="d-flex justify-content-between">
                        <h6>{renderEmployeeDetails()}</h6>
                        <h6>{renderLastAssessed()}</h6>
                    </div>
                    <CustomFieldDisplay
                        fields={assessmentFields}
                        excludeFromOptions={[selectedEmployeeId]}
                        assessment={assessment}
                        readOnly={props.disableAssessmentEdits}
                    />
                </div>
            )}
            <div className="d-flex dashboard-row pb-3 f-1">
                <div className="f-3 d-flex flex-column">
                    <h6>Rating Summaries</h6>
                    <div className="d-flex mb-4 pt-3">
                        <Gauges
                            isPending={isPending}
                            employeeId={selectedEmployeeId}
                            snap={snap}
                            employeeTalentBoardIds={employeeTalentBoardIds}
                        />
                    </div>
                    <div className="f-1 d-flex flex-column">
                        <TalentStrength
                            isPending={isPending}
                            wordCloudId="employeeTab"
                            className="bg-white rounded-4 overflow-hidden"
                            talentAreaRatings={talentAreas}
                            ratings={ratings}
                        />
                    </div>
                    <h6 className="mb-1 mt-4">Notes</h6>
                    <div
                        style={{ minHeight: MIN_PANEL_HEIGHT }}
                        className="f-1 d-flex flex-column bg-white rounded-4 overflow-hidden border-all"
                    >
                        <RichTextDisplay content={assessment.notes} />
                    </div>
                </div>
                <div className="f-2 d-flex flex-column p-0">
                    <div className="d-flex justify-content-between">
                        <h6>Action Plan</h6>
                        <AppButton
                            onClick={() => setReportsOpen(true)}
                            outline
                            disabled={incompleteActions.length === 0}
                        >
                            <DownloadIcon size={22} />
                        </AppButton>
                    </div>
                    <div className="f-1 border-all bg-white overflow-hidden rounded-4">
                        <ResponsiveContainer>
                            <ScheduledActionsTable
                                data={incompleteActions}
                                columnKeys={ACTION_COLS}
                                rowsPerPage={15}
                                removeBorder
                            />
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

EmployeeTab.propTypes = {
    activeRole: PropTypes.object,
    activeEmployee: PropTypes.object,
    disableAssessmentEdits: PropTypes.bool,
};

export default EmployeeTab;
