import React, { useState } from "react";
import { RichTextEditor } from "../../components";
import UncontrolledCollapseBlock from "../../components/CollapseBlock/CollapseBlock";
import { fetchAction } from "../../firebase/fetch";
import { lookAction } from "../../redux/utils/looks";

const ActionItem = (props) => {
    const { actionId, type, onCheckAction, checked, disabled } = props;
    const [fullAction, setFullAction] = useState();
    const action = lookAction(actionId);

    if (!action) return null;

    const handleExpandAction = async () => {
        if (!fullAction) {
            fetchAction(actionId).then((actionDoc) => {
                const details = actionDoc?.details ?? "No further details provided for this action.";
                setFullAction({ ...actionDoc, details: details });
            });
        }
    };

    return (
        <UncontrolledCollapseBlock
            title={action.actionName}
            checked={checked}
            type={type}
            onAction={() => onCheckAction(actionId)}
            disabled={disabled}
            onExpand={handleExpandAction}
            smallHeader
        >
            {fullAction && <RichTextEditor content={fullAction.details} readOnly minHeight={20} />}
        </UncontrolledCollapseBlock>
    );
};

export default ActionItem;
