import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import SegmentView from "../../pages/People/Views/Role/Views/SegmentView";
import { createGrid, getFieldLabel, prepGridData, prepSingleAxisGridData } from "../../utils/snapshotUtils";
import CustomGrid from "./Components/CustomGrid";
import TalentScatterChart from "./TalentScatterChart";
import "./talent-map.scss";
import { useSelector } from "react-redux";

const TalentMap = React.forwardRef((props, ref) => {
    const gridThresholds = useSelector((state) => state.assessments.settings.gridThresholds);
    const { snapshots, config, activeSegmentId, onItemHover, onItemClick, className } = props;
    const navigate = useNavigate();
    const [hovered, setHovered] = useState(null);
    const scatterChartView = config.chartType === "scatter";
    const hideYAxis = config.singleAxis && !scatterChartView;

    const grid = useMemo(() => {
        let gridPlacements = [];
        if (config.singleAxis) {
            gridPlacements = prepSingleAxisGridData(snapshots, config, gridThresholds);
        } else {
            gridPlacements = prepGridData(snapshots, config);
        }
        const newGrid = createGrid(gridPlacements, config);
        return newGrid;
    }, [snapshots, config, gridThresholds]);

    const activeSegment = useMemo(() => {
        if (activeSegmentId && grid.length > 0) {
            const y = activeSegmentId.charAt(0);
            const x = activeSegmentId.charAt(2);
            const col = [...grid[x - 1]];
            const segment = col[y - 1];
            return segment;
        } else {
            return null;
        }
    }, [grid, activeSegmentId]);

    const handleSegmentClick = (segment) => {
        navigate(`${segment.y}-${segment.x}`);
    };

    const handleHover = (id) => {
        setHovered(id);
        onItemHover(id);
    };

    if (activeSegment) {
        return <SegmentView segment={activeSegment} onItemClick={onItemClick} />;
    }

    return (
        <div ref={ref} className="f-1 d-flex flex-column talent-map-container">
            <div className={classNames("talent-map", className)}>
                {!hideYAxis && (
                    <div className="axis y-axis">
                        <div className="text">{getFieldLabel(config.y)}</div>
                    </div>
                )}
                {config.chartType === "scatter" && (
                    <TalentScatterChart config={config} snapshots={snapshots} onItemClick={onItemClick} />
                )}
                {config.chartType === "grid" && (
                    <CustomGrid
                        grid={grid}
                        config={config}
                        snapshots={snapshots}
                        hovered={hovered}
                        onSegmentClick={handleSegmentClick}
                        onItemHover={handleHover}
                        onItemClick={onItemClick}
                    />
                )}
            </div>
            <div className="axis text-center mb-3">{getFieldLabel(config.x)}</div>
        </div>
    );
});

TalentMap.propTypes = {
    snapshots: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onSegmentClick: PropTypes.func,
    onItemHover: PropTypes.func,
    onItemClick: PropTypes.func,
};

TalentMap.defaultProps = {
    onSegmentClick: () => {},
    onItemHover: () => {},
    onItemClick: () => {},
};

export default TalentMap;
