import classNames from "classnames";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button } from "reactstrap";

function ShowMore({ children, className, itemLimit = 1, buttonClassName = "mb-2" }) {
    const [showAll, setShowAll] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const firstAllowed = children.slice(0, itemLimit);
    const rest = children.slice(itemLimit);

    return (
        <div className={className}>
            {firstAllowed}
            {showAll && rest}
            {rest.length > itemLimit && (
                <Button className={classNames(buttonClassName, "text-only")} onClick={toggleShowAll}>
                    {showAll ? <FaMinus /> : <FaPlus />}
                </Button>
            )}
        </div>
    );
}
export default ShowMore;
