import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { completeUserSignIn, isConfirmLinkValid, userSignOut } from "../../firebase/auth";
import { useLocalStorage } from "../../hooks/hooks";
import EmailForm from "./EmailForm";
import SignInPanel from "./SignInPanel";
import "./sign-in.scss";
import { LoadingIndicator } from "../../components";

const CompleteSignIn = () => {
    const navigate = useNavigate();
    const [email] = useLocalStorage("email", "");
    const [signInError, setSignInError] = useState();
    const authHasLoaded = useSelector((state) => state.app.authHasLoaded);
    const loggedIn = useSelector((state) => state.user.loggedIn);
    const validConfirmLink = isConfirmLinkValid(window.location.href);

    // If the user hasn't provided a valid signin url, redirect them to the request page
    useEffect(() => {
        if (!validConfirmLink) navigate("/sign-in/request");
    }, [navigate, validConfirmLink]);

    // If the user is already signed in, navigate to the auth app
    useEffect(() => {
        if (loggedIn) navigate("/");
    }, [navigate, loggedIn]);

    const handleSignIn = (email, staySignedIn) => {
        completeUserSignIn(email, window.location.href, staySignedIn).then((error) => {
            if (error) setSignInError(error);
        });
    };

    const handleRequestLink = () => {
        if (loggedIn) userSignOut();
        navigate("/sign-in/request");
    };

    if (!authHasLoaded) {
        return <LoadingIndicator fullscreen message="Preparing Talent Mapper." />;
    }

    return (
        <SignInPanel
            title="Confirm Sign-in"
            description="For security reasons we need you to confirm your email address."
            signInStatus="confirming"
            onBailOut={handleRequestLink}
            bailOutText="Request sign-in link"
        >
            <EmailForm email={email} formType="confirmSignIn" onSubmit={handleSignIn} />
            <p className="mt-2 c-danger text-center fs-tiny">{signInError}</p>
        </SignInPanel>
    );
};

export default CompleteSignIn;
