import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FaCheck, FaEnvelope } from "react-icons/fa";
import { Button } from "reactstrap";
import fullnameLogo from "../../assets/img/logo/small-fulltext-logo.png";
import { ALT_TEXT } from "../../constants";
import { userSignOut } from "../../firebase/auth";

const SignInPanel = (props) => {
    const renderHeaderImage = () => {
        switch (props.signInStatus) {
            case "signed-in":
                return <img src={fullnameLogo} alt={ALT_TEXT.LOGO} />;
            case "confirming":
                return <img src={fullnameLogo} alt={ALT_TEXT.LOGO} />;
            case "not-requested":
                return (
                    <div className={classNames("sign-in-image sent")}>
                        <FaEnvelope size={50} />
                    </div>
                );
            case "requested":
                return (
                    <div className={classNames("sign-in-image request")}>
                        <FaCheck size={50} />
                    </div>
                );
            default:
                return <img src={fullnameLogo} alt={ALT_TEXT.LOGO} />;
        }
    };

    const handleSignOut = () => {
        if (props.onBailOut) {
            props.onBailOut();
        } else {
            userSignOut();
        }
    };

    return (
        <div className="f-1 d-flex flex-column py-5 p-3 scrollable">
            <div className="f-1 d-flex flex-column justify-content-start mb-4">
                <div className="f-1 d-flex justify-content-center">{renderHeaderImage()}</div>
                <div className="f-5">
                    <p id="sign-in-title" className="mt-3 text-center mb-2 fs-4 fw-lighter">
                        {props.title}
                    </p>
                    <div className="mb-4 d-flex flex-column justify-content-start">
                        <p className="text-center mb-0 fs-6">{props.description}</p>
                        {props.more && <p className="mt-3 text-center mb-0 fs-6">{props.more}</p>}
                    </div>
                    <div className="w-100 form-section mx-auto">{props.children}</div>
                </div>
            </div>
            {!props.hideBailOut && (
                <div className="d-flex justify-content-center">
                    <Button className="text-only" onClick={handleSignOut}>
                        {props.bailOutText}
                    </Button>
                </div>
            )}
        </div>
    );
};

SignInPanel.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    more: PropTypes.string,
    signInStatus: PropTypes.oneOf(["signed-in", "requested", "confirming", "not-requested"]),
    hideBailOut: PropTypes.bool,
    onBailOut: PropTypes.func,
    bailOutText: PropTypes.string,
};

SignInPanel.defaultProps = {
    signInStatus: "not-requested",
    bailOutText: "Sign Out",
};

export default SignInPanel;
