import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RadioButtons, RolePicker } from "../../../../../components";
import { SettingsButton } from "../../../../../components/Buttons/MyButtons";
import { CheckBox } from "../../../../../components/Inputs/Inputs";
import SnapshotFieldPicker from "../../../../../components/Pickers/FieldPickers/SnapshotFieldPicker";
import SuccessionFieldPicker from "../../../../../components/Pickers/FieldPickers/SuccessionFieldPicker";
import { RATING_VIEW_OPTIONS } from "../../../../../constants";
import { saveLocalPreferences } from "../../../../../firebase/actions/user";
import { setLocalPreferences } from "../../../../../redux/utils/dispatches";

const Preferences = (props) => {
    const { topLevelRoleId } = props;
    const [isOpen, setIsOpen] = useState(false);
    const preferences = useSelector((state) => state.user.localPreferences);
    const {
        showName,
        showJobTitle,
        autoCloseSiblings,
        excludeTalentBoardRatings,
        excludeTalentBoardTraits,
        excludeUnrated,
    } = preferences;
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    const handleSetPreference = (key, value) => {
        setLocalPreferences({ [key]: value });
    };

    return (
        <div ref={ref}>
            <UncontrolledDropdown isOpen={isOpen}>
                <DropdownToggle nav className="p-0">
                    <SettingsButton checked={isOpen} onClick={() => setIsOpen(!isOpen)} />
                </DropdownToggle>
                <DropdownMenu end className="dropdown-menu" style={{ margin: 0, width: 350 }}>
                    <DropdownItem header>View Options</DropdownItem>
                    <DropdownItem>
                        <CheckBox
                            onClick={() => handleSetPreference("showName", !showName)}
                            checked={showName}
                            label="Show Name"
                        />
                    </DropdownItem>
                    <DropdownItem>
                        <CheckBox
                            onClick={() => handleSetPreference("showJobTitle", !showJobTitle)}
                            checked={showJobTitle}
                            label="Show Job Title"
                        />
                    </DropdownItem>
                    <DropdownItem header>Behaviour</DropdownItem>
                    <DropdownItem>
                        <CheckBox
                            onClick={() => handleSetPreference("autoCloseSiblings", !autoCloseSiblings)}
                            checked={autoCloseSiblings}
                            label="Auto-close Siblings"
                        />
                    </DropdownItem>
                    <DropdownItem header>Node Display Options</DropdownItem>
                    <DropdownItem>
                        <CheckBox
                            onClick={() => handleSetPreference("excludeTalentBoardRatings", !excludeTalentBoardRatings)}
                            checked={excludeTalentBoardRatings}
                            label="Exclude Talent Boards"
                        />
                    </DropdownItem>
                    <DropdownItem>
                        <CheckBox
                            onClick={() => handleSetPreference("excludeTalentBoardTraits", !excludeTalentBoardTraits)}
                            checked={excludeTalentBoardTraits}
                            label="Exclude Talent Board Traits"
                        />
                    </DropdownItem>
                    <DropdownItem>
                        <CheckBox
                            onClick={() => handleSetPreference("excludeUnrated", !excludeUnrated)}
                            checked={excludeUnrated}
                            label="Exclude Unrated"
                        />
                    </DropdownItem>
                    <DropdownItem header>Top of Org Chart</DropdownItem>
                    <DropdownItem>
                        <RolePicker
                            isClearable
                            selectedIds={topLevelRoleId}
                            className="f-1"
                            onChange={(roleId) => handleSetPreference("filteredTopLevelRoleId", roleId)}
                            hideLabel
                        />
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

const OrgChartToolbar = (props) => {
    const { topLevelRoleId } = props;
    const preferences = useSelector((state) => state.user.localPreferences);
    const { ratingFilter, nodeCategory, excludeTalentBoardRatings, excludeTalentBoardTraits } = preferences;
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const nodeView = preferences?.[activeWorkspaceId]?.nodeView;
    const successionView = preferences?.[activeWorkspaceId]?.successionView;
    const enabledModules = useSelector((state) => state.workspace.enabledModules);
    const { successionPlanning } = enabledModules; 

    const viewSnapshotOptions = [
        { label: "Succession", value: "succession" },
        { label: "Talent", value: "talent" },
    ];

    const handleChangeNodeCategory = (option) => {
        setLocalPreferences({ nodeCategory: option });
    };
    const handleChangeSuccessionView = (option) => {
        const isWorkspacePreferences = true;
        setLocalPreferences({ successionView: option }, isWorkspacePreferences);
    };

    const handleChangeFilter = (option) => {
        setLocalPreferences({ ratingFilter: option });
    };

    const handleChangeNodeView = (option) => {
        const isWorkspacePreferences = true;
        setLocalPreferences({ nodeView: option }, isWorkspacePreferences);
        // Performance settings saved by default
        saveLocalPreferences();
    };

    return (
        <div className="justify-between">
            <div className="tour-rating-view me-3 d-flex">
                {successionPlanning && (
                    <RadioButtons
                        className="me-2"
                        options={viewSnapshotOptions}
                        onChange={handleChangeNodeCategory}
                        selected={nodeCategory || "talent"}
                        outline
                    />
                )}
                {(!successionPlanning || nodeCategory === "talent") && (
                    <SnapshotFieldPicker
                        onChange={handleChangeNodeView}
                        selected={nodeView}
                        excludeUnrated
                        excludeTalentBoardRatings={excludeTalentBoardRatings}
                        excludeTalentBoardTraits={excludeTalentBoardTraits}
                        onlyAxisFields
                    />
                )}
                {successionPlanning && nodeCategory === "succession" && (
                    <SuccessionFieldPicker onChange={handleChangeSuccessionView} selected={successionView} />
                )}
            </div>
            <div className="justify-end">
                <div className="tour-rating-filter me-3">
                    <RadioButtons options={RATING_VIEW_OPTIONS} onChange={handleChangeFilter} selected={ratingFilter} />
                </div>
                <Preferences topLevelRoleId={topLevelRoleId} />
            </div>
        </div>
    );
};

OrgChartToolbar.propTypes = {
    selectedRole: PropTypes.object,
    ratingView: PropTypes.object,
    ratingFilter: PropTypes.string,
    onChangeView: PropTypes.func,
    onChangeFilter: PropTypes.func,
    tooltipId: PropTypes.string,
    tableView: PropTypes.bool,
    setTableView: PropTypes.func,
};

OrgChartToolbar.default = {
    ratingFilter: "employee",
    tooltipId: "",
    onChangeView: () => {},
    onChangeFilter: () => {},
};

export default OrgChartToolbar;
