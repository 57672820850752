import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import TraitPieChart from "../../../../../components/Charts/TraitCharts/TraitPieChart";
import { AssessmentStatusIcon, LoadingIconPuff } from "../../../../../components/Icons/MyIcons";
import { RoleModel } from "../../../../../models";
import { getAssessmentProgress } from "../../../../../utils/assessmentUtils";
import { getChildEmployeeIds } from "../../../../../utils/roleUtils";
import {
    filterSnapshotsByRole,
    getExactColor,
    getGridMemberDetails,
    getGridPositionCounts,
    getRatingCounts,
} from "../../../../../utils/snapshotUtils";
import "../org.scss";

const OrgChartIndicator = ({
    branchView,
    ratingCounts,
    hasChildren,
    roleProgress = 0,
    ratingColor,
    isAssessed = false,
    isExpired,
    isSkipped,
    showProgress,
}) => {
    const showAsSkipped = isSkipped && !isExpired 
    const iconColor = showAsSkipped ? "purple" : "white";
    function renderBody() {
        const style = { backgroundColor: ratingColor };
        if (branchView && hasChildren) {
            const showGridPosition = Array.isArray(ratingCounts);
            return <TraitPieChart showGridPosition={showGridPosition} ratingCounts={ratingCounts} />;
        } else {
            return (
                <div className="indicator-child-container">
                    <div className={classNames("indicator-child")} style={style}>
                        <AssessmentStatusIcon
                            isAssessed={isAssessed}
                            isExpired={isExpired}
                            size={12}
                            color={iconColor}
                        />
                    </div>
                </div>
            );
        }
    }

    if (branchView || !showProgress) {
        return <>{renderBody()}</>;
    }

    return (
        <CircularProgressbarWithChildren strokeWidth={12} value={roleProgress}>
            {renderBody()}
        </CircularProgressbarWithChildren>
    );
};

const TableIndicator = ({ ratingColor, isSkipped, isAssessed, tooltipId }) => {
    const style = { backgroundColor: ratingColor, borderWidth: 1 };
    return (
        <div className={classNames("indicator-child-container no-children")} style={style}>
            <AssessmentStatusIcon
                isSkipped={isSkipped}
                isAssessed={isAssessed}
                tooltipId={tooltipId}
                size={12}
                color="white"
            />
        </div>
    );
};

const AssessmentIndicator = (props) => {
    const { tableView, activeRoleId, tooltipId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [assessmentProgress, setAssessmentProgress] = useState(0);
    const [rating, setRating] = useState();
    const [ratingColor, setRatingColor] = useState();
    const [ratingCounts, setRatingCounts] = useState({});
    const activeRole = useSelector((state) => state.org.roles?.[activeRoleId]);
    const roles = useSelector((state) => state.org.roles);
    const incumbentId = activeRole && activeRole.incumbentId;
    const employeeSnapshots = useSelector((state) => state.talent.snapshots);
    const employeeSnapshot = useSelector((state) => state.talent.snapshots[incumbentId]);
    const pendingOrgChanges = useSelector((state) => state.org.pendingChanges);
    const pendingSnapshotChanges = useSelector((state) => state.talent.pendingChanges);
    const localPreferences = useSelector((state) => state.user.localPreferences);
    const activeWorkspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const talentMapConfig = useSelector((state) => state.talent.talentMapConfig);
    const userEmployeeId = useSelector((state) => state.user.claims.employeeId);
    const thresholdDate = useSelector((state) => state.assessments.settings.thresholdDate);
    const gridThresholds = useSelector((state) => state.assessments.settings.gridThresholds);
    const displayField = localPreferences?.[activeWorkspaceId]?.nodeView;
    const { ratingFilter } = localPreferences;
    const isSelf = incumbentId === userEmployeeId;
    const roleModel = new RoleModel(activeRole, "AssessmentIndicator-1");
    const hasChildren = roleModel && roleModel.hasChildren();
    const showGridPosition = displayField && displayField.fieldId === "gridPosition";
    const branchView = ratingFilter === "branch";
    const isSkipped = employeeSnapshot?.skipped;
    const isExpired = employeeSnapshot?.expired;
    const isAssessed = !!employeeSnapshot && !isExpired;

    useEffect(() => {
        const allSubordinateIds = getChildEmployeeIds(activeRole.id, true, roles);
        setAssessmentProgress(getAssessmentProgress(allSubordinateIds, employeeSnapshots));
    }, [incumbentId, activeRole, roles, employeeSnapshots, thresholdDate]);

    useEffect(() => {
        const fieldValue = displayField && displayField.fieldValue;
        const fieldId = displayField && displayField.fieldId;
        function getRating(snapshot) {
            if (!snapshot) return null;
            if (fieldValue) {
                return snapshot?.[fieldId]?.[fieldValue];
            } else {
                return snapshot[fieldId];
            }
        }
        if (!displayField) {
            setRating();
            setRatingCounts({});
        } else {
            if (branchView) {
                const filteredSnapshots = filterSnapshotsByRole(activeRoleId, true, employeeSnapshots);
                if (fieldId === "gridPosition") {
                    setRatingCounts(getGridPositionCounts(filteredSnapshots, talentMapConfig));
                } else {
                    setRatingCounts(getRatingCounts(filteredSnapshots, fieldId, fieldValue));
                }
            }
            setRating(getRating(employeeSnapshot));
        }
    }, [talentMapConfig, branchView, displayField, employeeSnapshots, employeeSnapshot, activeRoleId]);

    useEffect(() => {
        if (pendingOrgChanges.includes(incumbentId) || pendingOrgChanges.includes(activeRoleId)) {
            setIsLoading(true);
        } else if (pendingSnapshotChanges.includes(incumbentId)) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [pendingOrgChanges, pendingSnapshotChanges, activeRoleId, incumbentId]);

    // Set the rating color
    useEffect(() => {
        let newRatingColor;
        if (!incumbentId) setRatingColor(false);
        if (!isSelf) {
            if (employeeSnapshot && showGridPosition) {
                const details = getGridMemberDetails(incumbentId, employeeSnapshot, talentMapConfig, gridThresholds);
                newRatingColor = details?.gridColor;
            } else {
                newRatingColor = getExactColor(rating);
            }
            setRatingColor(newRatingColor);
        }
    }, [isSelf, showGridPosition, employeeSnapshot, incumbentId, talentMapConfig, rating, gridThresholds]);

    function getTooltip() {
        let ratingStatus = isExpired && "(Expired)";
        if (!ratingStatus) {
            ratingStatus = isSkipped && "(Skipped)";
        }
        const jobTitle = activeRole.jobTitle;
        return ReactDOMServer.renderToString(
            <div className="app-tooltip">
                <span>{jobTitle}</span>
                <span>{ratingStatus}</span>
            </div>
        );
    }

    if (isLoading) return <LoadingIconPuff style={{ width: props.size, height: props.size }} />;

    return (
        <div
            data-html={true}
            data-tip={getTooltip()}
            data-for={tooltipId}
            className={classNames("rating-progress-indicator box shadow-sm")}
            style={{ width: props.size, height: props.size }}
        >
            {!tableView && (
                <OrgChartIndicator
                    hasChildren={hasChildren}
                    tooltipId={tooltipId}
                    showProgress={hasChildren}
                    isAssessed={isAssessed}
                    isSkipped={isSkipped}
                    isExpired={isExpired}
                    ratingColor={ratingColor}
                    roleProgress={assessmentProgress}
                    branchView={branchView}
                    ratingCounts={ratingCounts}
                    showGridPosition={showGridPosition}
                    isSelected={props.isSelected}
                />
            )}
            {tableView && (
                <TableIndicator
                    ratingColor={ratingColor}
                    isSkipped={isSkipped}
                    isAssessed={isAssessed}
                    tooltipId={tooltipId}
                />
            )}
        </div>
    );
};

AssessmentIndicator.propTypes = {
    activeRole: PropTypes.object,
    ratingFilter: PropTypes.string,
    ratingView: PropTypes.object,
    isSelected: PropTypes.bool,
    tableView: PropTypes.bool,
    showProgress: PropTypes.bool,
    size: PropTypes.number,
};

AssessmentIndicator.defaultProps = {
    size: 20,
};

export default memo(AssessmentIndicator);
