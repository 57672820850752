import { withFormik } from "formik";
import React from "react";
import { Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import {
    ActionPicker,
    FormStep,
    FormStepper,
    RadioButtons,
    RichTextEditor,
    TalentAreaPicker,
    TalentBoardPicker,
} from "../../../components";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { useLanguage } from "../../../translations/LanguageContext";
import { isTraitNameUnique } from "../../../utils/talentUtils";
import * as Yup from "yup";

const TraitInnerForm = ({
    toggle,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validationSchemas,
    status,
}) => {
    let nameError = errors.traitName || errors.traitAlreadyExists;
    const maxHeight = 860;
    const { t } = useLanguage();
    const language = t("trait_form", "forms");
    const { scope_options, scope_all, scope_selected, board_all, board_selected } = language;

    return (
        <FormStepper
            validationSchemas={validationSchemas}
            isOpen={status.isOpen}
            values={values}
            onSubmit={handleSubmit}
            headerText={status.header}
            toggle={toggle}
            size="xl"
            isWaiting={status.isWaiting}
            minHeight={540}
            className="trait-form"
        >
            <FormStep title="Details">
                <Row>
                    <Col md={7} className="scrollable" style={{ maxHeight: maxHeight - 200 }}>
                        <FormGroup className="d-flex flex-column px-3">
                            <FormGroup className="mb-4">
                                <div className="d-flex justify-content-between f-1">
                                    <Label>{t("name")}</Label>
                                    <FormText className="field-error">{nameError}</FormText>
                                </div>
                                <Input
                                    type="text"
                                    name="traitName"
                                    maxLength={FIELD_SIZE_LIMITS.TRAIT_NAME}
                                    invalid={(errors.traitName || errors.traitAlreadyExists) && touched.traitName}
                                    placeholder={t("required")}
                                    onChange={(e) => {
                                        setFieldValue("traitName", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        const isUnique = isTraitNameUnique(e.target.value);
                                        setFieldTouched("traitName", true);
                                        setFieldTouched("traitAlreadyExists", true);
                                        setFieldValue("traitAlreadyExists", !isUnique);
                                    }}
                                    value={values.traitName || ""}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup>
                                <div className="d-flex justify-content-between f-1">
                                    <Label for="talentAreaIds">{t("talent_areas")}</Label>
                                    <FormText className="field-error">{errors.talentAreaIds}</FormText>
                                </div>
                                <TalentAreaPicker
                                    selectedIds={values.talentAreaIds}
                                    isMulti
                                    isClearable={false}
                                    onBlur={() => {
                                        setFieldTouched("talentAreaIds", true);
                                    }}
                                    onChange={(selectedTalentAreas) => {
                                        setFieldValue("talentAreaIds", selectedTalentAreas);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="d-flex flex-column h-100">
                                <Label for="description">{t("description")}</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    style={{ minHeight: 80 }}
                                    maxLength={FIELD_SIZE_LIMITS.DESCRIPTION}
                                    invalid={errors.description && touched.description}
                                    placeholder={t("optional")}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description || ""}
                                    autoComplete="off"
                                />
                                <FormFeedback>{errors.description}</FormFeedback>
                            </FormGroup>
                            <Col>
                                <div className="mb-0">
                                    <Label for="guidance">{t("guidance")}</Label>
                                    <RichTextEditor
                                        minHeight={220}
                                        content={values.guidance}
                                        onChange={(rawContent) => {
                                            setFieldValue("guidance", rawContent);
                                        }}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={5} className="d-flex flex-column">
                        <FormGroup className="rounded-4 border-all p-3 bg-light f-1">
                            <FormGroup className="f-1 mb-3">
                                <Label for="traitName">{t("scope")}</Label>
                                <RadioButtons
                                    options={scope_options}
                                    selected={values.boardsOnly ? "selectedEmps" : "allEmps"}
                                    onChange={(opt) => {
                                        if (opt === "selectedEmps") {
                                            setFieldValue("boardsOnly", true);
                                        } else {
                                            setFieldValue("boardsOnly", null);
                                        }
                                    }}
                                />
                                <FormText>{values.boardsOnly ? scope_selected : scope_all}</FormText>
                            </FormGroup>
                            <hr />
                            <FormGroup className="d-flex flex-column mb-3">
                                <Label for="talentBoardIds">{t("talent_boards")}</Label>
                                <FormText className="mb-2">{values.boardsOnly ? board_selected : board_all}</FormText>
                                <TalentBoardPicker
                                    selectedIds={values.talentBoardIds}
                                    isMulti
                                    isClearable={false}
                                    onBlur={() => {
                                        setFieldTouched("talentBoardIds", true);
                                    }}
                                    onChange={(ids) => {
                                        setFieldValue("talentBoardIds", ids);
                                    }}
                                />
                            </FormGroup>
                            <hr />
                            <FormGroup className="d-flex flex-column mb-3">
                                <Label for="actionIds">{t("actions")}</Label>
                                <FormText className="mb-2">{language.action_help}</FormText>
                                <ActionPicker
                                    isClearable={false}
                                    selectedIds={values.actionIds}
                                    onBlur={() => {
                                        setFieldTouched("actionIds", true);
                                    }}
                                    onChange={(selectedActions) => {
                                        setFieldValue("actionIds", selectedActions);
                                    }}
                                />
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </FormStep>
        </FormStepper>
    );
};

const TraitForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isWaiting: props.isWaiting,
            isOpen: props.isOpen,
            header: props.header,
        };
    },
    mapPropsToValues: (props) => {
        return { ...props.initialValues };
    },
    enableReinitialize: true,
    mapPropsToErrors: () => {
        return {};
    },
    validationSchema: (props) => {
        let mergedSchema = {};
        props.validationSchemas.forEach((schema) => {
            mergedSchema = { ...mergedSchema, ...schema };
        });
        return Yup.object().shape(mergedSchema);
    },
    handleSubmit: (formValues, { props }) => {
        const { traitAlreadyExists, ...values } = formValues;
        props.onSubmit(values);
    },
})(TraitInnerForm);

export default TraitForm;
