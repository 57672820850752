import classNames from "classnames";
import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaHandshake } from "react-icons/fa";
import { MdAssessment, MdTask } from "react-icons/md";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Col, Row } from "reactstrap";
import { lookAction, lookTrait } from "../../redux/utils/looks";
import { displayDate } from "../../utils/basicUtils";
import EmptyState from "../EmptyState/EmptyState";

const TimeLineElement = ({ timelineId, eventDate, eventType, eventDetails }) => {
    switch (eventType) {
        case "startedRole":
            return (
                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "#9856c9", color: "#fff" }}
                    contentArrowStyle={{ borderRight: "7px solid  #9856c9" }}
                    date={displayDate(eventDate)}
                    iconStyle={{ background: "#9856c9", color: "#fff", height: 38, width: 38 }}
                    icon={<FaHandshake />}
                >
                    <h4>Started a new Role</h4>
                    <p>{eventDetails.jobTitle}</p>
                </VerticalTimelineElement>
            );
        case "leftRole":
            return (
                <VerticalTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "#fec135", color: "#fff" }}
                    contentArrowStyle={{ borderRight: "7px solid  #fec135" }}
                    date={displayDate(eventDate)}
                    iconStyle={{ background: "#fec135", color: "#fff", height: 38, width: 38 }}
                    icon={<FaHandshake />}
                >
                    <h4>Left a Role</h4>
                    <p>{eventDetails.jobTitle}</p>
                </VerticalTimelineElement>
            );
        case "managerAssessment":
            const { ratingChanges, assessmentId } = eventDetails;
            return (
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
                    date={displayDate(eventDate)}
                    iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff", height: 38, width: 38 }}
                    icon={<MdAssessment />}
                >
                    <div className="w-100">
                        <h6>Rating Changes</h6>
                        <Row className="f-1">
                            {Object.entries(ratingChanges || {}).map(([traitId, change]) => {
                                const trait = lookTrait(traitId);
                                const traitName = trait.traitName || "Unknown";
                                const negative = change < 0;
                                const numberLabel = negative ? change - change * 2 : change;
                                return (
                                    <Col
                                        key={`${timelineId}-${assessmentId}-${traitId}}`}
                                        md={6}
                                        className="mb-2 p-1 d-flex"
                                    >
                                        <div className="f-2">{traitName}</div>
                                        <div
                                            className={classNames("f-1", {
                                                "c-danger": negative,
                                                "c-success": !negative,
                                            })}
                                        >
                                            {negative && <AiFillCaretDown />}
                                            {!negative && <AiFillCaretUp />}
                                            {numberLabel}
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </VerticalTimelineElement>
            );
        case "actionCompleted":
            const action = lookAction(eventDetails.actionId);
            const actionName = eventDetails.actionName || action?.actionName || "Unknown";
            return (
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: "#4878ff", color: "#fff" }}
                    contentArrowStyle={{ borderRight: "7px solid  #4878ff" }}
                    date={displayDate(eventDate)}
                    iconStyle={{ background: "#4878ff", color: "#fff", height: 38, width: 38 }}
                    icon={<MdTask />}
                >
                    <h4>Action Completed</h4>
                    <p>{actionName}</p>
                </VerticalTimelineElement>
            );

        default:
            return null;
    }
};

const TimeLine = ({ timeline, timelineId }) => {
    if (!timeline || timeline.length === 0) {
        return <EmptyState header="No timeline events" />;
    }
    return (
        <div className="side-area no-scroll h-100 d-flex flex-column justify-content-between bg-transparent dashboard-headers">
            <div className="scrollable f-1">
                <VerticalTimeline layout="1-column-left">
                    {timeline.map((event, i) => (
                        <TimeLineElement key={`${timelineId}-${i}`} timelineId={timelineId} {...event} />
                    ))}
                </VerticalTimeline>
            </div>
        </div>
    );
};

export default TimeLine;
