import classNames from "classnames";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonGroup, DropdownItem, FormGroup, Input, Label, Nav, NavItem, NavLink } from "reactstrap";
import { LoadingIndicator, RichTextEditor, TraitPicker } from "../../components";
import { confirmModal } from "../../components/Dialogs/ConfirmAlert";
import ResponsiveContainer from "../../components/ResponsiveContainer/ResponsiveContainer";
import EmployeesTable from "../../components/Tables/EmployeesTable/EmployeesTable";
import { FIELD_SIZE_LIMITS } from "../../constants";
import AppDropDown from "../../containers/AppDropDown";
import { completeScheduledAction, deleteAction, deleteScheduledAction, setAction } from "../../firebase/actions/action";
import { addActionToTraits, removeActionFromTraits } from "../../firebase/actions/trait";
import { listenAction } from "../../firebase/listeners";
import { EmployeeModel } from "../../models";
import ActionModel from "../../models/ActionModel";
import { SELECT_ACTION, SET_CHECKED_IDS } from "../../redux/appSlice";
import { REMOVE_FROM_STATE } from "../../redux/talentSlice";
import { constructMailTo } from "../../utils/employeeUtils";

const EMPLOYEE_TABLE_ID = "EMP";
const EMPLOYEE_TABLE_KEYS = ["displayName", "email", "managerName"];

const TABS = {
    scheduled: "Scheduled to Complete",
    completed: "Previously Completed",
};
const TabBar = (props) => {
    const { activeTab, bulkActionsDisabled, completeSelectedActions, cancelSelectedActions, createEmail } = props;
    return (
        <div className="pt-2 d-flex justify-content-between bg-transparent align-items-center px-3 border-bottom">
            <div className="d-flex justify-content-between bg-transparent">
                <Nav tabs className="f-3 d-flex fullpage-tabs justify-content-between bg-transparent">
                    <div className="d-flex">
                        {Object.keys(TABS).map((tab) => {
                            return (
                                <NavItem key={`tab-${tab}`}>
                                    <NavLink
                                        className={classNames({
                                            active: activeTab === tab,
                                        })}
                                        onClick={() => {
                                            props.changeTab(tab);
                                        }}
                                    >
                                        {TABS[tab]}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </div>
                </Nav>
            </div>
            <div className="d-flex pb-2">
                <ButtonGroup className="me-3">
                    <AppDropDown caret title="Bulk Actions" disabled={bulkActionsDisabled}>
                        <DropdownItem header>EMAIL</DropdownItem>
                        <DropdownItem onClick={createEmail}>Email Employeees</DropdownItem>
                        <DropdownItem onClick={() => createEmail(true)}>Email Managers</DropdownItem>
                        <DropdownItem onClick={completeSelectedActions}>Mark as Complete</DropdownItem>
                        <DropdownItem onClick={cancelSelectedActions}>Cancel</DropdownItem>
                    </AppDropDown>
                </ButtonGroup>
            </div>
        </div>
    );
};

const Action = () => {
    // Fetch the action details
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const activeActionId = params.actionId;
    const [activeTab, setActiveTab] = useState("scheduled");
    const [prevValues, setPrevValues] = useState();
    const [newValues, setNewValues] = useState({});
    const [prevLinkedTraitIds, setPrevLinkedTraitIds] = useState([]);
    const [linkedTraitIds, setLinkedTraitIds] = useState([]);
    const [scheduled, setScheduled] = useState();
    const [completed, setCompleted] = useState();
    const [actionHasChanged, setActionHasChanged] = useState(false);
    const [traitsHaveChanged, setTraitsHaveChanged] = useState(false);
    const employeeTableId = `${activeActionId}-${activeTab}-${EMPLOYEE_TABLE_ID}`;
    const checkedEmployeeIds = useSelector((state) => state.app.checkedIds[employeeTableId]);
    const { actionName, details } = newValues;

    // Ensure the active action is selected in the redux state
    useEffect(() => {
        dispatch(SELECT_ACTION(activeActionId));
        return () => {
            dispatch(SELECT_ACTION(null));
        };
    }, [dispatch, activeActionId]);

    // Load the full action doc
    useEffect(() => {
        if (activeActionId) {
            const unsubscribe = listenAction(activeActionId, (action) => {
                const actionModel = action && new ActionModel(action);
                const linkedTraitIds = actionModel && actionModel.getLinkedTraitIds();

                // Get employees who are scheduled to complete the action
                const scheduledEmployees = actionModel.getScheduledEmployees();
                setScheduled(
                    scheduledEmployees.map((employee) => {
                        const employeeModel = employee && new EmployeeModel(employee);
                        const employeeFields = employeeModel.getSearchObject();
                        return {
                            ...employee,
                            ...employeeFields,
                        };
                    })
                );

                // Get employees who have completed the action
                const previousEmployees = actionModel.getCompletedEmployees();
                setCompleted(
                    previousEmployees
                        .map((employee) => {
                            const employeeModel = employee && new EmployeeModel(employee);
                            const employeeFields = employeeModel.getSearchObject();
                            return {
                                ...employee,
                                ...employeeFields,
                            };
                        })
                        .filter((employee) => !employee.disabled)
                );

                setPrevLinkedTraitIds(linkedTraitIds);
                setLinkedTraitIds(linkedTraitIds);
                setPrevValues(action);
                setNewValues(action);
            });
            return () => {
                unsubscribe();
            };
        }
    }, [activeActionId]);

    // Check if any newValues on the action have changed
    useEffect(() => {
        setActionHasChanged(!isEqual(prevValues, newValues));
    }, [prevValues, newValues]);

    // Check if any traits need to be updated to link to this action
    useEffect(() => {
        setTraitsHaveChanged(!isEqual(prevLinkedTraitIds, linkedTraitIds));
    }, [prevLinkedTraitIds, linkedTraitIds]);

    // Creates the email to string
    const createEmail = (contactManager) => {
        checkedEmployeeIds && constructMailTo(checkedEmployeeIds, contactManager);
    };

    const completeSelectedActions = () => {
        const onConfirm = () => {
            checkedEmployeeIds.forEach((empId) => {
                completeScheduledAction(activeActionId, empId);
            });
            dispatch(SET_CHECKED_IDS({ [employeeTableId]: [] }));
        };

        // Confirm before running
        if (checkedEmployeeIds) {
            confirmModal("complete_actions", onConfirm);
        }
    };

    const cancelSelectedActions = () => {
        const onConfirm = () => {
            checkedEmployeeIds.forEach((empId) => {
                deleteScheduledAction(activeActionId, empId);
            });
            dispatch(SET_CHECKED_IDS({ [employeeTableId]: [] }));
        };

        // Confirm before running
        if (checkedEmployeeIds) {
            confirmModal("cancel_actions", onConfirm);
        }
    };

    // Sets the field value but doesn't save the changes
    const handleSetFieldValue = (field, newValue) => {
        setNewValues({ ...newValues, [field]: newValue });
    };

    // Sets the linked Trait Ids but doesn't save them to the links
    const handlePrepNewLinkedTraitIds = (traitIds) => {
        setLinkedTraitIds(traitIds);
    };

    const handleSaveAction = async () => {
        if (actionHasChanged) {
            await setAction(newValues).catch((error) => {
                toast.error(error.message);
            });
            setPrevValues(newValues);
        }
    };

    const handleDeleteAction = () => {
        const onConfirm = () => {
            deleteAction(activeActionId);
            dispatch(REMOVE_FROM_STATE({ type: "actions", ids: [activeActionId] }));
            navigate(-1);
        };
        // Confirm before deleting
        confirmModal("delete", onConfirm, "actions");
    };

    // Saves the changes to the linked traits
    const handleAddNewLinkedTraitIds = () => {
        setAction(newValues).then(() => {
            // Remove actionId from removed traits
            const removed = prevLinkedTraitIds.filter((id) => !linkedTraitIds.includes(id));
            // Add actionId to added traits
            const added = linkedTraitIds.filter((id) => !prevLinkedTraitIds.includes(id));
            removeActionFromTraits(activeActionId, removed);
            addActionToTraits(activeActionId, added);
            setPrevLinkedTraitIds(linkedTraitIds);
            toast.success("Linked Traits Changed");
        });
    };

    const renderTable = () => {
        if (activeTab === "scheduled") {
            return (
                <EmployeesTable
                    tableId={employeeTableId}
                    data={scheduled}
                    columnKeys={EMPLOYEE_TABLE_KEYS}
                    pagination
                    selectableRows
                    rowsPerPage={10000}
                    checkbox
                    removeBorder
                />
            );
        } else {
            return (
                <EmployeesTable
                    tableId={employeeTableId}
                    data={completed}
                    columnKeys={EMPLOYEE_TABLE_KEYS}
                    pagination
                    selectableRows
                    rowsPerPage={10000}
                    checkbox
                    removeBorder
                />
            );
        }
    };

    if (!prevValues) return <LoadingIndicator />;

    return (
        <div className="inner-container flex-row align-items-stretch bg-white">
            <div className="f-2 d-flex flex-column px-3 scrollable pb-5 overflow-x border-right">
                <div className="d-flex title-box">
                    <div className="f-2 ps-4 py-3 d-flex flex-column justify-content-between">
                        <h3 className="mb-3">{actionName}</h3>
                    </div>
                </div>
                <div className="tile mb-3">
                    <FormGroup>
                        <Label for="actionName">Action Name</Label>
                        <Input
                            type="text"
                            name="actionName"
                            maxLength={FIELD_SIZE_LIMITS.ACTION_NAME}
                            placeholder="Action Name"
                            onChange={(e) => {
                                handleSetFieldValue("actionName", e.target.value);
                            }}
                            value={actionName || ""}
                            autoComplete="off"
                        />
                        <p className={classNames("mt-2 fs-small c-light", { hidden: !!newValues.actionName })}>
                            Name cannot be blank
                        </p>
                    </FormGroup>
                    <FormGroup>
                        <Label for="details">Details</Label>
                        <RichTextEditor
                            onChange={(rawContent) => handleSetFieldValue("details", rawContent)}
                            minHeight={180}
                            maxHeight={300}
                            maxLength={800}
                            content={details}
                        />
                    </FormGroup>
                    <Button
                        disabled={!actionHasChanged || !newValues.actionName}
                        className="basic mt-3 float-end"
                        onClick={handleSaveAction}
                    >
                        SAVE
                    </Button>
                </div>
                <div className="tile">
                    <Label>Linked Traits</Label>
                    <TraitPicker
                        selectedIds={linkedTraitIds}
                        isMulti
                        onChange={(traitIds) => {
                            handlePrepNewLinkedTraitIds(traitIds);
                        }}
                    />
                    <Button
                        disabled={!traitsHaveChanged}
                        className="basic mt-3 float-end"
                        onClick={handleAddNewLinkedTraitIds}
                    >
                        SAVE
                    </Button>
                </div>
                <div className="tile">
                    <Button onClick={handleDeleteAction}>DELETE ACTION</Button>
                </div>
            </div>
            <div className="inner-container f-5 justify-content-between">
                <TabBar
                    activeTab={activeTab}
                    changeTab={setActiveTab}
                    bulkActionsDisabled={!checkedEmployeeIds || checkedEmployeeIds.length === 0}
                    completeSelectedActions={completeSelectedActions}
                    cancelSelectedActions={cancelSelectedActions}
                    createEmail={createEmail}
                />
                <ResponsiveContainer>{renderTable()}</ResponsiveContainer>
            </div>
        </div>
    );
};

export default Action;
