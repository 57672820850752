import React from "react";
import { useLanguage } from "../../translations/LanguageContext";
import AppTour from "./AppTour";
import TourWelcome from "./TourWelcome";

const AssessmentTour = (props) => {
    const { t } = useLanguage();
    const language = t("assessment_tour", "tours");
    const tourSteps = [
        {
            target: "body",
            placement: "center",
            content: <TourWelcome language={language} />,
        },
        {
            target: ".stepper-nav",
            placement: "auto",
            content: language.step1,
        },
        {
            target: ".trait-details",
            placement: "auto",
            content: language.step2,
        },
        {
            target: ".rating-guidance",
            placement: "auto",
            content: language.step3,
        },
        {
            target: ".action-list",
            placement: "auto",
            content: (
                <div className="fs-small">
                    <p>{language.step4a}</p>
                    <p>{language.step4b}</p>
                </div>
            ),
        },
    ];

    if (!props.hide) {
        return <AppTour tourId="assessment-steps" steps={tourSteps} />;
    }
    return null;
};

export default AssessmentTour;
