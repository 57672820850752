import { withFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import RadioButtons from "../../components/Buttons/RadioButtons";
import { CheckBox } from "../../components/Inputs/Inputs";
import PsNumberInput from "../../components/Inputs/NumberInput/PsNumberInput";
import { TalentGridConfig } from "../../components/TalentMap/TalentGridConfig";
import { FIELD_SIZE_LIMITS } from "../../constants";
import { updateWorkspace } from "../../firebase/actions/workspace";
import { useLanguage } from "../../translations/LanguageContext";

const WorkspaceSettingsInnerForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    language,
    status,
    setFieldTouched,
    isValid,
    dirty,
}) => {
    const { t } = useLanguage();
    const grid = values.talentMapConfig?.grid ?? {};

    const handleSetTalentMapConfig = (field, option) => {
        let newConfig = { ...values.talentMapConfig };
        newConfig[field] = option;
        setFieldValue("talentMapConfig", newConfig);
    };

    return (
        <Form onSubmit={handleSubmit} className="f-1 d-flex flex-column justify-content-between">
            <h5>{t("general")}</h5>
            <hr />
            <div className="d-flex flex-column scrollable">
                <div className="mb-5">
                    <Col md={6}>
                        <FormGroup>
                            <Label for="workspaceName">{language.workspace_name}</Label>
                            <Input
                                type="text"
                                name="workspaceName"
                                maxLength={FIELD_SIZE_LIMITS.WORKSPACE_NAME}
                                minLength={1}
                                invalid={errors.workspaceName && touched.workspaceName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.workspaceName}
                            />
                            <FormFeedback>{errors.workspaceName}</FormFeedback>
                        </FormGroup>
                        {/*}
                        <FormGroup>
                            <Label>{t("owner")}</Label>
                            <EmployeePicker />
                        </FormGroup>
                        {*/}
                    </Col>
                </div>
                <div className="mb-5">
                    <h5>{language.assessment_settings}</h5>
                    <hr />
                    <Row>
                        <Col md={6}>
                            <div className="d-flex flex-column mt-3">
                                <Label>{language.assessment_requests}</Label>
                                <RadioButtons
                                    disabled
                                    options={language.assessment_options}
                                    selected={values.schedulingMode}
                                    onChange={(change) => {
                                        setFieldValue("schedulingMode", change);
                                    }}
                                />
                                <FormText>
                                    {
                                        language.assessment_options.find(
                                            (option) => option.value === values.schedulingMode
                                        )?.tooltip
                                    }
                                </FormText>
                                <FormText>
                                    The option for Automatic Requests will be enabled in the next release.
                                </FormText>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex">
                            <div className="d-flex flex-column mt-3 f-1">
                                <Label>{language.assessment_cycle}</Label>
                                <PsNumberInput
                                    className="mb-1"
                                    number={values.assessmentCycle || 180}
                                    min={30}
                                    max={365}
                                    scrollIncrement={1}
                                    onBlur={(val) => {
                                        setFieldValue("assessmentCycle", val);
                                    }}
                                />
                            </div>
                            <div className="d-flex flex-column mt-3 f-1">
                                <Label>{language.late_threshold}</Label>
                                <PsNumberInput
                                    className="mb-1"
                                    number={values.lateThreshold || 30}
                                    min={10}
                                    max={60}
                                    scrollIncrement={1}
                                    onBlur={(val) => {
                                        setFieldValue("lateThreshold", val);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <CheckBox
                                label={language.self_assessment}
                                checked={values.enableSelfAssessment}
                                onClick={(val) => setFieldValue("enableSelfAssessment", val)}
                            />
                            <FormText>
                                {values.enableSelfAssessment
                                    ? language.self_assessment_enabled
                                    : language.self_assessment_disabled}
                            </FormText>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Label>{language.talent_map_config}</Label>
                        <Col md={6}>
                            <TalentGridConfig grid={grid} setConfig={handleSetTalentMapConfig} />
                        </Col>
                    </Row>
                </div>
                <div className="mb-5">
                    <h5>{language.succession_settings}</h5>
                    <hr />
                    <Row className="mt-3">
                        <Col md={6}>
                            <CheckBox
                                disabled={!status.successionPlanningAllowed}
                                label={language.enable_succession_planning}
                                checked={values.enableSuccessionPlanning}
                                onClick={(val) => setFieldValue("enableSuccessionPlanning", val)}
                            />
                            {!status.successionPlanningAllowed && <FormText>{t("package_not_included")}</FormText>}
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="form-footer py-3 border-top">
                <Button className="float-right" type="submit" disabled={!isValid || !dirty}>
                    {t("save")}
                </Button>
            </div>
        </Form>
    );
};

const WorkspaceSettingsForm = withFormik({
    mapPropsToValues: (props) => {
        const { workspace, assessmentSettings } = props;
        const { enabledModules } = workspace;
        return {
            workspaceName: workspace.workspaceName,
            schedulingMode: assessmentSettings.schedulingMode,
            assessmentCycle: assessmentSettings.assessmentCycle,
            lateThreshold: assessmentSettings.lateThreshold,
            enableSelfAssessment: assessmentSettings.enableSelfAssessment,
            enableSuccessionPlanning: enabledModules?.successionPlanning,
            talentMapConfig: { ...props.talentMapConfig } || {},
        };
    },
    mapPropsToStatus: (props) => {
        const { subscribedModules } = props;
        return {
            successionPlanningAllowed: subscribedModules?.successionPlanning,
        };
    },
    enableReinitialize: true,
    validationSchema: () => {
        // lets you validate what's been added to the form
        return Yup.object().shape({
            workspaceName: Yup.string()
                .required("Workspace name is required.")
                .max(FIELD_SIZE_LIMITS.WORKSPACE_NAME, "Workspace name is too long."),
        });
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(WorkspaceSettingsInnerForm);

const WorkspaceSettings = () => {
    const assessmentSettings = useSelector((state) => state.assessments.settings);
    const workspace = useSelector((state) => state.workspace);
    const { t } = useLanguage();
    const language = t("settings", "pages");
    const talentMapConfig = useSelector((state) => state?.workspace?.assessmentSettings?.talentMapConfig);
    const subscribedModules = useSelector((state) => state?.workspace?.subscribedModules);

    const handleSubmit = (values) => {
        const updateValues = {
            workspaceName: values.workspaceName,
            enabledModules: {
                successionPlanning: values.enableSuccessionPlanning || false,
            },
            assessmentSettings: {
                schedulingMode: values.schedulingMode,
                assessmentCycle: values.assessmentCycle,
                lateThreshold: values.lateThreshold,
                enableSelfAssessment: values.enableSelfAssessment,
                talentMapConfig: values.talentMapConfig,
            },
        };
        updateWorkspace(updateValues)
            .then(() => {
                toast.success("Workspace update");
            })
            .catch((error) => {
                toast.error("Error updating workspace");
            });
    };

    return (
        <WorkspaceSettingsForm
            talentMapConfig={talentMapConfig}
            assessmentSettings={assessmentSettings}
            subscribedModules={subscribedModules}
            workspace={workspace}
            language={language}
            onSubmit={handleSubmit}
        />
    );
};

export default WorkspaceSettings;
