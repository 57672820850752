import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { TiWarningOutline } from "react-icons/ti";
import { Button } from "reactstrap";
import { LanguageProvider, useLanguage } from "../../translations/LanguageContext";

const iconSize = 36;

const ConfirmAlert = (props) => {
    const { t } = useLanguage();
    const language = t(props.actionType, "confirm_alerts") || {};
    const defaultCancelLabel = t("cancel");
    const defaultConfirmLabel = t("confirm");

    const handleConfirm = () => {
        props.onConfirm();
        props.onClose();
    };

    return (
        <div className="d-flex flex-column shadow rounded">
            <div
                className={classNames(
                    "f-1 bg-white p-4 d-flex flex-column align-items-center justify-content-center border-bottom c-warning"
                )}
            >
                <TiWarningOutline size={iconSize} />
                <h5 className="mt-2 mb-0">{language.title}</h5>
            </div>
            <div className={classNames("f-2 bg-light p-3 d-flex flex-column align-items-between")}>
                <div className="text-center mb-4 px-3 text-capitalize fw-bold">
                    <p className="mb-2">{language.details}</p>
                    {language.extra_details && <p>{language.extra_details}</p>}
                </div>
                <div className="d-flex justify-content-center px-3">
                    <Button className="f-1 me-2 text-uppercase" onClick={props.onClose}>
                        {language.cancel_button_label || defaultCancelLabel}
                    </Button>
                    <Button className="f-1 text-uppercase" onClick={handleConfirm}>
                        {language.confirm_button_label || defaultConfirmLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};

ConfirmAlert.propTypes = {
    alertType: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string,
    extraDetails: PropTypes.string,
    singleButton: PropTypes.bool,
    confirmButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

ConfirmAlert.defaultProps = {
    confirmButtonLabel: "Confirm",
    cancelButtonLabel: "Cancel",
    singleButton: false,
    onClose: () => {},
    onConfirm: () => {},
};

export function confirmModal(actionType, onConfirm) {
    return confirmAlert({
        customUI: ({ onClose }) => (
            <LanguageProvider>
                <ConfirmAlert actionType={actionType} onClose={onClose} onConfirm={onConfirm} />
            </LanguageProvider>
        ),
    });
}

export default ConfirmAlert;
