import { lookTraits } from "../redux/utils/looks";
import { cLog } from "../utils/basicUtils";

class TalentAreaModel {
    constructor(talentArea) {
        if (!talentArea || typeof talentArea === "string") {
            cLog("TalentAreaModel received invalid value:", typeof talentArea);
        } else {
            this.id = talentArea.id;
            this._talentArea = talentArea;
        }
    }

    getLinkedTraitIds() {
        const traits = lookTraits();
        return (
            traits &&
            Object.entries(traits)
                .filter(([traitId, trait]) => trait.talentAreaIds.includes(this.id))
                .map(([id, trait]) => id)
        );
    }
}

export default TalentAreaModel;
