// Firebase stuff
import { getApp, initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { cLog } from "../utils/basicUtils";

const firebaseConfig = {
    apiKey: "AIzaSyA7vpBmdmMHy9NFMec66fo1DOFfmmxQazs",
    authDomain: "talent-mapper-app.firebaseapp.com",
    projectId: "talent-mapper-app",
    storageBucket: "talent-mapper-app.appspot.com",
    messagingSenderId: "85520720073",
    appId: "1:85520720073:web:388961ad941f2dbe6d6d18",
    measurementId: "G-V8RD6DJ37W",
};

export const firebaseApp = initializeApp(firebaseConfig);

// Initialize firebase instance
export const db = getFirestore();
export const functions = getFunctions(getApp());
export const auth = getAuth();


// Use emulators if in Development
export const useEmulators = true;

const IS_DEV = process.env.NODE_ENV === "development";
const IS_TEST = process.env.NODE_ENV === "test";
const NOT_PROD = IS_DEV || IS_TEST;
export const disableEmails = NOT_PROD && useEmulators;

if (useEmulators && NOT_PROD) {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
    cLog("CONNECTED TO EMULATORS");
}


