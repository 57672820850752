import { PropTypes } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import IdPicker from "./IdPicker";

const TalentAreaPicker = (props) => {
    const { selectedIds, excludeIds, isMulti, isClearable } = props;
    const talentAreas = useSelector((state) => state.talent.talentAreas);

    const getLabel = (talentArea) => {
        return talentArea.talentAreaName;
    };

    return (
        <IdPicker
            {...props}
            name="talentAreas"
            getLabel={getLabel}
            options={talentAreas}
            selectedIds={selectedIds}
            excludeIds={excludeIds}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

TalentAreaPicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

TalentAreaPicker.defaultProps = {
    selectedIds: [],
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
};

export default TalentAreaPicker;
