import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { FormStep, FormStepper, RichTextEditor, TraitPicker } from "../..";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { setAction } from "../../../firebase/actions/action";
import { addActionToTraits } from "../../../firebase/actions/trait";
import { useLanguage } from "../../../translations/LanguageContext";
import { getUniqueId } from "../../../utils/basicUtils";

const CreateActionInnerForm = ({
    toggle,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    validationSchemas,
    language,
    status,
}) => {
    const { t } = useLanguage();
    return (
        <FormStepper
            toggle={toggle}
            validationSchemas={validationSchemas}
            isOpen={status.isOpen}
            values={values}
            onSubmit={handleSubmit}
            size="md"
            isWaiting={status.isWaiting}
            headerText={language.form_header}
            minHeight={580}
        >
            <FormStep title={t("details")}>
                <FormGroup>
                    <Label for="actionName">{t("label")}</Label>
                    <Input
                        type="text"
                        name="actionName"
                        maxLength="140"
                        invalid={errors.actionName && touched.actionName}
                        placeholder={t("name")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.actionName || ""}
                        autoComplete="off"
                    />
                    <FormFeedback>{errors.actionName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="actionName">{t("description")}</Label>
                    <RichTextEditor
                        onChange={(rawContent) => {
                            setFieldValue("details", rawContent);
                        }}
                        minHeight={240}
                    />
                </FormGroup>
            </FormStep>
            <FormStep title={t("linked_traits")}>
                <FormGroup>
                    <Label for="traits">{t("trait")}</Label>
                    <TraitPicker
                        selectedIds={values.linkedTraitIds}
                        onChange={(traitIds) => {
                            setFieldValue("linkedTraitIds", traitIds);
                        }}
                        isMulti
                    />
                </FormGroup>
            </FormStep>
        </FormStepper>
    );
};

const CreateActionForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isOpen: props.isOpen,
            isWaiting: props.isWaiting,
        };
    },
    mapPropsToValues: ({ ...props }) => {
        return { ...props.initialValues };
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: (props) => {
        let mergedSchema = {};
        props.validationSchemas.forEach((schema) => {
            mergedSchema = { ...mergedSchema, ...schema };
        });
        return Yup.object().shape(mergedSchema);
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(CreateActionInnerForm);

const CreateAction = (props) => {
    const [newId] = useState(getUniqueId());
    const [isWaiting, setIsWaiting] = useState(false);
    const newAction = useSelector((state) => state.talent.actions[newId]);
    const toggle = props.toggle;
    const { t } = useLanguage();
    const language = t("create_action", "forms");

    const validationSchemas = [
        {
            actionName: Yup.string()
                .required(t("required", "warnings"))
                .max(FIELD_SIZE_LIMITS.ACTION_NAME, t("length_max", "warnings")),
        },
    ];

    const initialValues = {
        id: newId,
        linkedTraitIds: [],
    };

    useEffect(() => {
        if (isWaiting && newAction) {
            toggle();
        }
    }, [isWaiting, newAction, toggle]);

    const handleSubmit = (values) => {
        let { linkedTraitIds, ...actionUpdate } = values;
        setIsWaiting(true);
        addActionToTraits(actionUpdate.id, linkedTraitIds);
        setAction(actionUpdate);
    };

    return (
        <CreateActionForm
            {...props}
            validationSchemas={validationSchemas}
            language={language}
            isWaiting={isWaiting}
            onSubmit={handleSubmit}
            initialValues={initialValues}
        />
    );
};

export default CreateAction;
