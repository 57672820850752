import PropTypes from "prop-types";
import React from "react";
import GaugeChart from "react-gauge-chart";

const DialGauge = ({ value, size, strokeWidth, strokeColor, fillColor }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - (value / 100) * circumference;
    const greyStrokeOffset = circumference - circumference;

    return (
        <div className="text-center">
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill={fillColor}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                />
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="transparent"
                    stroke="#ffffff"
                    strokeWidth={strokeWidth + 2}
                />
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="transparent"
                    stroke="#d1d1d1"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={greyStrokeOffset}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                />
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="transparent"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                />
                <text x={size / 2} y={size / 2} textAnchor="middle" dominantBaseline="middle" fontSize={size * 0.2}>
                    {value ? `${value}%` : "N/A"}
                </text>
            </svg>
        </div>
    );
};

const PointerGauge = ({ value, colors, arcWidth }) => {
    const ratingScale = colors.length;
    const greyedOut = colors.map(() => "#e5e5e5");
    const gaugeAdjustment = 0;
    const useColors = value ? colors : greyedOut;

    return (
        <GaugeChart
            className={"gauge-chart"}
            animate={false}
            nrOfLevels={ratingScale}
            hideText={true}
            percent={!value ? 0 : (value - gaugeAdjustment) / 100}
            arcWidth={arcWidth}
            colors={useColors}
        />
    );
};

const PercentageGaugeChart = (props) => {
    if (props.round) {
        return <DialGauge {...props} size={props.width / 1.5} />;
    } else {
        return <PointerGauge {...props} />;
    }
};

PercentageGaugeChart.propTypes = {
    colors: PropTypes.array,
    height: PropTypes.number,
    width: PropTypes.number,
    size: PropTypes.number,
    percentage: PropTypes.number,
    arcWidth: PropTypes.number,
    strokeWidth: PropTypes.number,
    strokeColor: PropTypes.string,
    fillColor: PropTypes.string,
    round: PropTypes.bool,
};

PercentageGaugeChart.defaultProps = {
    colors: [],
    height: 100,
    width: 100,
    size: 100,
    percentage: 0,
    arcWidth: 0.3,
    strokeWidth: 10,
    strokeColor: "#1e88e5",
    fillColor: "transparent",
};

export default PercentageGaugeChart;
