import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Badge, CloseButton } from "reactstrap";
import "./draggable-board.scss";

const RowTitleCell = ({ row }) => (
    <div className="cell f-1 p-2">
        <h5 className="fs-regular">{row.title}</h5>
        <h6 className="fs-small">{row.subtitle}</h6>
        <Badge
            className={classNames({
                "bg-success": row.strength === 100,
                "bg-warning": row.strength === 40,
                "bg-danger": row.strength === 0,
            })}
        >{`${row.strength}%`}</Badge>
    </div>
);

const BufferRow = ({ columns }) => (
    <div key="buffer-cell" className="board-row d-flex border-bottom-dashed">
        <div className="cell f-1 p-2"></div>
        {columns.map((col) => {
            return (
                <div
                    style={{ backgroundColor: col.color, height: 100 }}
                    key={`buffer-cell-${col.key}`}
                    className="drop-zone-cell f-1 p-2"
                />
            );
        })}
    </div>
);

const Card = ({ item, rowId, onRemove, className }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "CARD",
        item: item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div className={classNames("card p-2 mb-2", className)} ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <CloseButton className="close-button" onClick={() => onRemove(item)} />
            <div className="card-content">
                <div className="fs-normal fw-bold">{item?.title}</div>
                <div
                    className={classNames("text-muted", {
                        "c-warning": item.highlightLevel > 1 && item.highlightLevel < 3,
                        "c-danger": item.highlightLevel >= 3,
                    })}
                >
                    {item?.subtitle}
                </div>
            </div>
        </div>
    );
};

const BoardCell = ({ onDrop, rowId, colId, items, className, onRemove, style }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "CARD",
        drop: (item) => {
            onDrop(item, rowId, colId);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} style={style} className={`${className} ${isOver ? "cell-over" : ""}`}>
            {items.map((item) => (
                <Card key={item.key} item={item} rowId={rowId} colId={colId} onRemove={onRemove} />
            ))}
        </div>
    );
};

const DraggableBoard = React.forwardRef((props, ref) => {
    const {
        rows,
        columns,
        onDrop,
        onRemove,
        primaryColHeader,
        toolbarComponent: Toolbar,
        optionsComponent: Options,
        summaryComponent: Summary,
    } = props;

    return (
        <div className="d-flex draggable-board flex-row">
            <Options {...props.optionProps} />
            <div className="d-flex board-container inner-container">
                {props.toolbarComponent && (
                    <div className="toolbar">
                        <Toolbar {...props.toolbarProps} />
                    </div>
                )}
                <div ref={ref} className="d-flex flex-column inner-container">
                    {Summary && (
                        <div className="board-summary">
                            <Summary {...props.summaryProps} />
                        </div>
                    )}
                    <div className="board-header d-flex flex-row border-bottom text-center text-uppercase fw-bold">
                        <div className="primary-col-header header f-1 p-2">{primaryColHeader}</div>
                        {columns.map((col) => (
                            <div key={`header-${col.key}`} className="drop-zone-header header f-1 p-2">
                                {col.title}
                            </div>
                        ))}
                    </div>
                    <div className="board-rows scrollable board-rows-space">
                        {rows.map((row) => {
                            return (
                                <div key={`cell-${row.key}`} className="board-row d-flex border-bottom-dashed">
                                    <RowTitleCell row={row} />
                                    {columns.map((col) => {
                                        const items = row?.items ?? [];
                                        const colItems = items?.filter((item) => item.colId === col.key);
                                        return (
                                            <BoardCell
                                                style={{ backgroundColor: col.color }}
                                                key={`cell-${row.key}-${col.key}`}
                                                className="drop-zone-cell f-1 p-2"
                                                onDrop={onDrop}
                                                onRemove={onRemove}
                                                items={colItems || []}
                                                rowId={row.key}
                                                colId={col.key}
                                            />
                                        );
                                    })}
                                </div>
                            );
                        })}
                        <BufferRow columns={columns} />
                    </div>
                </div>
            </div>
        </div>
    );
});

DraggableBoard.propTypes = {
    primaryColHeader: PropTypes.string,
};

DraggableBoard.defaultProps = {
    primaryColHeader: "Role",
};

export default DraggableBoard;
