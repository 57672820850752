import { EmployeeModel } from "../models";
import { lookClaims, lookEmployee, lookEmployees, lookRole, lookWorkspace } from "../redux/utils/looks";

export function emailInUseAlready(email, empId = "") {
    const employees = lookEmployees();
    return (
        Object.values(employees).filter((emp) => {
            return emp.id !== empId && emp.email === email;
        }).length !== 0
    );
}

export function employeeNumberInUseAlready(employeeNumber, empId = "") {
    const employees = lookEmployees();
    return (
        Object.values(employees).filter((emp) => {
            return emp.id !== empId && emp.employeeNumber === employeeNumber;
        }).length !== 0
    );
}

export function getEmployeeRole(empId) {
    const emp = lookEmployee(empId);
    return emp && lookRole(emp.roleId);
}

export function userManagesEmployee(empId) {
    const emp = lookEmployee(empId);
    const role = emp && lookRole(emp.roleId);
    const claims = lookClaims();
    return role && claims && role.parentRoleId && role.parentRoleId === claims.roleId;
}

export function constructMailTo(employeeIds, contactManager) {
    let emails = employeeIds
        .map((empId) => {
            const employee = lookEmployee(empId);
            if (contactManager) {
                const employeeModel = employee && new EmployeeModel(employee);
                const manager = employeeModel && employeeModel.getManager();
                return manager && manager.email;
            } else {
                return employee && employee.email;
            }
        })
        .filter((email) => !!email);

    const uniqueEmails = [...new Set(emails)];
    let mailTo = "mailTo:";
    uniqueEmails.forEach((email) => (mailTo = `${mailTo}${email};`));
    window.open(mailTo, "_blank");
}

export function checkIfOwner(empId) {
    const workspace = lookWorkspace();
    return workspace.ownerId === empId;
}

export function checkIfAdmin(employee) {
    return employee && (employee.userRole === "admin" || employee.userRole === "owner");
}

export function checkIfSelf(employeeId) {
    const claims = lookClaims();
    return claims && claims.employeeId === employeeId;
}
