import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { ScheduledActionsTable } from "../../../components";
import { listenEmployee } from "../../../firebase/listeners";
import { lookAction } from "../../../redux/utils/looks";

const docsPerPage = 10;

const TABS = {
    incomplete: "Incomplete",
    complete: "Complete",
};

const ACTION_COLS = ["actionName"];

const TabBar = (props) => {
    return (
        <div className="sticky-tab-bar d-flex justify-content-between control-section px-4 bg-transparent">
            <Nav tabs className="f-3 d-flex fullpage-tabs justify-content-center bg-transparent fs-small">
                <div className="d-flex">
                    {Object.entries(TABS).map(([key, label]) => {
                        return (
                            <NavItem key={`tab-${key}`}>
                                <NavLink
                                    className={classNames({
                                        active: props.activeTab === key,
                                    })}
                                    onClick={() => {
                                        props.onChangeTab(key);
                                    }}
                                >
                                    {label}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </div>
            </Nav>
        </div>
    );
};

const EmployeeActionsArea = () => {
    const [activeTab, setActiveTab] = useState("incomplete");
    const [completeActions, setCompleteActions] = useState([]);
    const [incompleteActions, setIncompleteActions] = useState([]);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);

    useEffect(() => {
        setCompleteActions([]);
        setIncompleteActions([]);
        if (selectedEmployeeId) {
            const unsubscribe = listenEmployee(selectedEmployeeId, (employee) => {
                const incompleteActionIds = employee.scheduledActionIds || [];
                const incompleteActions = incompleteActionIds.map((id) => {
                    const action = lookAction(id);
                    return { ...action, actionId: id, employeeId: selectedEmployeeId, complete: false };
                });
                setIncompleteActions(incompleteActions);

                const completedActionIds = employee.completedActionIds || [];
                const completeActions = completedActionIds.map((id) => {
                    const action = lookAction(id);
                    return { ...action, actionId: id, employeeId: selectedEmployeeId, complete: false };
                });
                setCompleteActions(completeActions);
            });
            return () => {
                unsubscribe();
            };
        }
    }, [selectedEmployeeId]);

    const actions = activeTab === "complete" ? completeActions : incompleteActions;

    return (
        <div className="side-area no-scroll px-0 h-100 d-flex flex-column align-items-stretch bg-transparent">
            <TabBar activeTab={activeTab} onChangeTab={setActiveTab} />
            <div className="scrollable mb-3 f-1">
                <ScheduledActionsTable data={actions} columnKeys={ACTION_COLS} removeBorder docsPerPage={docsPerPage} />
            </div>
        </div>
    );
};

export default EmployeeActionsArea;
