import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { LoadingIndicator, Switch, TraitPicker } from "../../../components";
import { confirmModal } from "../../../components/Dialogs/ConfirmAlert";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { disableTalentArea, setTalentArea } from "../../../firebase/actions/talentArea";
import { addTalentAreaToTrait, removeTalentAreaFromTrait } from "../../../firebase/actions/trait";
import { TalentAreaModel } from "../../../models";
import { SET_SIDE_AREA } from "../../../redux/appSlice";

const EditTalentArea = (props) => {
    // Fetch the action details
    const dispatch = useDispatch();
    const [traitsHaveChanged, setTraitsHaveChanged] = useState(false);
    const [areaHasChanged, setAreaHasChanged] = useState(false);
    const selectedTalentAreaId = useSelector((state) => state.app.selectedTalentAreaId);
    const selectedTalentArea = useSelector((state) => state.talent.talentAreas[selectedTalentAreaId]);
    const [editedTalentArea, setEditedTalentArea] = useState({ ...selectedTalentArea });
    const [linkedTraitIds, setLinkedTraitIds] = useState([]);
    const [prevLinkedTraitIds, setPrevLinkedTraitIds] = useState([]);

    useEffect(() => {
        if (selectedTalentArea) {
            const talentAreaModel = new TalentAreaModel(selectedTalentArea);
            const linkedTraitIds = talentAreaModel.getLinkedTraitIds();
            setPrevLinkedTraitIds(linkedTraitIds);
            setLinkedTraitIds(linkedTraitIds);
        }
    }, [selectedTalentArea]);

    // Determine if the Talent Areas has changed
    useEffect(() => {
        const talentAreaHasChanged = !isEqual(editedTalentArea, selectedTalentArea);
        setAreaHasChanged(talentAreaHasChanged);
    }, [editedTalentArea, selectedTalentArea]);

    // Determine if LinkedTraits have changed
    useEffect(() => {
        const linkedTraitsHaveChanged = !isEqual(prevLinkedTraitIds, linkedTraitIds);
        setTraitsHaveChanged(linkedTraitsHaveChanged);
    }, [prevLinkedTraitIds, linkedTraitIds]);

    if (!editedTalentArea) return <LoadingIndicator />;

    const handleDisableTalentArea = () => {
        const onConfirm = () => {
            disableTalentArea(selectedTalentAreaId).then(() => {
                prevLinkedTraitIds.forEach((traitId) => {
                    removeTalentAreaFromTrait(traitId, selectedTalentAreaId);
                });
                dispatch(SET_SIDE_AREA());
            });
        };

        // Confirm before delete
        confirmModal("delete", onConfirm, "talentArea");
    };

    const handlePrepChange = (field, change) => {
        const update = { ...editedTalentArea, [field]: change };
        setEditedTalentArea(update);
    };

    const handleSubmit = () => {
        if (areaHasChanged) {
            setTalentArea(editedTalentArea);
        }
        if (traitsHaveChanged) {
            const removeFrom = prevLinkedTraitIds.filter((id) => !linkedTraitIds.includes(id));
            removeFrom.forEach((traitId) => {
                removeTalentAreaFromTrait(traitId, selectedTalentAreaId);
            });

            const addTo = linkedTraitIds.filter((id) => !prevLinkedTraitIds.includes(id));
            addTo.forEach((traitId) => {
                addTalentAreaToTrait(traitId, selectedTalentAreaId);
            });
        }
        dispatch(SET_SIDE_AREA());
    };

    return (
        <div className="side-area h-100 d-flex flex-column align-items-stretch bg-transparent">
            <div className="f-1 pt-3 scrollable">
                <FormGroup>
                    <Label for="talentAreaName">Talent Area Name</Label>
                    <Input
                        type="text"
                        name="talentAreaName"
                        maxLength={FIELD_SIZE_LIMITS.TALENT_AREA_NAME}
                        placeholder="Talent Area Name"
                        onChange={(e) => handlePrepChange("talentAreaName", e.target.value)}
                        value={editedTalentArea.talentAreaName || ""}
                        autoComplete="off"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                        style={{ minHeight: 150 }}
                        type="textarea"
                        name="description"
                        maxLength={FIELD_SIZE_LIMITS.DESCRIPTION}
                        placeholder="Description"
                        onChange={(e) => handlePrepChange("description", e.target.value)}
                        value={editedTalentArea.description || ""}
                        autoComplete="off"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="description">Linked Traits</Label>
                    <TraitPicker
                        selectedIds={linkedTraitIds}
                        isMulti
                        onChange={(newTraitIds) => {
                            setLinkedTraitIds(newTraitIds);
                        }}
                    />
                </FormGroup>
                <FormGroup row>
                    <Label sm={10} for="excludeFromOverview" className="text-end">
                        <h6>Exclude From Overview</h6>
                    </Label>
                    <Col sm={2}>
                        <Switch
                            checked={editedTalentArea.excludeFromOverview}
                            onChange={() =>
                                handlePrepChange("excludeFromOverview", !editedTalentArea.excludeFromOverview)
                            }
                        />
                    </Col>
                </FormGroup>
            </div>
            <div className="pt-3 border-top d-flex justify-content-between">
                <Button onClick={handleDisableTalentArea}>Delete</Button>
                <Button disabled={!areaHasChanged && !traitsHaveChanged} onClick={handleSubmit}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default EditTalentArea;
