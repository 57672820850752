import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectRole } from "../../../redux/utils/dispatches";
import { RemoveButton } from "../../Buttons/MyButtons";
import BaseTable from "../BaseTable";


const BoardMembersTable = (props) => {
    const navigate = useNavigate();
    const {
        data,
        columnKeys,
        tooltipId,
        iconSize,
        includeRemove,
        onRemove,
    } = props;
    const [includedColumns, setIncludedColumns] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const pendingChangeIds = useSelector((state) => state.org.pendingChanges);


    // Set the columns for the table
    useEffect(() => {
        let columns = [
            {
                key: "displayName",
                title: "Name",
                dataIndex: "displayName",
            },
            {
                key: "jobTitle",
                title: "Job Title",
                dataIndex: "jobTitle",
            },
            {
                key: "managerName",
                dataIndex: "managerName",
                title: "Manager",
            },
        ];

        // Include only the basic colums included in the chosen columnKey list
        columns = columns.filter((column) => columnKeys.includes(column.key));

        if (includeRemove) {
            columns.push({
                key: "remove",
                width: "1%",
                render: (label, role, index) => {
                    return <RemoveButton round={false} size={18} icon onClick={() => onRemove(role.id)} />;
                },
            });
        }

        setIncludedColumns(columns);
    }, [
        columnKeys,
        tooltipId,
        iconSize,
        includeRemove,
        onRemove,
    ]);

    const handleRowClick = (roleId) => {
        selectRole(roleId);
    };

    const handleRowDoubleClick = (roleId) => {
        if (roleId) {
            navigate(`/people/${roleId}/employee`);
        }
    };

    return (
        <BaseTable
            {...props}
            data={data}
            className={classNames("employees-table", props.className)}
            selectedId={selectedEmployeeId}
            pendingChangeIds={pendingChangeIds}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleRowDoubleClick}
            checkbox={props.checkbox}
            columns={includedColumns}
            pageIndex={pageIndex}
            sortKey="displayName"
            onSetPageIndex={setPageIndex}
        />
    );
};

BoardMembersTable.propTypes = {
    data: PropTypes.array,
    disableRowClick: PropTypes.bool,
    iconSize: PropTypes.number,
    rowsPerPage: PropTypes.number,
    checkbox: PropTypes.bool,
    selectableRows: PropTypes.bool,
    disableRowDoubleClick: PropTypes.bool,
    includeRemove: PropTypes.bool,
};

BoardMembersTable.defaultProps = {
    data: [],
    iconSize: 32,
    rowsPerPage: 30,
};

export default BoardMembersTable;
