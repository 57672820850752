import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { FormStep, FormStepper } from "../..";
import { setTalentArea } from "../../../firebase/actions/talentArea";
import { useLanguage } from "../../../translations/LanguageContext";
import { getUniqueId } from "../../../utils/basicUtils";

const CreateTalentAreaInnerForm = ({
    toggle,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    status,
    validationSchemas,
    language,
}) => {
    const { t } = useLanguage();
    return (
        <FormStepper
            toggle={toggle}
            validationSchemas={validationSchemas}
            isOpen={status.isOpen}
            values={values}
            size="md"
            onSubmit={handleSubmit}
            headerText={language.form_header}
            isWaiting={status.isWaiting}
            minHeight={460}
        >
            <FormStep>
                <FormGroup>
                    <Label for="talentAreaName">{t("label")}</Label>
                    <Input
                        type="text"
                        name="talentAreaName"
                        maxLength="140"
                        invalid={errors.talentAreaName && touched.talentAreaName}
                        placeholder={t("name")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.talentAreaName || ""}
                        autoComplete="off"
                    />
                    <FormFeedback>{errors.talentAreaName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="description">{t("description")}</Label>
                    <Input
                        type="textarea"
                        name="description"
                        maxLength="340"
                        invalid={errors.description && touched.description}
                        placeholder={t("description")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description || ""}
                        autoComplete="off"
                    />
                    <FormFeedback>{errors.description}</FormFeedback>
                </FormGroup>
            </FormStep>
        </FormStepper>
    );
};

const CreateTalentAreaForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isOpen: props.isOpen,
            isWaiting: props.isWaiting,
        };
    },
    mapPropsToValues: ({ ...props }) => {
        return {
            id: props.newId,
            talentAreaName: props.talentAreaName || "",
        };
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: (props) => {
        let mergedSchema = {};
        props.validationSchemas.forEach((schema) => {
            mergedSchema = { ...mergedSchema, ...schema };
        });
        return Yup.object().shape(mergedSchema);
    },
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(CreateTalentAreaInnerForm);

const CreateTalentArea = (props) => {
    const [newId] = useState(getUniqueId());
    const [isWaiting, setIsWaiting] = useState(false);
    const newTalentArea = useSelector((state) => state.talent.talentAreas[newId]);
    const toggle = props.toggle;
    const { t } = useLanguage();
    const language = t("create_talent_area", "forms");

    useEffect(() => {
        if (isWaiting && newTalentArea) {
            toggle();
        }
    }, [isWaiting, newTalentArea, toggle]);

    const validationSchemas = [
        {
            talentAreaName: Yup.string()
                .required(t("required", "warnings"))
                .min(1, t("required", "warnings"))
                .max(140, t("length_max", "warnings")),
        },
    ];

    const handleSubmit = (values) => {
        setIsWaiting(true);
        setTalentArea(values);
    };

    return (
        <CreateTalentAreaForm
            {...props}
            newId={newId}
            isWaiting={isWaiting}
            onSubmit={handleSubmit}
            language={language}
            validationSchemas={validationSchemas}
        />
    );
};

export default CreateTalentArea;
