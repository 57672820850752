import { deleteField, increment, setDoc, writeBatch } from "firebase/firestore";
import { EmployeeModel } from "../../models";
import { setPendingOrgChanges, updateEmployee } from "../../redux/utils/dispatches";
import { lookEmployee } from "../../redux/utils/looks";
import { removeUndefined } from "../../utils/basicUtils";
import { db } from "../firebase";
import { getBatch, getEmployeeDocRef, getNewEmployeeOrgInfoId } from "../firebaseUtils";
import { setRole } from "./role";

export function setEmployee(employeeId, receivedData, batch = writeBatch(db)) {
    const currentData = lookEmployee(employeeId);
    // Some of the fields here are stripping out legacy fields that might remain in error
    let { id, displayName, dateLastAssessed, emailInUse, talentBoardIds, importCount, ...preppedData } = receivedData;
    preppedData = removeUndefined(preppedData);

    // Ensure email is always stored in lowercase
    if (preppedData.email) {
        preppedData.email = preppedData.email.toLowerCase();
    }

    // don't do anything if there are no values to update
    if (Object.keys(preppedData).length === 0) return batch;

    // Set to pending if any public fields are changing
    if (currentData) setPendingOrgChanges(employeeId, currentData, receivedData);

    // Set the publicinfoDocId
    if (!preppedData.publicInfoDocId) {
        const currentEmployee = lookEmployee(employeeId);
        const existingPublicInfoDocId = currentEmployee && currentEmployee.publicInfoDocId;
        preppedData.publicInfoDocId = existingPublicInfoDocId || getNewEmployeeOrgInfoId();
    }

    // Update the employee doc
    const employeeDocRef = getEmployeeDocRef(employeeId);
    batch.set(employeeDocRef, preppedData, { merge: true });

    return batch;
}

export function resendInvite(employeeId) {
    const update = { inviteCounter: increment(1) };
    const employeeDocRef = getEmployeeDocRef(employeeId);
    setDoc(employeeDocRef, update, { merge: true });
}

export function setFirstEmployee(employeeId, update, batch) {
    // Update the employee doc
    const employeeDocRef = getEmployeeDocRef(employeeId);
    updateEmployee(employeeId, update);
    setDoc(employeeDocRef, update, { merge: true });
}

export async function deleteEmployee(employeeId) {
    const employeeRef = getEmployeeDocRef(employeeId);
    let batch = getBatch();
    batch.set(employeeRef, { disabled: true }, { merge: true });
    return batch;
}

export function removeEmployeeFromRole(employeeId, batch = writeBatch(db)) {
    const employee = lookEmployee(employeeId);
    const roleId = employee && employee.roleId;
    if (roleId) {
        batch = setRole(roleId, { incumbentId: deleteField() }, batch);
    }
    if (employeeId) {
        batch = setEmployee(employeeId, { roleId: deleteField() }, batch);
    }
    return batch;
}

export function changeWorkspaceClaims(employeeId, newClaims, batch = writeBatch(db)) {
    const currentEmployee = lookEmployee(employeeId);
    let claimUpdate = { ...newClaims };

    // Ensure the isUser flag is added/removed dependent on the userRole
    const isUser = newClaims.userRole && newClaims.userRole !== "none";
    claimUpdate.isUser = isUser;

    // Add the pending flag if the user has been invited but not yet accepted
    claimUpdate.pending = isUser && !currentEmployee.uid;

    // Create and invite new user;
    const createInvite = !currentEmployee.isUser && isUser;
    if (createInvite) {
        claimUpdate.inviteCounter = increment(1);
    }

    batch = setEmployee(employeeId, claimUpdate, batch);
    return batch;
}

export function moveEmployeeToNewRole(newRoleId, employeeId, newParentRoleId, batch = writeBatch(db)) {
    const employee = lookEmployee(employeeId);
    const employeeModel = new EmployeeModel(employee);
    const jobTitle = employeeModel.getJobTitle();

    // Remove the incumbentId from the previous role
    const previousRoleId = employee && employee.roleId;
    if (previousRoleId) {
        batch = setRole(previousRoleId, { incumbentId: deleteField() }, batch);
    }

    // Create the new role
    if (newRoleId) {
        let newRole = {
            parentRoleId: newParentRoleId,
            incumbentId: employeeId,
            jobTitle: jobTitle,
        };
        batch = setRole(newRoleId, newRole, batch);
    }

    return batch;
}
