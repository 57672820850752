import { lookTalentArea, lookTalentBoard, lookTrait, lookTraits } from "../redux/utils/looks";
import { dedupe } from "./basicUtils";

export function traitHasTalentArea(traitId, talentAreaId) {
    const trait = lookTrait(traitId);
    return trait && trait.talentAreaIds && trait.talentAreaIds.includes(talentAreaId);
}

export function getUniqueTalentAreaIds(arrayOfObjectsWithTalentAreaId) {
    let allTalentAreas = [];
    arrayOfObjectsWithTalentAreaId.forEach((trait) => {
        allTalentAreas = [...allTalentAreas, ...trait.talentAreaIds];
    });
    return dedupe(allTalentAreas);
}

// TRAIT //

export function isTraitNameUnique(traitName) {
    let traitNames = [];
    if (traitName) {
        const traits = lookTraits();
        traitNames = Object.values(traits).filter((trait) => {
            // check if trait matches trait.traitName ignoring case
            return traitName.toLowerCase() === trait.traitName.toLowerCase();
        });
    }
    return traitNames.length === 0;
}

export function getTraitIdsForAssessment(talentBoardIds = []) {
    const allTraits = lookTraits();
    const talentBoards = talentBoardIds.map((id) => lookTalentBoard(id) || {});
    const allBoardTraits = new Set();

    talentBoards.forEach((talentBoard) => {
        const traitIds = talentBoard.traitIds || [];
        traitIds.forEach((id) => allBoardTraits.add(id));
    });

    const traitIds = Object.entries(allTraits)
        .filter(([traitId, trait]) => {
            // If the trait applies to all boards, include it
            const appliesToAllBoards = !trait.boardsOnly;
            if (appliesToAllBoards) return true;

            // If the trait doesn't apply to all boards, see if it's included in any valid talentboards
            return allBoardTraits.has(traitId);
        })
        .map(([traitId, _]) => traitId);

    return [...new Set(traitIds)];
}

export function filterTraitsByTalentArea(traits, talentAreaId) {
    if (talentAreaId) {
        return Object.values(traits).filter((trait) => {
            return trait.talentAreaIds && trait.talentAreaIds.includes(talentAreaId);
        });
    } else {
        return [];
    }
}

export function prepAssessmentSteps(traits) {
    const dedupedTalentAreaIds = getUniqueTalentAreaIds(traits);
    const assessmentSteps = Object.values(dedupedTalentAreaIds).map((talentAreaId) => {
        const includedTraits = traits.filter((trait) => {
            return trait.talentAreaIds.includes(talentAreaId);
        });
        const includedTraitsId = includedTraits.map((trait) => trait.id);
        const talentArea = lookTalentArea(talentAreaId);
        return { ...talentArea, talentAreaId: talentAreaId, traitIds: includedTraitsId };
    });
    return assessmentSteps;
}

export function groupTraitsByTalentArea(traits) {
    const dedupedTalentAreaIds = getUniqueTalentAreaIds(traits);
    const groupedByTalentArea = Object.values(dedupedTalentAreaIds).map((talentAreaId) => {
        const traitsWithTalentArea = traits.filter((trait) => {
            return trait.talentAreaIds.includes(talentAreaId);
        });
        const talentArea = lookTalentArea(talentAreaId);
        return { ...talentArea, ...{ traits: traitsWithTalentArea, key: talentAreaId } };
    });
    return groupedByTalentArea;
}

export function mergePlannedActions(originalActions = [], newActions = []) {
    let mergedActions = {};
    originalActions.forEach((action) => {
        mergedActions[action.id] = action;
    });
    newActions.forEach((action) => {
        mergedActions[action.id] = action;
    });
    return Object.values(mergedActions);
}
