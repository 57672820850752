import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Joyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { setTourViewed } from "../../firebase/actions/user";
import { SET_ALLOW_TOUR, SET_RUN_TOUR } from "../../redux/appSlice";
import "./app-tour.scss";

const AppTour = (props) => {
    const dispatch = useDispatch();
    const runTour = useSelector((state) => state.app.runTour);
    const viewedTours = useSelector((state) => state.user.viewedTours);
    const activeModal = useSelector((state) => state.app.activeModal);
    const showTour = !viewedTours.includes(props.tourId) || runTour;

    useEffect(() => {
        dispatch(SET_ALLOW_TOUR(true));
        return function cleanup() {
            dispatch(SET_ALLOW_TOUR(false));
            dispatch(SET_RUN_TOUR(false));
        };
    }, [dispatch]);

    const handleStateChange = (state) => {
        if (state.status === "finished") {
            setTourViewed(props.tourId);
            dispatch(SET_RUN_TOUR(false));
        }
    };

    // Ensures the orgchart tour doesn't run in the background when modals are open
    if (props.tourId === "orgchart" && activeModal) return null;

    return (
        <Joyride
            steps={props.steps}
            run={showTour}
            continuous
            scrollToSteps
            scrollToFirstStep
            callback={handleStateChange}
            styles={{
                options: {
                    primaryColor: "#4878ff",
                    textColor: "#004a14",
                    zIndex: 5000,
                },
            }}
        />
    );
};

AppTour.propTypes = {
    steps: PropTypes.array,
    tourId: PropTypes.string,
    onComplete: PropTypes.func,
};

AppTour.defaultProps = {
    steps: [],
    onComplete: () => {},
};

export default AppTour;
