import React from "react";
import { useResizeDetector } from "react-resize-detector";

const ChartContainer = ({ children, padding = 0 }) => {
    const { width, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const size = width - padding;
    return (
        <div ref={ref} style={{ width: "100%" }}> 
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, { width: size, height: size });
            })}
        </div>
    );
};

export default ChartContainer;
