// Should be exactly the same as shared in the functions folder
// Workaround for the legacy version, doesn't carry over to the next one
const excludedFieldsAll = ["importCount", "publicInfoDocId", "disabled"];
const excludedFieldsByCollection = {
    roles: ["authRoles", "childRoles", "forceCascadeCount", "successors"],
    employees: [
        "dateLastAssessed",
        "dateLastSelfAssessed",
        "timeline",
        "roleId",
        "prevRoleId",
        "inviteCounter",
        "uid",
        "scheduledActionIds",
        "completedActionIds",
    ],
    actions: ["details", "scheduledEmployeeIds", "completedEmployeeIds"],
    talentAreas: ["guidance", "details"],
    traits: ["guidance", "description", "linkedTalentBoardIds"],
    talentBoards: ["guidance", "description"],
};
const rootRoleId = "eLKBas";
const companyAddress = "B43 Art House, 1 York Way, London, Kings Cross, N1C 4AT";
const ownerEmployeeId = "pUrmk";
const firstRolePublicDocId = "peBn15";
const firstEmployeePublicDoc = "fuynke";
const firstSnapshotId = "Molng";

module.exports = {
    EXCLUDED_FIELDS_ALL: excludedFieldsAll,
    EXCLUDED_FIELDS_BY_COLLECTION: excludedFieldsByCollection,
    ROOT_ROLE_ID: rootRoleId,
    COMPANY_ADDRESS: companyAddress,
    OWNER_EMPLOYEE_ID: ownerEmployeeId,
    FIRST_ROLE_PUBLIC_DOC_ID: firstRolePublicDocId,
    FIRST_EMPLOYEE_PUBLIC_DOC_ID: firstEmployeePublicDoc,
    FIRST_SNAPSHOT_ID: firstSnapshotId,
};
