import { groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getExactColor } from "../../../utils/snapshotUtils";
import TraitPieChart from "../../Charts/TraitCharts/TraitPieChart";

const SummaryTile = (props) => {
    const { bigTile, segment } = props;
    const { tile, cohort, percent } = segment;
    const [pieData, setPieData] = useState([]);
    const backgroundColor = getExactColor(tile.color);

    useEffect(() => {
        let groupedByZ = {};
        Object.entries(groupBy(cohort, "z")).forEach(([key, ary]) => {
            groupedByZ[key] = ary.length;
        });
        setPieData(groupedByZ);
    }, [cohort]);

    const textStyle = {
        color: backgroundColor,
        fontWeight: "bold",
    };

    const employeeLabel = cohort.length > 1 ? "Employees" : "Employee";

    const renderSmallTile = () => (
        <div className="f-1 d-flex p-2">
            <div className="p-2 f-3 info-container d-flex flex-column justify-content-between">
                <div style={textStyle}>
                    <h2 className="mb-2">{cohort.length}</h2>
                    <h6 className="fs-small">{`${employeeLabel} (${percent}%)`}</h6>
                </div>
                <Button className="w-50 py-1 px-3 fs-tiny" outline onClick={() => props.onClick(props.segment)}>
                    View
                </Button>
            </div>
            <div className="f-2">
                <TraitPieChart ratingCounts={pieData} className="px-3" />
            </div>
        </div>
    );

    const renderBigTile = () => (
        <div className="f-1 d-flex p-2 flex-column">
            <div className="p-2 f-3 info-container d-flex flex-column align-items-center">
                <div style={{ ...textStyle, textAlign: "center" }}>
                    <h1 className="mb-2">{cohort.length}</h1>
                    <h6>{`${employeeLabel} (${percent}%)`}</h6>
                </div>
            </div>
            <div className="f-2 px-5 pb-5">
                <TraitPieChart ratingCounts={pieData} className="p-2" />
            </div>
            <Button className="mb-5 m-auto w-50 py-1 px-3 fs-tiny" outline onClick={() => props.onClick(props.segment)}>
                View
            </Button>
        </div>
    );

    if (bigTile) {
        return renderBigTile();
    } else {
        return renderSmallTile();
    }
};

SummaryTile.propTypes = {
    segment: PropTypes.object,
    onClick: PropTypes.func,
};

SummaryTile.defaultProps = {
    segment: {},
    onClick: () => {},
};

export default SummaryTile;
