import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { RichTextEditor } from "../../components";
import UncontrolledCollapseBlock from "../../components/CollapseBlock/CollapseBlock";
import CustomField from "../../components/CustomField/CustomField";
import { RichTextDisplay } from "../../components/RichTextEditor/RichTextEditor";
import ActionItem from "./ActionItem";
import { AssessmentStep } from "./AssessmentStepper";
import "./assessments.scss";

const ScheduledActions = ({ actionIds, onCheckAction, prevActionIds }) => {
    const [actionsToSchedule, setActionsToSchedule] = useState([]);

    useEffect(() => {
        setActionsToSchedule(actionIds.filter((id) => !prevActionIds.includes(id)));
    }, [actionIds, prevActionIds]);

    return (
        <div className="f-1 h-100 action-list">
            <UncontrolledCollapseBlock title="Scheduled Actions" className="mb-3" defaultOpen>
                {prevActionIds.map((actionId, idx) => (
                    <div className="mb-3" key={`${actionId}-${idx}`}>
                        <ActionItem actionId={actionId} type="list" />
                    </div>
                ))}
                {prevActionIds.length === 0 && <p className="pt-3">No Scheduled Actions</p>}
            </UncontrolledCollapseBlock>
            <hr />
            <UncontrolledCollapseBlock title="New Actions to Schedule" defaultOpen>
                {actionsToSchedule.map((actionId) => (
                    <div className="mb-3" key={actionId}>
                        <ActionItem actionId={actionId} onCheckAction={onCheckAction} checked={true} type="remove" />
                    </div>
                ))}
                {actionsToSchedule.length === 0 && <p className="pt-3">No New Actions to Schedule</p>}
            </UncontrolledCollapseBlock>
        </div>
    );
};

const FinalStep = (props) => {
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const { preppedAssessment, actionIds, prevActionIds = [], onCheckAction } = props;

    const customFields = useSelector((state) => state.workspace.customFields);
    const assessmentFields = customFields.filter((field) => field.category === "assessment");

    //const actionsToSchedule = actionIds.filter((id) => !prevActionIds.includes(id));

    const handleFieldChange = (fieldName, value) => {
        props.onSetAssessmentValue(fieldName, value);
    };

    const pickers = assessmentFields.filter((field) => field.type === "picker" || field.type === "employeePicker");
    const switches = assessmentFields.filter((field) => field.type === "switch");

    return (
        <AssessmentStep hideSidePanel className="d-flex flex-column inner-container body-padding">
            {/*<AssessmentTour finalStep />*/}
            {switches.length > 0 && (
                <div className="d-flex border-bottom mb-3 pb-3">
                    {switches.map((field) => (
                        <CustomField
                            key={field.id}
                            field={field}
                            //label={field.label}
                            value={preppedAssessment[field.id]}
                            className="me-3"
                            onChange={handleFieldChange}
                        />
                    ))}
                </div>
            )}
            <div className="finish-assessment rating-section">
                <div className="f-3 assessment-flags d-flex flex-column scrollable">
                    <div className="f-1 d-flex flex-column">
                        <Row className="g-0 custom-field-row">
                            {pickers.map((field) => (
                                <Col md={6} className="mb-3 col" key={field.id}>
                                    <CustomField
                                        field={field}
                                        value={preppedAssessment[field.id]}
                                        className="mb-3"
                                        excludeFromOptions={[selectedEmployeeId]}
                                        onChange={handleFieldChange}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <div className="d-flex flex-column f-1 notes">
                            <h4>Manager Notes</h4>
                            <RichTextEditor
                                onChange={(rawContent) => {
                                    props.onSetAssessmentValue("notes", rawContent);
                                }}
                                minHeight={220}
                                maxHeight={280}
                                className="f-1 mb-3"
                                content={preppedAssessment.notes}
                            />
                            {props.selfNotes && (
                                <>
                                    <h4>Employee Notes</h4>
                                    <RichTextDisplay maxHeight={320} className="f-1" content={props.selfNotes} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="ps-3 f-2 scrollable">
                    <div className="f-1 ps-3 tour-action-plan action-plan f-3">
                        <ScheduledActions
                            actionIds={actionIds}
                            prevActionIds={prevActionIds}
                            onCheckAction={onCheckAction}
                        />
                    </div>
                </div>
            </div>
        </AssessmentStep>
    );
};

export default FinalStep;
