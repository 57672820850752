import classNames from "classnames";
import { ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "./rich-text-editor.scss";

export const RichTextDisplay = ({ content }) => {
    const createEditorState = () => {
        if (content) {
            try {
                const rawContent = JSON.parse(content);
                const contentState = convertFromRaw(rawContent);
                return EditorState.createWithContent(contentState);
            } catch (e) {
                return EditorState.createWithContent(ContentState.createFromText(content));
            }
        } else {
            return EditorState.createEmpty();
        }
    };

    const editorState = createEditorState();
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);

    return <div className="py-2 px-3 rich-text-display" dangerouslySetInnerHTML={{ __html: html }} />;
};

const TOOLBAR_OPTIONS = {
    options: ["inline", "list", "link"],
    inline: {
        options: ["bold", "italic", "underline"],
    },
    list: { options: ["unordered"] },
};

const RichTextEditor = (props) => {
    const [editorState, setEditorState] = useState();
    const [initialised, setInitialised] = useState();
    const { content, readOnly, minHeight, maxLength, maxHeight } = props;
    let wrapperStyle = {
        minHeight: minHeight,
        maxHeight: maxHeight,
    };

    useEffect(() => {
        setInitialised(true);
    }, []);

    useEffect(() => {
        if (!initialised || readOnly) {
            if (!content) {
                setEditorState(EditorState.createEmpty());
            } else {
                // Ensure no unhandled styles are copied into the editor
                let allowedStyles = ["BOLD", "ITALIC", "UNDERLINE"];
                let richTextObj = {};
                try {
                    richTextObj = JSON.parse(content);
                } catch (e) {
                    setEditorState(EditorState.createWithContent(ContentState.createFromText(content)));
                }
                const richTextBlocks = richTextObj.blocks || [];
                let cleanedBlocks = [];
                richTextBlocks.forEach((block) => {
                    let { inlineStyleRanges, ...cleanedBlock } = block;
                    inlineStyleRanges = inlineStyleRanges.filter((range) => allowedStyles.includes(range.style));
                    cleanedBlock = { ...cleanedBlock, inlineStyleRanges: inlineStyleRanges };
                    cleanedBlocks.push(cleanedBlock);
                });
                richTextObj.blocks = cleanedBlocks;
                try {
                    setEditorState(EditorState.createWithContent(convertFromRaw(richTextObj)));
                } catch (e) {
                    setEditorState(EditorState.createWithContent(ContentState.createFromText(content)));
                }
            }
        }
    }, [initialised, content, readOnly]);

    const handleBeforeInput = (input) => {
        const contentState = editorState.getCurrentContent();
        if (maxLength) {
            if (contentState.getPlainText().length > maxLength) {
                return "handled";
            }
        }
    };

    const onEditorStateChange = (newEditorState) => {
        const contentState = newEditorState.getCurrentContent();
        props.onChange(JSON.stringify(convertToRaw(contentState)));
        setEditorState(newEditorState);
    };

    const handleBlur = () => {
        props.onBlur();
    };

    return (
        <Editor
            onBlur={handleBlur}
            readOnly={readOnly}
            toolbarHidden={readOnly}
            editorState={editorState}
            wrapperStyle={wrapperStyle}
            //stripPastedStyles={true}
            wrapperClassName={classNames(props.className, "text-editor-wrapper")}
            editorClassName={classNames(
                { "p-o": props.removePadding, "border-all": !readOnly, "bg-white": !readOnly },
                "text-editor"
            )}
            onEditorStateChange={onEditorStateChange}
            handleBeforeInput={handleBeforeInput}
            toolbar={TOOLBAR_OPTIONS}
        />
    );
};

RichTextEditor.propTypes = {
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    classNames: PropTypes.string,
    content: PropTypes.string,
};

RichTextEditor.defaultProps = {
    maxLength: 2500,
    readOnly: false,
    minHeight: "unset",
    maxHeight: "unset",
    onChange: () => {},
    onBlur: () => {},
};

export default RichTextEditor;
