import { removeTalentAreaFromTrait } from "../firebase/actions/trait";
import { lookTalentBoards } from "../redux/utils/looks";
import { cLog } from "../utils/basicUtils";

class TraitModel {
    constructor(trait) {
        if (!trait || typeof trait === "string") {
            cLog("TraitModel received invalid value:", typeof trait);
        } else {
            this.id = trait.id;
            this._trait = trait;
        }
    }

    getTraitName() {
        return this._trait.traitName;
    }

    getAssessmentTips() {
        return this._trait.getAssessmentTips;
    }

    removeTalentArea(talentAreaId) {
        removeTalentAreaFromTrait(this.id, talentAreaId);
    }

    getActionIds() {
        return this._trait.actionIds || [];
    }

    featuredInBoards() {
        const talentBoards = lookTalentBoards();
        const linkedBoards = Object.values(talentBoards).filter((talentBoard) => {
            const traitIds = talentBoard.traitIds || [];
            return traitIds.includes(this.id);
        });
        return linkedBoards.map((talentBoard) => talentBoard.id);
    }
}

export default TraitModel;
