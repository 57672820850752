import { createSlice } from "@reduxjs/toolkit";
import { getAllColors } from "../utils/assessmentUtils";
import { convertTimeStamp } from "../firebase/firebaseUtils";

const colors = getAllColors(100);

const initialState = {
    assessmentsOpen: false,
    roleProgress: {},
    pendingUpdates: [],
    unsavedProgress: {},
    requestDates: {},
    settings: {
        segmentInfo: {},
        snapshotHistory: [],
        snapshotId: null,
        allColors: colors,
        scaleColors: [],
        ratingScale: 4,
        enableSelfAssessment: false,
        schedulingMode: "manual",
        assessmentCycle: 365,
        lateThreshold: 14,
        lastSnapshotDate: null,
        thresholdDate: null,
        lateDate: null,
        gridThresholds: null,
    },
};

const assessmentsSlice = createSlice({
    name: "assessments",
    initialState,
    reducers: {
        SET_PENDING_ASSESSMENTS(state, action) {
            state.pendingUpdates = state.pendingUpdates.concat(action.payload);
        },
        SET_ASSESSMENT_SETTINGS(state, action) {
            if (!action.payload.assessmentsOpen) {
                state.employeeStatus = {};
            }
            state.assessmentsOpen = action.payload.assessmentsOpen;
            state.settings = { ...state.settings, ...action.payload };
            const thresholdDays = state.settings.assessmentCycle;
            const lateDays = state.settings.lateThreshold;
            const today = new Date();
            const thresholdDate = new Date();
            thresholdDate.setDate(today.getDate() - thresholdDays);
            state.settings.thresholdDate = convertTimeStamp(thresholdDate);
            const lateDate = new Date();
            lateDate.setDate(today.getDate() - (thresholdDays + lateDays));
            state.settings.lateDate = convertTimeStamp(lateDate);

            const ratingPoint = 99 / (state.settings.ratingScale - 1);
            let scaleColors = [];
            for (let i = 0; i <= 99; i += ratingPoint) {
                scaleColors.push(state.settings.allColors[Math.round(i)]);
            }
            state.settings.scaleColors = scaleColors;
            state.settings.ratingPoint = Math.round(ratingPoint);
        },
        SET_ASSESSMENT_REQUEST_DATES(state, action) {
            const newDates = action.payload;
            const prevDates = state.requestDates;
            Object.entries(prevDates).forEach(([roleId, date]) => {
                if (date !== newDates[roleId])
                    state.pendingUpdates = state.pendingUpdates.filter((entry) => entry !== roleId);
            });
            state.requestDates = { ...state.requestDates, ...newDates };
        },
        SAVE_ASSESSMENT_PROGRESS(state, action) {
            state.unsavedProgress = { ...state.unsavedProgress, ...action.payload };
        },
        CLEAR_ASSESSMENT_PROGRESS(state, action) {
            let newState = { ...state.unsavedProgress };
            delete newState[action.payload];
            state.unsavedProgress = newState;
        },
        RESET_ASSESSMENTS: (state) => (state = initialState),
    },
});

const { actions, reducer } = assessmentsSlice;

export const {
    SET_ASSESSMENT_REQUEST_DATES,
    SET_ASSESSMENT_SETTINGS,
    SET_PENDING_ASSESSMENTS,
    SAVE_ASSESSMENT_PROGRESS,
    CLEAR_ASSESSMENT_PROGRESS,
    RESET_ASSESSMENTS,
} = actions;

export default reducer;
