import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { sortArrayOfObjects } from "../../../utils/basicUtils";
import { getFieldLabel } from "../../../utils/snapshotUtils";
import "../picker.scss";
import FieldPicker from "./FieldPicker";

const SnapshotFieldPicker = (props) => {
    const {
        excludeUnrated,
        excludeAssessmentFields,
        excludeGlobalTraits,
        excludeTalentAreas,
        excludeTalentBoardRatings,
        excludeTalentBoardTraits,
        excludeGridPosition,
        onlyAxisFields,
    } = props;
    const traits = useSelector((state) => state.talent.traits);
    const talentAreas = useSelector((state) => state.talent.talentAreas);
    const talentBoards = useSelector((state) => state.talent.talentBoards);
    const customFields = useSelector((state) => state.workspace.customFields);
    const snapshots = useSelector((state) => state.talent.snapshots);
    const [optionGroups, setOptionGroups] = useState([]);


    useEffect(() => {
        // Removes fields that have no ratings (when excludeUnrated is true)
        function fieldHasRatings(field, id) {
            if (!excludeUnrated || !snapshots) return true;
            if (id) {
                const match = Object.values(snapshots).filter((snap) => {
                    const rating = snap[field] && snap[field][id];
                    return rating && !isNaN(rating);
                });
                return match.length > 0;
            } else {
                const match = Object.values(snapshots).filter((snap) => {
                    const rating = snap[field];
                    return rating && !isNaN(rating);
                });
                return match.length > 0;
            }
        }

        function createOptionGroup(items, labelField, collectionName, groupLabel) {
            let groupOptions = Object.values(items)
                .filter((item) => fieldHasRatings(collectionName, item.id))
                .map((item) => {
                    return {
                        label: item[labelField],
                        fieldId: collectionName,
                        fieldValue: item.id,
                        value: `${collectionName}-${item.id}`,
                    };
                });
            groupOptions = sortArrayOfObjects(groupOptions, "label");

            return { label: groupLabel, fieldId: collectionName, value: collectionName, options: groupOptions };
        }

        // Initialize the grouped options with the overall rating (always included)
        let newGroupedOptions = [
            { label: getFieldLabel({ fieldId: "overall" }), fieldId: "overall", value: "overall" },
        ];

        // Add other options based on props
        if (!excludeGridPosition) {
            newGroupedOptions.push({ label: "Grid Position", fieldId: "gridPosition", value: "gridPosition" });
        }
        if (!excludeAssessmentFields) {
            customFields
                .filter((field) => {
                    return field.category === "assessment" && field.enabled && (!onlyAxisFields || field.axis);
                })
                .forEach((field) => {
                    newGroupedOptions.push({ label: field.label, fieldId: field.id, value: field.id });
                });
        }
        // Sort before adding option groups
        newGroupedOptions = sortArrayOfObjects(newGroupedOptions, "label");

        if (!excludeTalentAreas) {
            newGroupedOptions.push(createOptionGroup(talentAreas, "talentAreaName", "talentAreas", "Talent Areas"));
        }
        if (!excludeGlobalTraits) {
            let globalTraits = Object.values(traits).filter((trait) => !trait.boardsOnly && !trait.managersOnly);
            newGroupedOptions.push(createOptionGroup(globalTraits, "traitName", "ratings", "Global Traits"));
        }
        if (!excludeTalentBoardRatings) {
            newGroupedOptions.push(createOptionGroup(talentBoards, "talentBoardName", "talentBoards", "Talent Boards"));
        }
        if (!excludeTalentBoardTraits) {
            let boardTraits = Object.values(traits).filter((trait) => trait.boardsOnly);
            newGroupedOptions.push(createOptionGroup(boardTraits, "traitName", "ratings", "Board Traits"));
        }

        setOptionGroups(newGroupedOptions);
    }, [
        excludeUnrated,
        excludeAssessmentFields,
        excludeGlobalTraits,
        excludeTalentAreas,
        excludeTalentBoardRatings,
        excludeTalentBoardTraits,
        excludeGridPosition,
        onlyAxisFields,
        traits,
        talentAreas,
        talentBoards,
        customFields,
        snapshots,
    ]);

    if (optionGroups.length === 0) return null;
    return (
        <div className="picker">
            <FieldPicker {...props} optionGroups={optionGroups} placeholder="Hidden" isClearable />
        </div>
    );
};

SnapshotFieldPicker.propTypes = {
    placeholder: PropTypes.string,
    excludeUnrated: PropTypes.bool,
    excludeAssessmentFields: PropTypes.bool,
    excludeGlobalTraits: PropTypes.bool,
    excludeTalentAreas: PropTypes.bool,
    excludeTalentBoardRatings: PropTypes.bool,
    excludeTalentBoardTraits: PropTypes.bool,
    excludeGridPosition: PropTypes.bool,
    onlyAxisFields: PropTypes.bool,
    selected: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

SnapshotFieldPicker.defaultProps = {
    placeholder: "Overall Rating",
    selected: {},
    onlyGlobalTraits: true,
    onBlur: () => {},
    onChange: () => {},
};

export default SnapshotFieldPicker;
